import React, { useEffect } from 'react'
import {removeCookie} from '../../../../Helper/Cokkies'
import { useNavigate } from "react-router-dom";
import { removeLocalStorage} from '../../../../Helper/LocalStorage'
function Logout() {
    const navigate=useNavigate()
    useEffect(() => {
        removeLocalStorage("alcUser");
        removeCookie("alcToken");
       return navigate("/allocation/login");
    }, [navigate])
  return (
    <div>Logout</div>
  )
}

export default Logout