import React from "react";
import Header from "../Components/Header/Header";
import Menu from "../Components/Sidebar/Menu";
import Footer from "../Components/Footer/Footer";
import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../../../Helper/Cokkies";
import { removeLocalStorage } from "../../../Helper/LocalStorage";

export default function AdminLayoutMain() {
  let token = getCookie("token")
  if(token === undefined){
    removeLocalStorage('user')
    return <Navigate to={'login'} />
  } 
  return (
    <div>
      {" "}
      <div className="wrapper">
        <Header></Header>
        <Menu />
        <div className="content-wrapper p-2">
        <Outlet></Outlet>
        </div>
        <Footer />
      </div>
    </div>
  );
}
