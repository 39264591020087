import React, { useEffect, useState } from 'react'
import { api } from "../../../../Helper/Data";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import axios from 'axios';
import moment from 'moment';
import { useLocation } from 'react-router-dom'

export default function PartyLog() {
  const location = useLocation()
  const pathname = location.pathname.split("/")
  const [pending, setPending] = useState(true);
  const [filterItems, setFilterItems] = useState([]);
  const user = getLocalStorage("alcUser");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortField: "createdAt", sortDirection: "desc" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>Log</strong>,
      selector: (row) => row.subject,
    },
    {
      name: <strong>Date</strong>,
      sortable: true,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "120px",
    },
    // {
    //   name: <strong>ACTION</strong>,
    //   selector: (row) => (<>
    //     <Tooltip title="View Logs">
    //       <Link to={"/allocation/view-logs"} state={row.party}>
    //         <IconButton>
    //           <LaunchIcon fontSize="small" color="success" />
    //         </IconButton>
    //       </Link>
    //     </Tooltip>
    //   </>
    //   ),
    // },
  ];

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-party-logs", { user: user, page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, id: pathname[pathname.length - 1], key: filterValue })
      .then(function (response) {
        console.log(response.data);
        response.data.activities.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setFilterItems(response.data.activities);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch])

  const updateSelectedRow = (e) => { }

  return (
    <div className="container">
      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  )
}
