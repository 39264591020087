import React from "react";

export default function ContactusCard() {
  return (
    <div className="bg-main text-white p-4 h-100">
      <h2 className="m-0">World Web Trade</h2>
      (Web Bright Infra Pvt. Ltd.)
      <h5 className="my-2">Add: 804, 8th Floor, DDA 2 Building, District Centre, Janakpuri, New Delhi - 110058, India</h5>
      <h3>Sales Inquiry:</h3>
      <h5>
        <i className="fas fa-phone-alt"></i> &nbsp; +91-8377007766
      </h5>
      <h5>
        <i className="fas fa-envelope"></i> &nbsp; inquiry@worldwebtrade.com
      </h5>
      <h5>
        <i className="fab fa-skype"></i> &nbsp; inquiry.worldwebtrade
      </h5>
      <h3>HR:</h3>
      <h5>
        <i className="fas fa-phone-alt"></i> &nbsp; 011-42351066
      </h5>
      <h5>
        <i className="fas fa-envelope"></i> &nbsp; hr@worlwebtrade.com
      </h5>
    </div>
  );
}
