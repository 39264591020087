import { getCookie } from "../../Helper/Cokkies";
import jwt_decode from "jwt-decode";
import Swal from 'sweetalert2'
import style from './style.module.css'

export function formValidation(data) {
  let isValid = true;
  data.forEach(el => {
    if (el === "" || el === undefined || el === false) {
      isValid = false;
    }
  });
  if (isValid) {
    return true;
  } else {
    return false;
  }
}

export function userValidate(data, tokenfor) {
  const token = getCookie(tokenfor);
  if (token !== undefined) {
    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    let isValid = false;

    data.forEach(el => {
      if (el === role) {
        isValid = true;
      }
    });
    if (isValid) {
      return true;
    } else {
      return false;
    }
  }
}

export function alcUserValidate(data) {
  const token = getCookie("alcToken");
  if (token !== undefined) {
    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    let isValid = false;

    data.forEach(el => {
      if (el === role) {
        isValid = true;
      }
    });
    if (isValid) {
      return true;
    } else {
      return false;
    }
  }
}

export function SweetAlert(data) {

  var Toast = Swal.mixin({
    
    background: "rgb(0 47 105)",
    showConfirmButton: false,
    timerProgressBar: true,
    showCloseButton: true,
    color:"white",
    customClass: { icon: style.iconStyle, closeButton: style.closeButtonStyle },
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
      // Swal.showLoading()
    }
  });

  Toast.fire(data)
}


