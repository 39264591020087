import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import style from './style.module.css'
import { api } from '../../../Helper/Data';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { CardHeader, Container } from '@mui/material';
import moment from 'moment'
import BlogsItem from './BlogsItem';

export default function BlogDetails(props) {
  const [blogData, setBlogData] = React.useState({})
  const [goToBlog, setbGoToBlog] = React.useState({ preBlog: "", nextBlog: "" })
  const { blogLink } = useParams()
  const [latestBlogs, setLatestBlogs] = React.useState([])
  const [tableOptions, setTableOptions] = React.useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "blogDate" });

  React.useEffect(() => {
    axios
      .post(api.url + "/get-blog-details", { currentBlog: blogLink })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          setBlogData(response.data.blog[0])
          setbGoToBlog({ preBlog: response.data.previous, nextBlog: response.data.next })
        }
      })
      .catch(function (error) {
        console.log(error);
      });

      axios
      .post(api.url + "/get-blogs", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          setLatestBlogs(response.data.blogs.filter((el, i)=>{
            return i < 4
          }))
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [blogLink, tableOptions])

  return (
    <Container>
      <div className="row p-4">
        <div className="col-md-8">
          {blogData._id ?
            <Card >
            <CardHeader
              title={<h1 style={{fontSize:"20px", margin:"0px"}}>{blogData.blogTitle}</h1>}
              subheader={<div className='float-right'>Date : {moment(blogData.blogDate).format("DD-MM-YYYY")}</div>}
            />
            <CardMedia
              component="img"
              image={api.blogImg + blogData.blogImages[0]}
              alt={blogData.blogTitle}
            />
            <CardContent>
              <Typography variant="body2" color="text.secondary">
              <p className={style.blogContent} dangerouslySetInnerHTML={{ __html: blogData.blogDesc }} />
              </Typography>
            </CardContent>
          </Card> : "loading"}
        </div>
        <div className="col-md-4">
          <div className='bg-white rounded shadow p-2' >
            <h5>Latest Blogs</h5>
            {latestBlogs.length ? latestBlogs.map((el, i) => {
                return <div className='p-2' key={i}>
                  <BlogsItem data={el} />
                </div> 
              }) : "Loading..."}
          </div>
        </div>
      </div>
    </Container>
  );
}