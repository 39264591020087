import React from "react";
import { Link } from "react-router-dom";
import { api } from "../../../../Helper/Data";
import style from './style.module.css'

export default function Trandingcard(props) {
  return (
    <Link to={"/search-products/" + props.data.productName.toLowerCase()} state={props.data.productName} >

      {/* <img src={props.data.imageUrl} style={{width:"100%", height:"100%"}} alt="tending products" />
          <span className="trend-text">
            {props.data.productName.toUpperCase()}
          </span> */}

      <div className={style.pageBannerSection}>
        <img src={api.trendProduct + props.data.imageUrl[0]} style={{ width: "100%", height: "100%" }} alt="tending products" />
        <span className="trend-text">
          {props.data.productName.toUpperCase()}
        </span>
      </div>
    </Link>
  );
}
