export const adminNav = [
  {
    title: "Dashboard",
    icon: "fas fa-th",
    url: "dashboard"
  },
  {
    type: "hasTreeview",
    title: "Manage Details",
    icon: "fas fa-cogs",
    linkItems: [
      { subTitle: "User Details", icon: "far fa-circle nav-icon", url: "user-details" }
    ],
  },
  // {
  //   type: "hasTreeview",
  //   title: "Operators",
  //   icon: "fas fa-user-tie",
  //   linkItems: [
  //     { subTitle: "View List", icon: "far fa-circle nav-icon", url: "operator-list" },
  //     { subTitle: "Add Operator", icon: "far fa-circle nav-icon", url: "add-operator" },
  //   ],
  // },
  {
    type: "hasTreeview",
    title: "Users",
    icon: "fas fa-user",
    linkItems: [
      { subTitle: "View List", icon: "far fa-circle nav-icon", url: "view-list" },
      { subTitle: "Add User", icon: "far fa-circle nav-icon", url: "user-add" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Trust Certificate",
    icon: "fas fa-user",
    linkItems: [
      { subTitle: "Add Certificate", icon: "far fa-circle nav-icon", url: "add-vcertificate" },
      { subTitle: "Certificates List", icon: "far fa-circle nav-icon", url: "vcertificates-list" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Invoice",
    icon: "fas fa-user",
    linkItems: [
      { subTitle: "Invoice List", icon: "far fa-circle nav-icon", url: "invoice-list" },
      { subTitle: "Generate Invoice", icon: "far fa-circle nav-icon", url: "generate-invoice" },
    ],
  },
  // {
  //   type: "hasTreeview",
  //   title: "User Role",
  //   icon: "fas fa-tachometer-alt",
  //   linkItems: [
  //     { subTitle: "User Role", icon: "far fa-circle nav-icon", url: "user-role" },
  //   ],
  // },
  {
    type: "hasTreeview",
    title: "Categories",
    icon: "fas fa-sitemap",
    linkItems: [
      { subTitle: "Add Category", icon: "far fa-circle nav-icon", url: "add-category" },
      { subTitle: "Add Sub-Category", icon: "far fa-circle nav-icon", url: "add-sub-category" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Banners",
    icon: "fas fa-chalkboard",
    linkItems: [
      { subTitle: "Add Banner", icon: "far fa-circle nav-icon", url: "add-banner" },
      { subTitle: "Banner List", icon: "far fa-circle nav-icon", url: "banner-list" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Products",
    icon: "fas fa-box-open",
    linkItems: [
      { subTitle: "Products List", icon: "far fa-circle nav-icon", url: "products-list" },
      { subTitle: "Add Product", icon: "far fa-circle nav-icon", url: "add-product" },
      { subTitle: "Trending Product", icon: "far fa-circle nav-icon", url: "trending-product" }
    ],
  },
  {
    type: "hasTreeview",
    title: "Leads",
    icon: "fas fa-tasks",
    linkItems: [
      { subTitle: "Buy leads list", icon: "far fa-circle nav-icon", url: "leads-list" },
      { subTitle: "Peding List", icon: "far fa-circle nav-icon", url: "pending-lead" },
      { subTitle: "Add buy leads", icon: "far fa-circle nav-icon", url: "add-lead" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Testimonial",
    icon: "far fa-comment-dots",
    linkItems: [
      { subTitle: "Testimonial list", icon: "far fa-circle nav-icon", url: "testimonial-list" },
      { subTitle: "Add Testimonial", icon: "far fa-circle nav-icon", url: "add-testimonial" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Queries & Requests",
    icon: "far fa-question-circle",
    linkItems: [
      { subTitle: "Product Queries", icon: "far fa-circle nav-icon", url: "product-queries" },
      { subTitle: "Call Back Requests", icon: "far fa-circle nav-icon", url: "call-back-requests" },
      { subTitle: "User Requirements", icon: "far fa-circle nav-icon", url: "user-requirements" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Blog",
    icon: "fas fa-blog",
    linkItems: [
      { subTitle: "Add Blog", icon: "far fa-circle nav-icon", url: "add-blog" },
      { subTitle: "Blogs list", icon: "far fa-circle nav-icon", url: "blogs-list" },
    ],
  },
];

export const hrNav = [
  {
    title: "Dashboard",
    icon: "fas fa-th",
    url: "dashboard"
  },
  {
    type: "hasTreeview",
    title: "Manage Details",
    icon: "fas fa-cogs",
    linkItems: [
      { subTitle: "User Details", icon: "far fa-circle nav-icon", url: "user-details" }
    ],
  },
  // {
  //   type: "hasTreeview",
  //   title: "Operators",
  //   icon: "fas fa-user-tie",
  //   linkItems: [
  //     { subTitle: "View List", icon: "far fa-circle nav-icon", url: "operator-list" },
  //     { subTitle: "Add Operator", icon: "far fa-circle nav-icon", url: "add-operator" },
  //   ],
  // },
  {
    type: "hasTreeview",
    title: "Users",
    icon: "fas fa-user",
    linkItems: [
      { subTitle: "View List", icon: "far fa-circle nav-icon", url: "view-list" },
      { subTitle: "Add User", icon: "far fa-circle nav-icon", url: "user-add" },
    ],
  },
  // {
  //   type: "hasTreeview",
  //   title: "Categories",
  //   icon: "fas fa-sitemap",
  //   linkItems: [
  //     { subTitle: "Add Category", icon: "far fa-circle nav-icon", url: "add-category" },
  //     { subTitle: "Add Sub-Category", icon: "far fa-circle nav-icon", url: "add-sub-category" },
  //   ],
  // },
  {
    type: "hasTreeview",
    title: "Banners",
    icon: "fas fa-chalkboard",
    linkItems: [
      { subTitle: "Add Banner", icon: "far fa-circle nav-icon", url: "add-banner" },
      { subTitle: "Banner List", icon: "far fa-circle nav-icon", url: "banner-list" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Products",
    icon: "fas fa-box-open",
    linkItems: [
      { subTitle: "Products List", icon: "far fa-circle nav-icon", url: "products-list" },
      { subTitle: "Add Product", icon: "far fa-circle nav-icon", url: "add-product" },
      { subTitle: "Trending Product", icon: "far fa-circle nav-icon", url: "trending-product" }
    ],
  },
  {
    type: "hasTreeview",
    title: "Leads",
    icon: "fas fa-tasks",
    linkItems: [
      { subTitle: "Buy leads list", icon: "far fa-circle nav-icon", url: "leads-list" },
      { subTitle: "Peding List", icon: "far fa-circle nav-icon", url: "pending-lead" },
      { subTitle: "Add buy leads", icon: "far fa-circle nav-icon", url: "add-lead" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Testimonial",
    icon: "far fa-comment-dots",
    linkItems: [
      { subTitle: "Testimonial list", icon: "far fa-circle nav-icon", url: "testimonial-list" },
      { subTitle: "Add Testimonial", icon: "far fa-circle nav-icon", url: "add-testimonial" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Queries & Requests",
    icon: "far fa-question-circle",
    linkItems: [
      { subTitle: "Product Queries", icon: "far fa-circle nav-icon", url: "product-queries" },
      { subTitle: "Call Back Requests", icon: "far fa-circle nav-icon", url: "call-back-requests" },
      { subTitle: "User Requirements", icon: "far fa-circle nav-icon", url: "user-requirements" },
    ],
  },
];

export const operatorNav = [
  {
    title: "Dashboard",
    icon: "fas fa-th",
    url: "dashboard"
  },
  {
    type: "hasTreeview",
    title: "Manage Details",
    icon: "fas fa-cogs",
    linkItems: [
      { subTitle: "User Details", icon: "far fa-circle nav-icon", url: "user-details" },
      { subTitle: "Profile Details", icon: "far fa-circle nav-icon", url: "operator-details" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Users",
    icon: "fas fa-user",
    linkItems: [
      { subTitle: "View List", icon: "far fa-circle nav-icon", url: "view-list" },
      { subTitle: "Add User", icon: "far fa-circle nav-icon", url: "user-add" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Categories",
    icon: "fas fa-sitemap",
    linkItems: [
      { subTitle: "Add Category", icon: "far fa-circle nav-icon", url: "add-category" },
      { subTitle: "Add Sub-Category", icon: "far fa-circle nav-icon", url: "add-sub-category" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Banners",
    icon: "fas fa-chalkboard",
    linkItems: [
      { subTitle: "Add Banner", icon: "far fa-circle nav-icon", url: "add-banner" },
      { subTitle: "Banner List", icon: "far fa-circle nav-icon", url: "banner-list" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Products",
    icon: "fas fa-box-open",
    linkItems: [
      { subTitle: "Products List", icon: "far fa-circle nav-icon", url: "products-list" },
      { subTitle: "Add Product", icon: "far fa-circle nav-icon", url: "add-product" }
    ],
  },
  {
    type: "hasTreeview",
    title: "Leads",
    icon: "fas fa-tasks",
    linkItems: [
      { subTitle: "Buy leads list", icon: "far fa-circle nav-icon", url: "leads-list" },
      { subTitle: "Add buy leads", icon: "far fa-circle nav-icon", url: "add-lead" },
    ],
  },
];

export const userNav = [
  {
    title: "Dashboard",
    icon: "fas fa-th",
    url: "dashboard"
  },
  {
    type: "hasTreeview",
    title: "Manage Details",
    icon: "fas fa-cogs",
    linkItems: [
      { subTitle: "User Details", icon: "far fa-circle nav-icon", url: "user-details" },
      { subTitle: "Company Details", icon: "far fa-circle nav-icon", url: "company-details" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Banners",
    icon: "fas fa-chalkboard",
    linkItems: [
      { subTitle: "Add Banner", icon: "far fa-circle nav-icon", url: "add-banner" },
      { subTitle: "Banner List", icon: "far fa-circle nav-icon", url: "banner-list" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Products",
    icon: "fas fa-box-open",
    linkItems: [
      { subTitle: "Products List", icon: "far fa-circle nav-icon", url: "products-list" },
      { subTitle: "Add Product", icon: "far fa-circle nav-icon", url: "add-product" }
    ],
  },
  {
    type: "hasTreeview",
    title: "Leads",
    icon: "fas fa-tasks",
    linkItems: [
      { subTitle: "My Leads", icon: "far fa-circle nav-icon", url: "purchased-lead" },
      { subTitle: "Buy leads list", icon: "far fa-circle nav-icon", url: "leads-list" },
    ],
  },
  {
    type: "hasTreeview",
    title: "Photo Gallery",
    icon: "far fa-images",
    linkItems: [
      { subTitle: "Add Image", icon: "far fa-circle nav-icon", url: "add-gallery-image" },
      { subTitle: "View Gallery", icon: "far fa-circle nav-icon", url: "product-gallery" },
    ],
  },
];
