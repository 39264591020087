import { Container } from "@mui/material";
import React from "react";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import PageHeading from "../../Components/PageHeading/PageHeading";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";

export default function Termcondition() {
  return (
    <div>
      <PageHeading page={"termConditions"}></PageHeading>
      <div className="container">
        <BreadCrumb primary={""} secondary={""} current={"Term & Conditions"}></BreadCrumb>
        <Container className={style.termContainer}>
          <p>
            <Link to="/" className="font-weight-bold">
              www.worldwebtrade.com
            </Link>{" "}
            is an enhanced web based platform meant for the development and enhancement of all categories of businesses. This is done in two ways;
            searching for suitable companies and then interacting with them. Web Bright Infra Pvt Ltd is the owner of the website.
          </p>
          <p>
            The use of the website in part and complete is subject to the acceptance of the website’s terms and conditions. Everyone willing to use the
            website’s services should agree to the below mentioned terms and conditions. Only those companies, firms or individuals can use the services
            that are eligible to create binding contracts that are legal under the Indian laws. While the company Web Bright Infra Pvt Ltd has the full
            rights over the amendment of those terms and conditions before publishing them on the site. Once they are published on the site, they may be
            deemed approved and effective from the date posted.
          </p>
          <h6>Definition Clause</h6>
          <ol>
            <li>
              <p>
                <strong>User:</strong> As per this agreement of worldwebtrade.com, a user is one who has acknowledged the use of the services by the
                website. It can be an individual or a company.
              </p>
            </li>
            <li>
              <p>
                <strong>Un-authorized Member or User:</strong> All users have to be registered under the terms and conditions of the company
                worldwebtrade.com. Someone who is not and willing to use the services is considered to be an un-authorized member or user.
              </p>
            </li>
            <li>
              <p>
                <strong>
                  Free Services: An individual or a firm that is willing to enlist his or their business without availing any other services of the
                  website is enjoying the free services.
                </strong>
              </p>
            </li>
            <li>
              <p>
                <strong>Paid Services: </strong> While most of the services are free for the users, there are certain tasks and services that are paid.
                A user who pays for those services is said to be enjoying the paid services. When enjoying the paid services, one has to be in agreement
                with the terms and conditions laid down by the website.
              </p>
            </li>

            <li>
              <p>
                <strong>When an individual or a company agrees to use the site and its services, the following statements should be adhered to:</strong>
              </p>
              <p>(a) that all the information provided is true and authentic. as per the best knowledge of the provider.</p>
              <p>(b) that it is the responsibility of the user to check whether the information provided is accurate.</p>{" "}
              <p>(c) that the user is not less than 18 years of age,</p>{" "}
              <p>(d) that no violation of any of the clauses, contents mentioned on the site takes place.</p>
              <p>
                (e) that the user’s profile can undergo termination as well as deletion, if found inappropriate or breaching of any contract terms and
                conditions.
              </p>
              <p>
                (f) that the user won’t hold the site responsible for the loss of date, any unforeseen event that may occur, as a result of the use of
                the site and the services. Or due to negligence of the user.
              </p>
            </li>

            <li>
              <p>
                The sites shall be used by the users in all lawful manners without any breach of law of the land. Content used should not be offensive
                or transmit a message that is derogatory in nature.
              </p>
            </li>

            <li>
              <p>
                Intellectual Property Rights can’t be tracked by the site. If a user posts or advertises products that have copyright issues can be
                solved once that is brought to light. through mutual means. The site is against the endorsement and advocacy of the products that may be
                subject to copyright.
              </p>
            </li>
            <li>
              <p>
                Contents that lack in verification like copyright, acknowledgement, knowledge and contraventions may lead to issues. The site is not
                responsible for such contents posted by the users.
              </p>
            </li>
            <li>
              <p>
                If any copyright or infringement issue crops up, the user shall be deleted permanently from the classified listing. It will be done
                under the Information Technology Act 2000 and no discussion pertaining to the action will occur.
              </p>
            </li>
            <li>
              <p>
                In case of a conflict between the terms of the paid services and the provisions of the agreement, it is the website
                www.worldwebtrade.com that has the right to take any action for the solution. If the problem still persists, the paid services will
                continue to prevail.
              </p>
            </li>
            <li>
              <p>
                The terms and conditions of the website can be amended by the worldwebtrade.com. Once they have been amended and approved, they will be
                posted on the site. There is no other way of modifying the terms unless in writing.
              </p>
            </li>
            <li>
              <p>
                One who seeks membership in the website can seek for it. Once the activation request is submitted by the user, it is activated in two
                business days. The period will depend on the activation duration requested for.
              </p>
            </li>
            <li>
              <p>
                Users or members should learn that the servers are third party owned which means that the site won’t be liable for any damage to your
                property or any hacking that may arise. To be on the safer side, members can back their messages up offline and save it in a secure
                manner. The user must be responsible for security and safety of the information , world web trade takes no responsibility in any manner.
                .
              </p>
            </li>
            <li>
              <p>The charges of membership are subject to change and alteration. They are also at the discretion of the site owners.</p>
            </li>
            <li>
              <p>The company worldwebtrade.com can deactivate and go offline any time. The users may not receive any clarification on the action.</p>
            </li>
            <li>
              <p>
                The mode of communication to the users can be specified during the registration of the membership. It can be through calls, SMS, emails
                etc.
              </p>
            </li>
          </ol>

          <h6>Users Generally</h6>
          <ol>
            <li>
              <p>
                The site can be used by the users for personal use only. No property of the site can be used by the users or members for reselling or
                redistribution purpose. They also can’t be used for advertisement of a business that is in line with worldwebtrade.com.
              </p>
            </li>
            <li>
              <p>
                Not all contents displayed on the website are by worldwebtrade.com. Some of the contents are posted and displayed as they had been put
                on the site by other parties, users or third parties. worldwebtrade.com is not responsible for incorrect information of erroneous
                content on display on the site.
              </p>
            </li>
            <li>
              <p>
                Users may have access to third party contents in the form of hyperlinks to those websites. Before using the contents and services of the
                third parties, worldwebtrade.com recommends the reading and understanding of the terms and conditions by those third party websites.
                worldwebtrade.com doesn’t have any rights and regulations over those sites and therefore, no product or service malfunctioning will be
                the site’s responsibility.
              </p>
            </li>
            <li>
              <p>
                Different users can have different access to various sites along with the features by worldwebtrade.com. The specialised IT department
                of world web trade also has the right to tweak the features without any prior information for the upgrading software . No request for
                modification or change is encouraged or will be entertained.
              </p>
            </li>

            <li>
              <p>
                Users should ensure that the integrity is maintained while using worldwebtrade.com site. Any infringement of the network or computer
                system used by the site will be taken seriously and actioned instantly.
              </p>
            </li>

            <li>
              <p>
                It is the duty of every potential user to read the privacy policy of worldwebtrade.com carefully. Once the user has read and understood
                the terms and conditions should be agreed upon. This privacy policy also states that worldwebtrade.com has the right to change and
                modify the privacy policy any time without any prior notice.
              </p>
            </li>

            <li>
              <p>
                It is repeatedly mentioned that a user, not paying heed to the intellectual property and copyright issue will be terminated with no
                further explanation. Such conduct by the users can deteriorate the image of worldwebtrade.com and its associates and affiliates.
              </p>
            </li>
            <li>
              <p>Membership charges are subject to revision and are at the discretion of the worldwebtrade.com</p>
            </li>
            <li>
              <p>
                User to be aware that the Web Servers belongs to third party and the continuation to access the services are subject to availability as
                the same is interrupted at times by technical problems /hackers etc. and worldwebtrade.com is not responsible for the
                costs/damages/extension of activation period etc. for the same in any manner under any circumstances. The members are advised to spool
                the messages etc. offline and save them elsewhere in order to avoid any hacking or technical problem.
              </p>
            </li>
          </ol>
          <h6>Registered Users</h6>
          <ol>
            <li>
              <p>
                Anyone who is willing to register with worldwebtrade.com with personal details provided is a registered user. worldwebtrade.com will
                provide each of these registered users with a unique user ID and a password. These credentials can be used to log in to the website.
              </p>
            </li>
            <li>
              <p>
                A web based email account will be provided to each of the registered users. The responsibility of the email exchanges will be borne by
                the user.
              </p>
            </li>
            <li>
              <p>
                <strong>In case of the registered user being a business organisation:</strong>
              </p>
              <p>(a) ensure that the organisation is authorized to bind with the agreement.</p>
              <p>(b) correct business address is provided.</p>
              <p>(c) all other information that has been made available to worldwebtrade.com is accurate and true.</p>
            </li>
            <li>
              <p>
                A registered user authorizes worldwebtrade.com to use the personal data in the database of the supplier/manufacturer/global buyer of
                worldwebtrade.com. He or she also authorizes the site to be able to share the information with other users too
              </p>
            </li>

            <li>
              <p>
                <strong>A registered user can be terminated within 24 hours in the following situations:</strong>
              </p>
              <p>(a) if the information provided is inaccurate.</p>
              <p>(b) if there is any breach of the agreement signed.</p>
              <p>(c) if the user has the potential of causing loss to worldwebtrade.com or its affiliates.</p>
            </li>

            <li>
              <p>
                The email account provided should not be used for any other purpose but business purpose only. Spam of any kind or offensive email
                content will not be tolerated at any cost.
              </p>
            </li>

            <li>
              <p>
                If a registered user is willing to withdraw the account, the account is non transferable unless a written consent is obtained from
                worldwebtrade.com.
              </p>
            </li>
            <li>
              <p>
                worldwebtrade.com may or may not transfer the user ID and password to some other users; be it for any reason. Once you have become a
                registered user of worldwebtrade.com, you authorize the site to use your details in all its market places without restricting to
                directory, catalogs, listing etc. in the database.
              </p>
            </li>
            <li>
              <p>
                In case of online promotion by worldwebtrade.com, your personal details will be published in every forum and you will have no objection
                to it.
              </p>
            </li>
            <li>
              <p>
                The intellectual property of the registered users can be used by worldwebtrade.com with respect to the requirements or leads posted.
              </p>
            </li>
            <li>
              <p>
                URLs that are provided by worldwebtrade.com can be revised, changed or edited without any prior notice. Any damage caused whether direct
                or indirect will not be the site’s responsibility.
              </p>
            </li>
          </ol>
          <h6>Users who Post information on worldwebtrade.com</h6>
          <ol>
            <li>
              <p>In order to post any information, you need to be a registered user.</p>
            </li>
            <li>
              <p>
                The users and worldwebtrade.com are not in any form of business relationship or sales relationship. Any damage caused by the post on the
                site will not be directed towards anyone associated with worldwebtrade.com or the site itself.
              </p>
            </li>
            <li>
              <p>Users who wish to post on the site should ensure the following:</p>
              <p>(a) all the information provided is true and accurate.</p>
              <p>(b) information once provided should be updated and amended as and when required without any fail.</p>
            </li>
            <li>
              <p>
                Any material or posts on the site, a user has to have all the required trademarks. Copyright, material rights etc. for no damage to be
                caused to the site in future.
              </p>
            </li>

            <li>
              <p>
                <strong>Each of the users will ascertain the following:</strong>
              </p>
              <p>(a) no information provided is fraudulent and there is no attempt of sale of services or items which are not abiding by the law.</p>
              <p>(b) no post should be published which discriminates against a race, community, culture, individuality etc.</p>
              <p>(c) no solicitation of business activity that is in competition with the site.</p>
            </li>

            <li>
              <p>
                Any material on the site found to be breaching the terms and condition of the site will be removed by worldwebtrade.com and the sites
                will entertain no discussion on the similar actions. The site may also terminate an account of a user if found involved in an unlawful
                act that may be harmful to the operation of the site.
              </p>
            </li>

            <li>
              <p>
                Business enquiries through phone calls, SMS and Emails are encouraged by worldwebtrade.com and the site will not be involved in the
                business communication between the two parties.
              </p>
            </li>
            <li>
              <p>
                worldwebtrade.com does not exercise any control over its Users for sending business enquiries to the Registered members and will not be
                involved in any scrutiny of the same. worldwebtrade.com does not represent the seller nor the buyer in specific transactions and does
                not charge any commissions from completing any transactions. worldwebtrade.com does not control and is not liable to or responsible for
                the quality, safety, lawfulness or availability of the products or services offered for sale on the Site or the ability of the suppliers
                to complete a sale or the ability of buyers to complete a purchase.
              </p>
            </li>
          </ol>
          <h6>Transaction between buyers and suppliers</h6>
          <ol>
            <li>
              <p>
                worldwebtrade.com is a platform that can be used by buyers and products and services’ suppliers for the exchange of information. It
                doesn’t charge any form of fee or commission for playing the role.
              </p>
            </li>
            <li>
              <p>
                The number of fraud users can occur irrespective of the site’s strict and stringent methods of identifying such users. worldwebtrade.com
                advises all its users to use the required tools to verify the information and also make use of common sense to identify such fake people
                or entities.
              </p>
            </li>
            <li>
              <p>
                The transaction between the users and suppliers is solely at their discretion. Any damage caused will be borne by the two parties and
                not the site. The damage can be pertaining to the manufacture, offer, display, import, supply etc.
              </p>
            </li>
            <li>
              <p>None but the users are responsible for fulfilling the terms and conditions of the transactions happening within the site.</p>
            </li>

            <li>
              <p>worldwebtrade.com will not be held responsible for any fraud activity happening under the veil of transaction.</p>
            </li>
          </ol>
          <h6>A user can avail the features of worldwebtrade.com as they are available on the site.</h6>
          <ol>
            <li>
              <p>
                Any form of warranty, accuracy, reliability, quality, standard etc. claimed by the user’s post will not be borne by worldwebtrade.com.
                It is the user who is answerable and held responsible for.
              </p>
            </li>
            <li>
              <p>
                Any material that a user downloads from the site is done at the risk of the user. The site is not responsible, in any way to bear the
                consequences as a result.
              </p>
            </li>
            <li>
              <p>
                Any form of delay in offering the services or failure to deliver will not hold worldwebtrade.com as the site is only a medium to connect
                you to the sellers. It is the users who are to be responsible for such acts.
              </p>
            </li>
            <li>
              <p>
                Any type of consequence that may arise, as a result of the act of the users will be borne by the users and not worldwebtrade.com. It can
                be an act pertaining to the products and services of any miscommunication.
              </p>
            </li>

            <li>
              <p>
                The users are advised to be careful in disclosing personal information to anyone seeking for it. A proper verification must be run in
                order to be sure of providing such information.
              </p>
            </li>
            <li>
              <p>
                Fee and paid features of the site are available only on the desktop version. No request will be entertained for the incorporation of
                such feature on the mobile version.
              </p>
            </li>
            <li>
              <p>worldwebtrade.com will not be responsible for any form of damage caused owing to the acts of the users.</p>
            </li>
            <li>
              <p>
                The users with worldwebtrade.com will have to materialize the business at their own levels. worldwebtrade.com will not be involved in
                the act.
              </p>
            </li>
            <li>
              <p>
                worldwebtrade.com will make available the listing of the products which are pharmaceutical related. Direct sales or purchases are not
                entertained by the site. The users are not authorized to post any information pertaining to the medicines, chemicals etc. as per the
                Cosmetics Act, 1940. The site can’t be held responsible for any transaction that may occur.
              </p>
            </li>
          </ol>

          <h6>The use or inability to use the site</h6>
          <p>
            A user may not be able to use the site as a result of any defect or damage arising out of goods or services by a user. In case of violation
            of third party rights too, a user won’t be able to use the site. Unauthorized access to third party properties etc. may result in the
            inability to use the site.
          </p>

          <h6>Intellectual Property rights</h6>
          <ol>
            <li>
              <p>
                Logos, registered marks, trademarks, etc. that are visible on the site are properties that are owned by the site or its associates or
                affiliates. All the rights are therefore vested on the site of the associate and affiliates.
              </p>
            </li>
            <li>
              <p>
                The use of the marks and logos by the site is strictly prohibited. Unable to adhere to this may lead to a legal offence resulting in
                civil and criminal action.
              </p>
            </li>
          </ol>

          <h6>Website Designing Services</h6>
          <p>
            Our membership packages come with website designing services. There is no additional charge for the maintenance of these sites. Technical
            errors arising out of any reason should not lead to legal problems.
          </p>

          <h6>Validity of Website Designing Services</h6>
          <p>
            Initially, we will call and email you for the details of the website development. In case you fail to answer, we will send your letters
            reminding of the requirements. It is after our second reminding letter that we shall terminate the website designing services of you.
          </p>

          <h6>Development of Website</h6>
          <p>
            We believe in customer satisfaction and therefore like to keep you in loop for every web development. In case of any doubt and query, we
            will try to contact you. We will send your reminder letters. After the second reminding letter too, if we don’t get any response, we will
            develop the website as per our standard format.
          </p>
          <h6>Hosting of Website</h6>
          <p>
            Once the website is deemed ready, we will send a temporary link to the clients. We wait for the next five business days to receive an email
            seeking modification or change. If we don’t receive any email or response, we will consider that to be approved and continue thereafter. The
            said number of days can undergo a change if it is requested earlier in writing.
          </p>

          <h6>Payment and Refund Issue</h6>
          <p>
            An order placed instantly will be sent for processing at the same time. Any change in the information is not a strong reason for a refund.
            Disclaimer: Payments for all the services of worldwebtrade.com should be made to "Web Bright Infra Pvt. Ltd”. No individual or person is
            authorized to collect payment in any form other than this method. worldwebtrade.com shall not be held responsible for payments made to
            someone else or through any other form of payment by user.
          </p>

          <h6>Matter of Disputes and Jurisdictional Aspects</h6>
          <p>Any kind of dispute arising out of worldwebtrade.com (Web Bright Infra Pvt Ltd) and its users will be dealt in New Delhi only.</p>

          <h6>Governing Law and Jurisdiction</h6>
          <p>
            Any form of disagreement and dispute that may arise as a result of incidental use, with worldwebtrade.com will be governed by the mediator
            as solely appointed by world web trade and the place for mediation will be New Delhi. If for any reasons, any part of the provision of the
            terms and services are deemed invalid, the remaining which is valid will be put to use.
          </p>
        </Container>
      </div>
    </div >
  );
}
