import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { api } from "../../../../Helper/Data";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import ProductQuery from "Modules/Public/Components/ProductQuery/ProductQuiry";
import Slider from "react-slick";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import style from "./style.module.css";
import Skeleton from '@mui/material/Skeleton';
import axios from "axios";

function ProductDetails() {
  const {productLink, companyLink} = useParams()
  const userId = getLocalStorage("user");
  const countries = useSelector((state) => state.CountriesList.countries);
  const [product, setProduct] = useState({});
  const location = useLocation();
  const sliderCarousel = useRef();

  useEffect(() => {
    // document.title = location.state.productName + " ( " + location.state.additionalLabel + " ) | World Web Trade";
    axios
      .post(api.url + "/getDataForProduct", { productLink: productLink, companyLink:companyLink })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          setProduct(response.data.products[0])
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [companyLink, location, productLink]);

  return (
    <div className="container py-3">
      {product.productName ? (
        <BreadCrumb primary={{ url: "/product-categories", text: "Product" }} current={product.productName + " (" + product.category + " )"} />
      ) : (
        <Skeleton animation="wave" height={70} />
      )}

      <div className="row">
        <div className="col-md-3 py-3">
          <RequirementForm data={countries} small={"sm"} />
        </div>
        <div className="col-md-9">
          <div className="row p-3">
            <div className="col-md-5">
              <div className={style.bannerContainer}>
                {product.productImages && product.productImages.length > 1 ? (
                  <div>
                    {" "}
                    <button
                      className={style.preBtn}
                      onClick={() => {
                        sliderCarousel.current.slickPrev();
                      }}
                    >
                      <i className="fas fa-chevron-circle-left"></i>
                    </button>
                    <button
                      className={style.nextBtn}
                      onClick={() => {
                        sliderCarousel.current.slickNext();
                      }}
                    >
                      <i className="fas fa-chevron-circle-right"></i>
                    </button>
                  </div>
                ) : (
                  ""
                )}

                <Slider dots={true} arrows={false} speed={500} ref={(slider) => (sliderCarousel.current = slider)} className={style.sliderContainer}>
                  {product.productImages
                    ? product.productImages.map((el, i) => {
                      return (
                        <div key={i}>
                          <div className={style.productItem}>
                            <img src={api.productImg + el} alt={"ProductImage"} />
                          </div>
                        </div>
                      );
                    })
                    : <Skeleton animation="wave" height={300} />}
                </Slider>
              </div>
            </div>
            <div className="col-md-7">
              {product.productImages ? (
                <div>
                  <h5 className={style.detailsText + " font-weight-bold"}> {product.productName}</h5>
                  <p className={style.detailsText}>
                    {" "}
                    <strong>{product.additionalLabel}</strong>
                  </p>
                  <p className={style.detailsText}>
                    <small>
                      {" "}
                      <span className={style.strongText}>Origin :</span> {product.company.country}{" "}
                    </small>{" "}
                    <img style={{ height: "10px" }} src={product.company.countryFlag} alt="" />
                  </p>
                  <p className={style.detailsText}>
                    <small>
                      {" "}
                      <span className={style.strongText}>Location :</span> {product.company.location}
                    </small>
                  </p>
                  <p className={style.detailsText}>
                    <small style={{ letterSpacing: "1px", textAlign: "justify" }}>
                      {" "}
                      <span className={style.strongText}>Details :</span> {product.productDetails}
                    </small>
                  </p>
                  {userId && userId.id === product.company.user._id ? "" : <ProductQuery data={product} />}
                </div>
              ) : (
                <Skeleton animation="wave" height={300} />
              )}
            </div>
            <div className="col-md-12">
              <h4>
                {" "}
                <strong>Supplier Details :</strong> <hr className="m-0 bg-main" style={{ height: "3px" }} />
              </h4>
              {product.productImages ? (
                <div className="p-3 row align-items-center">
                  <div className="col-5 col-md-2 py-2">
                    <div className="mx-3 text-center" style={{ maxWidth: "100px" }}>
                      <Link to={`/company/${product.company.catalogLink}`}>
                        {product.company.companyLogo === "noLogo.png" || !product.company.companyLogo ? (
                          <img style={{ width: "100%" }} src={api.companyUrl + "noLogo.png"} alt="" />
                        ) : (
                          <img style={{ width: "100%" }} src={api.companyUrl + product.company.companyLogo} alt="" />
                        )}
                      </Link>
                    </div>
                  </div>
                  <div className="col-7 col-md-4 py-2">
                    <a target="_" href={`/company/${product.company.catalogLink}`}>
                      <p className="font-weight-bold">{product.company.company}</p>
                    </a>
                    <p className="m-0">
                      <small>
                        <span className={style.strongText}>Country :</span> {product.company.country}
                      </small>
                    </p>
                    <p className="m-0">
                      <small>
                        <span className={style.strongText}> Type :</span> {product.company.businessType}
                      </small>
                    </p>
                  </div>
                  <div className="col-5 col-md-2 py-2">
                    <div className="mx-3 text-center" style={{ maxWidth: "100px" }}>
                      {product.company.membershipPlan === "Guest" ? (
                        <img
                          style={{ filter: "brightness(0) invert(1) drop-shadow(black 0px 1px 2px)", width: "100%" }}
                          src={api.planUrl + "silvercrown.png"}
                          alt=""
                        />
                      ) : (
                        <img
                          style={{ maxWidth: "100%" }}
                          src={api.planUrl + product.company.membershipPlan.toLowerCase().replaceAll(" ", "") + "crown.png"}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-7 col-md-4 py-2">
                    <p className="m-0">
                      <small>
                        <span className={style.strongText}>Plan :</span> {product.company.membershipPlan} Member
                      </small>
                    </p>
                    <p className="m-0">
                      <small>
                        <span className={style.strongText}>CEO Name :</span> {product.company.companyCEO}
                      </small>
                    </p>
                    <p className="m-0">
                      <small>
                        <span className={style.strongText}>Location :</span> {product.company.location}
                      </small>
                    </p>
                  </div>
                </div>
              ) : (
                <Skeleton animation="wave" height={200} />
              )}
            </div>
            {product.productDesc ? (
              <div className="col-md-12">
                <h4>
                  {" "}
                  <strong>Product Descriptions :</strong> <hr className="m-0 bg-main" style={{ height: "3px" }} />
                </h4>
                <div>
                  <p className={style.description + ' py-2'} dangerouslySetInnerHTML={{ __html: product.productDesc }} />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductDetails;
