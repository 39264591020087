import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Link } from "react-router-dom";
import { Box, Container, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import PropTypes from "prop-types";
import style from "./style.module.css";
import { useSelector } from "react-redux";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function Categories(props) {
  const [value, setValue] = useState(0);
  const categoriesStore = useSelector((state) => state.CategoryState.data);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className="px-1" sx={{ flexGrow: 1, display: "flex", flexDirection: props.horizontal ? "column" : "row", maxHeight: "350px" }}>
      {categoriesStore.length ? (
        <>
          <Tabs
            orientation={props.horizontal ? "horizontal" : "vertical"}
            variant="scrollable"
            value={value}
            allowScrollButtonsMobile
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ border: 1, borderColor: "divider", width: props.horizontal ? "100%" : "300px" }}
          >
            {categoriesStore.map((item, i) => {
              return <Tab key={i} className={style.tabsBtn} label={item.category} {...a11yProps(i)} />;
            })}
          </Tabs>
          {categoriesStore.map((item, i) => {
            return (
              <TabPanel key={"cat" + i} className="w-100 overflow-auto" value={value} index={i}>
                <div>
                  <List className={"row " + style.pListBox}>
                    {item.subCategories.map((el, i) => {
                      return (
                        <Link
                          key={i}
                          to={`/products?category=${el.subCategory.toLowerCase().replaceAll(" ", "-")}`}
                          className="col-md-4 col-sm-6 border p-1 border-0"
                        >
                          <ListItem disablePadding className="border" style={{borderRadius:"0.5rem"}}>
                            <ListItemButton component="a" href={"#"}>
                              <ListItemText className={style.subCategoryText}> {el.subCategory}</ListItemText>
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      );
                    })}
                  </List>
                </div>
              </TabPanel>
            );
          })}
        </>
      ) : (
        ""
      )}
    </Container>
  );
}
