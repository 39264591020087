import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label, Spinner, FormText } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { useLocation } from 'react-router-dom'
import { formValidation } from "../../../../components/Utils/Utils";
import { getCookie } from "../../../../Helper/Cokkies";
import CreatableSelect from 'react-select/creatable';
import moment from "moment";
const axios = require("axios").default;

function UpdateSupport() {
  const location = useLocation()
  const [submiting, setSubmiting] = useState(false)
  let token = getCookie("alcToken");
  const [formData, setFormData] = useState(location.state);
  const [productsList, setProductsList] = useState([location.state.productsList]);


  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const newlist = { ...formData, productsList: productsList }
    setSubmiting(true)
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([formData.contactName, formData.contact, formData.email, formData.company, formData.remarks, formData.companyAddress, formData.joinDate, formData.expiry])) {
      if (filter.test(formData.email)) {
        axios
          .post(api.url + "/update-suport-party", newlist, { headers: { authorization: "Bearer " + token } })
          .then(function (response) {
            if (response.data.success) {
              alert("Party have been Update Successfully");
              setSubmiting(false)
            } else if (response.data.partyExceed) {
              alert(response.data.message);
              setSubmiting(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setSubmiting(false)
        alert("Please enter valid email / contact");
      }
    } else {
      setSubmiting(false)
      alert("Please fill the required fields");
    }
  };

  const changeHandlerDate = (e) => {
    const momentdate = moment(e.target.value).format()
    const nextdate = moment(e.target.value).add(1, 'years').subtract(1, 'day').calendar();
    const next = moment(nextdate).format();
    setFormData({ ...formData, [e.target.name]: momentdate, expiry: next });
  }

  const changeExpirtyDate = (e) => {
    const momentdate = moment(e.target.value).format()
    setFormData({ ...formData, [e.target.name]: momentdate });
  }

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add New Party</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
        <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contactName">
                Contact Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="contactName"
                name="contactName"
                value={formData.contactName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Contact Name"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Contact No."}
                title="10 digits number allowed"
                type="number"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="altContact">
                Alternate No.{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="altContact"
                name="altContact"
                value={formData.altContact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Alternate No."}
                type="text"
              />
              <FormText color="info">
                Note :- No. can be multiple & separate with comma ( " , " )
              </FormText>
            </FormGroup>

            <FormGroup className="col-md-8">
              <Label className={style.formLabel} for="company">
                Company Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Company Name"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.formLabel} for="membershipPlan">
                Membership Plan{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <select name="membershipPlan" className="w-100 form-control-sm border" id="membershipPlan" onChange={(e) => changeHandler(e)} value={formData.membershipPlan}>
                <option value="">Select</option>
                <option value="Silver">Silver</option>
                <option value="Platinum">Platinum</option>
                <option value="Platinum Pro">Platinum Pro</option>
                <option value="Elite">Elite</option>
                <option value="Prime Growth">Prime Growth</option>
              </select>
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.formLabel} for="settlement">
                Settlement{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="settlement"
                name="settlement"
                value={formData.settlement}
                onChange={(e) => changeHandler(e)}
                placeholder={"Total Settlement"}
                type="number"
              />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.formLabel} for="balancePending">
                Balance Pending{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <select name="balancePending" className="w-100 form-control-sm border" id="balancePending" onChange={(e) => changeHandler(e)} value={formData.balancePending}>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </FormGroup>

            {formData.balancePending ? <FormGroup className="col-md-4">
              <Label className={style.formLabel} for="balanceAmount">
                Balance Amount{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="balanceAmount"
                name="balanceAmount"
                value={formData.balanceAmount}
                onChange={(e) => changeHandler(e)}
                placeholder={"Balance Amount"}
                type="number"
              />
            </FormGroup> : ""}

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="catalogLink">
                Catalog Link{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="catalogLink"
                name="catalogLink"
                value={formData.catalogLink}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Catalog Link"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="website">
                Website{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="website"
                name="website"
                value={formData.website}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Website"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label>Products</Label>
              <CreatableSelect defaultValue={productsList.map((el) => { return { value: el, label: el }})}
                isMulti
                onChange={(e) => {
                  setProductsList(e.map((el) => el.value));
                }}
                theme={customThemeFn}
                name="tagKeywords"
                id="tagKeywords"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.formLabel} for="joinDate">
                Date{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <input type="date" value={moment(formData.joinDate).format("YYYY-MM-DD")} onChange={(e) => changeHandlerDate(e)} className="w-100 form-control-sm border" name="joinDate" id="joinDate" />
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.formLabel} for="expiry">
                Expiry{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <input type="date" value={moment(formData.expiry).format("YYYY-MM-DD")} onChange={(e) => changeExpirtyDate(e)} className="w-100 form-control-sm border" name="expiry" id="expiry" />
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="remarks">
                Remarks/Comment{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="remarks"
                name="remarks"
                value={formData.remarks}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter remarks/comment..."}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Company Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={"Enter company address"}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button className="btn-sm btn-main px-4" disabled={submiting} type="submit">
            Update Party {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
        </Form>
      </div>
    </div>
  );
}

export default UpdateSupport;
