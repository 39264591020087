import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import DeleteIcon from '@mui/icons-material/Delete';
import style from "./style.module.css";
import { Input } from "reactstrap";
import { IconButton } from "@mui/material";
import { getCookie } from "Helper/Cokkies";
import moment from "moment";
const axios = require("axios").default;

function CallBackRequests() {
  const token = getCookie("token");
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
    axios
      .post(api.url + "/get-callback-requests", { id: "" }, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response);
        response.data.callbacks.forEach((item, index) => {
          item.serial = index + 1;
        });
        setListData(response.data.callbacks);
        setFilterItems(response.data.callbacks);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <strong>Subject</strong>,
      selector: (row) => row.subject,
      sortable: true,
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.postedAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      selector: (row) => (
        <IconButton className={style.actionBtn} onClick={(e) => deleteItem(row._id)} color="error" size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
      button: true,
      width: "65px",
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-callback-query", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => item.keyWords && item.keyWords.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "5px" }}>
        <ul>
          <li>
            <u>Email Address</u> : {data.email}
          </li>
          <li>
            <u>Alternate Contact</u> : {data.altContact}
          </li>
          <li>
            <u>Country</u> : {data.country}
          </li>
          {" "}
          <li>
            <u>Subject</u> : {data.subject}
          </li>
          <li>
            {" "}
            <u>Message</u> : {data.message}
          </li>
        </ul>
      </div>
    );
  };

  const expandItem = () => {
    return (
      <h3 className="text-danger m-1 ml-2">
        <i className="fas fa-caret-square-up"></i>
      </h3>
    );
  };
  const collapseItem = () => {
    return (
      <h3 className="text-primary m-1 ml-2">
        <i className="fas fa-caret-square-down"></i>
      </h3>
    );
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Call Back Requests</h4>
          <hr className="my-2" />
        </div>
      </div>

      <Input id="search" name="search" className="border-primary" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      <DataTable
        pagination
        persistTableHead
        highlightOnHover
        columns={columns}
        data={filterItems}
        progressPending={pending}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }}
      />
    </div>
  );
}

export default CallBackRequests;
