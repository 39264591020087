import React, { useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { formValidation } from "../../../../components/Utils/Utils";
const axios = require("axios").default;

function AddTestimonial() {
  const [formData, setFormData] = useState({
    companyName: "",
    fullName: "",
    designation: "",
    message: "",
  });
  const [fileSelected, setFileSelected] = useState(false);
  const [imageName, setImageName] = useState("");
  const [uploadLogo, setUploadLogo] = useState({});
  let token = getCookie("token");

  const uploadLogoImg = (e) => {
    const form = new FormData();
    if (e.target.files[0] !== undefined && e.target.files[0].size < 41500) {
      if (e.target.files[0] === undefined) {
        setFileSelected(false);
      } else {
        setImageName(e.target.files[0].name);
        form.append("files", e.target.files[0]);
        setFileSelected(true);
        setUploadLogo(form);
      }
    } else {
      alert("Logo sould be less then 40kb")
      setFileSelected(false);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    if (formValidation([formData.message, formData.designation, formData.fullName, formData.companyName])) {
      if (!fileSelected) {
         addTestimonial(formData, "noLogo.png");
      } else {
        let request = await fetch(api.url + "/selectFile", {
          method: "post",
          body: uploadLogo,
          headers: { authorization: "Bearer " + token },
        });
        const response = await request.json();
        if (response.success) {
          addTestimonial(formData, response.newFileName + "-" + imageName);
        }
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const addTestimonial = (data, newFile) => {
    let newData = data;
    newData = { ...newData, logo: newFile };
    axios
      .post(api.url + "/add-testimonial", newData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Add Successfull");
          setFormData({
            designation: "",
            fullName: "",
            companyName: "",
            message: "",
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Testimonial</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <div>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="image">
                Logo{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="image"
                className={style.fileInput}
                name="image"
                accept="image/*"
                onChange={(e) => uploadLogoImg(e)}
                type="file"
              />
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyName">
                Company{" "}
                <small>
                  <sup className="text-danger"> (Required) </sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyName"
                name="companyName"
                value={formData.companyName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter name..."}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="fullName">
                Full Name{" "}
                <small>
                  <sup className="text-danger"> (Required) </sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="fullName"
                name="fullName"
                value={formData.fullName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter name..."}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="designation">
                Designation{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="designation"
                name="designation"
                value={formData.designation}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter designation..."}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="message">
                Message{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="message"
                style={{ minHeight: "100px" }}
                value={formData.message}
                onChange={(e) => changeHandler(e)}
                name="message"
                placeholder={"Enter message..."}
                type="textarea"
                minLength={50}
              />
            </FormGroup>
          </div>
          <Button type="submit" onClick={submitHandler} className="btn-sm btn-main px-4">
            Add Testimonial
          </Button>
        </div>

      </div>
    </div>
  );
}

export default AddTestimonial;
