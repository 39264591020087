import React, { useState } from "react";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { Container, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import GroupIcon from "@mui/icons-material/Group";
import AddTaskIcon from "@mui/icons-material/AddTask";
import StarIcon from "@mui/icons-material/Star";
import { Button, FormGroup, FormText, Input, Label, Spinner } from "reactstrap";
import style from "./style.module.css";
import SelectJS from "../../../../components/Select/SelectJS";
import { api } from "Helper/Data";
import { formValidation } from "../../../../components/Utils/Utils";
import { useSelector } from "react-redux";
import { SweetAlert } from '../../../../components/Utils/Utils'
const axios = require("axios").default;

export default function JoinformPage() {
  const countries = useSelector((state) => state.CountriesList.countries);
  const [submiting, setSubmiting] = useState(false)
  const [formData, setFormData] = useState({
    firstName: "", role: "user", userName: "", country: "India", contact: "", lastName: "", email: "", confirmPassword: "", countryFlag: "https://flagcdn.com/in.svg", password: "", website: "", company: "", catalogLink: "", leadsCredits: 0, membershipPlan: "Guest", skypeid: "", businessType: "Manufacturer", companyAddress: "",
  });
  const navigate = useNavigate();

  const gotologin = () => {
    navigate("/admin");
  };

  const getSelected = (select) => {
    setFormData({ ...formData, country: select.value, countryFlag: select.flag });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setSubmiting(true)
    
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var tel = /^[0-9]{10}$/;
    if (formValidation([formData.userName, formData.firstName, formData.catalogLink, formData.email, formData.contact, formData.company, formData.companyAddress])) {
      if (filter.test(formData.email) && formData.contact.match(tel)) {
        axios
          .post(api.url + "/signup", formData)
          .then(function (response) {
            console.log(response);
            if (response.data.success) {
              joinForm(response.data.data._id);
            } else {
              SweetAlert({
                title: response.data.message,
                icon: 'error',
              })
              setSubmiting(false)
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
            SweetAlert({
              title: 'Please contact admin',
              icon: 'error',
            })
            setSubmiting(false)
          });
      } else {
        SweetAlert({
          title: 'Please enter valid email / contact',
          icon: 'warning',
        })
        setSubmiting(false)
      }
    } else {
      SweetAlert({
        title: 'Please enter required fields',
        icon: 'error'
      })
      setSubmiting(false)
    }
  };

  const createCatalogLink = (e) => {
    var format = /[!@#$%^*+]/g;
    if (!format.test(e.target.value)) { setFormData({ ...formData, catalogLink: e.target.value.toLowerCase().replace(" ","") }); }
  }

  const joinForm = (id) => {
    axios
      .post(api.url + "/join-req", { data: formData, userId: id })
      .then(function (response) {
        // console.log(response);
        if (response.data.Join) {
          SweetAlert({
            title: 'You have Join Successfull',
            icon: 'success',
            footer: '<a href="/admin" class="btn btn-light">Go to login page</a>'
          })
          setSubmiting(false)
        }
      })
      .catch(function (error) {
        console.log(error);
        SweetAlert({
          title: 'Please contact admin',
          icon: 'error',
        })
        setSubmiting(false)
      });
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <PageHeading page={"joinUs"}></PageHeading>
      <Container className="py-3 mx-3">
        <BreadCrumb current={"Join Us"} />
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <form onSubmit={submitHandler}>
              {/* Login Information  */}
              <h5 className="text-center my-2 font-weight-bold">Login Information</h5>
              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="userName">
                    User Name{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    id="userName"
                    name="userName"
                    onChange={(e) => changeHandler(e)}
                    placeholder="Enter your User Name"
                  />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="email">
                    Login Email{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input bsSize="sm" type="email" name="email" onChange={(e) => changeHandler(e)} placeholder="Enter your email" />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="password">
                    Password{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input bsSize="sm" type="password" id="password" onChange={(e) => changeHandler(e)} name="password" placeholder="Enter password" />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="confirmPassword">
                    Confirm Password{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    type="text"
                    id="confirmPassword"
                    onChange={(e) => changeHandler(e)}
                    name="confirmPassword"
                    placeholder="Enter confirmed password"
                  />
                </FormGroup>
              </div>
              {/* Contact Information */}
              <h5 className="text-center my-2 font-weight-bold">Contact Information</h5>
              <div className="form-row">
                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="firstName">
                    First Name{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input bsSize="sm" id="firstName" name="firstName" onChange={(e) => changeHandler(e)} placeholder="Enter first name" type="text" />
                </FormGroup>

                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="lastName">
                    Last Name{" "}
                    <small>
                      <sup className="text-danger"></sup>
                    </small>
                  </Label>
                  <Input bsSize="sm" id="lastName" name="lastName" onChange={(e) => changeHandler(e)} placeholder="Enter last name" type="text" />
                </FormGroup>

                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="country">
                    Country{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  {countries.length ? <SelectJS sm={"sm"} selected={getSelected} data={countries} defValue={formData.country} /> : ""}
                </FormGroup>

                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="contact">
                    Contact No.{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    type="number"
                    onChange={(e) => changeHandler(e)}
                    name="contact"
                    placeholder="Enter contact no."
                    autoComplete="off"
                  />
                </FormGroup>

                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="website">
                    Website
                  </Label>
                  <Input bsSize="sm" type="text" id="website" onChange={(e) => changeHandler(e)} name="website" placeholder="Enter your website" />
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="catalogLink">
                    Create Catalog Link <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input bsSize="sm" type="text" value={formData.catalogLink} id="catalogLink" onChange={(e) => createCatalogLink(e)} name="website" placeholder="Enter your catalog Link" />
                  <FormText>
                    Please enter url link like "company_name".
                  </FormText>
                </FormGroup>
              </div>

              {/* Business Profile */}
              <h5 className="text-center my-2 font-weight-bold">Business Profile</h5>
              <div className="form-row">
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="company">
                    Company Name{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input bsSize="sm" id="company" name="company" onChange={(e) => changeHandler(e)} placeholder="Enter company name" type="text" />
                </FormGroup>
                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="skypeid">
                    Your Skype Id
                  </Label>
                  <Input bsSize="sm" id="skypeid" name="skypeid" onChange={(e) => changeHandler(e)} placeholder="Enter Skype Id" type="text" />
                </FormGroup>

                <FormGroup className="col-md-6">
                  <Label className={style.formLabel} for="businessType">
                    Business Type
                  </Label>
                  <Input bsSize="sm" id="businessType" onChange={(e) => changeHandler(e)} name="businessType" type="select">
                    <option>Manufacturer</option>
                    <option>Exporter</option>
                    <option>Importer</option>
                    <option>Exporter & Importer</option>
                    <option>Supplier</option>
                    <option>Retailer</option>
                    <option>Trader</option>
                    <option>Service Provider</option>
                  </Input>
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="companyAddress">
                    Company Address{" "}
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    id="companyAddress"
                    onChange={(e) => changeHandler(e)}
                    name="companyAddress"
                    placeholder="Enter company address"
                    type="textarea"
                  />
                </FormGroup>
              </div>
              <Button size="sm" disabled={submiting} block={true} type="submit" color="main">
                Submit {submiting ? <Spinner
                  color="primary"
                  size="sm"
                /> : ""}</Button>
            </form>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <h4 className="text-center my-3">
              <strong>Buyers Advantages?</strong>
            </h4>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                  <GroupIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Get Instant Feedback from Suppliers</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <AddTaskIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Accredited Suppliers that Meet Your Needs.</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Get the power to choose the best!</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem onClick={gotologin}>
                <ListItemIcon>
                  <LoginIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">
                    Already Join ?{" "}
                    <Link className="font-weight-bold" to="admin">
                      {" "}
                      Login
                    </Link>{" "}
                  </p>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </div>
        </div>
      </Container>
    </div>
  );
}
