import React, { useState } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import {useSelector} from 'react-redux'
import UpdateDetail from './UpdateDetails'

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CompanyDetails() {
  const company = useSelector((state)=>state.CompanyUser.data)
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <>
    <div className="content-header p-0">
        <div className="container-fluid">
              <h4 className="m-0 text-dark">Company Details</h4>
              <hr className='my-2'/>
        </div>
      </div>
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className={style.tabHeader} label="Company Details" {...a11yProps(0)} />
          <Tab className={style.tabHeader} label="Update Details" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {company.businessType?<img src={api.companyUrl +""+ company.companyLogo} height={100} alt="" />:'Loading'}
        <h4 className="m-0">{company.company}</h4>
      {company.businessType?<UpdateDetail data={company} disable={true}></UpdateDetail>:'Loading'}
      </TabPanel>
      <TabPanel value={value} index={1}>
      {company.businessType?<UpdateDetail data={company}></UpdateDetail>:'Loading'}
      </TabPanel>
    </Box>
    </>
  );
}
