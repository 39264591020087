import React, { useState } from "react";
import { Button, FormGroup, Input, InputGroup, InputGroupText, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useSelector } from "react-redux";
import SelectJS from "../../../../components/Select/SelectJS";
import { formValidation } from "../../../../components/Utils/Utils";
import CreatableSelect from 'react-select/creatable';
import { getLocalStorage } from "../../../../Helper/LocalStorage";
const axios = require("axios").default;

function AllocationAddLeads() {
  const user = getLocalStorage("alcUser");
  const countries = useSelector((state) => state.CountriesList.countries);
  const [formData, setFormData] = useState({
    subCategory: "", leadTitle: "", leadingName: "", leadMessage: "", countryFlag: "https://flagcdn.com/in.svg", tagKeywords: [], createdBy: user.name, location: "", company: "", country: "India", callCode: "+91", email: "", contact: "",
  });
  let token = getCookie("token");

  const getSelected = (select) => {
    if (select.flag) {
      setFormData({ ...formData, country: select.label, callCode: "+" + select.code, countryFlag: select.flag });
    } else {
      setFormData({ ...formData, subCategory: select.label });
    }
  };

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  const onSelecteChange = (e) => {
    setFormData({ ...formData, tagKeywords: e.map((el) => el.value) });
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    if (formValidation([formData.leadMessage, formData.company, formData.contact, formData.leadTitle, formData.leadingName, formData.tagKeywords.length])) {
      axios
        .post(api.url + "/add-lead-from-allocation", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          if (response.data.success) {
            alert("Add Successfull");
            setFormData({
              subCategory: "",
              leadTitle: "",
              leadingName: "",
              location: "",
              leadMessage: "",
              countryFlag: "https://flagcdn.com/in.svg",
              tagKeywords: [],
              createdBy: user.name,
              company: "",
              country: "India",
              email: "",
              contact: "",
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Leads</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label for="company" className={style.formLabel}>
                Behalf Of
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Company Name..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="leadTitle">
                Lead Title{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="leadTitle"
                name="leadTitle"
                value={formData.leadTitle}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter title..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="leadingName">
                Name{" "}
                <small>
                  <sup className="text-danger"> (Required) </sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="leadingName"
                name="leadingName"
                value={formData.leadingName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter person name..."}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="country">
                Country{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} data={countries} defValue={formData.country} selected={getSelected} />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="location">
                Location
              </Label>
              <Input
                bsSize="sm"
                id="location"
                name="location"
                value={formData.location}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter location..."}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <InputGroup>
                <InputGroupText className="py-0">
                  {formData.callCode}
                </InputGroupText>
                <Input bsSize="sm"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={(e) => changeHandler(e)}
                  placeholder={"Enter Contact No..."}
                  type="number" />
              </InputGroup>
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email..."}
                type="email"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="tagKeywords">
                Tag Keywords  <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <CreatableSelect
                defaultValue={formData.tagKeywords.map((el) => {
                  return { value: el, label: el }
                })}
                isMulti
                onChange={(e) => {
                  onSelecteChange(e);
                }}
                theme={customThemeFn}
                name="tagKeywords"
                id="tagKeywords"
                // options={subCategories}
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="leadMessage">
                Lead Message{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="leadMessage"
                style={{ minHeight: "100px" }}
                value={formData.leadMessage}
                onChange={(e) => changeHandler(e)}
                name="leadMessage"
                placeholder={"Enter message..."}
                type="textarea"
                minLength={50}
              />
            </FormGroup>
          </div>
          <Button type="submit" onClick={submitHandler} className="btn-sm btn-main px-4">
            Add Lead
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AllocationAddLeads;
