import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import SelectJS from "../../../../components/Select/SelectJS";
import { getCookie } from "../../../../Helper/Cokkies";
import TextField from '@mui/material/TextField';
const axios = require("axios").default;

function Grouping() {
    const [partyList, setPartyList] = useState([]);
    const [CompanyList, setCompanyList] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [filterValue, setFilterValue] = useState("");
    let token = getCookie("alcToken");
    const [formData, setFormData] = useState({});

    useEffect(() => {
        axios
            .post(api.url + "/get-list-for-merge", { id: "id" })
            .then(function (response) {
                // console.log(response);
                setCompanyList(response.data.userslist.map((el) => {
                    return { value: el._id, label: el.companyCEO ? el.company + " ( " + el.companyCEO + " )" : el.company }
                }))
                setPartyList(response.data.partylist.map((el) => {
                    return { value: el._id, label: `${el.company} ( ${el.firstName} ${el.lastName} )` }
                }))
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    const searchGroup = (e) => {
        e.preventDefault()
        axios
            .post(api.url + "/fetch-companies", { key: filterValue })
            .then(function (response) {
                console.log(response);
                if (response.data.success) {
                    setSearchResult(response.data.companies)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const partySelected = (select) => {
        setFormData({ ...formData, party: select.value, partyName: select.label });
    };
    const companySelected = (select) => {
        setFormData({ ...formData, company: select.value, companyName: select.label });
    };

    const submitHandler = (e) => {
        e.preventDefault();
        if (formData.party && formData.company) {
            axios
                .post(api.url + "/create-company-merge", formData, { headers: { authorization: "Bearer " + token } })
                .then(function (response) {
                    if (response.data.success) {
                        alert("find")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            alert("All Fields are mendatory")
        }
    };

    return (
        <div>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Party Company Merge</h4>
                    <hr className="my-2" />
                </div>
            </div>
            <div className="container px-4">
                <Form className="my-3" onSubmit={submitHandler}>
                    <div className="form-row">
                        <FormGroup className="col-md-6">
                            <Label className={style.formLabel} for="assignTo">
                                Party{" "}
                                <small>
                                    <sup className="text-danger">(Required)</sup>
                                </small>
                            </Label>
                            <SelectJS sm={"sm"} selected={partySelected} data={partyList} />
                        </FormGroup>

                        <FormGroup className="col-md-6">
                            <Label className={style.formLabel} for="assignTo">
                                Company{" "}
                                <small>
                                    <sup className="text-danger">(Required)</sup>
                                </small>
                            </Label>
                            <SelectJS sm={"sm"} selected={companySelected} data={CompanyList} />
                        </FormGroup>
                    </div>
                    <Button type="submit" className="btn-sm btn-main px-4">
                        Merge
                    </Button>
                </Form>

                <form className="w-100" onSubmit={(e) => searchGroup(e)}>  <TextField fullWidth size="small" label="Search..." type="search" onChange={(e) => setFilterValue(e.target.value)} /></form>

                <div className="result-cotainer p-2">
                    <div className="row g-2">
                        {searchResult.length ? searchResult.map((el) => {
                            return <div className="col-md-4 p-2">
                                <div className="border px-2">
                                    <p className="m-0" >{el.company.company}</p>
                                    <p className="m-0">{el.party.status}</p>
                                    <p className="m-0"><small>{el.party.assignTo.fullname}</small></p>
                                </div>
                            </div>
                        }) : ""}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Grouping;
