import { Grid, Link } from "@mui/material";
import React from "react";
import ProductQuery from "../../Components/ProductQuery/ProductQuiry";
import style from './style.module.css'
import { api } from "../../../../Helper/Data";

function ProductItem(props) {

  return (
    <div className={"card " + style.singleItem} >
      <div className="callout callout-danger p-0 m-0">
        <Grid container spacing={{ xs: 1, md: 2 }} style={{ alignItems: "center" }} columns={{ xs: 8, sm: 8, md: 12, lg: 12 }}>
          <Grid item xs={8} sm={3} md={3} lg={3}>
            <div className="m-3">
              <Link href={"/product-details"+props.data.productLink} target={"_blank"} underline={"none"}>
                <img src={api.productImg + props.data.productImages[0]} alt="" />
              </Link>
            </div>
          </Grid>
          <Grid item xs={8} sm={5} md={5} lg={5}>
            <div className="m-3">
              <Link href={"/product-details"+props.data.productLink} target={"_blank"} underline={"none"} className="text-decoration-none">
                <p className="font-weight-bold">{props.data.productName}</p>
              </Link>
              <p className="m-0 text-justify">
                <small> <span className={style.strongText}>Details :</span> {props.data.productDetails.length > 120 ? props.data.productDetails.substring(0, 120) + "..." : props.data.productDetails}</small>
              </p>
              <p className="m-0">
                <small><span className={style.strongText}>Country :</span> {props.data.company.country}</small>
              </p>
              <p className="m-0">
                <small><span className={style.strongText}>Location :</span> {props.data.company.location}</small>
              </p>
            </div>
          </Grid>
          <Grid item xs={8} sm={8} md={4} lg={4}>
            <div className="m-3 text-center">
              <a href={`/company/${props.data.company.catalogLink}`} target="_">
                <h6>
                  <i>{props.data.company.company}</i>
                </h6>
              </a>
              <p>
                <small>Business Type : {props.data.company.businessType}</small>
              </p>
              <div>
                <div style={{ width: "80px", display: "inline-block" }}>
                  {props.data.company.membershipPlan === "Guest" ? (
                    <img style={{ filter: "brightness(0) invert(1) drop-shadow(black 0px 1px 2px)" }} src={api.planUrl + "silvercrown.png"} alt="" />
                  ) : (
                    <img src={api.planUrl + props.data.company.membershipPlan.toLowerCase().replaceAll(" ", "") + "crown.png"} alt="" />
                  )}
                </div>
                <p>
                  <small>{props.data.company.membershipPlan} Member</small>
                </p>
                <ProductQuery btn={"sm"} data={props.data} />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default ProductItem;
