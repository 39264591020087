import React, { useEffect } from "react";
import { Button, FormText } from "reactstrap";
import Cropper from "react-easy-crop";
import style from "./style.module.css";
import Slider from "@mui/material/Slider";
import { generateDownload } from "../Utils/cropImage";

export default function ImageCrop(props) {
  const inputRef = React.useRef();
  const triggerFileSelectPopup = () => inputRef.current.click();
  const [aspect, setAspect] = React.useState(16/10);
  const [image, setImage] = React.useState(props.src);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  useEffect(() => {
    const split = props.aspect.split("/");
    if (split.length > 1) {
      setAspect(split[0] / split[1]);
    } else {
      setAspect(1);
    }
  }, [props.aspect])
  

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };

  const onDownload = () => {
    props.closeEdit(false)
    generateDownload(image, croppedArea);
  };

  return (
    <>
        <input type="file" accept="image/*" ref={inputRef} onChange={onSelectFile} style={{ display: "none" }} />
        <div className="px-3">
          <Button variant="contained" color="secondary" onClick={onDownload}>
            Download
          </Button>
          <FormText className="text-alert">Note : Please check download folder for croped image file ( image.jpeg ) and choose image again</FormText>
        </div>
      <div style={{ height: "500px" }}>
        <div className={style.containerCropper}>
          {image ? (
            <>
              <div className={style.cropper}>
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={aspect}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>

              <div className={style.slider}>
                <Slider min={1} max={3} step={0.01} value={zoom} onChange={(e, zoom) => setZoom(zoom)} />
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}