import React, { useEffect, useRef, useState } from "react";
import { Button, FormGroup, FormText, Input, Label, Modal, Spinner } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useSelector } from "react-redux";
import ImageCrop from "../../../../components/ImageCrop/ImageCrop";
import SelectJS from "../../../../components/Select/SelectJS";
import { userValidate } from "../../../../components/Utils/Utils";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

function Banner() {
  const navigate = useNavigate()
  const [image, setImage] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [imageSelected, setImageSelected] = useState(false);
  const [imgAspect, setImgAspect] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [companiesList, setCompaniesList] = useState([]);
  const [processing, setProcessing] = useState(false);
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const [formData, setFormData] = useState({role: "",
    createdBy: "",
    company: ""});
  const [checked, setChecked] = useState(true);
  const inputRef = useRef(null);
  const verify_admin_hr = userValidate(['admin', 'hr'], 'token');
  let token = getCookie("token");

  useEffect(() => {
    axios
      .post(api.url + "/get-all-companies", { id: "id" })
      .then(function (response) {
        // console.log(response.data);
        const selectCompanies = response.data.companies.map((el) => {
          return { value: el._id, label: el.company, type: "company" };
        });
        setCompaniesList(selectCompanies);
      })
      .catch(function (error) {
        console.log(error);
      });
    verify_admin_hr ? setImgAspect("4/3") : setImgAspect("16/10");
    if (currentCompanyUser._id !== undefined) {
      setFormData({...formData, 
        role: currentCompanyUser.user.role,
        createdBy: currentCompanyUser.firstName + "( " + currentCompanyUser.user.role + " )",
        company: currentCompanyUser._id,
      })
    }
  }, [currentCompanyUser, verify_admin_hr]);

  const getSelected = (select) => {
    setFormData({ ...formData, company: select.value });
  };

  const uploadLogoImg = (e) => {
    const file = e.target.files[0]
    var reader = new FileReader();
    if (e.target.files && file) {
      setImage(file);
      reader.readAsDataURL(file);
      setImageUrl(URL.createObjectURL(file));
      if (file.size > 350000) {
        alert("Size sould be less then 350 kb ");
        // reduceImg(file)
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width < height) {
              alert("Image's height sould be less then Width");
              // reduceImg(file)
            } else {
              setImageSelected(true);
            }
          };
        };
      }
    } else {
      setImageSelected(false);
    }
  };

  const reduceImg = (e) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 600;
        var MAX_HEIGHT = 450;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL("image/png");
        setImageUrl(dataurl);
        setOpenModal(true);
        setImageSelected(false);
      }
      img.src = e.target.result;
    }
    reader.readAsDataURL(e);
  }

  const uploadToServer = async (event) => {
    event.preventDefault();
    setProcessing(true)
    const body = new FormData();
    body.append("company", formData.company);
    body.append("role", formData.role);
    body.append("createdBy", formData.createdBy);
    body.append("bannerFor", checked ? "Company" : "WWT");
    body.append("bannerFile", image);

    if (imageSelected) {
      axios
        .post(api.url + "/upload-banner", body, {
          headers: {
            "content-type": "multipart/form-data",
            authorization: "Bearer " + token,
          },
        })
        .then(function (response) {
          if (response.data.success) {
            alert("uploded");
            navigate("/admin/banner-list")
          } else {
            alert("More then 4 banner not allowed, pleade some banner first")
          }
        });
    } else {
      alert("Please choose correct file");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Banner</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <form onSubmit={uploadToServer}>
          {verify_admin_hr ? (
            <div className="m-2">
              {checked ? <Button outline className={"text-secondary px-3"} size="sm" onClick={() => setChecked(false)}>
                Click to Add Main Banner
              </Button> : <Button outline className={"text-secondary px-3"} size="sm" onClick={() => setChecked(true)}>
                Click to Behalf of Company
              </Button>}
            </div>
          ) : (
            ""
          )}
          {verify_admin_hr && checked ? (
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Select Company
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              {companiesList.length ? <SelectJS sm={"sm"} data={companiesList} selected={getSelected} /> : "Geting List..."}
            </FormGroup>
          ) : (
            ""
          )}

          <FormGroup className="col-sm-6">
            <Label className={style.formLabel} for="image">
              Banner Image <small className="text-danger"></small>
            </Label>
            <Input
              ref={inputRef}
              bsSize="sm"
              id="image"
              className={style.fileInput}
              name="image"
              accept="image/*"
              onChange={(e) => uploadLogoImg(e)}
              type="file"
            />
            <FormText className="text-alert">Note : After Download select image from download folder.</FormText>
          </FormGroup>
          <Button type="submit" disabled={processing} className="btn-sm btn-main px-4 mx-3">
            Add Product {" "} {processing ? <Spinner size="sm" color="secondary">
            </Spinner> : ""}
          </Button>
          {openModal ? (
            <ModalComponent
              heading={"Edit Image"}
              size={"modal-lg"}
              setModal={(e) => setOpenModal(e)}
              url={imageUrl}
              aspect={imgAspect}
              open={openModal}
            />
          ) : (
            ""
          )}
        </form>
      </div>
    </div>
  );
}

export default Banner;

const ModalComponent = (props) => {
  return (
    <Modal
      scrollable
      isOpen={props.open}
      style={{ overflow: "auto" }}
      toggle={() => {
        props.setModal(false);
      }}
      modalClassName="modal-register"
      className={props.size}
    >
      <div className="modal-header no-border-header text-center pb-0">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            props.setModal(false);
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <h5 className="text-center">
          <strong>{props.heading}</strong>
        </h5>
      </div>
      <div className="modal-body pt-0 mb-3 px-3">
        <ImageCrop closeEdit={(e) => props.setModal(e)} src={props.url} aspect={props.aspect} />
      </div>
    </Modal>
  );
};
