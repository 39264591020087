import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import DataTable from "react-data-table-component";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { formValidation } from "../../../../components/Utils/Utils";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import LaunchIcon from "@mui/icons-material/Launch";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
const axios = require("axios").default;

function GenerateProforma() {
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  let token = getCookie("alcToken");
  const user = getLocalStorage("alcUser");
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({
    createdBy: "",
    proformaNo: "",
    email: "",
    company: "",
    gstn: "",
    amount: "",
    pan: "",
    item: "",
    companyAddress: "",
  });

  useEffect(() => {
    setFormData({ ...formData, createdBy: user.id });
    axios
      .post(
        api.url + "/get-proforma-list",
        { user: user },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        console.log(response.data.list);
        if (response.data.success) {
          const newList = response.data.list.map((item, index) => {
            return { ...item, serial: index + 1 };
          });
          setListData(newList);
          setFilterItems(newList);
          setPending(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deleteItem = (id) => {
    axios
      .post(
        api.url + "/delete-proforma",
        { id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (
      formValidation([
        formData.proformaNo,
        formData.email,
        formData.company,
        formData.companyAddress,
        formData.amount,
      ])
    ) {
      if (filter.test(formData.email)) {
        axios
          .post(api.url + "/generate-proforma", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            // console.log(response.data);
            if (response.data.success) {
              alert("Successfully Added");
              setFormData({
                createdBy: "",
                proformaNo: "",
                email: "",
                company: "",
                gstn: "",
                amount: "",
                pan: "", 
                item: "",
                companyAddress: "",
              });
            } else {
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        alert("Please enter valid email");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter(
        (item) =>
          item.fullname &&
          item.fullname.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "60px",
    },
    {
      name: <strong>Proforma No</strong>,
      selector: (row) => row.proformaNo,
      sortable: true,
      width: "100px",
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
    },
    {
      name: <strong>Email</strong>,
      selector: (row) => row.email,
    },
    {
      name: <strong>Amount</strong>,
      selector: (row) => row.amount,
      width:"100px"
    },
    {
      name: <strong>Created Date</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "150px",
      sortable: true,
    },
    {
      name: <strong>ACTION</strong>,
      width: "150px",
      cell: (row) => (
        <div>
          <a
            href={"https://www.invoice.worldwebtrade.com/proforma.html?no="+row.proformaNo}
            target="_"
          >
            <IconButton>
              <LaunchIcon fontSize="small" color="secondary" />
            </IconButton>
          </a>
          <IconButton
            onClick={(e) => {
              deleteItem(row._id);
            }}
          >
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Generate Proforma Invoice</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Email..."}
                type="email"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="proformaNo">
                Proforma No{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="proformaNo"
                name="proformaNo"
                value={formData.proformaNo}
                onChange={(e) => changeHandler(e)}
                placeholder={"Proforma No..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="company">
                Company Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Company Name..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="gstn">
                GSTN{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="gstn"
                name="gstn"
                value={formData.gstn}
                onChange={(e) => changeHandler(e)}
                placeholder={"GSTN..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="pan">
                PAN{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="pan"
                name="pan"
                value={formData.pan}
                onChange={(e) => changeHandler(e)}
                placeholder={"PAN..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="amount">
                Amount{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="amount"
                name="amount"
                value={formData.amount}
                onChange={(e) => changeHandler(e)}
                placeholder={"Amount..."}
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="companyAddress">
                Company Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={"Address..."}
                type="textarea"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="item">
                Item Details{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="item"
                style={{ minHeight: "50px" }}
                value={formData.item}
                onChange={(e) => changeHandler(e)}
                name="item"
                placeholder={"Invoice Item..."}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button type="submit" className="btn-sm btn-main px-4">
            Generate Invoice
          </Button>
        </Form>
      </div>

      <div className="py-3 px-4">
        <div className="content-header p-0">
          <div className="container-fluid">
            <h4 className="m-0 text-dark">Proforma Invoice List</h4>
            <hr className="my-2" />
          </div>
        </div>

        <InputGroup style={{ border: "solid 1px #007bff", borderRadius: "5px" }}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="nc-icon nc-zoom-split"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            id="search"
            name="search"
            placeholder="Text here for Search..."
            type="search"
            onChange={changeHandle}
          />
        </InputGroup>

        <DataTable
          pagination
          persistTableHead
          highlightOnHover
          columns={columns}
          data={filterItems}
          progressPending={pending}
        />
      </div>
    </div>
  );
}

export default GenerateProforma;
