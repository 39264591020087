import React, { useEffect, useState } from "react";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import { api } from "../../../../Helper/Data";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import style from "./style.module.css";
import CompanyItem from "./CompanyItem";
import Skeleton from '@mui/material/Skeleton';
const axios = require("axios").default;

function Companies() {
  const [loading, setLoading] = useState(true);
  const [listPage, setListPage] = useState(1);
  const [dataResponse, setDataResponse] = useState({});

  useEffect(() => {
    getCompanies(listPage);
  }, [listPage]);

  const getCompanies = (page) => {
    axios
      .post(api.url + "/get-companies", { page: page })
      .then(function (response) {
        console.log(response.data);
        setDataResponse({ companiesList: response.data.companies, totalItemLength: response.data.totalItems });
        setLoading(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handlePageChange = (event, value) => {
    setLoading(true);
    setListPage(value);
    getCompanies(value);
  };

  return (
    <div className="container py-3">
      <BreadCrumb current={"Companies"} />
      <div className="row">
        <div className="col-md-3 px-2 rounded bg-white">
          <h6 className="text-center my-2">
            What is Your <span className="text-danger"> Requirement</span> <hr className="m-0" />
          </h6>
          <RequirementForm small={"sm"} />
        </div>
        <div className="col-md-9 px-3">
          {dataResponse.totalItemLength / 10 >= 1 ? (
            <div className={style.searchHeader}>
              <p className="font-weight-bold">Total Result : {dataResponse.totalItemLength}</p>
              <Stack className="mb-1" spacing={2}>
                <Pagination count={Math.ceil(dataResponse.totalItemLength / 10)} color="primary" onChange={handlePageChange} />
              </Stack>
            </div>
          ) : (
            <Skeleton animation="wave" height={70} />
          )}
          {loading ? (
            <><h5 className="font-weight-bold">Loading...</h5>
              {[1, 2, 3, 4].map((el, i) => <Skeleton key={i} style={{ borderRadius: "8px", margin: "5px" }} variant="rounded" animation="wave" height={150} />)}
            </>
          ) : !loading && dataResponse.companiesList.length ? (
            dataResponse.companiesList.map((el, i) => {
              return <CompanyItem key={i} data={el} />;
            })
          ) : (
            "No Record Found"
          )}
        </div>
      </div>
    </div>
  );
}

export default Companies;
