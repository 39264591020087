import React, {useState} from "react";
import { Button, FormGroup, Input, Label, Modal, Spinner } from "reactstrap";
import {formValidation} from "../../../../components/Utils/Utils"
import {api} from '../../../../Helper/Data'
import style from "./style.module.css";
import Swal from 'sweetalert2/dist/sweetalert2.js'
const axios = require("axios").default;

function ProductQuery(props) {
  const [loginModal, setLoginModal] = useState(false);
  const [formData, setFormData] = useState({queryMessage:"", name:"", email:"", contact:"",location:"", product:props.data._id});
  const [submiting, setSubmiting] = useState(false)

  const changeHandler = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  };
  const resetForm = () => {
    setFormData({queryMessage:"", name:"", email:"", contact:"",location:"", product:props.data._id})
    setLoginModal(false)
  };
  const submithandler = () => {
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var tel = /^[0-9]{10}$/;
    if(formValidation([formData.queryMessage, formData.name, formData.email, formData.contact, formData.location])){
      if (filter.test(formData.email) && formData.contact.match(tel)) {
        axios.post(api.url+'/productQuery',formData)
        .then(function (response) {
          if(response.data.query){
            Swal.fire({
              text: 'You query is submited successfull.',
              icon: 'success',
              timer: 500,
              confirmButtonText: 'Cool'
            })
            setSubmiting(false)
            resetForm()
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      } else{
          alert("Please enter valid email / contact");
        }
    } else{
      alert("Please fill the required fields");
    }
  };
  return (
    <>
      <Button className={"btn-sm py-0 px-3 rounded"} color="danger" type="button" onClick={() => setLoginModal(true)}>
        <small>Get Query</small>
      </Button>
      <Modal isOpen={loginModal} toggle={() => setLoginModal(false)} modalClassName="modal-register" className="modal-lg">
        <div className="modal-header no-border-header text-center pb-0">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setLoginModal(false)}>
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="text-center"><strong>Query for Product</strong></h5>
        </div>
        <div className="modal-body pt-0">
          <div className={style.quiryProduct}>
          <img src={api.productImg + props.data.productImages[0]} alt="" />
          <div className="ml-2">
            <h5 className="m-0">
              <strong>
            {props.data.productName}
              </strong>
            </h5>
            <p className="m-0">
            {props.data.company.company}
            </p>
            <p className="m-0">
              <img style={{height:"15px"}} src={`${props.data.company.countryFlag}`} alt={props.data.company.country} /> {"  "}
            {props.data.company.country}
            </p>
          </div>
          </div>
          <div>
            <div className="form-row">
              <FormGroup className="col-md-12">
                <Label className={style.formLabel} for="queryMessage">
                  Message
                </Label>
                <Input
                rows="7"
                  type="textarea"
                  onChange={(e) => changeHandler(e)}
                  id="queryMessage"
                  name="queryMessage"
                  placeholder="Enter you message query..."
                />
              </FormGroup>

              <h5 className="col-md-12">Your Contact Information</h5>

              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="name">
                  Name
                </Label>
                <Input bsSize={"sm"} type="text" onChange={(e) => changeHandler(e)} id="name" name="name" placeholder="Enter name" />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="contact">
                  Contact No.
                </Label>
                <Input
                  bsSize={"sm"}
                  title="Number only"
                  type="tel"
                  onChange={(e) => changeHandler(e)}
                  id="contact"
                  name="contact"
                  placeholder="Enter contact no."
                  autoComplete="off"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="email">
                  Email
                </Label>
                <Input bsSize={"sm"} type="text" onChange={(e) => changeHandler(e)} id="email" name="email" placeholder="Enter your email" />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="location">
                  Location
                </Label>
                <Input bsSize={"sm"} type="text" onChange={(e) => changeHandler(e)} id="location" name="location" placeholder="Enter location" />
              </FormGroup>
            </div>

            <Button size={"sm"} onClick={resetForm} className="px-5 m-2 float-right" color="default">
              Cancel
            </Button>
            <Button size={"sm"} onClick={submithandler} className="px-5 m-2 float-right" color="main">
              Submit {submiting? <Spinner
                  color="primary"
                  size="sm"
                />:""}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ProductQuery;
