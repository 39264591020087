import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Container from "@mui/material/Container";
import style from "./style.module.css";
import PageHeading from "../../Components/PageHeading/PageHeading";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
const axios = require("axios").default;

export default function Faq() {
  const [expanded, setExpanded] = useState(false);
  const [faqs, setFaqs] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    axios
      .get("dist/docs/faqdata.json")
      .then(function (response) {
        // console.log(response);
        setFaqs(response.data.faqs);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div className={style.accordionContainer}>
      <PageHeading page={"faqs"}></PageHeading>
      <Container>
      <BreadCrumb current={"FAQ"}/>
        {faqs.map((el, i) => {
          let panel = "panel" + i;
          return (
            <Accordion key={"key"+i} expanded={expanded === panel} className={style.accordionBox} onChange={handleChange(panel)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={panel + "bh-content"} id={panel + "bh-header"}>
                <h6 className={style.titleString}>{el.title}</h6>
              </AccordionSummary>
              <AccordionDetails>
                {el.subTitle.map((item, i) => {
                  return (
                    <>
                      {typeof item == "string" ? (
                        <p key={"s"+i} className={style.subTitleString}>{item}</p>
                      ) : (
                        item.map((el, i) => {
                          return <p key={"p"+i}>{el}</p>;
                        })
                      )}{" "}
                    </>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Container>
    </div>
  );
}
