import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import style from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserCompany } from "../../../../Redux/CompanyUser";
import SelectJS from "../../../../components/Select/SelectJS";
import CreatableSelect from 'react-select/creatable';
import { formValidation } from "../../../../components/Utils/Utils";
const axios = require("axios").default;

export default function UpdateDetails(props) {
  const subCategoriesStore = useSelector((state) => state.CategoryState.subData);
  let token = getCookie("token");
  const [uploadLogo, setUploadLogo] = useState({});
  const [imageName, setImageName] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [formData, setFormData] = useState(props.data);
  const [subCategories, setSubCategories] = useState([]);
  const countries = useSelector((state) => state.CountriesList.countries);
  const dispatch = useDispatch();

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    }
  });

  const getSelected = (select) => {
    setFormData({ ...formData, country: select.value, countryFlag: select.flag });
  };

  useEffect(() => {
    if (subCategoriesStore.length) {
      const selectList = subCategoriesStore.map((el) => {
        return { value: el.subCategory, label: el.subCategory };
      });
      setSubCategories(selectList);
    }
  }, [subCategoriesStore]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      formValidation([formData.firstName, formData.contact, formData.company, formData.aboutCompany, formData.dealsIn, formData.companyAddress, formData.companyCEO, formData.location, formData.yearEstb,
      ])
    ) {
      if (!fileSelected) {
        formData.companyLogo !== "noLogo.png" ? updateCompany(formData, formData.companyLogo) : updateCompany(formData, "noLogo.png");
      } else {
        let request = await fetch(api.url + "/selectFile", {
          method: "post",
          body: uploadLogo,
          headers: { authorization: "Bearer " + token },
        });
        const response = await request.json();
        if (response.success) {
          updateCompany(formData, response.newFileName + "-" + imageName);
        }
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const createCatalogLink = (e) => {
    var format = /[!@#$%^*+]/g;
    if (!format.test(e.target.value)) { setFormData({ ...formData, catalogLink: e.target.value.toLowerCase().replace(" ", "") }); }
  }

  const onBlurHandler = (e, min) => {
    if (e.target.value.split(" ").length < min && e.target.value) {
      alert("Minimum " + min + " words are required");
    }
  };

  const onSelecteChange = (e) => {
    setFormData({ ...formData, dealsIn: e.map((el) => el.value) });
  };

  const updateCompany = (data, newFile) => {
    let newData = data;
    newData = { ...newData, image: newFile };

    axios
      .post(api.url + "/update-company", newData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data) {
          dispatch(UpdateUserCompany(newData));
          alert("Update Successfull");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadLogoImg = (e) => {
    if (e.target.files[0] !== undefined && e.target.files[0].size < 41500) {
      const form = new FormData();
      if (e.target.files[0] === undefined) {
        setFileSelected(false);
      } else {
        setImageName(e.target.files[0].name);
        form.append("files", e.target.files[0]);
        setFileSelected(true);
        setUploadLogo(form);
      }
    } else {
      alert("Logo sould be less then 40kb")
      setFileSelected(false);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Form onSubmit={submitHandler}>
      {/* Contact Information */}
      <h5 className="text-center  ">
        {" "}
        <strong>Contact Information</strong>
      </h5>
      <div className="form-row">
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="firstName">
            First Name{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter first name"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="lastName">
            Last Name{" "}
            <small>
              <sup className="text-danger"></sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter last name"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="country">
            Country{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          {props.disable ? (
            <Input bsSize="sm" type="text" value={formData.country} disabled={true} />
          ) : (
            <SelectJS data={countries} sm={"sm"} defValue={formData.country} selected={getSelected} />
          )}
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="contact">
            Contact No.{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            pattern="[0-9]+"
            title="Number only"
            type="text"
            id="contact"
            value={formData.contact}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="contact"
            placeholder={props.disable ? "" : "Enter contact no."}
            autoComplete="off"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="altContact">
            Alternate No.{" "}
            <small>
              <sup className="text-danger"></sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            title="Number only"
            type="text"
            id="altContact"
            value={formData.altContact}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="altContact"
            placeholder={props.disable ? "" : "Enter Alternate no."}
            autoComplete="off"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="skypeid">
            Your Skype Id
          </Label>
          <Input
            bsSize="sm"
            id="skypeid"
            name="skypeid"
            value={formData.skypeid}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Skype Id"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-6">
          <Label className={style.formLabel} for="website">
            Website
          </Label>
          <Input
            bsSize="sm"
            type="text"
            id="website"
            value={formData.website}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="website"
            placeholder={props.disable ? "" : "Enter your website"}
          />
        </FormGroup>

        <FormGroup className="col-md-6">
          <Label className={style.formLabel} for="catalogLink">
            Create Catalog Link <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input bsSize="sm" type="text" value={formData.catalogLink}  disabled={props.disable ? props.disable : false} id="catalogLink" onChange={(e) => createCatalogLink(e)} name="website" placeholder="Enter your catalog Link" />
          <FormText>
            Please enter url link like '/company_name'.
          </FormText>
        </FormGroup>
      </div>

      {/* Business Profile */}
      <h5 className="text-center ">
        {" "}
        <strong>Business Profile</strong>
      </h5>
      <div className="form-row">
        <FormGroup className="col-md-8">
          <Label className={style.formLabel} for="company">
            Company Name{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="company"
            name="company"
            value={formData.company}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter company name"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="location">
            Location{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="location"
            name="location"
            value={formData.location}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Location"}
            type="text"
          />
        </FormGroup>
        {!props.disable ? (
          <FormGroup className="col-md-4 col-sm-6">
            <Label className={style.formLabel} for="image">
              Logo{" "}
              <small>
                <sup className="text-danger"></sup>
              </small>
            </Label>
            <Input
              bsSize="sm"
              id="image"
              className={style.fileInput}
              name="image"
              accept="image/*"
              disabled={props.disable ? props.disable : false}
              onChange={(e) => uploadLogoImg(e)}
              type="file"
            />
          </FormGroup>
        ) : (
          ""
        )}

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="gstn">
            GSTN
          </Label>
          <Input
            bsSize="sm"
            id="gstn"
            name="gstn"
            value={formData.gstn}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter GST No"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="companyCEO">
            CEO of the Company{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="companyCEO"
            name="companyCEO"
            value={formData.companyCEO}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter CEO Name"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="yearEstb">
            Year of Establishment{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="yearEstb"
            name="yearEstb"
            value={formData.yearEstb}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Year fo Establishment"}
            type="number"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="secBusiness">
            Secondary Business
          </Label>
          <Input
            bsSize="sm"
            id="secBusiness"
            name="secBusiness"
            value={formData.secBusiness}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Secondary Business"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="businessType">
            Business Type{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="businessType"
            value={formData.businessType}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="businessType"
            type="select"
          >
            <option>Manufacturer</option>
            <option>Exporter</option>
            <option>Supplier</option>
            <option>Retailer</option>
            <option>Trader</option>
            <option>Importer</option>
            <option>Service Provider</option>
          </Input>
        </FormGroup>
        <FormGroup className="col-md-12">
          <Label className={style.formLabel} for="dealsIn">
            Deals In{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          {props.disable ? (
            <Input bsSize="sm" type="text" value={formData.dealsIn} disabled={true} />
          ) : subCategories.length ? (
            <CreatableSelect
              defaultValue={formData.dealsIn.map((el) => {
                return { value: el, label: el }
              })}
              isMulti
              onChange={(e) => {
                onSelecteChange(e);
              }}
              theme={customThemeFn}
              name="dealsIn"
              id="dealsIn"
              options={subCategories}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          ) : (
            ""
          )}

          <FormText className="text-danger">Hint : select or create keywords for buyers leads...</FormText>
        </FormGroup>

        <FormGroup className="col-md-12">
          <Label className={style.formLabel} for="aboutCompany">
            About Company{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="aboutCompany"
            value={formData.aboutCompany}
            onBlur={(e) => onBlurHandler(e, 20)}
            style={{ minHeight: "70px" }}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="aboutCompany"
            placeholder={props.disable ? "" : "Enter About Company"}
            type="textarea"
          />
        </FormGroup>

        <FormGroup className="col-md-12">
          <Label className={style.formLabel} for="companyAddress">
            Company Address{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="companyAddress"
            style={{ minHeight: "50px" }}
            value={formData.companyAddress}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="companyAddress"
            placeholder={props.disable ? "" : "Enter company address"}
            type="textarea"
          />
        </FormGroup>
      </div>

      {/* Social Links */}
      <h5 className="text-center ">
        {" "}
        <strong>Social</strong>
      </h5>
      <div className="form-row">
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="facebook">
            <i className={"fab fa-facebook-square " + style.fbIcon}></i> Facebook
          </Label>
          <Input
            bsSize="sm"
            id="facebook"
            name="facebook"
            value={formData.facebook}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Facebook Link"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="twitter">
            <i className={"fab fa-twitter-square " + style.twitterIcon}></i> Twitter
          </Label>
          <Input
            bsSize="sm"
            id="twitter"
            name="twitter"
            value={formData.twitter}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Twitter Link"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="linkedIn">
            <i className={"fab fa-linkedin " + style.linkedinIcon}></i> LinkedIn
          </Label>
          <Input
            bsSize="sm"
            id="linkedIn"
            name="linkedIn"
            value={formData.linkedIn}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter LinkedIn Link"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="instagram">
            <i className={"fab fa-instagram-square " + style.instagramIcon}></i> Instagram
          </Label>
          <Input
            bsSize="sm"
            id="instagram"
            name="instagram"
            value={formData.instagram}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Instagram Link"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="pintrest">
            <i className="fab fa-pinterest-square" style={{ color: "red" }}></i> Pinterest
          </Label>
          <Input
            bsSize="sm"
            id="pintrest"
            name="pintrest"
            value={formData.pintrest}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Printrest Link"}
            type="text"
          />
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="tumblr">
            <i className="fab fa-tumblr-square" style={{ color: "" }}></i> Tumblr
          </Label>
          <Input
            bsSize="sm"
            id="tumblr"
            name="tumblr"
            value={formData.tumblr}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Tumblr Link"}
            type="text"
          />
        </FormGroup>
      </div>

      {!props.disable ? (
        <Button size="sm" type="submit" color="main">
          Submit
        </Button>
      ) : (
        ""
      )}
    </Form>
  );
}
