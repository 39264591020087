import axios from 'axios';
import moment from 'moment';
import React, { useState } from 'react'
import { Button, FormGroup, Modal } from 'reactstrap';
import { api } from '../../../../Helper/Data';
import { getLocalStorage } from '../../../../Helper/LocalStorage';
import { FormControlLabel, TextareaAutosize, TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Switch from '@mui/material/Switch';
import { useDispatch } from "react-redux";
import { addAllocationCount, deleteAllocationCount } from '../../../../Redux/Counter'

function StatusPoppover(props) {
  const [formData, setFormData] = useState({ remarks: "", schedule: "" });
  const [schedule, setSchedule] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const dispatch = useDispatch()
  const user = getLocalStorage("alcUser");

  const submitHandler = (status) => {
    if (formData.remarks) {
      setSubmitDisabled(true)
      axios
        .post(api.url + "/set-party-status", { id: props.party._id, preStatus: props.party.status, status: status, remarks: formData.remarks, isScheduled: schedule, schedule: formData.schedule, remarksBy: `${user.name} ( ${user.username} )` })
        .then(function (response) {
          // console.log(response.data);
          if (response.data.parties) {
            alert("Successfuly moved");
            setSubmitDisabled(false)
            props.updated(props.party._id);
            if (props.reduxValue.decrease) { dispatch(deleteAllocationCount(props.reduxValue.decrease)) }
            if (props.reduxValue.increase) { dispatch(addAllocationCount(props.reduxValue.increase)) }
          }
        })
        .catch(function (error) {
          console.log(error);
          setSubmitDisabled(false)
        });
    } else {
      alert("Please enter remarks...")
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  return (
    <>

      <Button
        className="btn-round mx-1"
        color={
          props.text.color
        }
        style={{fontSize:"10px", padding:"3px 10px"}}
        outline
        onClick={() => props.setModal(true)}
      >
        <i
          className={
            props.text.icon
          }
          />{" "}
        {props.text.text}
      </Button>
      <Modal
        isOpen={props.open}
        toggle={() => {
          props.setModal(false);
        }}
        className={"modal-md"}
      >
        <div className="modal-header no-border-header text-center pb-0">
          <button
            className="close"
            type="button"
            onClick={() => {
              props.setModal(false);
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className={"text-center text-" + props.text.color}>
            <strong> Send to {props.text.text}</strong>
          </h5>
        </div>
        <div className="modal-body mb-3 px-4 py-1">
          <div className="p-2">
            <FormGroup className='my-1'>
              <TextareaAutosize
                aria-label="empty textarea"
                placeholder="Remarks/Comments..."
                id="remarks"
                className="w-100"
                minRows={3}
                name="remarks"
                onChange={(e) => { setFormData({ ...formData, remarks: e.target.value }) }}
              />
            </FormGroup>
            {
              props.text.text !== "Delete" ?
                <FormGroup className='my-1'>
                  <FormControlLabel
                    control={<Android12Switch defaultChecked={schedule} onChange={(e) => setSchedule(e.target.checked)} />}
                    label="Set Schedule"
                  />
                </FormGroup> : ""
            }

            {schedule ? <FormGroup>
              <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="Call Schedule"
                  renderInput={(params) => <TextField {...params} />}
                  value={formData.schedule}
                  onChange={(newValue) => {
                    setFormData({ ...formData, schedule: moment(newValue._d).format() });
                  }}
                />
              </LocalizationProvider>
            </FormGroup> : ""}

            <Button disabled={submitDisabled} onClick={() => submitHandler(props.text.text)} type="submit" className="btn-sm btn-main px-4">
              {"Move to " + props.text.text}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default StatusPoppover