import React, { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import style from './style.module.css'
import { Avatar, CardHeader, Button, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";

const Header = () => {
  const user = getLocalStorage("alcUser");
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const navigate = useNavigate()

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.split(' ').length > 1? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : `${name.split(' ')[0][0]}`,
    };
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
 
  const goToPage = (event, url) => {
    handleClose(event)
    navigate(url)
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  return (
    <>
      <nav className="main-header navbar navbar-expand navbar-white navbar-light navbar-fixed">
        {/* Left navbar links */}
        <ul className="navbar-nav m-2">
          <li className="nav-item">
            <Link className="nav-link nav-menu m-0" data-widget="pushmenu" to="#">
              <i className="fas fa-bars" />
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="contact-us" className="nav-link nav-menu m-0">
              Contacts
            </Link>
          </li>
          <li className="nav-item d-none d-sm-inline-block">
            <Link to="/" className="nav-link nav-menu m-0">
              Go To Public Page
            </Link>
          </li>
        </ul>
        {/* Right navbar links */}
        <ul className="navbar-nav ml-auto mr-2">
          {/* Profile Dropdown Menu */}
          <li className={"nav-item dropdown " + style.rightHeader}>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <CardHeader
                style={{ padding: "0px" }}
                avatar={
                  <Avatar {...stringAvatar(user.name)} />
                }
                title={user.name}
                subheader={user.username}
              />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        onKeyDown={handleListKeyDown}
                      >
                        <MenuItem onClick={(e)=>goToPage(e, "logout")}>
                          Log Out <span className="float-right text-muted mx-1 text-sm">
                            <i className="fas fa-sign-out-alt"></i>
                          </span></MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </li>
        </ul>
      </nav>
    </>
  );
};
export default Header;
