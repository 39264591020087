import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import style from "./style.module.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUserCompany } from "../../../../Redux/CompanyUser";
import SelectJS from "../../../../components/Select/SelectJS";
import CreatableSelect from 'react-select/creatable';
import { formValidation } from "../../../../components/Utils/Utils";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from "@mui/material";
const axios = require("axios").default;

export default function UpdateUser(props) {
  const location = useLocation();
  const subCategoriesStore = useSelector((state) => state.CategoryState.subData);
  let token = getCookie("token");
  const [uploadLogo, setUploadLogo] = useState({});
  const [imageName, setImageName] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [formData, setFormData] = useState(location.state);
  const [subCategories, setSubCategories] = useState([]);
  const countries = useSelector((state) => state.CountriesList.countries);
  const dispatch = useDispatch();

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  const planObj = { silver: { sort: 4, leads: 10 }, platinum: { sort: 3, leads: 30 }, platinumpro: { sort: 2, leads: 60 }, elite: { sort: 1, leads: 80 }, primegrowth: { sort: 0, leads: 100 }, guest: { sort: 5, leads: 0 } }

  const getSelected = (select) => {
    setFormData({ ...formData, country: select.value, countryFlag: select.flag });
  };

  useEffect(() => {
    if (subCategoriesStore.length) {
      const selectList = subCategoriesStore.map((el) => {
        return { value: el.subCategory, label: el.subCategory };
      });
      setSubCategories(selectList);
    }
  }, [subCategoriesStore]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      formValidation([formData.firstName, formData.contact, formData.company, formData.aboutCompany, formData.companyAddress, formData.companyCEO, formData.location, formData.yearEstb
      ]) && formData.dealsIn.length
    ) {
      if (!fileSelected) {
        formData.companyLogo !== "noLogo.png" ? updateCompany(formData, formData.companyLogo) : updateCompany(formData, "noLogo.png");
      } else {
        let request = await fetch(api.url + "/selectFile", {
          method: "post",
          body: uploadLogo,
          headers: { authorization: "Bearer " + token },
        });
        const response = await request.json();
        if (response.success) {
          updateCompany(formData, response.newFileName + "-" + imageName);
        }
      }
    } else {
      alert("Please fill the required fields");
    }
  };
  const selectPlan = (e) => {
    if (e.target.value !== "Guest") { setFormData({ ...formData, subscriptionPeriod: '1' }); }
    setFormData({
      ...formData,
      membershipPlan: e.target.value,
      leadsCredits: planObj[e.target.value.replace(" ", "").toLowerCase()].leads,
      sort: planObj[e.target.value.replace(" ", "").toLowerCase()].sort
    });
  };

  const onBlurHandler = (e, min) => {
    if (e.target.value.split(" ").length < min && e.target.value) {
      alert("Minimum " + min + " words are required");
    }
  };

  const onSelecteChange = (e) => {
    setFormData({ ...formData, dealsIn: e.map((el) => el.value) });
  };

  const updateCompany = (data, newFile) => {
    let newData = data;
    newData = { ...newData, image: newFile };
    axios
      .post(api.url + "/update-company", newData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data) {
          dispatch(UpdateUserCompany(newData));
          alert("Update Successfull");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const uploadLogoImg = (e) => {
    const form = new FormData();
    if (e.target.files[0] !== undefined && e.target.files[0].size < 41500) {
      setImageName(e.target.files[0].name);
      form.append("files", e.target.files[0]);
      setFileSelected(true);
      setUploadLogo(form);
    } else {
      alert("Logo sould be less then 40kb")
      setFileSelected(false);
    }
  };

  const selectPeriod = (e) => {
    if (formData.membershipPlan !== "Guest") {
      let date = moment(formData.subscribeAt)._d
      var year = date.getFullYear();
      var month = date.getMonth();
      var day = date.getDate();
      var c = new Date(year + parseInt(e.target.value), month, day);
      setFormData({ ...formData, subscriptionPeriod: e.target.value, subscribeEnd: moment(c).format() })
    } else { setFormData({ ...formData, subscriptionPeriod: formData.subscriptionPeriod ? formData.subscriptionPeriod : 1 }); alert("Please select plan") }
  }

  const createCatalogLink = (e) => {
    var format = /[!@#$%^*+]/g;
    if (!format.test(e.target.value)) { setFormData({ ...formData, catalogLink: e.target.value.toLowerCase().replace(" ", "") }); }
  }

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Update Company Details : <strong>{formData.company}</strong></h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="px-3 py-1">
        <Form onSubmit={submitHandler}>
          {/* Contact Information */}
          <h5 className="text-center  ">
            {" "}
            <strong>Contact Information</strong>
          </h5>
          <div className="form-row">
            <div className="col-12">
              <img style={{ height: "100px" }} src={api.companyUrl + formData.companyLogo} alt="" />
            </div>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="firstName">
                First Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter first name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="lastName">
                Last Name{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter last name"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="country">
                Country{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              {props.disable ? (
                <Input bsSize="sm" type="text" value={formData.country} disabled={true} />
              ) : (
                <SelectJS data={countries} sm={"sm"} defValue={formData.country} selected={getSelected} />
              )}
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                pattern="[0-9]+"
                title="Number only"
                type="text"
                id="contact"
                value={formData.contact}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="contact"
                placeholder={props.disable ? "" : "Enter contact no."}
                autoComplete="off"
              />
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="altContact">
                Alternate No.{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                title="Number only"
                type="text"
                id="altContact"
                value={formData.altContact}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="altContact"
                placeholder={props.disable ? "" : "Enter Alternate no."}
                autoComplete="off"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="website">
                Website
              </Label>
              <Input
                bsSize="sm"
                type="text"
                id="website"
                value={formData.website}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="website"
                placeholder={props.disable ? "" : "Enter your website"}
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="catalogLink">
                Create Catalog Link <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input bsSize="sm" type="text" value={formData.catalogLink} disabled={props.disable ? props.disable : false} id="catalogLink" onChange={(e) => createCatalogLink(e)} name="website" placeholder="Enter your catalog Link" />
              <FormText>
                Please enter url link like '/company_name'.
              </FormText>
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="isHidden">
                Hide Company
              </Label>
              <Input
                bsSize="sm"
                id="isHidden"
                name="isHidden"
                value={formData.isHidden}
                onChange={(e) => { setFormData({ ...formData, "isHidden": e.target.value }) }}
                type="select"
              >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
                <Label className={style.formLabel} for="image">
                  Logo{" "}
                  <small>
                    <sup className="text-danger"></sup>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="image"
                  className={style.fileInput}
                  name="image"
                  accept="image/*"
                  disabled={props.disable ? props.disable : false}
                  onChange={(e) => uploadLogoImg(e)}
                  type="file"
                />
              </FormGroup>
          </div>

          {/* Business Profile */}
          <h5 className="text-center ">
            {" "}
            <strong>Business Profile</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-8">
              <Label className={style.formLabel} for="company">
                Company Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter company name"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="location">
                Location{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="location"
                name="location"
                value={formData.location}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Location"}
                type="text"
              />
            </FormGroup>
            {!props.disable ? (
              <FormGroup className="col-md-4 col-sm-6">
                <Label className={style.formLabel} for="image">
                  Logo{" "}
                  <small>
                    <sup className="text-danger"></sup>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="image"
                  className={style.fileInput}
                  name="image"
                  accept="image/*"
                  disabled={props.disable ? props.disable : false}
                  onChange={(e) => uploadLogoImg(e)}
                  type="file"
                />
              </FormGroup>
            ) : (
              ""
            )}

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="gstn">
                GSTN
              </Label>
              <Input
                bsSize="sm"
                id="gstn"
                name="gstn"
                value={formData.gstn}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter GST No"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="companyCEO">
                CEO of the Company{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyCEO"
                name="companyCEO"
                value={formData.companyCEO}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter CEO Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="yearEstb">
                Year of Establishment{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="yearEstb"
                name="yearEstb"
                value={formData.yearEstb}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Year fo Establishment"}
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="secBusiness">
                Secondary Business
              </Label>
              <Input
                bsSize="sm"
                id="secBusiness"
                name="secBusiness"
                value={formData.secBusiness}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Secondary Business"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="membershipPlan">
                Membership Plan{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="membershipPlan"
                name="membershipPlan"
                value={formData.membershipPlan}
                onChange={(e) => selectPlan(e)}
                type="select"
              >
                <option>Guest</option>
                <option>Silver</option>
                <option>Platinum</option>
                <option>Platinum Pro</option>
                <option>Elite</option>
                <option>Prime Growth</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="subscribeAt">
                Subscription Date{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <LocalizationProvider className="m-0" dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Date"
                  className={style.datePicker}
                  renderInput={(params) => <TextField className="py-1" {...params} />}
                  value={formData.subscribeAt}
                  onChange={(newValue) => {
                    setFormData({ ...formData, subscribeAt: moment(newValue._d).format() });
                  }}
                />
              </LocalizationProvider>
            </FormGroup>

            <FormGroup className="col-md-2 col-sm-3">
              <Label className={style.formLabel} for="subscriptionPeriod">
                Subs Period{" "}
                <small>
                  {/* (in Year) */}
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="subscriptionPeriod"
                value={formData.subscriptionPeriod}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => selectPeriod(e)}
                name="subscriptionPeriod"
                type="select"
              >
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-2 col-sm-3">
              <Label className={style.formLabel} for="leadsCredits">
                Leads Credits
              </Label>
              <Input
                bsSize="sm"
                id="leadsCredits"
                name="leadsCredits"
                value={formData.leadsCredits}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Leads Credits"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="businessType">
                Business Type{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="businessType"
                value={formData.businessType}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="businessType"
                type="select"
              >
                <option>Manufacturer</option>
                <option>Exporter</option>
                <option>Supplier</option>
                <option>Retailer</option>
                <option>Trader</option>
                <option>Importer</option>
                <option>Service Provider</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="dealsIn">
                Deals In{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              {props.disable ? (
                <Input bsSize="sm" type="text" value={formData.dealsIn} disabled={true} />
              ) : subCategories.length ? (
                <CreatableSelect
                  defaultValue={formData.dealsIn.map((el) => {
                    return { value: el, label: el };
                  })}
                  isMulti
                  onChange={(e) => {
                    onSelecteChange(e);
                  }}
                  theme={customThemeFn}
                  name="dealsIn"
                  id="dealsIn"
                  options={subCategories}
                  className="basic-multi-select"
                  classNamePrefix="select"
                />
              ) : (
                ""
              )}
              <FormText>Hint : enter product category example --- onion, mango, oil</FormText>
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="aboutCompany">
                About Company{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="aboutCompany"
                value={formData.aboutCompany}
                onBlur={(e) => onBlurHandler(e, 20)}
                style={{ minHeight: "70px" }}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="aboutCompany"
                placeholder={props.disable ? "" : "Enter About Company"}
                type="textarea"
              />
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Company Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={props.disable ? "" : "Enter company address"}
                type="textarea"
              />
            </FormGroup>
          </div>

          {/* Social Links */}
          <h5 className="text-center ">
            {" "}
            <strong>Social</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="facebook">
                <i className={"fab fa-facebook-square " + style.fbIcon}></i> Facebook
              </Label>
              <Input
                bsSize="sm"
                id="facebook"
                name="facebook"
                value={formData.facebook}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Facebook Link"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="twitter">
                <i className={"fab fa-twitter-square " + style.twitterIcon}></i> Twitter
              </Label>
              <Input
                bsSize="sm"
                id="twitter"
                name="twitter"
                value={formData.twitter}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Twitter Link"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="linkedIn">
                <i className={"fab fa-linkedin " + style.linkedinIcon}></i> LinkedIn
              </Label>
              <Input
                bsSize="sm"
                id="linkedIn"
                name="linkedIn"
                value={formData.linkedIn}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter LinkedIn Link"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="instagram">
                <i className={"fab fa-instagram-square " + style.instagramIcon}></i> Instagram
              </Label>
              <Input
                bsSize="sm"
                id="instagram"
                name="instagram"
                value={formData.instagram}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Instagram Link"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="pintrest">
                <i className="fab fa-pinterest-square" style={{ color: "red" }}></i> Pinterest
              </Label>
              <Input
                bsSize="sm"
                id="pintrest"
                name="pintrest"
                value={formData.pintrest}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Printrest Link"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 col-sm-6">
              <Label className={style.formLabel} for="tumblr">
                <i className="fab fa-tumblr-square" style={{ color: "" }}></i> Tumblr
              </Label>
              <Input
                bsSize="sm"
                id="tumblr"
                name="tumblr"
                value={formData.tumblr}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Tumblr Link"}
                type="text"
              />
            </FormGroup>
          </div>
          
          {/* Meta Data */}
          <h5 className="text-center ">
            {" "}
            <strong>Meta Data</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="metaTitle">
                Meta Title <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="metaTitle"
                name="metaTitle"
                value={formData.metaTitle}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Meta Title"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="metaKeywords">
                Meta Keywords<small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="metaKeywords"
                name="metaKeywords"
                value={formData.metaKeywords}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                placeholder={props.disable ? "" : "Enter Meta Keywords"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="metaDesc">
                Meta Description{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="metaDesc"
                value={formData.metaDesc}
                style={{ minHeight: "70px" }}
                disabled={props.disable ? props.disable : false}
                onChange={(e) => changeHandler(e)}
                name="metaDesc"
                placeholder={props.disable ? "" : "Enter Meta Description"}
                type="textarea"
              />
            </FormGroup>
          </div>

          {!props.disable ? (
            <Button size="sm" type="submit" color="main">
              Submit
            </Button>
          ) : (
            ""
          )}
        </Form>
      </div>
    </>
  );
}
