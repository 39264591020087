import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import DeleteIcon from '@mui/icons-material/Delete';
import style from './style.module.css'
import { Input } from "reactstrap";
import { IconButton } from "@mui/material";
import { getCookie } from "Helper/Cokkies";
import moment from "moment";
const axios = require("axios").default;

function ProductQueries() {
  const token = getCookie("token")
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
    axios
      .post(api.url + "/get-product-queries", { id: "id" }, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        response.data.queries.forEach((item, index) => { item.serial = index + 1; });
        setListData(response.data.queries);
        setFilterItems(response.data.queries);
        setPending(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <strong>Query For</strong>,
      selector: (row) => row.product.productName.slice(0, 15),
      sortable: true,
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: <strong>Location</strong>,
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: <strong>Add On</strong>,
      selector: (row) => moment(row.postedAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px"
    },
    {
      selector: (row) => <IconButton className={style.actionBtn} onClick={(e)=> deleteItem(row._id)} color="error" size="small">
        <DeleteIcon fontSize="small" />
      </IconButton>,
      button: true,
      width: "65px",
    },
  ];
  
  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-product-query", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => { console.log(item.keyWords.toLowerCase().includes(e.target.value.toLowerCase())); (item.keyWords.toLowerCase().includes(e.target.value.toLowerCase())) });
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const ExpandedComponent = ({ data }) => {
    return <div style={{ width: "100%", fontWeight: "bolder", padding: "5px" }}>
      <ul>
        <li><u>Query for (Product)</u> : {data.product.productName}</li>
        <li><u>Query for (Company)</u> : {data.product.company.company} ({data.product.company.membershipPlan} Member )</li>
        <li> <u>Contact Email</u> : {data.email}</li>
        <li> <u>Message</u> : {data.queryMessage}</li>
      </ul>
    </div>;
  }

  const expandItem = () => {
    return <h3 className="text-danger m-1 ml-2">
      <i className="fas fa-caret-square-up"></i>
    </h3>
  }
  const collapseItem = () => {
    return <h3 className="text-primary m-1 ml-2">
      <i className="fas fa-caret-square-down"></i>
    </h3>
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Product Queries</h4>
          <hr className="my-2" />
        </div>
      </div>

      <Input id="search" name="search" className="border-primary" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      <DataTable pagination persistTableHead highlightOnHover columns={columns} data={filterItems} progressPending={pending} expandableRows expandableRowsComponent={ExpandedComponent} expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }} />
    </div>
  );
}

export default ProductQueries;
