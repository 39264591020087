import React from 'react'
import Select from 'react-select'
function SelectJS(props) {
    const customThemeFn = (theme) => ({ 
        ...theme,
        spacing: {
          ...theme.spacing,
          controlHeight: 30,
          baseUnit: 2
        }
      })
      const colourStyles = {
        option: (base, state) => ({
          ...base,
          fontWeight: state.isSelected ? "400" : "400",
        }),
        control: provided => ({
          ...provided,
          fontWeight: "400"
        }),
        singleValue: provided => ({
          ...provided,
          fontWeight: "400"
        })
      }
  return (
    <Select onChange={(e)=>{props.selected(e)}} defaultInputValue={props.defValue} theme={props.sm?customThemeFn:''} options={props.data} styles={colourStyles} />
  )
}

export default SelectJS