import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import style from "./style.module.css";
import { Button, ButtonGroup } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent'
import { getCookie } from "../../../../Helper/Cokkies";
import { userValidate } from "../../../../components/Utils/Utils";
import { UpdateUserCompany } from "../../../../Redux/CompanyUser";
import Swal from "sweetalert2";
const axios = require("axios").default;

function LeadsList() {
  const dispatch = useDispatch()
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [rSelected, setRSelected] = useState(true);
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  let token = getCookie("token");
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [isSearch, setIsSearch] = useState(false);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });
  const verify_admin_hr = userValidate(['admin', 'hr'], 'token');

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Unique ID</strong>,
      selector: (row) => row.leadId,
      sortable: true,
      width: "150px",
    },
    {
      name: <strong>Title</strong>,
      selector: (row) => row.leadTitle,
      sortable: true,
      width: "330px",
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => row.leadingName,
      sortable: true,
      width: "180px",
    },
    {
      name: <strong>Add On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>Action</strong>,
      cell: (row) =>
        verify_admin_hr ? (
          <>
            <Link to={"/admin/update-lead/" + row._id} state={row}>
              <IconButton>
                <EditIcon fontSize="small" color="info" />
              </IconButton>
            </Link>
            <IconButton onClick={() => deleteItem(row._id)}>
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </>
        ) : (
          <button
            className={"btn btn-sm btn-info py-0 " + style.actionBtn}
            onClick={(e) => {
              buyConfirmation(row, currentCompanyUser._id)
            }}
            color="primary"
            size="small"
          >
            Buy
          </button>
        ),
      button: true,
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-lead", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const buyConfirmation = (lead, compId) => {
    Swal.fire({
      html: `
      <h5 class="mb-3">Balance Lead Details :</h5>
      <table class="table table-sm table-borderless">
        <tbody>
          <tr>
            <td class="text-left">You available limit: ${currentCompanyUser.leadsCredits}</td>
            <td>${currentCompanyUser.leadsCredits}</td>
          </tr>
          <tr>
            <td class="text-left">Limit used for this lead</td>
            <td>1</td>
          </tr>
          <tr>
            <th class="text-left">Remaining lead Balance</th>
            <th >${currentCompanyUser.leadsCredits - 1}</th>
          </tr>
        </tbody>
      </table>
      `,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Buy Now'
    }).then((result) => {
      if (result.isConfirmed) {
        buyLead(lead, compId)
      } else {
        addLogs("User Click on Buy Lead but canceled :- " + lead.leadTitle, compId)
      }
    })
  }

  const addLogs = (sub, compId) => {
    axios
      .post(api.url + "/add-user-log", { subject: sub, company: compId })
      .then(function (response) {
        console.log(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const buyLead = (lead, compId) => {
    axios
      .post(api.url + "/buy-lead", { lead: lead._id, company: compId }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response);
        if (response.data.leads) {
          Swal.fire(
            'Purchased!',
            'You have Purchased this Leads...',
            'success'
          )
          let newData = currentCompanyUser;
          newData = { ...newData, leadsCredits: currentCompanyUser.leadsCredits - 1 };
          addLogs("User Click on Buy Lead and confirmed :- " + lead.leadTitle +"( "+ lead.leadId  +" )", compId)
          dispatch(UpdateUserCompany(newData))
        } else {
          alert(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (currentCompanyUser.user) {
      setPending(true);
      axios
        .post(api.url + "/get-leads-for-admin", { user: currentCompanyUser, selectedLeads: rSelected, page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
        .then(function (response) {
          // console.log(response.data);
          response.data.leads.forEach((item, index) => {
            item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
          });
          setTotalRows(response.data.total);
          setIsSearch(false)
          setListData(response.data.leads);
          setFilterItems(response.data.leads);
          setPending(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [currentCompanyUser, rSelected, tableOptions, isSearch]);

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder" }}>
        <ul>
          <li>
            <u>Title</u> : {data.leadTitle}
          </li>
          <li>
            <u>Message</u> : {data.leadMessage}
          </li>
          <li>
            <u>Company Name</u> : {data.company}
          </li>
          <li>
            <u>Contact No.</u> :{" "}
            {verify_admin_hr ? data.contact : <Button
              color="link"
              onClick={(e) => {
                alert("Please purchase lead for View Contact No.");
              }}
              className="text-danger text-decoration-none py-0"
            >
              XXXXXXXXXX
            </Button>}
          </li>
          <li>
            <u>Email</u> :{" "}
            <Button
              color="link"
              onClick={(e) => {
                alert("Please purchase lead for View Email.");
              }}
              className="text-danger text-decoration-none py-0"
            >
              xxxxxxxx@xxxxx.xxx
            </Button>
          </li>
          <li>
            <u>Country</u> : {data.country}
          </li>
        </ul>
      </div>
    );
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Leads List</h4>
          <hr className="my-2" />
        </div>
      </div>

      {verify_admin_hr ? (
        ""
      ) : (
        <ButtonGroup className="mb-2">
          <Button
            outline
            className="btn-round px-5 btn-outline-secondary text-secondary "
            size="sm"
            onClick={() => {
              setPending(true);
              setRSelected(true);
            }}
            active={rSelected}
          >
            Leads for you
          </Button>
          <Button
            outline
            className="btn-round px-5 btn-outline-secondary text-secondary "
            size="sm"
            onClick={() => {
              setPending(true);
              setRSelected(false);
            }}
            active={!rSelected}
          >
            All Buy Leads
          </Button>
        </ButtonGroup>
      )}

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => { setIsSearch(true); addLogs("Search leads with key - " + filterValue, currentCompanyUser._id) }}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default LeadsList;
