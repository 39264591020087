import axios from 'axios';
import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { getCookie } from '../../Helper/Cokkies';
import { api } from '../../Helper/Data';

function ChangePassword() {
  const location = useLocation();
  const adminPortal = location.pathname.includes("admin");
  const navigate = useNavigate()
  const token = getCookie(adminPortal ?"token": "alcToken");
  const [hidePass, setHidePass] = useState(true);
  const [formData, setFormData] = useState(location.state);
  const [passwords, setPasswords] = useState({ id: formData.user._id, newPassword: "", confirmPassword: "" });

  const passwordShow = () => {
    hidePass ? setHidePass(false) : setHidePass(true);
  };
  const changeHandle = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!passwords.confirmPassword || !passwords.newPassword) {
      alert("Please enter mandetory fields");
    } else {
      if (passwords.newPassword === passwords.confirmPassword) {
        axios
          .post(
            api.url + "/update-user-password",
            { id: passwords.id, newPassword: passwords.newPassword, changeFor: adminPortal ? "admin" : "allocation" },
            { headers: { authorization: "Bearer " + token } }
          )
          .then(function (response) {
            if (response.data.success) {
              alert("Password change successfull");
              navigate(adminPortal ? "/admin/view-list" : "/allocation/candidates")
            }
          })
          .catch(function (error) {
            alert(error.response.data.error);
          });
      } else {
        alert("Confirm Password is not match");
      }
    }
  };
  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Change User's Login Password</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form style={{ maxWidth: "450px" }} onSubmit={submitHandler}>
          {adminPortal ? <h5>Company: <strong>{formData.company}</strong></h5> : <h5>User Name: <strong>{formData.user.fullname}</strong></h5>}
          <FormGroup>
            <Label>Login Id </Label>
            <Input disabled value={formData.user.email} type="text" />
          </FormGroup>{" "}
          <FormGroup>
            <Label for="newPassword">New Password <small>
              <sup className="text-danger">(Required)</sup>
            </small></Label>
            <Input id="newPassword" name="newPassword" onChange={(e) => changeHandle(e)} placeholder="Enter New Password" type="text" />
          </FormGroup>{" "}
          <FormGroup>
            <Label for="confirmPassword">Confirm Password <small>
              <sup className="text-danger">(Required)</sup>
            </small></Label>
            <InputGroup>
              <Input type={hidePass ? "password" : "text"} name="confirmPassword" onChange={(e) => changeHandle(e)} placeholder="Confirm Password" />
              <InputGroupAddon onClick={passwordShow} addonType="prepend">
                <InputGroupText >
                  {hidePass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <Button>Submit</Button>
        </Form>
      </div>
    </div>
  )
}

export default ChangePassword