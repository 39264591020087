import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { api } from '../../../Helper/Data';
import { Link } from 'react-router-dom';

export default function BlogsItem(props) {
  return (
    <Card >
        <CardMedia
          component="img"
          alt={props.data.blogTitle}
          image={api.blogImg + props.data.blogImages[0]}
        />
      <CardContent className='pb-0 pt-1'>
        <Link to={"/blogs/"+props.data.blogLink}>
          <Typography gutterBottom variant="h6" component="div">
           <small> {props.data.blogTitle.substring(0, 80)}...</small>
          </Typography>
        </Link>
      </CardContent>
    </Card>
  );
}