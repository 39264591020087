import { createSlice } from '@reduxjs/toolkit'


export const countriesSlice = createSlice({
  name: 'countries',
  initialState : {
    countries: [],
  },
  reducers: {
    setContries: (state, action) => {
      state.countries = action.payload
    },
  },
})

export const { setContries } = countriesSlice.actions

export default countriesSlice.reducer