import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import style from './style.module.css'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { TextField } from '@mui/material';
import { Button,Spinner } from 'reactstrap';
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import axios from 'axios';

export default function EditPartyStatus() {
  const { id } = useParams()
  const [constDetails, setConstDetails] = useState(null)
  const [details, setDetails] = useState(null)
  const [pending, setPending] = useState(true);
  let token = getCookie("alcToken");
  const [submiting, setSubmiting] = useState(false)

  useEffect(() => {
    setPending(true)
    axios
        .post(api.url + "/get-party-status", { id: id }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
            console.log(response.data);
            setConstDetails(response.data.details);
            setDetails(response.data.details);
            setPending(false);
        })
        .catch(function (error) {
            console.log(error);
        });
}, [id, token])

  const checkBoxChange = (e, key) => {
    let newObj = { ...details[key], "isComplete": e.target.checked, date: (constDetails[key].comment !== details[key].comment) || (constDetails[key].isComplete !== e.target.checked) ? (new Date()).toISOString(): constDetails[key].date }
    setDetails({ ...details, [key]: newObj })
  }
  
  const changeHandle = (e, key) => {
    let newObj = { ...details[key], "comment": e.target.value, date: (constDetails[key].comment !== e.target.value) || (constDetails[key].isComplete !== details[key].isComplete) ? (new Date()).toISOString(): constDetails[key].date }
    setDetails({ ...details, [key]: newObj })
  }

  const submitHandle = (e) => {
    setSubmiting(true)
    axios
      .post(api.url + ("/edit-perty-status"), details, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response);
        if (response.data.success) {
          alert("Update Successfully")
          setSubmiting(false)
        } else {
          alert("Only 3 candidate can add")
          setSubmiting(false)
        }
      })
      .catch(function (error) {
        console.log(error);
        setSubmiting(false)
      });
  }

  return (
    details === null ? "" : <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0">Company Details : <span className='text-dark d-inline'>{details.party.company}</span></h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <h6 className={"m-0 "}>Catalogue</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "catalogue")} checked={details.catalogue.isComplete} size="small" />} label={details.catalogue.isComplete ? "Completed" : "Not Completed"} /> </li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.catalogue.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "catalogue")} /> </li>
        </ul>
        <h6 className="m-0">Acknowledgement</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "ackEmail")} checked={details.ackEmail.isComplete} size="small" />} label={details.ackEmail.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.ackEmail.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "ackEmail")} /> </li>
        </ul>
        <h6 className="m-0">Payment</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "payment")} checked={details.payment.isComplete} size="small" />} label={details.payment.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.payment.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "payment")} /> </li>
        </ul>
        <h6 className="m-0">Catalogue Activation</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "activation")} checked={details.activation.isComplete} size="small" />} label={details.activation.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.activation.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "activation")} /> </li>
        </ul>
        <h6 className="m-0">Invoice Email</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "invoiceEmail")} checked={details.invoiceEmail.isComplete} size="small" />} label={details.invoiceEmail.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.invoiceEmail.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "invoiceEmail")} /> </li>
        </ul>
        <h6 className="m-0">Catalogue Email</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "catalogueEmail")} checked={details.catalogueEmail.isComplete} size="small" />} label={details.catalogueEmail.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.catalogueEmail.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "catalogueEmail")} /> </li>
        </ul>
        <h6 className="m-0">Website</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "site")} checked={details.site.isComplete} size="small" />} label={details.site.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.site.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "site")} /> </li>
        </ul>
        <h6 className="m-0">SEO</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "seo")} checked={details.seo.isComplete} size="small" />} label={details.seo.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.seo.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "seo")} /> </li>
        </ul>
        <h6 className="m-0">Site Keywords</h6>
        <hr className='m-0' style={{ maxWidth: "200px" }} />
        <ul className={style.contentList}>
          <li className='px-3'>Status : <FormControlLabel control={<Checkbox className='py-0' onChange={(e) => checkBoxChange(e, "siteKeywords")} checked={details.siteKeywords.isComplete} size="small" />} label={details.siteKeywords.isComplete ? "Completed" : "Not Completed"} /></li> |
          <li className='px-3 w-100'><TextField fullWidth label="Comment..." value={details.siteKeywords.comment} multiline size='small' rows={2} onChange={(e) => changeHandle(e, "siteKeywords")} /> </li>
        </ul>
        <Button className="btn-sm btn-main px-4" onClick={(e) => { submitHandle(e) }} disabled={submiting} type="submit">
          Update {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
      </div>
    </div>
  )
}
