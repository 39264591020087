import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";
import style from "./style.module.css";

export default function BreadCrumb(props) {
  return (
        <Breadcrumb className={style.breadcrumbContainer}>
          <BreadcrumbItem>
            <Link to="/">Home</Link>
          </BreadcrumbItem>
          {props.primary ? (
            <BreadcrumbItem>
              <Link to={props.primary.url}>{props.primary.text}</Link>
            </BreadcrumbItem>
          ) : (
            ""
          )}
          {props.secondary ? (
            <BreadcrumbItem>
              <Link to={props.secondary.url}>{props.secondary.text}</Link>
            </BreadcrumbItem>
          ) : (
            ""
          )}
          <BreadcrumbItem active>{props.current}</BreadcrumbItem>
        </Breadcrumb>
  );
}
