import React, { useEffect } from 'react'
import {removeCookie} from '../../../../Helper/Cokkies'
import { useNavigate } from "react-router-dom";
import { removeLocalStorage} from '../../../../Helper/LocalStorage'
function Logout() {
    const navigate=useNavigate()
    useEffect(() => {
        removeLocalStorage("user");
        removeCookie("token");
       return navigate("/admin/login");
    }, [navigate])
    
  return (
    <div>Logout</div>
  )
}

export default Logout