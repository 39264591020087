import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data"
import { getCookie } from "../../../../Helper/Cokkies";
import { IconButton } from "@mui/material";
import { formValidation } from "../../../../components/Utils/Utils"
import DeleteIcon from '@mui/icons-material/Delete';
import DataTable from "react-data-table-component";
import moment from "moment";
const axios = require("axios").default;

function TrendingProduct() {
  let token = getCookie("token");
  const [formData, setFormData] = useState({ productName: "", imageUrl: [] });
  const [pending, setpending] = useState(true);
  const [productList, setProductList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]); 

  useEffect(() => {
    axios.post(api.url + '/get-trending-product', { id: "id" }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response);
        if (response.data.success) {
          response.data.product.forEach((item, index) => {
            item.serial = index + 1;
          });
          setProductList(response.data.product)
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    setTimeout(() => {
      setpending(false)
    }, 300);
  }, [token])


  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>PRODUCT NAME</strong>,
      selector: (row) => row.productName,
      sortable: true,
    },
    {
      name: <strong>IMAGE</strong>,
      selector: (row) => (<div >
        <img style={{ height: "50px" }} src={api.trendProduct + row.imageUrl[0]} alt="Tending product" />
      </div>),
      sortable: true,
    },
    {
      name: <strong>Add On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "130px",
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => <IconButton onClick={(e) => deletehandle(row._id)} className={style.actionBtn} color="error" size="small">
        <DeleteIcon fontSize="small" />
      </IconButton>,
      width: "100px",
    },
  ];

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deletehandle = (id) => {
    try {
      axios.post(api.url + '/delete-trending-product', { id }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            alert("Delete Successfull")
            const filtered = productList.filter((el) => {
              return el._id !== id
            })
            setProductList(filtered)
          }
        })
        .catch(function (error) {
          alert(error.response.data.message)
        });
    } catch (error) {
      console.log(error);
    }
  }

  const uploadLogoImg = (el) => {
    const file = el.target.files[0]
    let postPhoto = [];
    var reader = new FileReader();
    const form = new FormData();
    if (el.target.files && file) {
      postPhoto.push(file.name);
      form.append("trendFile", file, file.name);
      setUploadFiles(form);
      reader.readAsDataURL(file);
      if (file.size > 70000) {
        alert("Size sould less then 70 kb ");
        setFormData({ ...formData, imageUrl: [] });
        el.target.value = "";
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width === 256 && height === 171) {
              if (postPhoto.length) {
                setFormData({ ...formData, imageUrl: postPhoto });
              }
            } else {
              alert("image size should be 256 * 171 px")
              setFormData({ ...formData, imageUrl: [] });
              el.target.value = "";
            }
          };
        };

      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formValidation([formData.productName]) && formData.imageUrl.length) {
      axios
      .post(api.url + "/upload-trendImage", uploadFiles)
      .then(function (response) {
        // console.log(response.data);
          if (response.data.success) {
            addTrendProduct(formData, response.data.newFileName);
          }
      })
      .catch(function (error) {
          console.log(error);
      });
    } else {
      alert("Please fill the required fields");
    }
  }

  const addTrendProduct=(data, newName)=>{
    let newData = data;
        newData = { ...newData, imageUrl: [newName] };
    axios.post(api.url + '/add-trending-product', newData, { headers: { authorization: "Bearer " + token } })
    .then(function (response) {
      // console.log(response);
      if (response.data.success) {
        alert("Add Successfull")
        response.data.product.serial = productList.length + 1
        setProductList([...productList, response.data.product])
        setFormData({ productName: "", imageUrl: "" })
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Category</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <Form onSubmit={submitHandler}>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label for="productName" className={style.formLabel}>Product Name</Label>
              <Input
                bsSize="sm"
                id="productName"
                name="productName"
                value={formData.productName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Product Name..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Label className={style.formLabel} for="image">
                Product Image <small className="text-info">image size should be 256 * 171 px</small>
              </Label>
              <Input
                bsSize="sm"
                id="image"
                className={style.fileInput}
                name="image"
                accept="image/*"
                onChange={(e) => uploadLogoImg(e)}
                type="file"
              />
            </FormGroup>
            <FormGroup className="col-md-4">
              <Button type="submit" className="btn-sm btn-main px-4">Add List</Button>
            </FormGroup>
          </div>
        </Form>

        {/* Table */}
        <p className="m-0 text-dark py-2"> <strong>Products List :</strong></p>
        <DataTable pagination persistTableHead highlightOnHover columns={columns} progressPending={pending} data={productList} />
      </div>
    </div>
  );
}

export default TrendingProduct;
