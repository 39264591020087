import React from 'react'
import style from './style.module.css'
import { Link } from 'react-router-dom';
import { Container } from '@mui/material';

function CategoryCard(props) {
  
  return (
    <Container className="py-3">
      <div className="card-outline card-danger bg-white shadow-sm">
        <div className="card-header">
          <h3 className={"card-title " + style.cardTitle}>
            {props.data.category}
          </h3>
          <div className="card-tools ">
            <Link className="font-main" to={"/directory/" + props.data.category.toLowerCase().split(" ").join('-')} state={{ category: props.data.category, subCategory: props.data.subCategories }} >
              -View More-
            </Link>
          </div>
        </div>
        <div className={'card-body d-flex '+ style.catBody}>
          <div className={style.catHeader}>
            <img src={props.imageUrl} alt="" />
          </div>
          <div className='row px-2 w-100'>
            {props.data.subCategories.map((el, i) => {
              return i < 12 ? <Link key={i} to={`/products?category=${el.subCategory.toLowerCase().replaceAll(" ", "-")}`} className={"col-6 col-sm-3 p-1"} style={{minHeight:"120px"}}>
                <div className={style.subCategoriesItem}>
                  <img style={{width:"100%"}} src={el.imageUrl} alt="" />
                  <p className='m-0'><strong>{el.subCategory.substring(0, 15)}</strong></p>
                </div>
              </Link> :""
            })}
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CategoryCard