import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Badge, Button, FormGroup, Label, Modal } from 'reactstrap';
import { api } from '../../../../Helper/Data';
import { getLocalStorage } from '../../../../Helper/LocalStorage';
import { FormControlLabel, TextareaAutosize, TextField } from "@mui/material";
import { styled } from '@mui/material/styles';
import CreatableSelect from 'react-select/creatable';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Switch from '@mui/material/Switch';

function PartyComments(props) {
    const [commentList, setCommentList] = useState([{ remarks: "" }]);
    const [company, setCompany] = useState("")
    const [remarks, setRemarks] = useState("")
    const [scheduleDate, setScheduleDate] = useState("")
    const [commentLoad, setCommentLoad] = useState(true)
    const [schedule, setSchedule] = useState(false);
    const [productsList, setProductsList] = useState(props.party.productsList);
    const user = getLocalStorage("alcUser")

    useEffect(() => {
        axios
            .post(props.support ? (api.url+"/get-support-comments"): (api.url+"/get-party-comments"), { party: props.party._id })
            .then(function (response) {
                console.log(response.data);
                if (response.data.comment.length) {
                    setCommentList(response.data.comment);
                    setCompany(response.data.comment[0].party.company)
                }
                setCommentLoad(false)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [props.party]);

    const Android12Switch = styled(Switch)(({ theme }) => ({
        padding: 8,
        '& .MuiSwitch-track': {
            borderRadius: 22 / 2,
            '&:before, &:after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 16,
                height: 16,
            },
            '&:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
                left: 12,
            },
            '&:after': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
                    theme.palette.getContrastText(theme.palette.primary.main),
                )}" d="M19,13H5V11H19V13Z" /></svg>')`,
                right: 12,
            },
        },
        '& .MuiSwitch-thumb': {
            boxShadow: 'none',
            width: 16,
            height: 16,
            margin: 2,
        },
    }));

    const customThemeFn = (theme) => ({
        ...theme,
        spacing: {
            ...theme.spacing,
            controlHeight: 30,
            baseUnit: 2,
        },
    });

    const addComment = (e) => {
        console.log(user);
        console.log(props.party);
        
        setCommentLoad(true)
        axios
            .post(props.support ? (api.url + "/add-support-comment") : (api.url + "/add-comment"), { remarks: remarks, party: props.party, remarksBy: `${user.name} ( ${user.username} )`, isScheduled: schedule, schedule: scheduleDate, productsList: productsList })
            .then(function (response) {
                // console.log(response.data);
                setCommentList([...commentList, response.data.comment])
                // props.assigned(props.party._id);
                alert("Comment added...");
                setCommentLoad(false)
                // props.setModal(false);
            })
            .catch(function (error) {
                console.log(error);
                setCommentLoad(false)
            });
    };

    return (
        <>
            <Button className="btn-round mx-1" style={{ fontSize: "10px", padding: "3px 10px" }} onClick={() => props.setModal(true)} >
                <i className="fas fa-comment-dots"></i> Comments
            </Button>
            <Modal
                isOpen={props.open}
                toggle={() => {
                    props.setModal(false);
                }}
                className={"modal-md"}
            >
                <div className="modal-header no-border-header text-center pb-0">
                    <button className="close" type="button" onClick={() => { props.setModal(false)}}> <span aria-hidden={true}>×</span> </button>
                    <h5 className="text-center">
                        <strong>{company} Comments</strong>
                    </h5>
                </div>
                <div className="modal-body mb-3 px-4 py-1">
                    {/* Comment List */}
                    <div className="commentList" style={{ maxHeight: "300px", overflow: "auto" }}>
                        {commentLoad ? <h4 className='my-1'>Loading...</h4> : commentList.map((el, i) => {
                            return el.remarks ? <div key={i} className={"callout callout-info py-1 my-1"}>
                                <p className="m-0">{el.remarks}<Badge className='float-right m-0'
                                    color={el.status === "Follow up" ? "danger" : el.status === "Call back" ? "success" : el.status === "Prospective" ? "info" : el.status === "Materialize" ? "warning" : "secondary"}
                                    pill
                                >
                                    <small>{el.status}</small>
                                </Badge></p>
                                <small> By : {el.remarksBy} <span className="float-right"> <span><i className="far fa-clock"></i> {moment(el.remarksAt).format("DD/MM/YYYY hh:mm A")}</span></span></small>
                            </div> : "No Comment Added.."
                        })
                        }
                    </div>

                    <FormGroup className='my-0'>
                        <FormControlLabel className='m-0'
                            control={<Android12Switch defaultChecked={schedule} onChange={(e) => setSchedule(e.target.checked)} />}
                            label="Set Schedule"
                        />
                    </FormGroup>

                    {schedule ? <FormGroup className='my-2'>
                        <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label="Call Schedule"
                                renderInput={(params) => <TextField {...params} />}
                                value={scheduleDate}
                                onChange={(newValue) => {
                                    setScheduleDate(moment(newValue._d).format());
                                }}
                            />
                        </LocalizationProvider>
                    </FormGroup> : ""}

                    {!props.notComment ? <div className="form-row py-2">
                        <FormGroup className="mb-2 col-12 border pb-2">
                            <Label>Products</Label>
                            <CreatableSelect
                                defaultValue={productsList.map((el) => {
                                    return { value: el, label: el }
                                })}
                                isMulti
                                onChange={(e) => {
                                    setProductsList(e.map((el) => el.value));
                                }}
                                theme={customThemeFn}
                                name="tagKeywords"
                                id="tagKeywords"
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                        </FormGroup>
                        <FormGroup className="m-0 col-12">
                            <TextareaAutosize
                                aria-label="empty textarea"
                                placeholder="Add Comments..."
                                id="remarks"
                                className="w-100 rounded"
                                minRows={2}
                                name="remarks"
                                onChange={(e) => setRemarks(e.target.value)}
                            />
                        </FormGroup>
                        <Button type="submit" disabled={commentLoad} onClick={(e) => { addComment(e) }} className={"btn-main btn-sm m-2"} > Add Comment </Button>
                    </div> : ""}

                </div>
            </Modal>
        </>
    );
}

export default PartyComments