import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import DataTable from "react-data-table-component";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../Helper/Cokkies";
import { formValidation } from "../../../../components/Utils/Utils";
import { AddTLData } from "../../../../Redux/Candidates";
import { IconButton, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import LaunchIcon from '@mui/icons-material/Launch';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import moment from "moment";
const axios = require("axios").default;

function TeamLeader() {
  const dispatch = useDispatch()
  const tlData = useSelector((state) => state.CanditatesList.tlData);
  const [hidePass, setHidePass] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  let token = getCookie("alcToken");
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    axios
      .post(api.url + "/get-alcusers-for-admin", { id: "id" }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          const newList = response.data.teamLeader.map((item, index) => {
            return { ...item, serial: index + 1 };
          })
          setListData(newList)
          setFilterItems(newList)
          setPending(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deleteItem = (id) => {
    axios
      .post(
        api.url + "/delete-candidate",
        { id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([formData.username, formData.email])) {
      if (filter.test(formData.email)) {
        axios
          .post(api.url + "/new-team-leader", formData, { headers: { authorization: "Bearer " + token } })
          .then(function (response) {
            if (response.data.success) {
              alert("Successfully Added");
              // console.log(response.data);
              dispatch(AddTLData(response.data.data))
              setFormData({
                username: "",
                fullname: "",
                email: "",
                password: "",
                confirmPassword: "",
              });
            } else {
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        alert("Please enter valid email");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const passwordShow = () => {
    hidePass ? setHidePass(false) : setHidePass(true);
  };

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => item.fullname && item.fullname.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const changeStatus = (id, status) => {
    axios
      .post(api.url + "/change-user-status", { id: id, status: status }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Status Updated")
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.fullname,
      sortable: true,
    },
    {
      name: <strong>Email</strong>,
      selector: (row) => row.email,
    },
    {
      name: <strong>DOJ</strong>,
      selector: (row) => moment(row.doj).format("DD/MM/YYYY"),
      width: "150px",
      sortable: true,
    },
    {
      name: <strong>Active</strong>,
      selector: (row) => (
        <Tooltip
          title={row.isActive ? "Active" : "Inactive"}
          placement="top"
          arrow
        >
          <IconButton
            onClick={(e) => {
              window.confirm("Change Status")
                ? changeStatus(row._id, !row.isActive)
                : alert("Cancel");
            }}
          >
            {row.isActive ? (
              <CheckCircleOutlineIcon fontSize="small" color="success" />
            ) : (
              <HighlightOffIcon fontSize="small" color="error" />
            )}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      name: <strong>ACTION</strong>,
      width: "250px",
      cell: (row) => (
        <div>
            <Tooltip title="Allocate" placement="top" arrow>
              <Link
                to={"/allocation/allocation-list/" + row.username}
                state={{ user: row }}
              >
                <IconButton>
                  <LaunchIcon fontSize="small" color="secondary" />
                </IconButton>
              </Link>
            </Tooltip>{" "}
            <Tooltip title="Change Password" placement="top" arrow>
              <Link
                to={"/allocation/change-password"}
                state={{ user: row }}
              >
                <IconButton>
                  <VpnKeyIcon fontSize="small" color="info" />
                </IconButton>
              </Link>
            </Tooltip>{" "}
            <Tooltip title="Update" placement="top" arrow>
              <Link
                to={"/allocation/user-update/" + row._id}
              >
                <IconButton>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>{" "}
            <IconButton
              onClick={(e) => {
                deleteItem(row._id);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
        </div>
      ),
    }
  ];

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Team Leader</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="username">
                User Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="username"
                name="username"
                value={formData.username}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter First Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="fullname">
                Full Name <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="fullname"
                name="fullname"
                value={formData.fullname}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Full Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="password">
                New Password{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                id="password"
                bsSize="sm"
                name="password"
                onChange={(e) => changeHandler(e)}
                placeholder="Enter New Password"
                type="text"
              />
            </FormGroup>{" "}
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="confirmPassword">
                Confirm Password{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <InputGroup>
                <Input
                  type={hidePass ? "password" : "text"}
                  bsSize="sm"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={(e) => changeHandler(e)}
                  placeholder="Confirm Password"
                />
                <InputGroupAddon onClick={passwordShow} addonType="prepend">
                  <InputGroupText className={style.addOnInput}>
                    {hidePass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </div>
          <Button type="submit" className="btn-sm btn-main px-4">
            Add Team Leader
          </Button>
        </Form>
      </div>

      <div className="py-3 px-4">
        <div className="content-header p-0">
          <div className="container-fluid">
            <h4 className="m-0 text-dark">Team Leaders List</h4>
            <hr className="my-2" />
          </div>
        </div>

        <InputGroup style={{ border: "solid 1px #007bff", borderRadius: "5px" }}>
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="nc-icon nc-zoom-split"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input id="search" name="search" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
        </InputGroup>

        <DataTable
          pagination
          persistTableHead
          highlightOnHover
          columns={columns}
          data={filterItems}
          progressPending={pending}
        />
      </div>
    </div>
  );
}

export default TeamLeader;
