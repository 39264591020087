import BreadCrumb from "Modules/Public/Components/BreadCrumb/BreadCrumb";
import React from "react";
import style from './style.module.css';
import Slider from "react-slick";
import { api } from "../../../../Helper/Data";

export default function Testimonial() {
  const settings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 5000,
    arrows: false,
    slidesToScroll: 1,
    cssEase: "linear",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 550,
        settings: {
          slidesPerRow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesPerRow: 1,
        },
      },
    ],
  };

  return (
    <div className="container py-3">
      <BreadCrumb current={"Testimonial"} />
      <Slider className="m-2" {...settings}>
        {/* {allData.testimonial ? allData.testimonial.map((el, i) => {
          return (
            <div key={i}>
              <div className={style.testimonialItem}>
                <div className={style.testimonialHead}>
                  {el.logo !== "noLogo.png" ? <img style={{ height: "70px", marginRight: "5px", borderRadius: "5px" }} src={api.companyUrl + el.logo} alt="" /> : ""}
                  <div className="testimonialTitle">
                    <h5 className="m-0 text-primary">
                      {el.companyName}
                    </h5>
                    <p className="m-0">
                      {el.fullName}
                    </p>
                    <small>
                      {el.designation}
                    </small>
                  </div>
                </div>
                <p style={{ textAlign: "justify", padding: "5px" }}>{el.message}</p>
              </div>
            </div>
          )
        }) : ""} */}
      </Slider>
    </div>
  );
}
