import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Notfound from "../../../components/404/Notfound";
import Blogs from "../View/Blogs";
import BlogDetails from "../View/BlogDetails";
import BlogLayout from "./BlogLayout";

export default function BlogRoutes() {

    return (
        <Routes>
            <Route path="/" element={<BlogLayout />}>
                <Route path="/" element={<Blogs />} />
                <Route path="/:blogLink" element={<BlogDetails />}></Route>
            </Route>
            <Route path="*" element={<Navigate replace to="404" />} />
            <Route path="/404" element={<Notfound direction={'admin'} />}></Route>
        </Routes>
    );
}
