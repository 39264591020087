import React, { useEffect, useState } from "react";
import style from "./style.module.css";
import { api } from "../../Helper/Data";
import { Link, Navigate } from 'react-router-dom'
import FormControlLabel from '@mui/material/FormControlLabel';
import { formValidation } from '../../components/Utils/Utils'
import Checkbox from '@mui/material/Checkbox';
import { Button, FormGroup, Input, Label, Modal } from "reactstrap";
const axios = require("axios").default;

export default function PaymentForm() {
  const [formData, setFormData] = useState({
    membershipPlan: "", serviceYear: "1", currency: "INR", amount: "", name: "", email: "", contact: "", companyName: "", address: "", payGateway: ""
  })
  const [errorData, setErrorData] = useState({})
  const [privacyCheck, setPrivacyCheck] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [liveDemo, setLiveDemo] = useState(false);
  const [otherPlan, setOtherPlan] = useState(false);

  const checkvalidation = (event) => {
    if (!formData[event.target.name]) {
      setErrorData({ ...errorData, [event.target.name]: `${event.target.name} is mendatory.` })
    } else {
      setErrorData({ ...errorData, [event.target.name]: "" })
    }
  }

  const changeHandle = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const selectPlan = (e) => {
    e.target.value === 'Other' ? setOtherPlan(true) : setOtherPlan(false)
    setFormData({ ...formData, membershipPlan: e.target.value })
  }

  const privacyCheckFunc = (e) => {
    setPrivacyCheck(e.target.checked)
  }

  const submitForm = (e) => {
    e.preventDefault()
    if (formValidation([formData.membershipPlan, formData.name, formData.amount, formData.email, formData.contact, formData.companyName, formData.address, formData.payGateway])) {
      if (Object.values(errorData).join("") !== "") { alert("Please fill all fields") } else if (!privacyCheck) {
        alert("Please check term & conditions")
      } else {
        setLiveDemo(true)
      }
    } else {
      alert('Please fill all mandatory field')
    }
  }

  const modalToogle = () => {
    setLiveDemo(!liveDemo)
  }

  const confirmPay = (e) => {
    axios
      .post(api.url + "/pay-order", formData)
      .then(function (response) {
        setLiveDemo(!liveDemo)
        setFormData({ ...formData, 'orderId': response.data.id })
        verifyPayment(response.data)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  if(paymentSuccess){
    return <Navigate to={'/'}/>
  }

  const verifyPayment = (data) => {
    const options = {
      key: process.env.RZPAY_ID,
      amount: data.amount,
      currency: data.currency,
      name: "World Web Trade",
      description: "Welcome to Web Bright Infra Pvt. Ltd.",
      image: "https://www.worldwebtrade.com/public/image/logos/logomini.png",
      config: {
        display: {
          hide: [{ method: 'paylater' }, { method: 'emi' }],
          preferences: { show_default_blocks: true },
        },
      },
      prefill: { email: data.email, contact: data.contact },
      order_id: data.id,
      handler: async (response) => {
        response.data = formData
        try {
          const verifyUrl = api.url + "/verify-payment";
          const { data } = await axios.post(verifyUrl, response);
          console.log(data)
          alert(data.message)
          setPaymentSuccess(true)
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  }


  return (
    <div style={{ background: `url(${api.logosUrl}bg.webp)` }} className={style.mainContainer}>
      <nav className={"navbar bg-white " + style.fixedTop}>
        <div className="container-fluid">
          <Link to={"/"} className={"navbar-brand p-2 " + style.navbarBrand}>
            <img src={api.logosUrl + "logo.png"} alt="" />
          </Link>
          <a
            className="d-flex text-decoration-none"
            href="mailto:info.worldwebtrade@gmail.com"
            data-bs-toggle="tooltip"
            data-bs-placement="bottom"
            title="Click for compose email."
          >
            info.worldwebtrade@gmail.com
          </a>
        </div>
      </nav>

      <div className={"container " + style.formContainer}>
        <div className={style.cardCantainer}>
          <form id="post_form" onSubmit={submitForm} className="card-body form-row g-3 px-5 py-4">
            <h5 className={"col-md-12  " + style.formSection}>
              ORDER DETAILS :
              <hr className="my-2" />
            </h5>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="membershipPlan">
                Service Member
              </Label>
              <Input id="membershipPlan" onChange={(e) => selectPlan(e)} onBlur={(e) => { checkvalidation(e) }} name="membershipPlan" type="select">
                <option value="">Select</option>
                <option value="Silver">Silver Member</option>
                <option value="Platinum">Platinum Member</option>
                <option value="Platinum Pro">Platinum Pro Member</option>
                <option value="Elite">Elite Member</option>
                <option value="Other">Other</option>
              </Input>
              <strong className="text-danger px-1"> <small>{errorData.membershipPlan}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="currency">
                Currency
              </Label>
              <Input id="currency" name="currency" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="select">
                <option value="INR">Indian Rupees (INR)</option>
                <option value="USD">US Doller (USD)</option>
              </Input>
              <strong className="text-danger px-1"> <small>{errorData.currency}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="amount">
                Amount
              </Label>
              <Input id="amount" name="amount" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="number" placeholder="Enter payment amount..."></Input>
              <strong className="text-danger px-1"> <small>{errorData.amount}</small></strong>
            </FormGroup>

            {otherPlan ?
              <FormGroup className="col-md-4">
                <Label className={style.label} for="membershipPlan">
                  Other Service
                </Label>
                <Input id="membershipPlan" name="membershipPlan" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="text" placeholder="Enter Service..."></Input>
                <strong className="text-danger px-1"> <small>{errorData.membershipPlan}</small></strong>
              </FormGroup> : ""
            }

            {/* <!-- Contact Information --> */}
            <h5 className={"col-md-12 " + style.formSection}>
              CONTACT INFORMATION :
              <hr className="my-2" />
            </h5>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="name">
                Name
              </Label>
              <Input id="name" name="name" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="text" placeholder="Enter full name..."></Input>
              <strong className="text-danger px-1"> <small>{errorData.name}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="email">
                Email
              </Label>
              <Input id="email" name="email" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="text" placeholder="Enter email..."></Input>
              <strong className="text-danger px-1"> <small>{errorData.email}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="contact">
                Contact No
              </Label>
              <Input id="contact" name="contact" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="number" placeholder="Enter contact no..."></Input>
              <strong className="text-danger px-1"> <small>{errorData.contact}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-8">
              <Label className={style.label} for="companyName">
                Company Name
              </Label>
              <Input id="companyName" name="companyName" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="text" placeholder="Enter  company name..."></Input>
              <strong className="text-danger px-1"> <small>{errorData.companyName}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-4">
              <Label className={style.label} for="serviceYear">
                Service Year
              </Label>
              <Input id="serviceYear" name="serviceYear" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="select" placeholder="Enter company name...">
                {["1", "2", "3", "4", "5"].map((el) => {
                  return <option key={el} value={el}>{el} Year</option>;
                })}
              </Input>
              <strong className="text-danger px-1"> <small>{errorData.serviceYear}</small></strong>
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.label} for="address">
                Address
              </Label>
              <Input id="address" name="address" onChange={(e) => changeHandle(e)} onBlur={(e) => { checkvalidation(e) }} type="textarea" placeholder="Enter full address..."></Input>
              <strong className="text-danger px-1"> <small>{errorData.address}</small></strong>
            </FormGroup>

            {/* <!-- Payment Option--> */}
            <h5 className={"col-md-12 " + style.formSection}>
              SELECT PAYMENT GATEWAY : <hr className="my-2" />
            </h5>

            {/* <label htmlFor="payu-gateway" className={"form-check col-md-12 py-1 px-5 bg-white " + style.paymentItem}>
              <input className="form-check-input my-2" type="radio" onChange={() => changeHandle({ target: { name: 'payGateway', value: "PayU" } })} name="paymentGateway" id="payu-gateway" />
              <label className="form-check-label" htmlFor="payu-gateway">
                <img style={{ height: "35px" }} src={api.logosUrl + "payu.jpg"} alt="" />
              </label>
            </label> */}
            <label htmlFor="razorpay-gateway" className={"form-check col-md-12 py-1 px-5 bg-white " + style.paymentItem}>
              <input
                className="form-check-input my-2"
                onChange={() => changeHandle({ target: { name: 'payGateway', value: "RazorPay" } })}
                type="radio"
                name="paymentGateway"
                id="razorpay-gateway"
              />
              <label className="form-check-label" htmlFor="razorpay-gateway">
                <img style={{ height: "35px" }} src={api.logosUrl + "razorpay.png"} alt="" />
              </label>
            </label>
            <strong className="text-danger px-1"> <small>{errorData.paymentGateway}</small></strong>
            {/* <div className="d-flex align-items-start">
              <div className="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                <button className={"nav-link active " + payType} id="pay-credit-card-tab" data-bs-toggle="pill"
                  data-bs-target="#pay-credit-card" type="button" role="tab" aria-controls="pay-credit-card"
                  aria-selected="true">Credit Card</button>
                <button className={"nav-link " + payType} id="pay-debit-card-tab" data-bs-toggle="pill"
                  data-bs-target="#pay-debit-card" type="button" role="tab" aria-controls="pay-debit-card"
                  aria-selected="false">Debit Card</button>
                <button className={"nav-link " + payType} id="pey-net-banking-tab" data-bs-toggle="pill"
                  data-bs-target="#pey-net-banking" type="button" role="tab" aria-controls="pey-net-banking"
                  aria-selected="false">Net Banking</button>
                <button className={"nav-link " + payType} id="pay-upi-tab" data-bs-toggle="pill"
                  data-bs-target="#pay-upi" type="button" role="tab" aria-controls="pay-upi"
                  aria-selected="false">UPI</button>
              </div>
              <div className="tab-content bg-white rounded p-3 w-100 h-100" id="v-pills-tabContent">
                <div className="tab-pane fade show active" id="pay-credit-card" role="tabpanel"
                  aria-labelledby="pay-credit-card-tab" tabindex="0">Credit Card...</div>
                <div className="tab-pane fade" id="pay-debit-card" role="tabpanel"
                  aria-labelledby="pay-debit-card-tab" tabindex="0">Debit Card...</div>
                <div className="tab-pane fade" id="pey-net-banking" role="tabpanel"
                  aria-labelledby="pey-net-banking-tab" tabindex="0">Net Banking...</div>
                <div className="tab-pane fade" id="pay-upi" role="tabpanel" aria-labelledby="pay-upi-tab"
                  tabindex="0">UPI...</div>
              </div>
            </div> */}
            <FormGroup className="col-md-12">
              <FormControlLabel control={<Checkbox defaultChecked={privacyCheck} onChange={(e) => privacyCheckFunc(e)} />} label={<span>I have read {" "}
                <Link to="/term-conditions" className="text-decoration-none"> term & conditions</Link></span>} />
            </FormGroup>
            <div className="d-grid gap-2 col-4 mx-auto">
              <button className={"btn btn-primary px-5 " + style.btnMain}>Submit</button>
            </div>
          </form>
          {liveDemo ? <ModalComponent payFunc={confirmPay} modalOpen={liveDemo} data={formData} toggleModal={modalToogle} /> : ""}
        </div>
      </div>
    </div>
  );
}


const ModalComponent = (props) => {

  const toggleFunc = (e) => {
    props.toggleModal(e)
  }

  return (
    <>
      <Modal isOpen={props.modalOpen} size={"lg"} toggle={(e) => toggleFunc(e)}>
        <div className="modal-header py-3" style={{ position: "relative" }}>
          <h5 className="modal-title" id="exampleModalLiveLabel">
            Modal title
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            style={{ position: "absolute", right: "20px", top: "20px" }}
            onClick={(e) => toggleFunc(e)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body px-3">
          <div className="row">
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Membership Plan : </Label>
                <Input disabled value={props.data.membershipPlan} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Name : </Label>
                <Input disabled value={props.data.name} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Amount : </Label>
                <Input disabled value={props.data.amount} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Email : </Label>
                <Input disabled value={props.data.email} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Contact No : </Label>
                <Input disabled value={props.data.contact} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Company : </Label>
                <Input disabled value={props.data.companyName} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Address : </Label>
                <Input disabled value={props.data.address} />
              </FormGroup>
            </div>
            <div className="col-md-6">
              <FormGroup className='px-2'>
                <Label >Gateway : </Label>
                <Input disabled value={props.data.payGateway} />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <div className="">
            <Button
              className="btn-link px-4 py-2"
              color="default"
              data-dismiss="modal"
              type="button"
              onClick={(e) => toggleFunc(e)}
            >
              Close
            </Button>
          </div>|
          <div className="">
            <Button
              className="btn-link px-4 py-2"
              color="success"
              type="button"
              onClick={(e) => props.payFunc(e)}
            >
              Pay & Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}