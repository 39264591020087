import { Avatar, Grid, Link, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import style from "./style.module.css";
import React from "react";

export default function Latestproduct(props) {
  return (
    <Grid item xs={6}>
      <Link className={style.productAvtar} target="_blank" href={`/product-details${props.data.productLink}`}>
        <ListItem className="p-0">
          <ListItemAvatar>
            <Avatar
              alt="Cindy Baker"
              sx={{ width: 30, height: 30, margin: "10px" }}
              src={props.data.company.countryFlag}
            ></Avatar>
          </ListItemAvatar>
          <ListItemText
            className={style.itemLink}
            primary={ props.data.productName}
            secondary={props.data.company.company}
          />
        </ListItem>
      </Link>
    </Grid>
  );
}
