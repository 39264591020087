import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import { Badge, Table } from "reactstrap";
import moment from "moment";
import PartyComments from '../../Components/PartyComments/PartyComments';
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import StatusPoppover from "../../Components/StatusPopover/StatusPoppover";
import { getCookie } from "../../../../Helper/Cokkies";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent';
const axios = require("axios").default;

function ScheduledParties() {
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  let token = getCookie("alcToken");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "schedule" });
  const [followUpModal, setFollowUpModal] = useState(false);
  const [callBackModal, setCallBackModal] = useState(false);
  const [prospectiveModal, setProspectiveModal] = useState(false);
  const [materializeModal, setMaterializeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const callbackProp = { text: "Call back", color: "success", icon: "fas fa-reply-all" }
  const followupProp = { text: "Follow up", color: "primary", icon: "fas fa-hand-point-right" }
  const prospectiveProp = { text: "Prospective", color: "info", icon: "fas fa-comment-dollar" }
  const materializeProp = { text: "Materialize", color: "warning", icon: "fas fa-tasks" }
  const deleteProp = { text: "Delete", color: "danger", icon: "fas fa-trash" }

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
      sortField: "schedule"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      sortable: true,
      sortField: "firstName",
      width: "250px",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
      sortField: "contact"
    },
    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <h5 className="m-0">
          <Badge
            color={row.status === "Follow up" ? "danger" : row.status === "Call back" ? "success" : row.status === "Prospective" ? "info" : row.status === "Materialize" ? "warning" : "secondary"}
            pill
          >
            {row.status}
          </Badge>
        </h5>
      ),
      sortField: "status",
      sortable: true,
      width: "180px",
    },
    {
      name: <strong>Schedule</strong>,
      selector: (row) => moment(row.schedule).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "schedule",
    },
  ];

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-scheduled-parties", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue, id: getLocalStorage("alcUser").id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setListData(response.data.parties);
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [tableOptions, token, isSearch]);

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Email</th>
              <td>
                <strong>{data.email}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Schedule</th>
              <td>
                <strong>{!data.schedule ? "NA" : moment(data.schedule).format("DD/MM/YYYY HH:MM A")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Created At</th>
              <td>
                <strong className="text-danger">{moment(data.createdAt).format("DD/MM/YYYY")}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Remarks</th>
              <td style={{ width: "200px" }}>
                <strong className="text-danger">{!data.remarks ? "NA" : data.remarks}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Address</th>
              <td style={{ maxWidth: "220px" }}>
                <strong>{data.companyAddress}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Products</th>
              <td>
                <strong>{data.productsList.length ?data.productsList.join(", "): "NA" }</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row px-2">
          <PartyComments setModal={(e) => setOpenModal(e)} assigned={statusmoved} party={data} open={openModal} />
          {data.status === "Fresh" ? <>
            <StatusPoppover reduxValue={{ decrease: "", increase: "followuplist" }} setModal={(e) => setFollowUpModal(e)} open={followUpModal} updated={statusmoved} text={followupProp} party={data} />
            <StatusPoppover reduxValue={{ decrease: "", increase: "callbacklist" }} setModal={(e) => setCallBackModal(e)} open={callBackModal} updated={statusmoved} text={callbackProp} party={data} />
            <StatusPoppover reduxValue={{ decrease: "", increase: "prospectivelist" }} setModal={(e) => setProspectiveModal(e)} open={prospectiveModal} updated={statusmoved} text={prospectiveProp} party={data} />
            <StatusPoppover reduxValue={{ decrease: "", increase: "materialzelist" }} setModal={(e) => setMaterializeModal(e)} open={materializeModal} updated={statusmoved} text={materializeProp} party={data} />{" "}
            <StatusPoppover reduxValue={{ decrease: "", increase: "" }} setModal={(e) => setDeleteModal(e)} open={deleteModal} updated={statusmoved} party={data} text={deleteProp} />
          </>
            : ""}
        </div>
      </div>
    );
  };

  const updateSelectedRow =(e)=>{ }

  const statusmoved = (e) => {
    setListData(
      listData.filter((el) => {
        return el._id !== e;
      })
    );
    setFilterItems(
      listData.filter((el) => {
        return el._id !== e;
      })
    );
  };

  return (
    <div>
      <AnnounceMarquee/>
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Parties List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        setRows={updateSelectedRow}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default ScheduledParties;