import React from "react";
import style from "./style.module.css";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import BusinessIcon from '@mui/icons-material/Business';
import CallIcon from '@mui/icons-material/Call';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import moment from "moment";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function BuyersItem(props) {
  const countries = useSelector((state) => state.CountriesList.countries);
  return (
    <div className={"card " +style.singleItem}>
      <div className="callout callout-danger p-0 m-0">
      <div className="card-body">
        <Link to={"/importer-details/" + props.data._id} state={props.data} className="card-header p-0 border-0">
          <div className="card-title m-0">
              <p className="font-weight-bold">{props.data.leadTitle}</p>
          </div>
          <div className="card-tools text-secondary">
            <AccessTimeIcon fontSize="small" /> <strong>
            {moment(props.data.createdAt).format("DD MMM YYYY")}
            </strong>
          </div>
        </Link>
        <p className="text-secondary">
          <small><strong>Lead Id : {props.data.leadId}</strong></small>
        </p>
        <p className="m-0 text-justify">
        {props.data.leadMessage.length > 350? <strong> {props.data.leadMessage.slice(0, 350)} <Link className="text-decoration-none text-primary font-weight-bold" to={"/importer-details/" + props.data._id} state={props.data}>more...</Link> </strong> : <strong> {props.data.leadMessage} </strong>}
        </p>
        <div className="row" style={{alignItems:"center"}}>
          <div className="col-6 col-sm-4 col-lg-2 my-2">
            <div className={style.leadContacts}>
              <p className="m-0"><BusinessIcon style={{fontSize:"small"}}/> Company</p>
              <p className={"m-0 " + style.blurItem}>
              <ModalComponent btnLink={true} btn={"XXXXXXXXXXXXXXX"} form={<RequirementForm data={countries} small={"sm"} />} size={""} heading={"Form for Your Requirement "} />
                </p>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 my-2">
            <div className={style.leadContacts}>
              <p className="m-0"><CallIcon style={{fontSize:"small"}}/> Contact No</p>
              <p className={"m-0 " + style.blurItem}>
              <ModalComponent btnLink={true} btn={"XXXXXXXXXX"} form={<RequirementForm small={"sm"} />} size={""} heading={"Form for Your Requirement "} />
                </p>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-3 my-2">
            <div className={style.leadContacts}>
              <p className="m-0">
                Country
              </p>
              <p className="m-0">
              <img src={props.data.countryFlag} style={{height:"10px"}} alt="" /> {props.data.country}
              </p>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-3 my-2">
          <div className={style.leadContacts}>
              <p className="m-0"><PersonPinIcon style={{fontSize:"small"}}/> Buyer</p>
              <p className="m-0">{props.data.leadingName}</p>
            </div>
          </div>
            <div className="col-6 col-sm-4 col-lg-2 my-2">
              <ModalComponent btnSm={"btn-sm"} btn={"Contact Now"} form={<RequirementForm small={"sm"} />} size={""} heading={"Form for Your Requirement "} />
            </div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default BuyersItem;
