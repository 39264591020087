import React, { useEffect, useState } from 'react'
import DataTable from "react-data-table-component";
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import Alert from '@mui/material/Alert';
import { Tooltip } from "@mui/material";
import Collapse from '@mui/material/Collapse';

function DataTableComponent(props) {
  const [currentRow, setCurrentRow] = useState(null);
  const [gotoPage, setGotoPage] = useState(1);
  const [sendPageNo, setSendPageNo] = useState(1);
  const [selectedRows, setSelectedRows] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (selectedRows) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [open, selectedRows])


  const ExpandedComponent = ({ data }) => {
    return props.expandedComponent(data)
  };

  const expandItem = () => {
    return (
      <Tooltip title="Hide">
        <h3 className="bg-danger text-sm py-0 px-1 rounded m-1 ml-2">
          {/* <i className="fas fa-caret-square-up fa-sm"></i> */}
          <i className="fas fa-eye-slash"></i>
        </h3>
      </Tooltip>
    );
  };

  const collapseItem = () => {
    return (
      <Tooltip title="View">
        <h3 className="bg-primary text-sm py-0 px-1 rounded m-1 ml-2">
          {/* <i className="fas fa-caret-square-down fa-sm"></i> */}
          <i className="fas fa-eye"></i>
        </h3>
      </Tooltip>
    );
  };

  const paginationOptions = {
    rowsPerPageText:
      <>
        <span>Page no : </span>
        <input type="number" style={{ maxWidth: "50px", border: "none", borderBottom: "solid 1px" }} value={gotoPage} onChange={(e) => e.target.value < 0 ? setGotoPage(1) : setGotoPage(e.target.value)} />
        <button style={{ border: "none", marginRight: "20px" }} onClick={(e) => { jumpToPage() }}>Go</button>
        <span>Rows per page:</span>
      </>,
  };

  const jumpToPage = () => {
    if (gotoPage < 1) {
      setSendPageNo(1)
      setGotoPage(1)
    } else {
      setSendPageNo(gotoPage)
    }
  }

  const handlePageChange = page => {
    props.pageChange(page)
    setGotoPage(page)
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setGotoPage(page)
    props.perRowsChange({ perPage: newPerPage, pageNo: page })
  };

  const handleSort = (column, sortDirection) => {
    props.setHandleSort({ sortDirection: sortDirection, sortField: column.sortField })
  };

  const handleRowSelected = React.useCallback(state => {
    props.setRows(state.selectedRows);
    setSelectedRows(state.selectedRows.length);
  }, []);

  return (
    <>
      {
        props.notFilter ? "" : <form onSubmit={(e) => { e.preventDefault(); props.setSearchTrue(true) }} >
          <InputGroup >
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="nc-icon nc-zoom-split"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input id="search" name="search" placeholder="Text here for Search..." type="search" onChange={(e) => props.inputFilter(e.target.value)} />
            <Button className="bg-main border-0" onClick={() => { props.setSearchTrue(true) }}>{props.inputFilterValue ? "Search" : "Reset"}</Button>
          </InputGroup>
        </form>
      }
      <Collapse in={open}>
        <Alert
          action={
            <Button className='px-3 py-1' onClick={() => props.selectedAssign(false)}>
              {props.action}
            </Button> 
          }
        >
          This is a success alert — check it out!
        </Alert>
      </Collapse>

      <DataTable
        persistTableHead
        highlightOnHover
        columns={props.columnsData}
        data={props.data}
        selectableRows={props.selecteRow}
        onSelectedRowsChange={handleRowSelected}
        progressPending={props.dataLoad}
        pagination
        expandableRowExpanded={(row) => (row === currentRow)}
        onRowClicked={(row) => setCurrentRow(row)}
        onRowExpandToggled={(bool, row) => setCurrentRow(row)}
        expandableRows={props.expandable}
        expandableRowsComponent={ExpandedComponent}
        expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }}
        paginationServer
        sortServer
        onSort={handleSort}
        paginationTotalRows={props.totalRowsData}
        paginationComponentOptions={paginationOptions}
        onChangeRowsPerPage={handlePerRowsChange}
        paginationDefaultPage={sendPageNo}
        onChangePage={handlePageChange} />
    </>
  )
}

export default DataTableComponent