import React, { useEffect, useRef, useState } from "react";
import { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import { Divider,Container, ListItemText, MenuItem, MenuList, Tooltip } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { api } from "../../../../Helper/Data";
import style from "./style.module.css";
import axios from "axios";

function MainContentHeader(props) {
  const bannerSlider = useRef();
  const navigate = useNavigate()
  const [banners, setBanners] = useState([]);
  const [toolTipPlace, setToolTipPlace] = useState("right");
  const { innerWidth: width } = window;
  useEffect(() => {
    // Banners
    axios
      .post(api.url + "/get-mainBanners", { id: "dk" })
      .then(function (response) {
        // console.log(response);
        setBanners(response.data.banner);
      })
      .catch(function (error) {
        console.log(error);
      });

    if (width < 900) {
      setToolTipPlace("bottom-end");
    } else {
      setToolTipPlace("right");
    }
  }, [width]);

  window.addEventListener("resize", function (e) {
    if (e.target.innerWidth < 900) {
      setToolTipPlace("bottom-end");
    } else {
      setToolTipPlace("right");
    }
  });

  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.white,
      filter: "drop-shadow(-1px 0px 1px #bdbdbd)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgb(233 233 233)",
      color: "black",
      width: 300,
      fontSize: theme.typography.pxToRem(12),
      filter: "drop-shadow(-1px 0px 1px #bdbdbd)",
    },
  }));
  return (
    <Container className="py-3">
      <div className="row bg-white" style={{ alignItems: "center", border: "solid 1px  #80808045", borderRadius:"10px" }}>
        <div className={"col-sm-12 col-md-12 col-lg-6 order-1 order-lg-2 " + style.bannerContainer}>
          <button
            className={style.preBtn}
            onClick={() => {
              bannerSlider.current.slickPrev();
            }}
          >
            <i className="fas fa-chevron-circle-left"></i>
          </button>
          <button
            className={style.nextBtn}
            onClick={() => {
              bannerSlider.current.slickNext();
            }}
          >
            <i className="fas fa-chevron-circle-right"></i>
          </button>
          <Slider
            autoplay={true}
            arrows={false}
            autoplaySpeed={2000}
            speed={1000}
            ref={(slider) => (bannerSlider.current = slider)}
            className={style.sliderContainer}
          >
            {banners.map((el, i) => {
              return (
                <div key={i}>
                  <div className={style.mainBannerItem}>
                    {/* <img src={api.bannerUrl + el.bannerImg} className={style.bgImage} alt="" /> */}
                    <img src={api.bannerUrl + el.bannerImg} className={style.mainImage} alt="promotion banner" />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-3 order-2 order-lg-1">
          <h5 className="mx-3 my-0">
            {" "}
            <strong>Categories :</strong>
          </h5>
          <MenuList style={{ maxHeight: "275px", overflow: "hidden" }}>
            {props.data.length
              ? props.data.map((cat, i) => {
                return (
                  <div key={i}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div className="row">
                            {cat.subCategories.map((sub, i) => {
                              return (
                                <div key={"key" + i} style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}} className={style.subCategory + " col-6"}>
                                  <Link to={`/products?category=${sub.subCategory.toLowerCase().replaceAll(" ", "-")}`}  className="p-2 text-dark">
                                    {sub.subCategory}
                                  </Link>
                                </div>
                              );
                            })}
                          </div>
                        </React.Fragment>
                      }
                      placement={toolTipPlace}
                    >
                      <MenuItem onClick={(e) => { navigate('/directory/' + cat.category.toLowerCase().split(" ").join('-'), { state: { category: cat.category, subCategory: cat.subCategories } }) }}>
                        <ListItemText className={style.catMenu}>{cat.category}</ListItemText>
                        <ArrowRightOutlinedIcon></ArrowRightOutlinedIcon>
                      </MenuItem>
                    </HtmlTooltip>
                    <Divider className="m-0" />
                  </div>
                );
              })
              : ""}
          </MenuList>
          <Link to={"/product-categories"}>
            <strong style={{ color: "black", padding: "0px 15px", fontSize: "15px" }}>ALL CATEGORIES</strong>
          </Link>
        </div>

        <div className="col-sm-12 col-md-6 col-lg-3 order-3 order-lg-3">
          <div className="py-1">
            <h6 className="text-center my-2">
              What is Your <span className="text-danger"> Requirement</span>{" "}
            </h6>
            <RequirementForm small={"sm"}></RequirementForm>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default MainContentHeader;
