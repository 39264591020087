import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { api } from "../../../../Helper/Data";
import moment from "moment";
import style from './style.module.css'
import axios from 'axios';
import { getCookie } from "../../../../Helper/Cokkies";

export default function PartyDetails() {
    const {id} = useParams()
    const [pending, setPending] = useState(true);
    const [details, setDetails] = useState({})
    let token = getCookie("alcToken");

    useEffect(() => {
        setPending(true)
        axios
            .post(api.url + "/get-party-status", { id:id }, { headers: { authorization: "Bearer " + token } })
            .then(function (response) {
                // console.log(response.data);
                setDetails(response.data.details);
                setPending(false);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [id, token])


    return (
        <div>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0">Company Details : <span className='text-dark d-inline'>{details._id ? details.party.company : "Loading..."}</span></h4>
                    <hr className="my-2" />
                </div>
            </div>
            {details._id ? <div className="container px-4">
                <h6 className={"m-0 "}>Catalogue</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.catalogue.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.catalogue.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.catalogue.comment ? details.catalogue.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Acknowledgement</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.ackEmail.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.ackEmail.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.ackEmail.comment ? details.ackEmail.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Payment</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.payment.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.payment.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.payment.comment ? details.payment.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Catalogue Activation</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.activation.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.activation.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.activation.comment ? details.activation.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Invoice Email</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.invoiceEmail.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.invoiceEmail.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.invoiceEmail.comment ? details.invoiceEmail.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Catalogue Email</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.catalogueEmail.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.catalogueEmail.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.catalogueEmail.comment ? details.catalogueEmail.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Website</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.site.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.site.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.site.comment ? details.site.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">SEO</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.seo.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.seo.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.seo.comment ? details.seo.comment : "NA"}</li>
                </ul>
                <h6 className="m-0">Site Keywords</h6>
                <hr className='m-0' style={{ maxWidth: "200px" }} />
                <ul className={style.contentList}>
                    <li className='px-3'>Status : {details.siteKeywords.isComplete ? <span class="text-success">Completed</span> : <span class="text-danger">Not Completed</span>}</li> |
                    <li className='px-3'>Last Update : {moment(details.siteKeywords.date).format("DD/MM/YYYY")}</li> |
                    <li className='px-3'>Comment : {details.siteKeywords.comment ? details.siteKeywords.comment : "NA"}</li>
                </ul>
            </div> : "Loading..."}

        </div>
    )
}
