import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Input, Label, Modal } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useLocation, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SunEditor from "../../../../components/SunEditor/SunEditor";
import SelectJS from "../../../../components/Select/SelectJS";
import ImageCrop from "../../../../components/ImageCrop/ImageCrop";
import { formValidation, userValidate } from "../../../../components/Utils/Utils";
const axios = require("axios").default;

function UpdateProduct() {
  const location = useLocation();
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const categoriesStore = useSelector((state) => state.CategoryState.data);
  const subCategoriesStore = useSelector((state) => state.CategoryState.subData);
  const [wordCount, setWordCount] = useState(0);
  const [productUpdated, setProductUpdated] = useState(false);
  const [formData, setFormData] = useState(location.state);
  const [imageUrl, setImageUrl] = useState("");
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [subCategoriesSelect, setSubCategoriesSelect] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const verify_admin_hr = userValidate(['admin', 'hr'], 'token');
  let token = getCookie("token");

  useEffect(() => {
    if (categoriesStore.length) {
      const selectList = categoriesStore.map((el) => { return { value: el._id, label: el.category, type: "category" }});
      setCategoriesSelect(selectList);
    }
    if (currentCompanyUser.user !== undefined) {
      setFormData({...formData, createdBy: currentCompanyUser.firstName + "( " + currentCompanyUser.user.role + " )"});
    }
    if (!verify_admin_hr) {
      setFormData({ ...formData, company: currentCompanyUser });
    } 
      setFormData({...formData, preImage: location.state.productImages });
  }, [categoriesStore, verify_admin_hr]);

  if(productUpdated){
    return <Navigate to={'/admin/products-list'}/>
  }

  const getSelected = (select) => {
    if (select.type === "category") {
      setFormData({ ...formData, category: select.label });
      subCategoryMenu(select.label);
    } else if (select.type === "company") {
      setFormData({ ...formData, company: select.value });
    } else {
      setFormData({ ...formData, subCategory: select.label });
    }
  };

  const subCategoryMenu = (category) => {
    const subSelectList = subCategoriesStore
      .filter(function (item) {
        return item.category === category;
      })
      .map(function (item) {
        return { value: item._id, label: item.subCategory, type: "subCategory" };
      });
    setSubCategoriesSelect(subSelectList);
  };

  const changeHandler = (e) => {
    e.target.value.length === " " ? setWordCount(0) : setWordCount(e.target.value.split(" ").length - 1);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const editorText = (text) => {
    setFormData({ ...formData, productDesc: text });
  };

  const reduceImg = (e) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 500;
        var MAX_HEIGHT = 500;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL("image/png");
        setImageUrl(dataurl);
        setFileSelected(false);
        setOpenModal(true);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(e);
  };

  const uploadLogoImg = (e) => {
    const file = e.target.files[0];
    let postPhoto = [];
    var reader = new FileReader();
    const form = new FormData();
    if (e.target.files && file) {
      postPhoto.push(file.name);
      form.append("productFile", file, file.name);
      setUploadFiles(form);
      setImageUrl(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      if (file.size > 200000) {
        alert("Size sould less then 200 kb ");
        reduceImg(file);
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width / height !== 1) {
              alert("Image's height & width sould be same");
              reduceImg(file);
            } else {
              if (postPhoto.length) {
                setFormData({ ...formData, productImages: postPhoto });
              }
              setFileSelected(true);
            }
          }
        }
      }
    } else {
      setFileSelected(false);
    }
  };

  const submitHandler = () => {
    if (formValidation([formData.category, formData.subCategory, formData.productName, formData.productDetails])) {
      if (fileSelected) {
        axios
          .post(api.url + "/upload-productImage", uploadFiles)
          .then(function (response) {
            if (response.data.success) {
              // console.log(formData);
              updateProducts(formData, [response.data.newFileName]);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        updateProducts(formData, formData.productImages);
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const updateProducts = (data, newName) => {
    let newData = data;
    newData = { ...newData, productImages: newName };
    console.log(newData);
    try {
      axios
        .post(api.url + "/update-product", newData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          if (response.data.success) {
            alert("Update Successfull")
            setProductUpdated(true)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onBlurHandler = (e) => {
    if (e.target.value.split(" ").length < 30 && e.target.value) {
      alert("Minimum 30 words are required");
      setFormData({ ...formData, [e.target.name]: "" });
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Update Product</h4>
          <hr className="my-2" />
        </div>
      </div>
      {formData.productName ? (
        <div className="container px-4">
          {/* Add Categories */}
          <div>
            <div className="form-row mt-4">
              {verify_admin_hr ? (
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel}>
                    Company Name
                  </Label>
                  <Input
                  bsSize="sm"
                  id="productName"
                  name="productName"
                  disabled
                  value={formData.company.company}
                  onChange={(e) => changeHandler(e)}
                  placeholder={"Enter product name"}
                  type="text"
                />
                </FormGroup>
              ) : (
                ""
              )}

              <FormGroup className="col-md-6">
                <Label className={style.formLabel}>
                  Category{" "}
                  <small>
                    <sup className="text-danger"> (Required)</sup>
                  </small>
                </Label>
                {categoriesSelect.length ? (
                  <SelectJS sm={"sm"} data={categoriesSelect} defValue={formData.category} selected={getSelected} />
                ) : (
                  <SelectJS sm={"sm"} defValue={formData.category} />
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel}>
                  Sub-Category{" "}
                  <small>
                    <sup className="text-danger"> (Required)</sup>
                  </small>
                </Label>
                {subCategoriesSelect.length ? (
                  <SelectJS sm={"sm"} data={subCategoriesSelect} defValue={formData.subCategory} selected={getSelected} />
                ) : (
                  <SelectJS defValue={formData.subCategory} sm={"sm"} />
                )}
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel}>
                  Make it featured product{" "}
                  <small>
                    <sup className="text-danger"> (Required)</sup>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="featuredProduct"
                  name="featuredProduct"
                  value={formData.featuredProduct}
                  onChange={(e) => changeHandler(e)}
                  type="select"
                >
                  <option value={false}>No</option>
                  <option value={true}>Yes</option>
                </Input>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label className={style.formLabel} for="image">
                  Product Image <small className="text-warning">Image width and height should be same</small>
                </Label>
                <Input
                  bsSize="sm"
                  id="image"
                  className={style.fileInput}
                  name="image"
                  accept="image/*"
                  onChange={(e) => uploadLogoImg(e)}
                  type="file"
                />
              </FormGroup>
              {openModal ? (
                <ModalComponent heading={"hellow"} size={"modal-lg"} setModal={(e) => setOpenModal(e)} url={imageUrl} open={openModal} />
              ) : (
                ""
              )}
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="email">
                  Product Name{" "}
                  <small>
                    <sup className="text-danger"> (Required)</sup>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="productName"
                  name="productName"
                  value={formData.productName}
                  onChange={(e) => changeHandler(e)}
                  placeholder={"Enter product name"}
                  type="text"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="email">
                  Additional Label{" "}
                  <small>
                    <sup className="text-danger"> </sup>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="additionalLabel"
                  name="additionalLabel"
                  value={formData.additionalLabel}
                  onChange={(e) => changeHandler(e)}
                  placeholder={"Enter product additional label"}
                  type="text"
                />
              </FormGroup>
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="email">
                  Tag Keywords
                  <small className="text-info">
                    <small> example: product name, category, sub-category, etc</small>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="tagKeywords"
                  name="tagKeywords"
                  value={formData.tagKeywords}
                  onChange={(e) => changeHandler(e)}
                  placeholder={"Enter keywords for search your product"}
                  type="text"
                />
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label className={style.formLabel} for="companyAddress">
                  Product Details{" "}
                  <small>
                    <sup className="text-danger">(Required)</sup>
                  </small>
                </Label>
                <Input
                  bsSize="sm"
                  id="productDetails"
                  style={{ minHeight: "50px" }}
                  value={formData.productDetails}
                  onChange={(e) => changeHandler(e)}
                  onBlur={onBlurHandler}
                  name="productDetails"
                  placeholder={"Enter product details"}
                  type="textarea"
                  minLength={50}
                />
                <FormText>Total words : {wordCount}</FormText>
              </FormGroup>
              <FormGroup className="col-md-12">
                <Label className={style.formLabel} for="companyAddress">
                  Product Description{" "}
                </Label>
                <SunEditor height={"150px"} placeholderValue={'Enter Product Description...'} quillValue={formData.productDesc} editor={editorText} />
              </FormGroup>
            </div>
            <Button type="submit" onClick={(e)=>submitHandler()} className="btn-sm btn-main px-4">
              Update Product
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default UpdateProduct;

const ModalComponent = (props) => {
  return (
    <Modal
      scrollable
      isOpen={props.open}
      style={{ overflow: "auto" }}
      toggle={() => {
        props.setModal(false);
      }}
      modalClassName="modal-register"
      className={props.size}
    >
      <div className="modal-header no-border-header text-center pb-0">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            props.setModal(false);
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <h5 className="text-center">
          <strong>{props.heading}</strong>
        </h5>
      </div>
      <div className="modal-body pt-0 mb-3 px-3">
        <ImageCrop aspect={"1"} />
      </div>
    </Modal>
  );
};
