import axios from "axios";
import jwt_decode from "jwt-decode";
import { getCookie } from "../../Helper/Cokkies";
import { getLocalStorage } from "../../Helper/LocalStorage";
import { getCategoryLoged } from "../../Redux/CategoryState";
import { getCurrentLoged } from "../../Redux/CompanyUser";
import { getCandidates, getTLData } from "../../Redux/Candidates";
import { api } from "../../Helper/Data";

export function mainReduxFunction(disPatch) {
  const token = getCookie("token");
  const user = getLocalStorage("user");

  axios
    .post(api.url + "/get-categories", { id: "Id" })
    .then(function (response) {
      // console.log(response.data);
      disPatch(getCategoryLoged(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });

  if (token !== undefined) {
    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    axios
      .post(api.url + "/current-user", { id: user.id, userRole: role }, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        disPatch(getCurrentLoged(response.data.user[0]));
      })
      .catch(function (error) {
        console.log(error);
      });
  }
}

export function alcmainReduxFunction(disPatch) {
  const token = getCookie("alcToken");
  const user = getLocalStorage("alcUser");

  if (token !== undefined) {
    axios
      .post(api.url + "/get-candidates", { id: user.id }, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        disPatch(getCandidates(response.data.candidates));
        disPatch(getTLData(response.data.teamLeader));
      })
      .catch(function (error) {
        console.log(error);
      });

    var decode = jwt_decode(token);
    const role = decode.id.split("-")[1];
    
    axios
      .post(api.url + "/current-user", { id: user.id, userRole: role }, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        disPatch(getCurrentLoged(response.data.user[0]));
      })
      .catch(function (error) {
        console.log(error);
      });
  }

}
