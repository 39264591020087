import React, { useEffect, useState } from 'react'
import { FormGroup, Input, InputGroup, Spinner, InputGroupAddon, InputGroupText, Button } from 'reactstrap';
import style from './style.module.css'
import ButtonGroup from '@mui/material/ButtonGroup';
import { api } from "../../../../Helper/Data";
import { alcUserValidate } from "../../../../components/Utils/Utils";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { getCookie } from "../../../../Helper/Cokkies";
const axios = require("axios").default;

export default function SiteKeywords() {
    const [searchValue, setSearchValue] = useState("")
    const [formData, setFormData] = useState({});
    const [keywordsList, setKeywordsList] = useState([]);
    const [submiting, setSubmiting] = useState(false)
    const [showCompanies, setShowCompanies] = useState(true)
    const [showCompaniesList, setShowCompaniesList] = useState([])
    const [update, setUpdate] = useState(false)
    let token = getCookie("alcToken");
    const if_admin_hr = alcUserValidate(['admin', "HR"])

    useEffect(() => {
        axios
            .post(api.url + "/show-companies-list", { key: "key" }, { headers: { authorization: "Bearer " + token } })
            .then(function (response) {
                // console.log(response.data);
                setShowCompaniesList(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [token])


    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(str);
    }

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const searchKeywords = (key) => {
        setShowCompanies(false)
        if (key.length > 2) {
            setKeywordsList([])
            axios
                .post(api.url + "/search-keywords", { key: key }, { headers: { authorization: "Bearer " + token } })
                .then(function (response) {
                    // console.log(response.data);
                    setKeywordsList(response.data.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        } else {
            alert("search length should be more then three Character")
        }
    }

    const submitHandle = (e) => {
        setSubmiting(true)
        if (formData.keyword && formData.position && formData.site) {
            if (validURL(formData.site)) {
                axios
                    .post(api.url + (update ? "/update-keyword" : "/add-keyword"), formData, { headers: { authorization: "Bearer " + token } })
                    .then(function (response) {
                        // console.log(response);
                        alert("Add Successfully")
                        setFormData({ site: "", position: "", keyword: "", company: "" })
                        setSubmiting(false)
                        setUpdate(false)
                    })
                    .catch(function (error) {
                        console.log(error);
                        setUpdate(false)
                        setSubmiting(false)
                    });
            } else {
                alert("Site url is not validate")
            }
        } else {
            alert("Please fill all fields")
            setUpdate(false)
            setSubmiting(false)
        }
    }

    const deleteKeyword = (id, text) => {
        axios
            .post(api.url + "/delete-keyword", { id: id }, { headers: { authorization: "Bearer " + token } })
            .then(function (response) {
                // console.log(response.data);
                if (response.data.success) { alert("Delete Successfull") }
                searchKeywords(text)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>

            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Site Keywords</h4>
                    <hr className="my-2" />
                </div>
            </div>

            <div className='px-2'>
                {if_admin_hr ?
                    <div className="row align-items-center">
                        <FormGroup className="col-md-6">
                            <Input
                                bsSize="sm"
                                id="keyword"
                                name="keyword"
                                value={formData.keyword}
                                onChange={(e) => changeHandler(e)}
                                placeholder={"Keyword"}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Input
                                bsSize="sm"
                                id="site"
                                name="site"
                                value={formData.site}
                                onChange={(e) => changeHandler(e)}
                                placeholder={"Website"}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Input
                                bsSize="sm"
                                id="company"
                                name="company"
                                value={formData.company}
                                onChange={(e) => changeHandler(e)}
                                placeholder={"Company"}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Input
                                bsSize="sm"
                                id="position"
                                name="position"
                                value={formData.position}
                                onChange={(e) => changeHandler(e)}
                                placeholder={"Ranking Position"}
                                type="text"
                            />
                        </FormGroup>
                        <FormGroup className="col-md-6">
                            <Button className="btn-sm btn-main px-4" onClick={(e) => { submitHandle(e) }} disabled={submiting} type="submit">
                                Add Keyword{submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
                        </FormGroup>
                    </div>
                    : ""}

                <div className="mt-4">
                    <div className="container-fluid">
                        <h4 className="m-0 text-dark">Search : </h4>
                        <hr className="my-2" />
                    </div>
                    <Button className="btn-main px-4 mb-2" onClick={(e) => { setShowCompanies(true) }} disabled={submiting} type="submit"> Show Company List</Button>
                    <form onSubmit={(e) => { e.preventDefault(); searchKeywords(searchValue) }} >
                        <InputGroup >
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                    <i className="nc-icon nc-zoom-split"></i>
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input id="search" name="search" placeholder="Search with keyword..." type="search" onChange={(e) => setSearchValue(e.target.value)} />
                        </InputGroup>
                    </form>
                    <div className={"row p-2"}>
                        {showCompanies ? <div class="row g-1">
                            {showCompaniesList.map((el, i) => {
                                return <p className='col-md-4'><button onClick={(e) => { searchKeywords(el) }} className="btn btn-outline btn-sm btn-block px-1">{el}</button></p>
                            })}
                        </div> : keywordsList.map((el, i) => {
                            return <div key={i} className={'col-md-6 p-1'}>
                                <div className={style.keywordItem} style={{ background: el.position.includes("1P") ? "linear-gradient(270deg, #cad7ff, transparent)" : "white" }}>
                                    <p>Keyword : {el.keyword}</p>
                                    <p>Company : <button className='btn btn-link p-0' onClick={(e) => searchKeywords(el.company)}>{el.company} <span class="badge badge-primary py-1">Find all</span></button></p>
                                    <p>Rank : {el.position}</p>
                                    <p>Site : <a href={el.site} target='_black' onClick={(e) => searchKeywords(el.site)}>{el.site}</a></p>
                                    {if_admin_hr ? <div className={style.actionBtn}>
                                        <ButtonGroup orientation="vertical" aria-label="vertical contained button group" variant="text" >
                                            <IconButton aria-label="delete" onClick={(e) => deleteKeyword(el._id, el.company)} color="error">
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </ButtonGroup>
                                    </div> : ''}
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
