import React, { useEffect, useState } from 'react'
import style from "./style.module.css"
import Marquee from "react-marquee-master";
import { api } from '../../../../Helper/Data'
import axios from 'axios';

export default function EventMarquee(props) {
    const [bdayEvent, setBdayEvent] = useState(null)

    useEffect(() => {
        axios
            .post(api.url + "/get-event-data", { id: "" })
            .then(function (response) {
                console.log(response.data);
                response.data.event.forEach((item, index) => {
                    item.serial = index + 1;
                });
                setBdayEvent(response.data.event)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])

    return (
        <section className="container mb-2">
            <div className={style.announceHeading}>
            {bdayEvent === null || !bdayEvent.length ? "": <div>
                    <div className={style.bdayText}>
                    <i class="fas fa-birthday-cake"></i> Today is <span>{bdayEvent[0].fullname}</span>' Birthday
                    </div>
                </div>}
            </div>
        </section>
    )
}
