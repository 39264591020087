import React from "react";
import BreadCrumb from "Modules/Public/Components/BreadCrumb/BreadCrumb";
import PageHeading from "../../Components/PageHeading/PageHeading"

export default function Jobcareer() {
  return <div>
    <PageHeading page={"jobCareer"}></PageHeading>
    <div className="container">
      <BreadCrumb current={"Job & Career"}/>
    </div>
  </div>;
}
