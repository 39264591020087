import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { IconButton } from "@mui/material";
import { formValidation } from "../../../../components/Utils/Utils";
import SelectJS from "../../../../components/Select/SelectJS";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
const axios = require("axios").default;

function AddSubCategory() {
  let token = getCookie("token");
  const [formData, setFormData] = useState({ catId: "", category: "", subCategory: "" });
  const [subCategoriesList, setSubCategoriesList] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  const [pending, setPending] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-categories-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
      .then(function (response) {
        // console.log(response);
        response.data.subCategory.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        const selectList = response.data.selectCategories.map((el) => {
          return { value: el._id, label: el.category };
        });
        setCategoriesSelect(selectList);
        setFilterItems(response.data.subCategory);
        setSubCategoriesList(response.data.subCategory);
        setPending(false)
        setIsSearch(false)
        setTotalRows(response.data.subTotal);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [ tableOptions, isSearch]);

  const getSelected = (select) => {
    setFormData({ ...formData, catId: select.value, category: select.label });
  };

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>CATEGORY</strong>,
      selector: (row) => row.category,
      sortable: true,
      sortField: "category"
    },
    {
      name: <strong>SUB-CATEGORY</strong>,
      selector: (row) => row.subCategory,
      sortable: true,
      sortField: "subCategory"
    },
    {
      name: <strong>IMAGE</strong>,
      selector: (row) => (
        <div>
          <img
            style={{ height: "50px" }}
            src={row.imageUrl ? row.imageUrl : "Demo.png"}
            alt=""
          />
        </div>
      ),
      width: "120px",
      center: true,
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <IconButton className={style.actionBtn} onClick={(e) => deletehandle(row._id)} color="error" size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
      width: "100px",
    },
  ];

  const deletehandle = (id) => {
    try {
      axios
        .post(api.url + "/delete-subcategory", { id }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            alert("Delete Successfull");
            setFilterItems(filterItems.filter((el) => {
              return id !== el._id
            }));
            setSubCategoriesList(subCategoriesList.filter((el) => {
              return id !== el._id
            }));
          }
        })
        .catch(function (error) {
          // console.log(error);
          alert(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formValidation([formData.category, formData.subCategory, formData.imageUrl])) {
      axios
        .post(api.url + "/add-subcategory", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            alert("Add Successfull");
            setFilterItems([...filterItems, { serial: filterItems.length + 1, _id: response.data.id, catId: formData.catId, imageUrl: formData.imageUrl, category: formData.category, subCategory: formData.subCategory }]);

            setSubCategoriesList([...subCategoriesList, { serial: subCategoriesList.length + 1, _id: response.data.id, catId: formData.catId, imageUrl: formData.imageUrl, category: formData.category, subCategory: formData.subCategory }]);

            setFormData({ catId: "", category: "", subCategory: "" });
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(error.response.data.message);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Sub Category</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <Form onSubmit={submitHandler}>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>Category</Label>
              {categoriesSelect.length ? <SelectJS sm="sm" defValue={formData.category} data={categoriesSelect} selected={getSelected} /> : <SelectJS sm="sm" defValue={formData.category} data={[{value: "Loading...", label: "Loading..." }]} selected={getSelected} />}
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="subCategory" className={style.formLabel}>Sub-Category</Label>
              <Input
                bsSize="sm"
                id="subCategory"
                name="subCategory"
                value={formData.subCategory}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Sub-Category"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label for="imageUrl" className={style.formLabel}>Image URL</Label>
              <Input
                bsSize="sm"
                id="imageUrl"
                name="imageUrl"
                value={formData.imageUrl}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Image URL..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Button type="submit" className="btn-sm btn-main px-4">
                Add Sub Category
              </Button>
            </FormGroup>
          </div>
        </Form>

        {/* Table */}
        <p className="m-0 text-dark py-2">
          {" "}
          <strong>Category List :</strong>
        </p>
        
        <DataTableComponent
          pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
          perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
          setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
          expandable={false}
          inputFilterValue={filterValue}
          setSearchTrue={(e)=>setIsSearch(true)}
          inputFilter={(e)=>setFilterValue(e)}
          setRows={updateSelectedRow}
          columnsData={columns}
          dataLoad={pending}
          totalRowsData={totalRows}
          data={filterItems} />
      </div>
    </div>
  );
}

export default AddSubCategory;
