import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import style from "./style.module.css";
import { formValidation } from "../../../../components/Utils/Utils";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
const axios = require("axios").default;

export default function CandidateDetails(props) {
  let token = getCookie("alcToken");
  const navigate = useNavigate()
  const [uploadProfilePic, setUploadProfilePic] = useState({});
  const [imageName, setImageName] = useState("");
  const [fileSelected, setFileSelected] = useState(false);
  const [formData, setFormData] = useState(props.data);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      formValidation([
        formData.username,
        formData.fullname,
        formData.address,
        formData.contact,
        formData.email,
        formData.doj,
        formData.designation,
        formData.salary
      ])
    ) {
      if (!fileSelected) {
        formData.profilePic !== "noLogo.png"
          ? updateCompany(formData, formData.profilePic)
          : updateCompany(formData, "noLogo.png");
      } else {
        let request = await fetch(api.url + "/selectFile-profilePic", {
          method: "post",
          body: uploadProfilePic,
          headers: { authorization: "Bearer " + token },
        });
        const response = await request.json();
        if (response.success) {
          updateCompany(formData, response.newFileName + "-" + imageName);
        }
      }
    } else {
      alert("Please fill the required fields");
    }
  };
  
  const updateCompany = (data, newFile) => {
    let newData = data;
    newData = { ...newData, profilePic: newFile };
    console.log(token);
    axios
      .post(api.url + "/update-candidate-details", newData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(function (response) {
        if (response.data.success) {
          alert("Update Successfull");
          navigate("/allocation/candidates")
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const uploadProfilePicImg = (e) => {
    if (e.target.files[0] !== undefined && e.target.files[0].size < 41500) {
      const form = new FormData();
      if (e.target.files[0] === undefined) {
        setFileSelected(false);
      } else {
        setImageName(e.target.files[0].name);
        form.append("files", e.target.files[0]);
        setFileSelected(true);
        setUploadProfilePic(form);
      }
    } else {
      alert("Logo sould be less then 40kb");
      setFileSelected(false);
    }
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <Form onSubmit={submitHandler} className="px-3">
      <div className="form-row">
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="fullname">
            Full Name{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="fullname"
            name="fullname"
            value={formData.fullname}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Full name"}
            type="text"
          />
        </FormGroup>
        
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="username">
            User Name{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="username"
            name="username"
            value={formData.username}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter User name"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="contact">
            Contact No.{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            pattern="[0-9]+"
            title="Number only"
            type="text"
            id="contact"
            value={formData.contact}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="contact"
            placeholder={props.disable ? "" : "Enter contact no."}
            autoComplete="off"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="email">
            Email Address{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="email"
            name="email"
            value={formData.email}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Email Address"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="altContact">
            Alternate No.{" "}
            <small>
              <sup className="text-danger"></sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            title="Number only"
            type="text"
            id="altContact"
            value={formData.altContact}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="altContact"
            placeholder={props.disable ? "" : "Enter Alternate no."}
            autoComplete="off"
          />
        </FormGroup>
        
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="prsnEmail">
            Personal Email{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="prsnEmail"
            name="prsnEmail"
            value={formData.prsnEmail}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Personal Address"}
            type="text"
          />
        </FormGroup>

        <FormGroup className="col-md-12">
          <Label className={style.formLabel} for="address">
            Address{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="address"
            style={{ minHeight: "50px" }}
            value={formData.address}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            name="address"
            placeholder={props.disable ? "" : "Enter company address"}
            type="textarea"
          />
        </FormGroup>

        {!props.disable ? (
          <FormGroup className="col-md-4 col-sm-6">
            <Label className={style.formLabel} for="profilePic">
              Profile Picture{" "}
              <small>
                <sup className="text-danger"></sup>
              </small>
            </Label>
            <Input
              bsSize="sm"
              id="profilePic"
              className={style.fileInput}
              name="profilePic"
              accept="image/*"
              disabled={props.disable ? props.disable : false}
              onChange={(e) => uploadProfilePicImg(e)}
              type="file"
            />
          </FormGroup>
        ) : (
          ""
        )}
        
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="designation">
            Designation{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="designation"
            name="designation"
            value={formData.designation}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Personal Address"}
            type="text"
          />
        </FormGroup>
        
        <FormGroup className="col-md-4 col-sm-6">
          <Label className={style.formLabel} for="salary">
            Salary{" "}
            <small>
              <sup className="text-danger">(Required)</sup>
            </small>
          </Label>
          <Input
            bsSize="sm"
            id="salary"
            name="salary"
            value={formData.salary}
            disabled={props.disable ? props.disable : false}
            onChange={(e) => changeHandler(e)}
            placeholder={props.disable ? "" : "Enter Personal Address"}
            type="text"
          />
        </FormGroup>
      </div>

      <div className="form-row pt-2">
      <FormGroup className="col-md-4 col-sm-6">
        <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DatePicker
                className={style.datePicker}
                  label="Birthday Date"
                  value={formData.dob}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) =>{
                    setFormData({
                      ...formData,
                      dob: newValue
                    })
                  }}
                />
              </LocalizationProvider>
        </FormGroup>

      <FormGroup className="col-md-4 col-sm-6">
        <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DatePicker
                className={style.datePicker}
                  label="Joining Date"
                  value={formData.doj}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) =>{
                    setFormData({
                      ...formData,
                      doj: newValue
                    })
                  }}
                />
              </LocalizationProvider>
        </FormGroup>
        <FormGroup className="col-md-4 col-sm-6">
        <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DatePicker
                className={style.datePicker}
                  label="Exit Date"
                  value={formData.doe}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) =>{
                    setFormData({
                      ...formData,
                      doe: newValue
                    })
                  }}
                />
              </LocalizationProvider>
        </FormGroup>
</div>

      {props.update ? (
        <Button size="sm" type="submit" color="main">
          Submit
        </Button>
      ) : (
        ""
      )}
    </Form>
  );
}
