import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import { useLocation } from "react-router-dom";
import { getCookie } from '../../Helper/Cokkies'
import { api } from '../../Helper/Data'

export default function Notfound(props) {
  const location = useLocation();
  let pageHeader = React.createRef();

  useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform = "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  }, [pageHeader]);

  return (
    <>
      <div
        className={"page-header bg-main"}
        data-parallax={true}
        ref={pageHeader}
      >
        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <div>
              <h3 className="m-0 text-lowercase"><span className="badge badge-pill badge-danger" >Page url : {"www.worldwebtrade.com" + location.pathname}</span></h3>
            </div>
            <img src={api.logosUrl + "404.png"} alt="" />
            <h2 className="m-0 text-danger font-weight-bold"><strong>E r r o r !</strong></h2>
            <br />
            <a href="/" className="btn btn-primary btn-round mr-1" color="neutral" outline>
              Goto Home
            </a>
            {location.pathname.includes("admin") ? 
              <a href={"/admin/login"} className="btn btn-primary btn-round mr-1" color="neutral" outline>  Goto Admin  </a>
            : location.pathname.includes("allocation") ? <a href={"/allocation/login"} className="btn btn-primary btn-round mr-1" color="neutral" outline> Goto Allocation  </a> : ""}
          </div>
        </Container>
      </div>
    </>
  );
}
