import { createSlice } from '@reduxjs/toolkit'


export const productLink = createSlice({
  name: 'ProductLink',
  initialState : {
    link: '',
  },
  reducers: {
    setLink: (state, action) => {
      state.link = action.payload;
    }
  },
})

export const { setLink} = productLink.actions

export default productLink.reducer