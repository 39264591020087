import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useNavigate } from "react-router-dom";
import SelectJS from "../../../../components/Select/SelectJS";
import { formValidation, userValidate } from "../../../../components/Utils/Utils";
const axios = require("axios").default;

function AddVcertificate() {
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [formData, setFormData] = useState({ certificateImage: [], domestic: true, company: "", verifiedId: "" });
  const [imageUrl, setImageUrl] = useState("");
  const [companiesList, setCompaniesList] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const verify_admin_hr = userValidate(["admin", "hr"], "token");
  let token = getCookie("token");

  useEffect(() => {
    if (verify_admin_hr) {
      axios
        .post(api.url + "/get-all-companies", { id: "id" })
        .then(function (response) {
          // console.log(response.data);
          const selectCompanies = response.data.companies.map((el) => {
            return { value: el, label: el.company, type: "company" };
          });
          setCompaniesList(selectCompanies);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

  }, [verify_admin_hr]);

  const getSelected = (select) => {
    setFormData({ ...formData, company: select.value });
  };

  const uploadLogoImg = (e) => {
    const file = e.target.files[0];
    let postPhoto = [];
    var reader = new FileReader();
    const form = new FormData();
    if (e.target.files && file) {
      postPhoto.push(file.name);
      form.append("vcertificate", file, file.name);
      setUploadFiles(form);
      // processFile(file);
      setImageUrl(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        var image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          if (postPhoto.length) {
            setFormData({ ...formData, certificateImage: postPhoto });
          }
          setFileSelected(true);
        };
      };
    } else {
      setFileSelected(false);
    }
  };

  const submitHandler = async () => {
    setIsSubmiting(true);
    if (
      formValidation([
        formData.company,
      ])
    ) {
      if (fileSelected) {
        axios
          .post(api.url + "/upload-cerficateImage", uploadFiles)
          .then(function (response) {
            if (response.data.success) {
              addCertificate(formData, response.data.newFileName);
            }
          })
          .catch(function (error) {
            setIsSubmiting(false);
            console.log(error);
          });
      } else {
        alert("Please fill the required fields");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const addCertificate = (data, newName) => {
    let newData = data;
    newData = { ...newData, certificateImage: [newName] };
    axios
      .post(api.url + "/add-certificate", newData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(function (response) {
        if (response.data.success) {
          alert("Add Successfull");
          navigate("/admin/vcertificate-list");
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmiting(false);
        alert(error.response.data.message);
        console.log(error.response.data.message);
      });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Product</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Type
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input bsSize="sm" className={style.fileInput} onChange={(e) => setFormData({ ...formData, domestic: e.target.value })} type="select" >
                <option value="true">Domestic</option>
                <option value="false">International</option>
              </Input>
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Company
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <SelectJS
                sm={"sm"}
                defValue={""}
                data={companiesList}
                selected={getSelected}
              />
            </FormGroup>

            <FormGroup className="col-sm-6">
              <Label className={style.formLabel} for="verifiedId">
                Certificate / Verified ID{" "}
              </Label>
              <Input
                bsSize="sm"
                id="verifiedId"
                className={style.fileInput}
                name="verifiedId"
                onChange={(e) => setFormData({ ...formData, "verifiedId": e.target.value })}
              />
            </FormGroup>

            <FormGroup className="col-sm-6">
              <Label className={style.formLabel} for="image">
                Certificate Image{" "}
                <small className="text-warning">
                  Image width and height should be same
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="image"
                className={style.fileInput}
                name="image"
                accept="image/*"
                onChange={(e) => uploadLogoImg(e)}
                type="file"
              />
            </FormGroup>

          </div>
          <Button
            disabled={isSubmiting}
            type="submit"
            onClick={submitHandler}
            className="btn-sm btn-main px-4"
          >
            Add Certificate
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddVcertificate;