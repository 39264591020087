import React, { useState,useEffecet } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import style from "./style.module.css";
import { Form, FormGroup, Label, Button, Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { Container, FormControl, TextField } from "@mui/material";
import { useSelector } from "react-redux";
const axios = require("axios").default;

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function UserDetails() {
  const user = getLocalStorage("user");
  const token = getCookie("token");
  const [value, setValue] = useState(0);
  const [hidePass, setHidePass] = useState(true);
  const [passwords, setPasswords] = useState({ password: "", newPassword: "", confirmPassword: "" });
  const company = useSelector((state) => state.CompanyUser.data);

  const passwordShow = () => {
    hidePass ? setHidePass(false) : setHidePass(true);
  };
  const changeHandle = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!passwords.confirmPassword || !passwords.password || !passwords.newPassword) {
      alert("Please enter mandetory fields");
    } else {
      if (passwords.newPassword === passwords.confirmPassword) {
        axios
          .post(
            api.url + "/update-password",
            { id: user.id, password: passwords.password, newPassword: passwords.newPassword },
            { headers: { authorization: "Bearer " + token } }
          )
          .then(function (response) {
            if (response.data.success) {
              alert("Password change successfull");
            }
          })
          .catch(function (error) {
            alert(error.response.data.error);
          });
      } else {
        alert("Confirm Password is not match");
      }
    }
  };
  return (
    <div>
      <div className="content-header p-0">
        <h4 className="m-0 text-dark">User Details</h4>
        <hr className="my-2" />
      </div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab className={style.tabHeader} label="User Details" {...a11yProps(0)} />
          <Tab className={style.tabHeader} label="Change Password" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {company.user ? (
          <Container>
            <FormControl className="my-2" variant="standard" fullWidth>
              <TextField
                disabled
                id="standard-disabled"
                label="User Name"
                defaultValue={company.user.username}
                variant="standard"
                className={style.inputView}
              />
            </FormControl>

            <FormControl className="my-2" variant="standard" fullWidth>
              <TextField
                disabled
                id="standard-disabled"
                label="Full Name"
                defaultValue={company.firstName + " " + company.lastName}
                variant="standard"
                className={style.inputView}
              />
            </FormControl>

            <FormControl className="my-2" variant="standard" fullWidth>
              <TextField
                disabled
                id="standard-disabled"
                label="Email Address"
                defaultValue={company.user.email}
                variant="standard"
                className={style.inputView}
              />
            </FormControl>
            <FormControl className="my-2" variant="standard" fullWidth>
              <TextField
                disabled
                id="standard-disabled"
                label="Mobile Number"
                defaultValue={company.contact}
                variant="standard"
                className={style.inputView}
              />
            </FormControl>
          </Container>
        ) : (
          "Loading"
        )}
      </TabPanel>
      
      <TabPanel value={value} index={1}>
        <Form style={{ maxWidth: "450px" }} onSubmit={submitHandler}>
          <FormGroup>
            <Label for="currentPassword">Current Password <small>
              <sup className="text-danger">(Required)</sup>
            </small></Label>
            <Input id="currentPassword" name="password" onChange={(e) => changeHandle(e)} placeholder="Enter Current Password" type="password" />
          </FormGroup>{" "}
          <FormGroup>
            <Label for="newPassword">New Password <small>
              <sup className="text-danger">(Required)</sup>
            </small></Label>
            <Input id="newPassword" name="newPassword" onChange={(e) => changeHandle(e)} placeholder="Enter New Password" type="text" />
          </FormGroup>{" "}
          <FormGroup>
            <Label for="confirmPassword">Confirm Password <small>
              <sup className="text-danger">(Required)</sup>
            </small></Label>
            <InputGroup>
              <Input type={hidePass ? "password" : "text"} name="confirmPassword" onChange={(e) => changeHandle(e)} placeholder="Confirm Password" />
              <InputGroupAddon onClick={passwordShow} addonType="prepend">
                <InputGroupText className={style.addOnInput}>
                  {hidePass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </FormGroup>
          <Button>Submit</Button>
        </Form>
      </TabPanel>
    </div>
  );
}

export default UserDetails;
