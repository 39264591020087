import React, { useEffect, useState } from "react";
import style from "./style.module.css";
// import data from "./list.json";
const axios = require("axios").default;

export default function PageHeading(props) {
  const [heading, setHeading] = useState([]);
  useEffect(() => {
    axios
      .get("dist/docs/pageHeading.json")
      .then(function (response) {
        // console.log(response);
        setHeading(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return heading[0] ? (
    <div
      className={style.pageBannerSection}
      style={{
        backgroundImage: "linear-gradient(to left, rgb(48 1 1 / 85%), rgb(0 0 58 / 76%)), url(" + heading[0][props.page].img + ")",
      }}
    >
      <h3>{heading[0][props.page].heading}</h3>
    </div>
  ) : (
    ""
  );
}
