import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Input, Label, Modal } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SunEditor from "../../../../components/SunEditor/SunEditor";
import SelectJS from "../../../../components/Select/SelectJS";
import ImageCrop from "../../../../components/ImageCrop/ImageCrop";
import {
  formValidation,
  userValidate,
} from "../../../../components/Utils/Utils";
const axios = require("axios").default;

function AddProduct() {
  const navigate = useNavigate();
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const [wordCount, setWordCount] = useState(0);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [formData, setFormData] = useState({
    category: "",
    subCategory: "",
    productImages: [],
    productDesc: "",
    productPrice: "",
    productDetails: "",
    productName: "",
    tagKeywords: "",
    keyWords: "",
    createdBy: "",
    company: "",
    featuredProduct: false,
    additionalLabel: "",
  });
  const categoriesStore = useSelector((state) => state.CategoryState.data);
  const subCategoriesStore = useSelector(
    (state) => state.CategoryState.subData
  );
  const [imageUrl, setImageUrl] = useState("");
  const [companiesList, setCompaniesList] = useState([]);
  const [categoriesSelect, setCategoriesSelect] = useState([]);
  const [subCategoriesSelect, setSubCategoriesSelect] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const verify_admin_hr = userValidate(["admin", "hr"], "token");
  let token = getCookie("token");

  useEffect(() => {
    if (verify_admin_hr) {
      axios
        .post(api.url + "/get-all-companies", { id: "id" })
        .then(function (response) {
          // console.log(response.data);
          const selectCompanies = response.data.companies.map((el) => {
            return { value: el, label: el.company, type: "company" };
          });
          setCompaniesList(selectCompanies);
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    if (categoriesStore.length) {
      const selectList = categoriesStore.map((el) => {
        return { value: el._id, label: el.category, type: "category" };
      });
      setCategoriesSelect(selectList);
    }
    if (currentCompanyUser.user !== undefined) {
      setFormData({
        ...formData,
        createdBy:
          currentCompanyUser.firstName +
          "( " +
          currentCompanyUser.user.role +
          " )",
      });
    }
    if (!verify_admin_hr) {
      setFormData({ ...formData, company: currentCompanyUser });
    }
  }, [categoriesStore, currentCompanyUser, verify_admin_hr]);

  const getSelected = (select) => {
    if (select.type === "category") {
      setFormData({ ...formData, category: select.label });
      subCategoryMenu(select.label);
    } else if (select.type === "company") {
      setFormData({ ...formData, company: select.value });
    } else {
      setFormData({ ...formData, subCategory: select.label });
    }
  };

  const subCategoryMenu = (category) => {
    const subSelectList = subCategoriesStore
      .filter(function (item) {
        return item.category === category;
      })
      .map(function (item) {
        return {
          value: item._id,
          label: item.subCategory,
          type: "subCategory",
        };
      });
    setSubCategoriesSelect(subSelectList);
  };

  const changeHandler = (e) => {
    e.target.value.length === " "
      ? setWordCount(0)
      : setWordCount(e.target.value.split(" ").length - 1);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const editorText = (text) => {
    setFormData({ ...formData, productDesc: text });
  };

  const reduceImg = (e) => {
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);

        var MAX_WIDTH = 500;
        var MAX_HEIGHT = 500;
        var width = img.width;
        var height = img.height;

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width;
            width = MAX_WIDTH;
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height;
            height = MAX_HEIGHT;
          }
        }
        canvas.width = width;
        canvas.height = height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        var dataurl = canvas.toDataURL("image/png");
        setImageUrl(dataurl);
        setFileSelected(false);
        setOpenModal(true);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(e);
  };

  const uploadLogoImg = (e) => {
    const file = e.target.files[0];
    let postPhoto = [];
    var reader = new FileReader();
    const form = new FormData();
    if (e.target.files && file) {
      postPhoto.push(file.name);
      form.append("productFile", file, file.name);
      setUploadFiles(form);
      // processFile(file);
      setImageUrl(URL.createObjectURL(file));
      reader.readAsDataURL(file);
      if (file.size > 200000) {
        alert("Size sould less then 200 kb ");
        reduceImg(file);
      } else {
        reader.onload = function (e) {
          var image = new Image();
          image.src = e.target.result;
          image.onload = function () {
            var height = this.height;
            var width = this.width;
            if (width / height !== 1) {
              alert("Image's height & width sould be same");
              // reduceImg(file)
            } else {
              if (postPhoto.length) {
                setFormData({ ...formData, productImages: postPhoto });
              }
              setFileSelected(true);
            }
          };
        };
      }
    } else {
      setFileSelected(false);
    }
  };

  const onBlurHandler = (e) => {
    if (e.target.value.split(" ").length < 10 && e.target.value) {
      alert("Minimum 10 words are required");
      setFormData({ ...formData, [e.target.name]: "" });
    }
  };

  const submitHandler = async () => {
    setIsSubmiting(true);
    if (
      formValidation([
        formData.category,
        formData.subCategory,
        formData.productName,
        formData.productDetails,
      ])
    ) {
      if (fileSelected) {
        axios
          .post(api.url + "/upload-productImage", uploadFiles)
          .then(function (response) {
            if (response.data.success) {
              addProducts(formData, response.data.newFileName);
            }
          })
          .catch(function (error) {
            setIsSubmiting(false);
            console.log(error);
          });
      } else {
        addProducts(formData, "Demo.png");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const addProducts = (data, newName) => {
    let newData = data;
    newData = { ...newData, productImages: [newName] };
    axios
      .post(api.url + "/add-product", newData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(function (response) {
        if (response.data.success) {
          alert("Add Successfull");
          navigate("/admin/products-list");
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmiting(false);
        alert(error.response.data.message);
        console.log(error.response.data.message);
      });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Product</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <div className="form-row mt-4">
            {verify_admin_hr ? (
              <>
                {/* Meta Data */}
                <h5 className="text-center col-12">
                  <strong>Meta Data</strong>
                </h5>
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="metaTitle">
                    Meta Title
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    id="metaTitle"
                    name="metaTitle"
                    value={formData.metaTitle}
                    onChange={(e) => changeHandler(e)}
                    placeholder={"Enter Meta Title"}
                    type="text"
                  />
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="metaKeywords">
                    Meta Keywords
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    id="metaKeywords"
                    name="metaKeywords"
                    value={formData.metaKeywords}
                    onChange={(e) => changeHandler(e)}
                    placeholder={"Enter Meta Keywords"}
                    type="text"
                  />
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel} for="metaDesc">
                    Meta Description
                    <small>
                      <sup className="text-danger">(Required)</sup>
                    </small>
                  </Label>
                  <Input
                    bsSize="sm"
                    id="metaDesc"
                    value={formData.metaDesc}
                    style={{ minHeight: "70px" }}
                    onChange={(e) => changeHandler(e)}
                    name="metaDesc"
                    placeholder={"Enter Meta Description"}
                    type="textarea"
                  />
                </FormGroup>
                <FormGroup className="col-md-12">
                  <Label className={style.formLabel}>
                    Behalf Of
                    <small>
                      <sup className="text-danger"> (Required)</sup>
                    </small>
                  </Label>
                  {companiesList.length ? (
                    <SelectJS
                      sm={"sm"}
                      defValue={""}
                      data={companiesList}
                      selected={getSelected}
                    />
                  ) : (
                    ""
                  )}
                </FormGroup>
              </>
            ) : (
              ""
            )}

            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Category{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              {categoriesSelect.length ? (
                <SelectJS
                  sm={"sm"}
                  data={categoriesSelect}
                  defValue={""}
                  selected={getSelected}
                />
              ) : (
                <SelectJS sm={"sm"} />
              )}
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Sub-Category{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              {subCategoriesSelect.length ? (
                <SelectJS
                  sm={"sm"}
                  data={subCategoriesSelect}
                  defValue={""}
                  selected={getSelected}
                />
              ) : (
                <SelectJS sm={"sm"} />
              )}
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Make it featured product{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="featuredProduct"
                name="featuredProduct"
                value={formData.featuredProduct}
                onChange={(e) => changeHandler(e)}
                type="select"
              >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Label className={style.formLabel} for="image">
                Product Image{" "}
                <small className="text-warning">
                  Image width and height should be same
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="image"
                className={style.fileInput}
                name="image"
                accept="image/*"
                onChange={(e) => uploadLogoImg(e)}
                type="file"
              />
            </FormGroup>
            {openModal ? (
              <ModalComponent
                heading={"Crop Image"}
                size={"modal-lg"}
                setModal={(e) => setOpenModal(e)}
                url={imageUrl}
                open={openModal}
              />
            ) : (
              ""
            )}
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Product Name{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="productName"
                name="productName"
                value={formData.productName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter product name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Additional Label{" "}
                <small>
                  <sup className="text-danger"> </sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="additionalLabel"
                name="additionalLabel"
                value={formData.additionalLabel}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter product additional label"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Product Price
                <small>
                  <sup className="text-danger"> </sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="productPrice"
                name="productPrice"
                value={formData.productPrice}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter product price"}
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Tag Keywords
                <small className="text-info">
                  <small>
                    {" "}
                    example: product name, category, sub-category, etc
                  </small>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="tagKeywords"
                name="tagKeywords"
                value={formData.tagKeywords}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter keywords for search your product"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Product Details{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="productDetails"
                style={{ minHeight: "50px" }}
                value={formData.productDetails}
                onChange={(e) => changeHandler(e)}
                onBlur={onBlurHandler}
                name="productDetails"
                placeholder={"Enter product details"}
                type="textarea"
                minLength={50}
              />
              <FormText>Total words : {wordCount}</FormText>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Product Description{" "}
              </Label>
              <SunEditor
                height={"150px"}
                placeholderValue={"Enter Product Description..."}
                quillValue={formData.productDesc}
                editor={editorText}
              />
            </FormGroup>
          </div>
          <Button
            disabled={isSubmiting}
            type="submit"
            onClick={submitHandler}
            className="btn-sm btn-main px-4"
          >
            Add Product
          </Button>
        </div>
      </div>
    </div>
  );
}

export default AddProduct;

const ModalComponent = (props) => {
  return (
    <Modal
      scrollable
      isOpen={props.open}
      style={{ overflow: "auto" }}
      toggle={() => {
        props.setModal(false);
      }}
      modalClassName="modal-register"
      className={props.size}
    >
      <div className="modal-header no-border-header text-center pb-0">
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            props.setModal(false);
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <h5 className="text-center">
          <strong>{props.heading}</strong>
        </h5>
      </div>
      <div className="modal-body pt-0 mb-3 px-3">
        <ImageCrop
          closeEdit={(e) => props.setModal(e)}
          src={props.url}
          aspect={"1"}
        />
      </div>
    </Modal>
  );
};
