import React from "react";
import { Container } from "reactstrap";
import PageHeading from "../../Components/PageHeading/PageHeading";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import style from "./style.module.css";
import { Grid } from "@mui/material";
import ContactusCard from "../../../../components/ContactusCard/ContactusCard";
import CallBackForm from "../../Components/CallBackForm/CallBackForm";

export default function Aboutus() {
  return (
    <div>
      <PageHeading page={"aboutUs"}></PageHeading>
      <div className={style.aboutContainer + " container"}>
        <BreadCrumb current={"About Us"}></BreadCrumb>
        <p>
          Global revelation, 24X7 leads showroom, instant inquiries, potential buyers! All this is often what B2B enterprise dream and want for. The
          decision from the international business market is to acknowledge the worth of net and rework businesses with effective E-Presence. The
          global appeal is the pre-requisite, in the present era, for any business to flourish in an arena where competition grows tougher at every
          level. With the growing competition, there arose a desire among the business enterprises to push their business online and appeal to global
          customers.
        </p>
        <p>
          At the same time, the need for a common B2B showroom to exhibit their products and a platform to promote their business became urgent.
          Besides this, the incessant tussle among the business entrepreneurs to revolutionize their business to attract more potential buyers and
          crack more prolific deals accounted for the presence of a common platform where they can promote their business online. WorldWebTrade is the
          answer to all, which continues to serve you with such a common B2B platform where innumerous manufacturers, wholesale suppliers, importers,
          exporters, service providers, etc. have registered in. A complete solution provider to all your business queries, WorldWebTrade is the
          destination where business enterprises have benefited from the much-needed promotion and exposure in the current scenario of the global
          market. WorldWebTrade has become a strong source of reliability because of the use of peerless technology and innovative measures. This
          online B2B directory is the home of innumerous products and businesses across the globe and hence it serves as the ideal destination for
          everyone who wants to witness a boom in the global trade scenario.
        </p>
        <p>
          Incepted in the recent year, this portal is owned and managed by Webbrightinfra Pvt Ltd., one of the leading names in the realm of web
          design and development and e-commerce solutions. Backed up by an invincible experience and dexterity, Webbrightinfra Pvt Ltd. has provided
          this portal the much-required exposure to the global business arena. For the same, this portal has become the sure-shot solution for all
          requirements of the buyers as well as the sellers.
        </p>
        <p>
          We are committed to provide each of the business entrepreneurs with the utmost exposure to the global market conditions and provide them a
          platform where they can interact with the respective community. We intend to be a destination where all the requirements of the business
          entrepreneur cease.
        </p>
        <p>
          We endeavor to grant a global status to every business irrespective of however small it is and wherever it is located on the earth’s face.
          Thus, we enable the businesses to have a strong stand against their peers through the B2B online interface.
        </p>
      </div>
      {/* Rewiew Couter */}
      <div>
        <Grid container className="bg-main my-5">
          <Grid item xs={6} md={3}>
            <div className={style.aboutCount}>
              <h2 className="m-0">
                <i className="fas fa-user-friends"></i> 1254
              </h2>
              <h4 className="m-1"> Customers</h4>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={style.aboutCount}>
              <h2 className="m-0">
                <i className="fas fa-id-card"></i> 3251
              </h2>
              <h4 className="m-1"> Buyers</h4>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={style.aboutCount}>
              <h2 className="m-0">
                <i className="fas fa-paperclip"></i> 4854
              </h2>
              <h4 className="m-1"> Products</h4>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={style.aboutCount}>
              <h2 className="m-0">
                <i className="fas fa-truck-loading"></i> 9854
              </h2>
              <h4 className="m-1"> Orders</h4>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* Team Mession Etc */}
      <Container className={style.aboutContainer}>
        <Grid container spacing={2}>
          <Grid item sm={12} md={6}>
            <div>
              <h3>
                <i className="fas fa-laptop-house"></i> Our Mission
              </h3>
              <p>
                Our mission is to touch the horizon where our capabilities may successfully meet with the requirements of our clients, that too with
                ultimate transparency and cost-effectiveness.
              </p>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <h3>
                <i className="fas fa-street-view"></i> Our Vision
              </h3>
              <p>To sow the seeds of par-excellence services with customer centric approach and reap the trust of worldwide clients.</p>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <h3>
                <i className="fas fa-hands-helping"></i> Core Values
              </h3>
              <p>
                Transpicuous Work Culture - Our words and actions always go hand-in-hand. We strongly preserve transparency to be correct ethically,
                legally and socially as well.
              </p>
              <p>
                Result-Orientation - By setting clear goals, fixing the priorities, organizing the resources-and rigorously monitoring the growth of
                project.
              </p>
              <p>
                Customer-Centric Approach - We revere the uniqueness of each client and his requirements and budget thus shape out the mirror-like
                solutions.
              </p>
              <p>
                Innovation - Think and do out of the box by setting the minds free. We also seek the unrevealed possibilities, hidden in feedbacks and
                suggestions of clients and co-workers.
              </p>
            </div>
          </Grid>
          <Grid item sm={12} md={6}>
            <div>
              <h3>
                <i className="fas fa-users"></i> Our Team
              </h3>
              <p>
                The efforts of an experienced and skilled team are rooted at the core of our working. All of our team members are adept in the
                respective fields and keep themselves abreast with the latest updates.
              </p>
              <p>
                Be it the designing and development, content or the management team, each member is dedicated towards the complete satisfaction of the
                buyers and the suppliers and carve out solutions accordingly.
              </p>
              <p>
                The experience inculcated over a period of more than a decade gives us the insight into the varying demands of the clients and lets us
                design solutions accordingly.
              </p>
            </div>
          </Grid>
        </Grid>
      </Container>
      {/* Contact US */}
      <Container className="p-3">
        <div className="row">
          <div className="col-md-6">
            <ContactusCard></ContactusCard>
          </div>
          <div className="col-md-6">
            <h5 className="text-center font-weight-bond">REQUEST <span className="text-danger">CALL BACK</span></h5>
            <CallBackForm></CallBackForm>
          </div>
        </div>
      </Container>
    </div>
  );
}
