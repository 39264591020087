import React from "react";
import { Avatar, Grid } from "@mui/material";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import ModalComponent from "../../../../components/Modal/ModalComponent";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";

function CompanyItem(props) {
  const companyDetails = props.data.aboutCompany !== undefined ? (props.data.aboutCompany + " " + props.data.dealsIn) : "";

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: name.split(' ').length > 1 ? `${ name.toUpperCase().split(' ')[0][0]}${name.toUpperCase().split(' ')[1][0]}`: `${ name.toUpperCase().split(' ')[0][0]}`,
    };
  }

  return (
    <div className={"card " + style.singleItem}>
      <div className="callout callout-danger p-0 m-0">
      <Grid container spacing={{ xs: 2, md: 3 }} style={{alignItems:"center"}} columns={{ xs: 8, sm: 8, md: 12, lg: 12 }}>
        <Grid item xs={8} sm={3} md={3} lg={3}>
          <div className={"m-3 text-center " + style.logo_box}>
          <a href={`/company/${props.data.catalogLink}`} className={"text-decoration-none"} target="_">
              {props.data.companyLogo === "noLogo.png" || !props.data.companyLogo? 
              <Avatar style={{width:"100px", height:"100px", margin:"auto", fontSize: "2rem"}} {...stringAvatar(props.data.company)} />
              // <img src={api.companyUrl + "noLogo.png"} alt="" /> 
              : <img src={api.companyUrl + props.data.companyLogo} alt="" />}
            </a>
          </div>
        </Grid>
        <Grid item xs={8} sm={5} md={5} lg={5}>
          <div className="m-3">
            <a className="text-decoration-none" href={`/company/${props.data.catalogLink}`} target="_">
              <p className="font-weight-bold">{props.data.company}</p>
            </a>
            <p className="m-0 text-justify">
              <small>
                {" "}
                <span className={style.strongText}>Details :</span>{" "}
                {companyDetails.length > 100 ? companyDetails.substring(0, 100) + "..." : companyDetails}
              </small>
            </p>
            <p className="m-0">
              <small>
                <span className={style.strongText}>Country :</span> {props.data.country}
              </small>
            </p>
            <p className="m-0">
              <small>
                <span className={style.strongText}>Location :</span> {props.data.location}
              </small>
            </p>
          </div>
        </Grid>
        <Grid item xs={8} sm={8} md={4} lg={4}>
          <div className="m-3 text-center">
            <p>
              <small>Business Type : {props.data.businessType}</small>
            </p>
            <div>
              <div style={{ width: "100px", display: "inline-block" }}>
                {props.data.membershipPlan === "Guest" ? (
                  <img style={{ filter: "brightness(0) invert(1) drop-shadow(black 0px 0px 1px)" }} src={api.planUrl + "silvercrown.png"} alt="" />
                ) : (
                  <img src={api.planUrl + props.data.membershipPlan.toLowerCase().replace(" ", "") + "crown.png"} alt="" />
                )}
              </div>
              <p>
                <small>
                  <strong>{props.data.membershipPlan} Member</strong>
                </small>
              </p>
              <div>
                <ModalComponent btnSm={"btn-sm"} btn={"Contact Now"} form={<RequirementForm small={"sm"} />} size={""} heading={"Form for Your Requirement "} />
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
      </div>
    </div>
  );
}

export default CompanyItem;
