import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, Table, Spinner } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { useSelector } from "react-redux";
import * as xlsx from 'xlsx';
import SelectJS from "../../../../components/Select/SelectJS";
import { getCookie } from "../../../../Helper/Cokkies";
import { formValidation } from "components/Utils/Utils";
const axios = require("axios").default;

function AddMultiParties() {
  const candidatesStore = useSelector((state) => state.CanditatesList.data);
  const tl = useSelector((state) => state.CanditatesList.tlData);
  const [submiting, setSubmiting] = useState(false)
  const [isFormValidated, setIsFormValidated] = useState(false)
  const [candidateList, setCandidateList] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [addedList, setAddedList] = useState([]);
  let token = getCookie("alcToken");
  const [formData, setFormData] = useState({ startFrom: 0, toEnd: 0, assignTo: "", data: [] });

  useEffect(() => {
    if (candidatesStore.length && tl.length) {
      const candidates = candidatesStore.map((el) => el.candidate)
      const listData = candidates.concat(tl)
      setCandidateList(listData.map((el) => {
        return { value: el._id, label: el.fullname + " ( " + el.username + " )" }
      }))
    }
  }, [candidatesStore, tl])

  const chandidateSelected = (select) => {
    setFormData({ ...formData, assignTo: select.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    setSubmiting(true)
    axios.post(api.url + "/new-multi-parties", formData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        if (response.data.duplicate) {
          alert(response.data.message);
          setSubmiting(false)
        } else if (response.data.partyExceed) {
          alert(response.data.message);
          setSubmiting(false)
        } else {
          alert("New Party have been Successfully Add");
          setFormData({ startFrom: "", toEnd: "", data: [], assignTo: "" });
          setAddedList(response.data.addedSerial)
          setSubmiting(false)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const selectFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setExcelData(json)
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const filterSelectedData = (start, end) => {
    if (excelData.length) {
      if (start) {
        setFormData({ ...formData, startFrom: Number(start) });
      } else {
        setFormData({ ...formData, toEnd: Number(end) });
      }
    } else {
      alert("Please upload file first.")
    }
  }

  const validateForm = (e) => {
    let excelValidate = true
    excelData.forEach(element => {
      let excelValidated = formValidation([element.contact, element.email, element.lastName, element.firstName, element.company, element.companyAddress, element.country])
      if (!excelValidated) { excelValidate = excelValidated; return }
    });

    if (!excelValidate) {
      alert("Please check excel file data some field is missing !!!")
      setIsFormValidated(false);
    } else {
      if (formData.toEnd > 0 && formData.startFrom > -1 && formData.assignTo) {
        if (formData.toEnd < formData.startFrom) {
          alert("End Value should be more then Start Value")
        } else if (formData.toEnd === formData.startFrom) {
          setFormData({ ...formData, data: excelData.filter((el, i) => i + 1 === formData.toEnd) })
          setIsFormValidated(true)
          alert("Validated")
        } else {
          setFormData({ ...formData, data: excelData.filter((el, i) => i + 1 >= formData.startFrom && i + 1 <= formData.toEnd) })
          setIsFormValidated(true)
          alert("Validated")
        }
      } else {
        alert("Please fill all required fields")
        setIsFormValidated(false)
      }
    }
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add New Party</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <div >
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="file">
                Excel File{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="file"
                name="file"
                value={formData.file}
                onChange={(e) => selectFile(e)}
                type="file"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="assignTo">
                Assigned To{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} selected={chandidateSelected} data={candidateList} />
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label className={style.formLabel} for="startFrom">
                Start From{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="startFrom"
                name="startFrom"
                value={formData.startFrom}
                onChange={(e) => filterSelectedData(e.target.value, "")}
                placeholder={"Starting Serial"}
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-3">
              <Label className={style.formLabel} for="toEnd">
                To End{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="toEnd"
                name="toEnd"
                value={formData.toEnd}
                onChange={(e) => filterSelectedData("", e.target.value)}
                placeholder={"Last Serial"}
                type="number"
              />
            </FormGroup>

          </div>
          <Button className="btn-sm btn-secondary px-4" disabled={submiting} onClick={(e) => validateForm()}>
            Validate {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
          {" "}
          <Button className="btn-sm btn-main px-4" onClick={(e) => submitHandler(e)} disabled={!isFormValidated} type="submit">
            Add Parties {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
        </div>

        {addedList.length ? (
          <>
            <h4 className="text-danger my-2">
              <strong>Added Serial No : {addedList.join(", ")}</strong>
            </h4>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddMultiParties;
