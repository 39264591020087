import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input,Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { IconButton } from "@mui/material";
import { formValidation } from "../../../../components/Utils/Utils";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent'
const axios = require("axios").default;

function AddCategory() {
  let token = getCookie("token");
  const [categoriesList, setCategoriesList] = useState([]);
  const [formData, setFormData] = useState({ category: "" });
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [pending, setPending] = useState(true);
  const [filterItems, setFilterItems] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-categories-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
      .then(function (response) {
        // console.log(response);
        response.data.categories.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setCategoriesList(response.data.categories);
        setFilterItems(response.data.categories);
        setTotalRows(response.data.total);
        setPending(false)
        setIsSearch(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch, tableOptions]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>CATEGORY</strong>,
      selector: (row) => row.category,
      sortable: true,
      sortField: "category"
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (
        <IconButton onClick={(e) => deletehandle(row._id)} className={style.actionBtn} color="error" size="small">
          <DeleteIcon fontSize="small" />
        </IconButton>
      ),
      width: "100px",
    },
  ];

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const deletehandle = (id) => {
    try {
      axios
        .post(api.url + "/delete-category", { id }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            alert("Delete Successfull");
            setFilterItems(categoriesList.filter((el) => {
              return id !== el._id
            }));
            setCategoriesList(categoriesList.filter((el) => {
              return id !== el._id
            }));
          }
        })
        .catch(function (error) {
          // console.log(error);
          alert(error.response.data.message);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (formValidation([formData.category])) {
      axios
        .post(api.url + "/add-category", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            alert("Add Successfull");
            formData["_id"] = response.data.catId;

            setCategoriesList([...categoriesList, { serial: categoriesList.length + 1, _id: response.data.catId, category: formData.category }]);

            setFilterItems([...filterItems, { serial: filterItems.length + 1, _id: response.data.catId, category: formData.category }]);

            setFormData({ category: "" });
          }
        })
        .catch(function (error) {
          console.log(error);
          alert(error.response);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Category</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <Form onSubmit={submitHandler}>
          <div className="form-row mt-4">
            <FormGroup className="col-md-8">
              <Label className={style.formLabel}>Category</Label>
              <Input
                bsSize="sm"
                id="category"
                name="category"
                value={formData.category}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Category"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-4 pt-4">
              <Button type="submit" style={{ marginTop: "4px" }} className="btn-sm btn-main px-4">
                Add Category
              </Button>
            </FormGroup>
          </div>
        </Form>

        {/* Table */}
        <p className="m-0 text-dark py-2">
          {" "}
          <strong>Category List :</strong>
        </p>

        <DataTableComponent
          pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
          perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
          setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
          expandable={false}
          inputFilterValue={filterValue}
          setSearchTrue={(e)=>setIsSearch(true)}
          inputFilter={(e)=>setFilterValue(e)}
          setRows={updateSelectedRow}
          columnsData={columns}
          dataLoad={pending}
          totalRowsData={totalRows}
          data={filterItems} />
      </div>
    </div>
  );
}

export default AddCategory;
