import React, { useEffect, useState } from "react";
import { getCookie } from "Helper/Cokkies";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import style from "./style.module.css"
import { Input } from "reactstrap";
import { IconButton } from "@mui/material";
import moment from "moment";
const axios = require("axios").default;
const columns = [
  {
    name: <strong>S.No.</strong>,
    selector: (row) => row.serial,
    sortable: true,
    width:"65px",
  },
  {
    name: <strong>USER NAME</strong>,
    selector: (row) => row.user.username,
    sortable: true,
  },
  {
    name: <strong>FULL NAME</strong>,
    selector: (row) => row.firstName + " " + row.lastName,
    sortable: true,
  },
  {
    name: <strong>CONTACT</strong>,
    selector: (row) => row.contact,
    sortable: true,
    width:"120px",
  },
  {
    name: <strong>EMAIL</strong>,
    selector: (row) => row.user.email,
    sortable: true,
  },
  {
    name: <strong>DATE</strong>,
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    sortable: true,
  },
  {
    selector: (row) => <IconButton className={style.actionBtn} color="primary" size="small">
    <DragIndicatorIcon fontSize="small" />
  </IconButton>,
    button: true,
    width:"65px",
  },
];

function Userlist() {
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const token = getCookie("token");
    axios
      .post(api.url + "/get-operator",{id:"ghf"}, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        response.data.operators.forEach((item, index) => { item.serial = index + 1; });
        setListData(response.data.operators);
        setFilterItems(response.data.operators);
        setPending(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => (item.company && item.company.toLowerCase().includes(e.target.value.toLowerCase())) || (item.firstName && item.firstName.toLowerCase().includes(e.target.value.toLowerCase())) || (item.lastName && item.lastName.toLowerCase().includes(e.target.value.toLowerCase())) || (item.userName && item.userName.toLowerCase().includes(e.target.value.toLowerCase())) || (item.contact.includes(e.target.value.toLowerCase())) || (item.user.email && item.user.email.toLowerCase().includes(e.target.value.toLowerCase())) );

      filteredList.forEach((item, index) => { item.serial = index + 1; });
      setFilterItems(filteredList);
    } else {
      listData.forEach((item, index) => { item.serial = index + 1; });
      setFilterItems(listData);
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Operators List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <Input id="search" name="search" className="border-primary" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      <DataTable pagination persistTableHead highlightOnHover columns={columns} data={filterItems} progressPending={pending} />
    </div>
  );
}

export default Userlist;
