import React from 'react'
import style from './style.module.css'

function ApkButton() {
  return (
    <a style={{color:"white !important"}} className={style.androidBtn} href={"../dist/docs/worldwebtrade.apk"} download>
      <img style={{ height: "15px" }} src="../dist/img/android.png" alt="" /> Mobile App
    </a>
  )
}

export default ApkButton