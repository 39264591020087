import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "assets/css/paper-kit.css";
import { Provider } from 'react-redux'
import { store } from './Redux/store'
import App from "./App";
import "assets/demo/demo.css";

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
    <App />
    </Provider>
  </React.Fragment>,
  document.getElementById("root")
);

reportWebVitals();
