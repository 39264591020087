import React from "react";
import style from "./style.module.css";
import Grid from "@mui/material/Grid";

export default function HorizontalBanner() {
  return (
    <div className={style.backGround}>
      <Grid container className={style.list}>
        <Grid item xs={6} md={3}>
          <div className={style.listItem}>
            <i className="fas fa-user-tie"></i> TRADE WITH CONFIDENCE
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={style.listItem}>
            <i className="fas fa-user-shield"></i> VERIFIED BUYERS
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={style.listItem}>
            <i className="fas fa-globe-asia"></i> GLOBAL NETWORK
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className={style.listItem}>
            <i className="fas fa-headset"></i> 24/7 HELP CENTER
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
