import React, { useState } from "react";
import style from "./style.module.css";
import { Button, Navbar,  Container, Input } from "reactstrap";
import Avatar from "@mui/material/Avatar";
import PersonAddAltRoundedIcon from "@mui/icons-material/PersonAddAltRounded";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { getCookie } from "../../../Helper/Cokkies";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../../Helper/Data";

function Header() {
  const [select, setSelect] = useState("Products/Services")
  const [searchPlaceholder, setSearchPlaceholder] = useState("Enter product or service name");
  const [searchObj, setsearchObj] = useState({ searchType: "products", searchKey: "" });
  const token = getCookie("token");
  const navigate = useNavigate();
  
  const selectHandle = (e) => {
    if (e.target.value === "Products/Services") {
      setSelect("Products/Services")
      setSearchPlaceholder("Enter product or service name");
      setsearchObj({ ...searchObj, searchType: "products" });
    } else if (e.target.value === "Companies") {
      setSearchPlaceholder("Enter company name");
      setSelect("Companies")
      setsearchObj({ ...searchObj, searchType: "companies" });
    } else {
      setSearchPlaceholder("Enter buy leads");
      setSelect("Buy Leads")
      setsearchObj({ ...searchObj, searchType: "importers" });
    }
  };

  const changeHandler = (e) => {
    setsearchObj({ ...searchObj, searchKey: e.target.value });
  };
  const searchHandle = (e) => {
    e.preventDefault();
    if(searchObj.searchKey){
      navigate("/search-" + searchObj.searchType+ "/" + searchObj.searchKey, { state: searchObj.searchKey });
    }else{
      navigate("/search-" + searchObj.searchType+ "/all-" + searchObj.searchType, { state: "" });
    }
  };
  return (
    <Navbar color="main" expand="lg" style={{boxShadow:"#80808085 1px 7px 8px 0px"}}>
      <div className="container">
        <Grid container spacing={2} style={{ alignItems: "center" }}>
          <Grid item xs={12} md={3}>
            <Link to={"/"}>
            <img style={{filter: "drop-shadow(2px 2px 10px white)"}} src={api.logosUrl + "logo.png"} className={style.logoImg} alt="worldwebtrade logo" />
            </Link>
          </Grid>
          <Grid item xs={12} md={5}>
            <div className={style.formBox}>
              <form onSubmit={searchHandle} className={style.form}>
                <Input className={style.searchType} name="searchType" onChange={selectHandle} value={select} type="select">
                  <option>Products/Services</option>
                  <option>Companies</option>
                  <option>Buy Leads</option>
                </Input>
                <Input type="text" name="searchValue" placeholder={searchPlaceholder} onChange={changeHandler} />
                <Button style={{background:'#b50008e8'}} type="submit" className={style.iconBtn}>
                  <i className="fas fa-search"></i>
                </Button>
              </form>
            </div>
          </Grid>
          
          <Grid item xs={12} md={4}>
            <div className={style.reqBtn}>
              {token === undefined ? (
                <>
                  <Link to="/join-form" className={style.requestBox}>
                    <Avatar className="mx-1">
                      <PersonAddAltRoundedIcon />
                    </Avatar>
                    <span className="mx-1">
                      <p className="m-0">
                        <strong>Join Now</strong>
                      </p>
                      <p className="m-0 text-secondary">
                        <small>Join Us for Free</small>
                      </p>
                    </span>
                  </Link>
                  <Link target={"_blank"} to="/admin" className={style.requestBox}>
                    <Avatar className="mx-1">
                      <HowToRegIcon />
                    </Avatar>
                    <span className="mx-1">
                      <p className="m-0">
                        <strong>Login</strong>
                      </p>
                      <p className="m-0 text-secondary">
                        <small>If already joined</small>
                      </p>
                    </span>
                  </Link>
                </>
              ) : (
                <>
                  <Link target={"_blank"} to="/admin/leads-list" className={style.requestBox}>
                    <Avatar className="mx-1">
                      <PostAddIcon />
                    </Avatar>
                    <span className="mx-1">
                      <p className="m-0">
                        <strong>Buy leads</strong>
                      </p>
                      <p className="m-0 text-secondary">
                        <small>Get leads for buy</small>
                      </p>
                    </span>
                  </Link>
                  <Link target={"_blank"} to="/admin" className={style.requestBox}>
                    <Avatar className="mx-1">
                      <ManageAccountsIcon />
                    </Avatar>
                    <span className="mx-1">
                      <p className="m-0">
                        <strong>Control Panel</strong>
                      </p>
                      <p className="m-0 text-secondary">
                        <small>Go to Admin Page</small>
                      </p>
                    </span>
                  </Link>
                </>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
    </Navbar>
  );
}

export default Header;
