import React, { useRef } from "react";
import Slider from "react-slick";
import style from "../style.module.css";
import { api } from "../../../../../Helper/Data";


function TemplateBanner(props) {
  const bannerSlider = useRef();

  return (
    <div className={style.bannerContainer}>
      <button
        className={style.preBtn}
        onClick={() => {
          bannerSlider.current.slickPrev();
        }}
      >
        <i className="fas fa-chevron-circle-left"></i>
      </button>
      <button
        className={style.nextBtn}
        onClick={() => {
          bannerSlider.current.slickNext();
        }}
      >
        <i className="fas fa-chevron-circle-right"></i>
      </button>
      <Slider
        autoplay={true}
        arrows={false}
        autoplaySpeed={2000}
        speed={1000}
        ref={(slider) => (bannerSlider.current = slider)}
        className={style.sliderContainer}
      >
        {props.banners.map((el, i) => {
          return (
            <div key={i} className={style.mainBannerItem}>
                <img src={api.bannerUrl + el.bannerImg} className={style.bgImage} alt="" />
                <img src={api.bannerUrl + el.bannerImg} className={style.mainImage} alt="" />
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default TemplateBanner;
