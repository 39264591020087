import React from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import ContactusCard from "../../../../components/ContactusCard/ContactusCard";
import CallBackForm from "../../Components/CallBackForm/CallBackForm";
import Container from "@mui/material/Container";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
const iframe = `<iframe
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d218.8619669869093!2d77.09183908358557!3d28.636011255774434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d04bf447aaaab%3A0xc9fc6fb41e7c6eb9!2sWeb%20Bright%20Infra!5e0!3m2!1sen!2sin!4v1654511300529!5m2!1sen!2sin"
height="450"
width="100%"
style="border:0;"
allowfullscreen="true"
loading="lazy"
referrerpolicy="no-referrer-when-downgrade"
></iframe>`;

function Iframe(props) {
  return <div dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }} />;
}

export default function Contactus() {
  return (
    <div>
      <PageHeading page={"contactUs"}></PageHeading>
      <div className={"container"}>
      <Container>
      <BreadCrumb current={"Contact Us"}></BreadCrumb>
        <Iframe iframe={iframe} />
      </Container>
      {/* Contact US */}
      <Container className="py-3">
        <div className="row">
          <div className="col-md-6">
            <ContactusCard></ContactusCard>
          </div>
          <div className="col-md-6">
            <h4 className="text-center font-weight-bold m-0">
              REQUEST <span className="text-danger"> CALL BACK</span>{" "}
            </h4>
            <CallBackForm></CallBackForm>
          </div>
        </div>
      </Container>
      </div>
    </div>
  );
}
