import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getCookie } from "../../../../Helper/Cokkies";
import jwt_decode from "jwt-decode";
import { adminNav, operatorNav, userNav, hrNav } from "../../AdminAssets/sideNavData";
import { Typography } from "@mui/material";
import style from './style.module.css'
import { api } from "../../../../Helper/Data";
import { useSelector } from "react-redux";

const Menu = () => {
  const company = useSelector((state) => state.CompanyUser.data);
  const [navLinks, setNavLinks] = useState([]);
  const location = useLocation();
  const path = location.pathname.split("/");
  const param = path[path.length - 1];
  const token = getCookie("token");
  var decode = jwt_decode(token);
  const role = decode.id.split("-")[1];

  useEffect(() => {
    role === "admin" ? setNavLinks(adminNav) : role === "operator" ? setNavLinks(operatorNav) :role === "hr" ? setNavLinks(hrNav) : setNavLinks(userNav);
  }, [role]);

  const menuOpen = (item) => {
    let data = item.linkItems.filter((el) => {
      return el.url === param;
    });
    return data.length ? true : false;
  };

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}

        <a href="/admin" className="brand-link bg-white" style={{ padding: "10px 8px" }}>
          <img src={api.logosUrl + "logomini.png"} alt="SA" style={{ opacity: ".8", margin: "0px 0px 0px 15px", height: "35px" }} />
           <span className="brand-text w-100">
            <img src={api.logosUrl + "logoText.png"} alt="SA" style={{ opacity: ".8", width: "150px" }} />
          </span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
        {role === "admin" || role === "hr" ? "" :
          <div className={style.companyLogo}>
            { company.companyLogo === "noLogo.png" ? <h5>{company.company}</h5> : <img alt="Logo" src={api.companyUrl + company.companyLogo}/>}
          </div>}
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul className="nav nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
              {navLinks.length
                ? navLinks.map((el, i) => {
                    return el.linkItems ? (
                      <li key={i} className={menuOpen(el) ? "nav-item menu-open" : "nav-item"}>
                        <Link to="#" className="nav-link">
                          <i className={"nav-icon " + el.icon} />
                          <Typography className="px-1">
                            {el.title}
                            <i className="right fas fa-angle-left" />
                          </Typography>
                        </Link>
                        <ul className={"nav nav-treeview"} style={{ display: menuOpen(el) ? "block" : "none" }}>
                          {el.linkItems.map((link, i) => {
                            return (
                              <li key={"link" + i} className="nav-item">
                                <NavLink className="nav-link" to={link.url}>
                                  <i className={"text-sm " + link.icon} />
                                  <Typography>{link.subTitle}</Typography>
                                </NavLink>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ) : (
                      <li key={i} className="nav-item">
                        <NavLink to={el.url} className="nav-link shadow-none">
                          <i className={"nav-icon " + el.icon} />
                          <Typography className="px-1">{el.title}</Typography>
                        </NavLink>
                      </li>
                    );
                  })
                : "Loading"}
            </ul>
          </nav>
          {/* /.sidebar-menu */}
        </div>
        {/* /.sidebar */}
      </aside>
    </>
  );
};

export default Menu;
