import { createSlice } from "@reduxjs/toolkit";

export const candidatesList = createSlice({
  name: "Candidates",
  initialState: {
    data: [],
    tlData: [],
  },
  reducers: {
    getCandidates: (state, action) => {
      state.data = action.payload;
    },
    UpdateCandidates: (state, action) => {
      state.data = action.payload;
    },
    getTLData: (state, action) => {
      state.tlData = action.payload;
    },
    UpdateTLData: (state, action) => {
      state.data = action.payload;
    },
    AddTLData: (state, action) => {
      state.tlData.push(action.payload);
    },
    AddCandidate: (state, action) => {
      state.data.push(action.payload);
    },
  },
});

export const { getCandidates, AddTLData, AddCandidate, UpdateCandidates, UpdateTLData, getTLData } = candidatesList.actions;

export default candidatesList.reducer;
