import React, { useState, useEffect } from "react";
import { Container } from "reactstrap";
import { api } from "../../../../Helper/Data";
import Categories from "../../Components/Categories/Categories";
import { useSelector } from "react-redux";
import { Avatar, Card, CardContent, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
const axios = require("axios").default;

function ProductCategories() {
  const [categoriesList, setCategoriesList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const categoriesStore = useSelector((state) => state.CategoryState.data);
  const subCategoriesStore = useSelector((state) => state.CategoryState.subData);

  useEffect(() => {
    axios
      .post(api.url + "/get-users", {id:"id"})
      .then(function (response) {
        // console.log(response.data)
        setCountryList(response.data.users);
      })
      .catch(function (error) {
        console.log(error);
      });

    const newList = categoriesStore.map((el) => {
      const list = [];
      subCategoriesStore.forEach((item) => {
        if (el.category === item.category) {
          list.push({ subCategory: item.subCategory, url: `/category/${item.category}/${item.subCategory}` });
        }
      });
      return { category: el.category, subCategories: list, url: `/category/${el.category}` };
    });
    setCategoriesList(newList);
  }, [categoriesStore, subCategoriesStore]);

  return (
    <Container className="my-3">
      <BreadCrumb current={"Products"} />
      <Card className="card-outline card-danger">
        <div className="card-header">
          <h5 className="text-center m-0 font-weight-bold"> Browse By <span className="text-main">Categories</span>{" "}</h5>
        </div>
        <CardContent className="py-3">
          {categoriesList.length ? <Categories categories={categoriesList}></Categories> : ""}
        </CardContent>
      </Card>

      <Card className="card-outline card-danger mt-3">
        <div className="card-header">
          <h5 className="text-center m-0 font-weight-bold"> Browse By <span className="text-main">Regions</span>{" "}</h5>
        </div>
        <CardContent className="py-3 px-4">
        <Grid container spacing={1}>
          {countryList.length
            ? countryList.map((el, i) => {
                return (
                  <Grid key={i} item xs={6} md={3}>
                    <Link className={style.regionalItem} to={`/products?country=${el._id.country.toLowerCase().replaceAll(" ", "-")}`}>
                      <Avatar sx={{ width: 30, height: 30 }} alt={el._id.country} src={el._id.countryFlag} />
                      <p className="mx-2"><strong>{el._id.country.toUpperCase()}</strong></p>
                    </Link>
                  </Grid>
                );
              })
            : ""}
        </Grid>
        </CardContent>
      </Card>
    </Container>
  );
}

export default ProductCategories;
