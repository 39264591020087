import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, } from "reactstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { getLocalStorage } from "../../../../Helper/LocalStorage";

function TeamList() {
  const candidates = useSelector((state) => state.CanditatesList.data);
  let user = getLocalStorage("alcUser");
  const [pending, setPending] = useState(true);
  const [teamList, setTeamList] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.candidate.fullname,
      sortable: true,
      width: "150px",
    },
    {
      name: <strong>User Name</strong>,
      selector: (row) => row.candidate.username,
      sortable: true,
    },
    {
      name: <strong>Email</strong>,
      selector: (row) => row.candidate.email,
      sortable: true,
    },
    {
      name: <strong>Add On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>ACTION</strong>,
      cell: (row) => (
        <>
          <Link to={"/allocation/allocation-list/"+row.candidate.username} state={{ user: row.candidate }}>
            <Button className="btn-link p-0 btn-sm" color="primary">
            Allocate <i class="fas fa-external-link-alt"></i>
            </Button>
          </Link>
        </>
      ),
    },
  ];

  useEffect(() => {
    console.log(candidates);
    const newList = candidates.filter((el) => {
      console.log(el);
      return user.id === el.teamLeader._id
    })

    setTeamList(newList.map((item, index) => {
      return { ...item, serial: index + 1 };
    }))
    setFilterItems(newList.map((item, index) => {
      return { ...item, serial: index + 1 };
    }))
    setPending(false)

  }, [candidates, user.id]);

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = teamList.filter((item) => item.keyWords && item.keyWords.toLowerCase().includes(e.target.value.toLowerCase()));
      setFilterItems(filteredList);
    } else {
      setFilterItems(teamList);
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Team List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <InputGroup style={{ border: "solid 1px #007bff", borderRadius: "5px" }}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="nc-icon nc-zoom-split"></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input id="search" name="search" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      </InputGroup>

      <DataTable
        pagination
        persistTableHead
        highlightOnHover
        columns={columns}
        data={filterItems}
        progressPending={pending}
      />
    </div>
  );
}

export default TeamList;