import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useSelector } from "react-redux";
import PreviewIcon from '@mui/icons-material/Preview';
import { IconButton } from "@mui/material";
import moment from "moment";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent'
import { getCookie } from "Helper/Cokkies";
const axios = require("axios").default;

function InvoiceList() {
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt"});
  const token = getCookie("token");

  useEffect(() => {
      axios
        .post(api.url + "/get-invoice-list", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
        .then(function (response) {
          console.log(response.data);
          response.data.invoicelist.forEach((item, index) => {
            item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
          });
          setListData(response.data.invoicelist);
          setFilterItems(response.data.invoicelist);
          setTotalRows(response.data.total);
          setIsSearch(false)
          setPending(false)
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [tableOptions, isSearch, filterValue]);

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-invoice", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
      sortField: "createdAt"
    },
    {
      name: <strong>Invoice No</strong>,
      selector: (row) => "#"+row.invoiceNo,
      sortable: true,
      width: "115px",
      sortField: "invoiceNo"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>GSTN</strong>,
      selector: (row) =>  row.gstn,
      width: "150px",
      sortField: "gstn"
    },
    {
      name: <strong>Pan</strong>,
      selector: (row) => row.pan,
      width: "120px",
      center: true,
    },
    {
      name: <strong>Date</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "createdAt"
    },
    {
      name: <strong>Action</strong>,
      cell: (row) => (
        <>
          <a href={"/invoice/" + row.invoiceNo} target="_">
            <IconButton>
              <PreviewIcon fontSize="small" color="info" />
            </IconButton>
          </a>
          <IconButton onClick={() => deleteItem(row._id)}>
            <DeleteIcon fontSize="small" color="error" />
          </IconButton>
        </>
      ),
    },
  ];

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder" }}>
        
      </div>
    );
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Product List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={false}
        inputFilterValue={filterValue}
        setSearchTrue={(e)=>setIsSearch(true)}
        inputFilter={(e)=>setFilterValue(e)}
        setRows={updateSelectedRow}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default InvoiceList;
