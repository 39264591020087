import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import moment from "moment";
import { getCookie } from "../../../../Helper/Cokkies";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import MergeComponent from "Modules/Allocation/Components/Merge/MergeComponent";
import { Button, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { userValidate } from "../../../../components/Utils/Utils";
const axios = require("axios").default;

function PartyLogsList() {
  const [pending, setPending] = useState(true);
  const navigate = useNavigate();
  const admin_validate = userValidate(["admin"], "alcToken");
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const user = getLocalStorage("alcUser")
  let token = getCookie("alcToken");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "asc", sortField: "updateAt" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
      sortField: "updateAt"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      sortable: true,
      sortField: "firstName",
      width: "250px",
    },
    {
      name: <strong>Last Update</strong>,
      selector: (row) => moment(row.updateAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
    {
      name: <strong>ACTION</strong>,
      cell: (row) => (
         <Tooltip title="View Logs">
          <Button onClick={(e) => navigate("/allocation/view-logs/"+ row.user)}>
            Log
          </Button>
        </Tooltip> 

      ),
      width: "150px",
    },
  ];

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/party-logs-list", {user : user, page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue, merged: true }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        response.data.companies.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setListData(response.data.companies);
        setFilterItems(response.data.companies);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch, tableOptions, token]);

  const updateSelectedRow = (data) => {
    setSelectedRows(data)
  }

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>

      </div>
    );
  };

  const statusmoved = (e) => {
    const filteredArray = listData.filter(function (x) {
      return e.indexOf(x) < 0;
    });
    setListData(filteredArray)
    setFilterItems(filteredArray)
  };

  return (
    <div>
      <AnnounceMarquee />
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Company List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <MergeComponent deleteComment={true} reduxValue={{ decrease: "unassignedparties", increase: "" }} setModal={(e) => setOpenModal(e)} merge={statusmoved} users={selectedRows} open={openModal} />

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={false}
        selecteRow={admin_validate}
        action={"Merge With"}
        selectedAssign={(e) => setOpenModal(true)}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default PartyLogsList;