import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import BusinessIcon from '@mui/icons-material/Business';
import CallIcon from '@mui/icons-material/Call';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import style from "./style.module.css";
import moment from "moment";
import ModalComponent from "../../../../components/Modal/ModalComponent";

function LeadDetails() {
  const countries = useSelector((state) => state.CountriesList.countries);
  const location = useLocation();

  return (
    <div className="container py-3">
      <BreadCrumb primary={{ url: "/importers", text: "Buyers-Importers" }} current={location.state.leadTitle} />

      <div className="row">
        <div className="col-md-3 py-3">
          <RequirementForm data={countries} small={"sm"} />
        </div>
        <div className="col-md-9">
          <div className="card-outline card-primary">
              <div className="card-body">
                <div className="card-header p-0 border-0">
                  <div className="card-title m-0">
                    <p className="font-weight-bold">{location.state.leadTitle}</p>
                  </div>
                  <div className="card-tools text-secondary">
                    <AccessTimeIcon fontSize="small" /> <strong>{moment(location.state.createdAt).format("DD MMM YYYY")}</strong>
                  </div>
                </div>
                <p className="text-secondary">
                  <small>
                    <strong>Lead Id : {location.state.leadId}</strong>
                  </small>
                </p>
                <p className="m-0 text-justify">
                  <strong>{location.state.leadMessage}</strong>
                </p>
                <div className="row" style={{ alignItems: "center" }}>
                  <div className="col-6 col-sm-4 col-lg-2 my-2">
                    <div className={style.leadContacts}>
                      <p className="m-0">
                        <BusinessIcon style={{ fontSize: "small" }} /> Company
                      </p>
                      <p className={"m-0 " + style.blurItem}>
                        <ModalComponent
                          btnLink={true}
                          btn={"XXXXXXXXXXXXXXX"}
                          form={<RequirementForm data={countries} small={"sm"} />}
                          size={""}
                          heading={"Form for Your Requirement "}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2 my-2">
                    <div className={style.leadContacts}>
                      <p className="m-0">
                        <CallIcon style={{ fontSize: "small" }} /> Contact No
                      </p>
                      <p className={"m-0 " + style.blurItem}>
                        <ModalComponent
                          btnLink={true}
                          btn={"XXXXXXXXXX"}
                          form={<RequirementForm small={"sm"} />}
                          size={""}
                          heading={"Form for Your Requirement "}
                        />
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2 my-2">
                    <div className={style.leadContacts}>
                      <p className="m-0">Country</p>
                      <p className="m-0">
                        <img src={location.state.countryFlag} style={{ height: "10px" }} alt="" /> {location.state.country}
                      </p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2 my-2">
                    <div className={style.leadContacts}>
                      <p className="m-0">
                        <PersonPinIcon style={{ fontSize: "small" }} /> Buyer
                      </p>
                      <p className="m-0">{location.state.leadingName}</p>
                    </div>
                  </div>
                  <div className="col-6 col-sm-4 col-lg-2 my-2">
                    <ModalComponent
                      btnSm={"btn-sm"}
                      btn={"Contact Now"}
                      form={<RequirementForm small={"sm"} />}
                      size={""}
                      heading={"Form for Your Requirement "}
                    />
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadDetails;
