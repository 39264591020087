import React, { useEffect, useState } from "react";
import { api } from "../../Helper/Data";
import InvoiceFormat from "./InvoiceFormat";
import { useParams } from "react-router-dom";
import axios from 'axios'
import { Button } from "@mui/material";

export default function InvoicePage() {
  const { invoiceNo } = useParams();
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const [printPage, setPrintPage] = useState(false)

  useEffect(() => {
    axios
        .post(api.url + "/get-invoice-details", { invoice: invoiceNo})
        .then(function (response) {
          console.log(response.data);
          if(response.data.success){
            setInvoiceDetails(response.data.invoice)
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [invoiceNo])

  const printPageFuct=()=>{
    setPrintPage(true)
    setTimeout(() => {
      window.print()
      setPrintPage(false)
    }, 10);
  }

  return (
    <div>
      <Button variant="outlined" size="small" style={{display:printPage? "none": "inline", margin:"10px"}} onClick={(e)=> printPageFuct()}>Print</Button>
      {invoiceDetails == null ? "" : <InvoiceFormat logo={api.logosUrl} data={invoiceDetails} />}
      <br />
    </div>
  );
}
