import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { Button, ButtonGroup, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { Container, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from 'react-simple-captcha';
import GroupIcon from "@mui/icons-material/Group";
import AddTaskIcon from "@mui/icons-material/AddTask";
import StarIcon from "@mui/icons-material/Star";
import { useEffect, useState } from "react";
import axios from "axios";
import { api } from "../../../../Helper/Data";

export default function VCertificate() {
  const [otpSend, setOtpSend] = useState(false)
  const [captchaInput, setCaptchaInput] = useState('');
  const [verifiedText, setVerifiedText] = useState('');
  const [certType, setCertType] = useState(true);
  const [otpCode, setOTPCode] = useState('');

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const submitMyForm = () => {
    if (verifiedText) {
      if (validateCaptcha(captchaInput) === true) {
        let digits = '0123456789';
        let OTP = '';
        let len = digits.length
        for (let i = 0; i < 4; i++) { OTP += digits[Math.floor(Math.random() * len)] }
        idVerification(OTP)
      } else {
        alert('Captcha is Not Matched');
        setCaptchaInput('');
        setOtpSend(false)
      }
    } else {
      setOtpSend(false)
      alert("Please fill the required fields");
    }
  };

  const idVerification = (otp) => {
    axios.post(api.url + "/send-verfiedId-otp", { verifiedId: verifiedText, otpCode: otp, domestic: certType }).then(function (response) {
      if (response.data) {
        // console.log(response.data);
        if (response.data.success) {
          setOtpSend(true)
          alert("OTP Send on Verified Email")
          setCaptchaInput('');
        } else {
          setOtpSend(false)
          alert(response.data.msg)
        }
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  const verifyOtp = () => {
    axios.post(api.url + "/verify-certificate-otp", { verifiedId: verifiedText, otpCode: otpCode }).then(function (response) {
      if (response.data) {
        // console.log(response.data);
        if (response.data.success) {
          setOtpSend(false)
          loadCaptchaEnginge(6)
          setVerifiedText("")
          setCaptchaInput("")
          window.open(api.certificateImg + response.data.certificate[0].certificateImage[0], '_blank')
        } else {
          setOtpSend(false)
          alert(response.data.msg)
        }
      }
    })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <PageHeading page={"authentication"}></PageHeading>
      <Container className="py-3 container">
        <BreadCrumb current={"Authentication"} />
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <h4 className="text-center my-3">
              <strong>
                Authentication
              </strong>
            </h4>
            <div className="container">
              <FormGroup className="col-md-9">
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" name="inlineRadioOptions" id="domestic" onChange={() => setCertType(true)} value="true"/>
                    <label class="form-check-label p-0" for="domestic">Domestic</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="radio" onChange={() => setCertType(false)} name="inlineRadioOptions" id="international" value="false"/>
                    <label class="form-check-label p-0" for="international">International</label>
                </div>
              </FormGroup>
              <FormGroup className="col-md-9">
                <Label className={style.formLabel} for="certId">
                  Verified Id / Authentication Id
                </Label>
                <Input type="text" onChange={(e) => setVerifiedText(e.target.value)} id="certId" name="certId" placeholder="Enter Verified Id..." />
              </FormGroup>

              {otpSend ? <FormGroup className="col-md-9">
                <Label className={style.formLabel} for="otpCode">
                  Enter OTP
                </Label>
                <Input type="text" onChange={(e) => setOTPCode(e.target.value)} id="otpCode" name="otpCode" placeholder="Enter OTP..." />
              </FormGroup> : <>
                <div className="col-md-9">
                  <LoadCanvasTemplate />
                </div>

                <FormGroup className="col-md-9">
                  <input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" value={captchaInput} onChange={(e) => setCaptchaInput(e.target.value)} />
                </FormGroup>
              </>}

              <div className="col-md-9">
                {!otpSend ? <Button block={true} onClick={submitMyForm} color="main">
                  Submit
                </Button> : <Button block={true} onClick={verifyOtp} color="main">
                  Verify
                </Button>}
              </div>
            </div>

          </div>
          <div className="col-md-6 order-1 order-md-2">
            <h4 className="text-center my-3">
              <strong>Verified Trust Certificate</strong>
            </h4>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                  <GroupIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Get Instant Feedback from Suppliers</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <AddTaskIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Accredited Suppliers that Meet Your Needs.</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Get the power to choose the best!</p>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </div>
        </div>
      </Container>
    </div>
  );
}
