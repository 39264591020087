import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import moment from "moment";
const axios = require("axios").default;

export default function Dashboard() {
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalLeads, setTotalLeads] = useState(0);
  let token = getCookie("token");
  

  useEffect(() => {
    if (currentCompanyUser.leadsCredits) {
      setTotalLeads(currentCompanyUser.leadsCredits)
    }

    axios
      .post(api.url + "/getProductCount", { id: currentCompanyUser._id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        setTotalProduct(response.data.productCount);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [currentCompanyUser, token]);

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Dashboard</h4>
          <hr className="my-2" />
        </div>
      </div>
      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 col-6">
              <div className="small-box bg-info">
                <div className="inner">
                  <h3>
                    {`${totalLeads} / ${currentCompanyUser.membershipPlan === "Silver"
                      ? 10
                      : currentCompanyUser.membershipPlan === "Platinum"
                        ? 30
                        : currentCompanyUser.membershipPlan === "Platinum Pro"
                          ? 60
                          : currentCompanyUser.membershipPlan === "Elite"
                            ? 80
                            : 0
                      }`}
                  </h3>
                  <p>Buy Lead Credit</p>
                </div>
                <div className="icon">
                  <i className="fas fa-clipboard-list"></i>
                </div>
                <Link to="/admin/leads-list" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-6">
              <div className="small-box bg-success">
                <div className="inner">
                  <h3>{totalProduct}</h3>
                  <p>Total Product</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <Link to="/admin/products-list" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>
            {/* ./col */}
            <div className="col-lg-4 col-6">
              {/* small box */}
              <div className="small-box bg-warning">
                <div className="inner">
                  <h4 className="my-0"> {currentCompanyUser.membershipPlan}</h4>
                  <h5 className="mb-2"> Member </h5>
                  <p>
                  {currentCompanyUser.subscribeAt ?  <strong>{moment(currentCompanyUser.subscribeAt).format("DD/MM/YYYY")} for {currentCompanyUser.subscriptionPeriod} yrs</strong> : ''}
                  </p>
                </div>
                <div className="icon">
                  {/* <i className="fas fa-user-shield fa-sm"></i> */}
                  <i className="fas fa-crown fa-sm"></i>
                </div>
                <Link to="/admin/company-details" className="small-box-footer">
                  More info <i className="fas fa-arrow-circle-right" />
                </Link>
              </div>
            </div>
            {/* ./col */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container-fluid */}
      </section>
      {/* /.content */}
      {/* </div> */}
    </div>
  );
}
