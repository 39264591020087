import React, { useState } from "react";
import { Button, Card, Form, Input, Container, Row, Col, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { setLocalStorage } from "../../../../Helper/LocalStorage";
import { getCookie, setCookie } from "../../../../Helper/Cokkies";
import { Link, Navigate } from "react-router-dom";
const axios = require("axios").default;
let adminAccess = getCookie("adminAccess")

function Login() {
  const [hidePass, setHidePass] = useState(true);
  const [accessValidate, setAccessValidate] = useState(adminAccess !== undefined);

  if (localStorage.getItem("alcUser")) {
    return <Navigate to={"/allocation"} />;
  }

  const setpass = (e) => {
    setHidePass(!hidePass)
  }

  return (
    <div className={style.loginContainer}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4">
            <img className="w-100" style={{ filter: "drop-shadow(0px 5px 15px white)" }} src={api.logosUrl + "logo.png"} alt="World Web Trade" />
            {accessValidate ? <AllocationLogin pass={hidePass} passwordShow={setpass} /> : <AdminAccess access={(e) => setAccessValidate(e)} />}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;

const AllocationLogin = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const changeHandle = (e, fieldType) => {
    fieldType === "email" ? setEmail(e.target.value.toLowerCase().replaceAll(" ", "")) : setPassword(e.target.value);
  };

  const loginForm = (e) => {
    e.preventDefault();
    axios
      .post(api.url + "/alc-login", { email: email, password: password })
      .then(function (response) {
        // console.log(response);
        if (response.data) {
          setLocalStorage("alcUser", { name: response.data.fullname, username: response.data.username, id: response.data.id, email: response.data.email, role: response.data.role });
          setCookie("alcToken", response.data.token, 3);
          window.location.replace("/allocation");
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message);
      });
  };
  return (
    <Card className="card-register ml-auto mr-auto">
      <h4 className="text-center text-dark font-weight-bold">ALLOCATION LOGIN</h4>
      <Form className="register-form" onSubmit={loginForm}>
        <label htmlFor="email">Email</label>
        <Input placeholder="Email" name="email" id="email" value={email} onChange={(e) => changeHandle(e, "email")} type="text" />
        <label htmlFor="password">Password</label>
        <InputGroup>
          <Input
            type={props.pass ? "password" : "text"}
            value={password}
            onChange={(e) => changeHandle(e, "password")}
            placeholder="Password"
          />
          <InputGroupAddon onClick={(e) => props.passwordShow(e)} addonType="prepend">
            <InputGroupText className={style.addOnInput}>
              {props.pass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
            </InputGroupText>
          </InputGroupAddon>
        </InputGroup>
        <Button block className="btn-round bg-main border-0" color="danger">
          Login
        </Button>
      </Form>
      <div className="forgot">
        <Button className="btn-link" href="#pablo" onClick={(e) => e.preventDefault()}>
          Forgot password?
        </Button>
        <Link className="btn btn-link my-3 text-white" block="true" to="/">
          Go To Public Page
        </Link>
      </div>
    </Card>
  );
};

const AdminAccess = (props) => {
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");

  const loginForm = (e) => {
    e.preventDefault();
    if (password && email) {
      axios
        .post(api.url + "/admin-access", { password: password, email: email })
        .then(function (response) {
          console.log(response.data);
          if (response.data) {
            setCookie("adminAccess", response.data.token, 30);
            adminAccess = response.data.token
            props.access(true)
          }
        })
        .catch(function (error) {
          console.log(error);
          alert("Access denied !");
        });
    } else {
      alert("Email & password are required")
    }
  };

  return (
    <Card className="card-register ml-auto mr-auto">
      <h1 className="text-center m-0" style={{ fontSize: "3rem" }}>
        <i className="fas fa-user-shield"></i>
      </h1>
      <h4 className="text-center text-dark font-weight-bold">Admin Access Permission</h4>
      <Form className="register-form my-1" onSubmit={loginForm}>
        <label htmlFor="email">User Email</label>
        <Input placeholder="Email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} type="text" />
        <label htmlFor="password">Admin Access Code</label>
        <Input
          type={"password"}
          id="password"
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <Button block className="btn-round bg-main border-0 my-3" color="danger">
          Submit
        </Button>
      </Form>
    </Card>
  );
};
