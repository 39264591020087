import { createSlice } from '@reduxjs/toolkit'


export const categoryLoged = createSlice({
  name: 'CategoryState',
  initialState: {
    data: [],
    subData: []
  },
  reducers: {
    getCategoryLoged: (state, action) => {
      state.data = action.payload.catGroup
      state.subData = action.payload.subCategory
    },
    addCategoryLoged: (state, action) => {
      state.data.push({ category: action.payload.category, url: `/category/${action.payload.category.toLowerCase().split(" ").join('-')}`, subCategories: [] })
    },
    UpdateCategory: (state, action) => {
      state.data = action.payload
    },
    addSubCategoryLoged: (state, action) => {
      state.data.map((el) => {
        return el.category === action.payload.category ? el.subCategories.push({
          subCategory: action.payload.subCategory,
          imageUrl: action.payload.imageUrl,
          url: `/category/${action.payload.category.toLowerCase().split(" ").join('-')}/${action.payload.subCategory.toLowerCase().split(" ").join('-')}`
        }) : ""
      })
      state.subData.push(action.payload)
    },
    UpdateSubCategory: (state, action) => {
      state.data.filter((el) => {
        return action.payload !== el._id
      })
    },
    deleteCategory: (state, action) => {
      const filterd = state.data.filter((el) => {
        return el._id !== action.payload
      })
      state.data = filterd
    },
    deleteSubCategory: (state, action) => {
      const filterd = state.subData.filter((el) => {
        return el._id !== action.payload
      })
      state.subData = filterd
    },
  },
})

export const { getCategoryLoged, addCategoryLoged, addSubCategoryLoged, UpdateSubCategory, UpdateCategory, deleteCategory, deleteSubCategory } = categoryLoged.actions

export default categoryLoged.reducer