import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectJS from "../../../../components/Select/SelectJS";
import { formValidation } from "../../../../components/Utils/Utils";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import moment from "moment";
const axios = require("axios").default;

function UpdateLead() {
  const location = useLocation();
  const countries = useSelector((state) => state.CountriesList.countries);
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const subCategoriesStore = useSelector(
    (state) => state.CategoryState.subData
  );
  const [formData, setFormData] = useState(location.state);
  const [subCategoriesSelect, setSubCategoriesSelect] = useState([]);

  let navigate = useNavigate();
  let token = getCookie("token");

  useEffect(() => {
    if (subCategoriesStore.length) {
      const selectList = subCategoriesStore.map((el) => {
        return { value: el._id, label: el.subCategory, type: "subCategory" };
      });
      setSubCategoriesSelect(selectList);
    }

    if (currentCompanyUser.user !== undefined) {
      setFormData({
        ...formData,
        createdBy:
          currentCompanyUser.firstName +
          "( " +
          currentCompanyUser.user.role +
          " )",
      });
    }
  }, [currentCompanyUser]);

  const customThemeFn = (theme) => ({
    ...theme,
    spacing: {
      ...theme.spacing,
      controlHeight: 30,
      baseUnit: 2,
    },
  });

  const getSelected = (select) => {
    if (select.flag) {
      setFormData({
        ...formData,
        country: select.label,
        countryFlag: select.flag,
        callCode: "+" + select.code,
      });
    } else {
      setFormData({ ...formData, subCategory: select.label });
    }
  };

  const onSelecteChange = (e) => {
    setFormData({ ...formData, tagKeywords: e.map((el) => el.value) });
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    if (
      formValidation([
        formData.leadMessage,
        formData.leadTitle,
        formData.leadingName,
        formData.tagKeywords.length > 0
      ])
    ) {
      axios
        .post(api.url + "/update-lead", formData, {
          headers: { authorization: "Bearer " + token },
        })
        .then(function (response) {
          if (response.data.success) {
            alert("Update Successfull");
            navigate("/admin/leads-list");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Please fill the required fields");
    }
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Update Leads</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label for="company" className={style.formLabel}>
                Behalf Of
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Company Name..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Sub-Category{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              {subCategoriesSelect.length ? (
                <SelectJS
                  sm={"sm"}
                  data={subCategoriesSelect}
                  defValue={formData.subCategory}
                  selected={getSelected}
                />
              ) : (
                <SelectJS sm={"sm"} defValue={formData.subCategory} />
              )}
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="leadTitle">
                Lead Title{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="leadTitle"
                name="leadTitle"
                value={formData.leadTitle}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter title..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="leadingName">
                Name{" "}
                <small>
                  <sup className="text-danger"> (Required) </sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="leadingName"
                name="leadingName"
                value={formData.leadingName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter person name..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email..."}
                type="email"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <InputGroup>
                <InputGroupText className="py-0">
                  {formData.callCode}
                </InputGroupText>
                <Input
                  bsSize="sm"
                  id="contact"
                  name="contact"
                  value={formData.contact}
                  onChange={(e) => changeHandler(e)}
                  placeholder={"Enter Contact No..."}
                  type="number"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="location">
                Location
              </Label>
              <Input
                bsSize="sm"
                id="location"
                name="location"
                value={formData.location}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter location..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="country">
                Country{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS
                sm={"sm"}
                data={countries}
                defValue={formData.country}
                selected={getSelected}
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="tagKeywords">
                Tag Keywords <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <CreatableSelect
                defaultValue={formData.tagKeywords.map((el) => {
                  return { value: el, label: el };
                })}
                isMulti
                onChange={(e) => {
                  onSelecteChange(e);
                }}
                theme={customThemeFn}
                name="tagKeywords"
                id="tagKeywords"
                className="basic-multi-select"
                classNamePrefix="select"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DateTimePicker
                  label="Change Lead Date"
                  renderInput={(params) => <TextField {...params} />}
                  value={formData.createdAt}
                  onChange={(newValue) => {
                    setFormData({
                      ...formData,
                      createdAt: moment(newValue._d).format(),
                    });
                  }}
                />
              </LocalizationProvider>
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="leadMessage">
                Lead Message{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="leadMessage"
                style={{ minHeight: "100px" }}
                value={formData.leadMessage}
                onChange={(e) => changeHandler(e)}
                name="leadMessage"
                placeholder={"Enter message..."}
                type="textarea"
                minLength={50}
              />
            </FormGroup>
            {/* Meta Data */}
            <h5 className="text-center col-12">
              <strong>Meta Data</strong>
            </h5>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="metaTitle">
                Meta Title
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="metaTitle"
                name="metaTitle"
                value={formData.metaTitle}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Meta Title"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="metaKeywords">
                Meta Keywords
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="metaKeywords"
                name="metaKeywords"
                value={formData.metaKeywords}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Meta Keywords"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="metaDesc">
                Meta Description
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="metaDesc"
                value={formData.metaDesc}
                style={{ minHeight: "70px" }}
                onChange={(e) => changeHandler(e)}
                name="metaDesc"
                placeholder={"Enter Meta Description"}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button
            type="submit"
            onClick={submitHandler}
            className="btn-sm btn-main px-4"
          >
            Update Lead
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UpdateLead;
