import React, { useState } from "react";
import { Button, FormGroup, Input, Label, Spinner } from "reactstrap";
import style from "./style.module.css";
import { formValidation } from "../../../../components/Utils/Utils";
import { api } from "../../../../Helper/Data";
import SelectJS from "../../../../components/Select/SelectJS";
import { useSelector } from "react-redux";
import Swal from 'sweetalert2/dist/sweetalert2.js'
const axios = require("axios").default;

export default function RequirementForm(props) {
  const countries = useSelector((state) => state.CountriesList.countries);
  const [reqForm, setReqForm] = useState({ postBy: "Buyer", name: "", country: "India", contact: "", companyName: "", email: "", requirement: "" });
  const [submiting, setSubmiting] = useState(false)

  const getSelected = (select) => {
    setReqForm({ ...reqForm, country: select.value });
  };

  const postRequirement = (e) => {
    e.preventDefault();
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([reqForm.postBy, reqForm.name, reqForm.country, reqForm.contact, reqForm.companyName, reqForm.email, reqForm.requirement])) {
      if (filter.test(reqForm.email)) {
        axios
          .post(api.url + "/reqForm", reqForm)
          .then(function (response) {
            if (response.data.reqForm) {
              Swal.fire({
                text: 'We get your requirement, we will connect you in few time.',
                icon: 'success',
                timer: 3000,
                confirmButtonText: 'Cool'
              })
              setSubmiting(false)
              setReqForm({ postBy: "Buyer", name: "", country: "India", contact: "", companyName: "", email: "", requirement: "" })
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        alert("Please enter valid email / contact");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const changeHandler = (e) => {
    setReqForm({ ...reqForm, [e.target.name]: e.target.value });
  };

  return (
    <div>
      <form onSubmit={postRequirement}>
        <div className="form-row">
          <FormGroup className="col-md-6">
            <Label className={style.formLabel} for="postBy">
              I am
            </Label>
            <Input bsSize={props.small ? "sm" : ""} onChange={(e) => changeHandler(e)} id="postBy" name="postBy" type="select">
              <option>Buyer</option>
              <option>Seller</option>
            </Input>
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label className={style.formLabel} for="name">
              Name
            </Label>
            <Input bsSize={props.small ? "sm" : ""} type="text" onChange={(e) => changeHandler(e)} id="name" name="name" placeholder="Enter name" />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label className={style.formLabel} for="country">
              Country
            </Label>
            <SelectJS sm={props.small ? "sm" : ""} selected={getSelected} data={countries} defValue={reqForm.country} />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label className={style.formLabel} for="contact">
              Contact No.
            </Label>
            <Input
              bsSize={props.small ? "sm" : ""}
              title="Number only"
              type="tel"
              onChange={(e) => changeHandler(e)}
              id="contact"
              name="contact"
              placeholder="Enter contact no."
              autoComplete="off"
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label className={style.formLabel} for="companyName">
              Company
            </Label>
            <Input
              bsSize={props.small ? "sm" : ""}
              type="text"
              onChange={(e) => changeHandler(e)}
              id="companyName"
              name="companyName"
              placeholder="Enter company"
            />
          </FormGroup>
          <FormGroup className="col-md-6">
            <Label className={style.formLabel} for="email">
              Email
            </Label>
            <Input
              bsSize={props.small ? "sm" : ""}
              type="text"
              onChange={(e) => changeHandler(e)}
              id="email"
              name="email"
              placeholder="Enter your email"
            />
          </FormGroup>
          <FormGroup className="col-md-12">
            <Label className={style.formLabel} for="requirement">
              Requirement
            </Label>
            <Input
              bsSize={props.small ? "sm" : ""}
              type="text"
              onChange={(e) => changeHandler(e)}
              id="requirement"
              name="requirement"
              placeholder="Enter products/services Name"
            />
          </FormGroup>
        </div>
        <Button size={props.small ? "sm" : ""} block={true} type="submit" color="main">
          Submit {submiting? <Spinner
                  color="primary"
                  size="sm"
                />:""}
        </Button>
      </form>
    </div>
  );
}
