import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import { api } from "../../../../Helper/Data";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import ProductItem from "./ProductItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Skeleton from '@mui/material/Skeleton';
import style from "./style.module.css";
const axios = require("axios").default;

function ProductView() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const param = location.search.split("=")[1];
  const [listPage, setListPage] = useState(1);
  const [dataResponse, setDataResponse] = useState({});

  useEffect(() => {
    const type = location.search.includes("category") ? "category" : "country"
    axios
      .post(api.url + "/getProductsList", { type: type, page: listPage, key: param.replaceAll("-", " ") })
      .then(function (response) {
        // console.log(response.data.products);
        setDataResponse({ productList: response.data.products, totalItemLength: response.data.totalItems })
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [listPage, location, param]);


  const handlePageChange = (event, value) => {
    setLoading(true)
    setListPage(value);
  };

  const currentPage = location.search.includes("country") ? `Browse by Regions : ${param.replaceAll("-", " ")}` : location.search.includes("category") ? `Browse by Category : ${param.replaceAll("-", " ")}` : `Browse by Search : ${param.replaceAll("-", " ")}`

  return (
    <>
      <div className="container py-3">
        <BreadCrumb
          primary={{ url: "/product-categories", text: "Products" }}
          current={
            currentPage
          }
        />
        <div className="row">
          <div className="col-md-3 px-2 rounded bg-white">
            <h6 className="text-center my-2">
              What is Your <span className="text-danger"> Requirement</span> <hr className="m-0" />
            </h6>
            <RequirementForm small={"sm"} />
          </div>
          <div className="col-md-9 px-3">
            {dataResponse.totalItemLength / 10 >= 1 ? (
              <div className={style.searchHeader}>
                <p className="font-weight-bold">Total Result : {dataResponse.totalItemLength}</p>
                <Stack className="mb-1" spacing={2}>
                  <Pagination count={Math.ceil(dataResponse.totalItemLength / 10)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            ) : (
              loading ? <Skeleton animation="wave" height={70} /> : <p className="font-weight-bold">Total Result : {dataResponse.totalItemLength}</p>
            )}
            {loading ? <><h5 className="font-weight-bold">Loading...</h5>
              {[1, 2, 3, 4].map((el, i) => <Skeleton key={i} style={{ borderRadius: "8px", margin: "5px" }} variant="rounded" animation="wave" height={150} />)}
            </> : !loading && dataResponse.productList.length
              ? dataResponse.productList.map((el, i) => {
                return <ProductItem key={i} data={el} />;
              })
              : "No Result Found"}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductView;
