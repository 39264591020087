import { Container} from "@mui/material";
import React from "react";
import { Button, Table } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import PageHeading from "../../Components/PageHeading/PageHeading";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { Link } from "react-router-dom";

export default function Membershipplans() {

  const digital = [
    { key: "Domain + Hosting", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "SSL Certificate", silver: 0, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Web Maintenance", silver: 0, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "SEO Friendly Website", silver: 0, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Responsive Website", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Social Media Linking", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Professional Logo", silver: 0, platinum: 0, platinumPro: 0, elite: 1 },
    { key: "Social Media Optimization", silver: 0, platinum: 0, platinumPro: 0, elite: 1 },
    { key: "Youtube Promotion", silver: 0, platinum: 0, platinumPro: 0, elite: 1 },
    { key: "Web Flipbook", silver: 0, platinum: 0, platinumPro: 1, elite: 1 },
    { key: "Water Marking", silver: 0, platinum: 0, platinumPro: 0, elite: 1 },
  ];
  const key = [
    { key: "Profile listing on WWT", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Number of products / Service Pages displayed on priority ranking", silver: 10, platinum: 20, platinumPro: 40, elite: 60 },
    { key: "Number of products / Service posting", silver: 10, platinum: 20, platinumPro: 40, elite: 60 },
    { key: "Buy Leads", silver: 10, platinum: 30, platinumPro: 60, elite: 80 },
    { key: "Website Control Panel", silver: 0, platinum: 0, platinumPro: 1, elite: 1 },
    { key: "Unlimited Enquiries Access", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Buyers performance report", silver: 0, platinum: 0, platinumPro: "Quarterly", elite: "Monthly" },
    { key: "Upload Company Certificate, etc.", silver: 0, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Global Support", silver: 0, platinum: 0, platinumPro: 1, elite: 1 },
  ];
  const manual = [
    { key: "Customer Support Assistance", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Import-Export Consultant", silver: 0, platinum: 0, platinumPro: 1, elite: 1 },
    { key: "Key Account Manager", silver: 0, platinum: 0, platinumPro: "3 Calls Weekly", elite: "10 Calls Weekly" },
    { key: "Trade Pattern Analysis", silver: 1, platinum: 1, platinumPro: 1, elite: 1 },
    { key: "Product HSN Code", silver: 10, platinum: 20, platinumPro: "Unlimited", elite: "Unlimited" }
  ];

  return (
    <div>
      <PageHeading page={"mamberShip"}></PageHeading>
      <Container className="p-5">
        <BreadCrumb primary={""} secondary={""} current={"Membership Plans"}></BreadCrumb>
        <div className={style.planContainer}>
        <PlansComponent data={digital} planHeading={true} icon={"fas fa-wifi"} arrow={false} pay={false} head={"DIGITAL SERVICES"} />
        <PlansComponent data={key} planHeading={false} icon={"fas fa-exclamation px-2"} arrow={true} pay={false} head={"KEY FEATURES"} />
        <PlansComponent data={manual} planHeading={false} icon={"fas fa-people-arrows"} arrow={true} pay={true} head={"MANUAL ASSISTANCE"} />
        </div>
      </Container>
    </div>
  );
}

const PlansComponent=(props)=>{
 return (<>
  {/* <h3 className='text-center m-0'>{props.arrow?<i class="fas fa-angle-double-down"></i>:""}</h3> */}
        <h4 className={style.planCategoryHeading}>{props.head} <i class={props.icon+ " border rounded p-1"}></i></h4>
        <Table hover responsive className="m-0" >
          {props.planHeading?  
          <thead>
            <tr>
              <th></th>
              <th style={{ width: "150px", textAlign: "center", padding: "0px" }}><div className={style.cardheader} style={{ backgroundImage: `url(${api.planUrl}silverbg.jpg)` }}>
                <img
                  src={api.planUrl + "silvercrown.png"}
                  style={{ filter: "brightness(0) invert(1) drop-shadow(-1px 5px 3px black)" }}
                  alt=""
                  width={70}
                />
                <h6 className="font-weight-bold m-0">SILVER</h6>
              </div></th>
              <th style={{ width: "150px", textAlign: "center", padding: "0px" }}><div className={style.cardheader} style={{ backgroundImage: `url(${api.planUrl}platinumbg.jpg)` }}>
                <img
                  src={api.planUrl + "platinumcrown.png"}
                  style={{ filter: "brightness(0) invert(1) drop-shadow(-1px 5px 3px black)" }}
                  alt=""
                  width={70}
                />
                <h6 className="font-weight-bold m-0">PLATINUM</h6>
              </div></th>
              <th style={{ width: "150px", textAlign: "center", padding: "0px" }}><div className={style.cardheader} style={{ backgroundImage: `url(${api.planUrl}platinumprobg.jpg)` }}>
                <img
                  src={api.planUrl + "platinumprocrown.png"}
                  style={{ filter: "brightness(0) invert(1) drop-shadow(-1px 5px 3px black)" }}
                  alt=""
                  width={70}
                />
                <h6 className="font-weight-bold text-white m-0">PLATINUM PRO</h6>
              </div></th>
              <th style={{ width: "150px", textAlign: "center", padding: "0px" }}><div className={style.cardheader} style={{ backgroundImage: `url(${api.planUrl}elitebg.jpg)` }}>
                <img
                  src={api.planUrl + "elitecrown.png"}
                  style={{ filter: "brightness(0) invert(1) drop-shadow(-1px 5px 3px black)" }}
                  alt=""
                  width={70}
                />
                <h6 className="font-weight-bold text-white m-0">ELITE</h6>
              </div></th>
            </tr>
          </thead>:""}
        
          <tbody>
            {props.data.map((el, i) => {
              return (
                <tr className={style.rowItems}>
                  <th scope="row">{el.key}</th>
                  <td className={style.rowItemChild}>
                  {el.key.includes('Buy Leads') ? <strong>
                      {el.silver === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.silver === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.silver
                      )}<span className="text-danger">*</span>
                    </strong> : <strong>
                      {el.silver === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.silver === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.silver
                      )}
                    </strong>}
                  </td>
                  <td className={style.rowItemChild}>
                  {el.key.includes('Buy Leads') ? <strong>
                      {el.platinum === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.platinum === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.platinum
                      )}<span className="text-danger">*</span>
                    </strong> : <strong>
                      {el.platinum === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.platinum === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.platinum
                      )}
                    </strong>}
                  </td>
                  <td className={style.rowItemChild}>
                  {el.key.includes('Buy Leads') ? <strong>
                      {el.platinumPro === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.platinumPro === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.platinumPro
                      )}<span className="text-danger">*</span>
                    </strong> : <strong>
                      {el.platinumPro === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.platinumPro === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.platinumPro
                      )}
                    </strong>}
                  </td>
                  <td className={style.rowItemChild}>
                  {el.key.includes('Buy Leads') ? <strong>
                      {el.elite === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.elite === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.elite
                      )}<span className="text-danger">*</span>
                    </strong> : <strong>
                      {el.elite === 0 ? (
                        <i className="far fa-times-circle text-danger fa-lg"></i>
                      ) : el.elite === 1 ? (
                        <i className="far fa-check-circle text-success fa-lg"></i>
                      ) : (
                        el.elite
                      )}
                    </strong>}
                  </td>
                </tr>
              );
            })}

            {props.pay?<>
            <tr>
              <th scope="row">Download Brochure</th>
              {["silver","platinum","platinumpro","elite",].map((el, i)=>{
                return <td key={i} className={style.rowItemChild+ " text-center"}>
                <a className="" href={"../dist/docs/"+el+".pdf"} download>
                  <Button className="btn-round btn-success py-0 btn-icon">
                    <i className="fas fa-file-download fa-lg"></i>
                  </Button>
                </a>
              </td>
              })}
            </tr>

            <tr className={style.paymentRow}>
              <th scope="row" className="py-3">Annual Price</th>
              {["20,000","40,000","70,000","1,10,000",].map((el, i)=>{
                return <td key={i} className={style.rowItemChild}>
                <h5 className="font-weight-bold text-center">{el}</h5>
              </td>
              })}
            </tr>
            <tr className={style.paymentRow}>
              <th scope="row" className="py-3"></th>
              {[1,2,3,4].map((el, i)=>{
                return <td key={i} className={style.rowItemChild}>
                <Link to={"/payment"}>
                <Button size="small" className="btn-block">
                  Buy Now
                </Button>
                </Link>
              </td>
              })}
            </tr>
            </>:""}
          </tbody>
        </Table>
 </>
 )
}