import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import style from './style.module.css'
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { getCookie } from "../../../../Helper/Cokkies";
import { api } from "../../../../Helper/Data";
import axios from "axios";

const GallaryPage = () => {
    const [listData, setListData] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
    let token = getCookie("token");

    useEffect(() => {
        axios
            .post(api.url + "/get-gallery", { company: currentCompanyUser._id })
            .then(function (response) {
                console.log(response.data);
                setListData(response.data.gallery);
                setFilterItems(response.data.gallery);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [currentCompanyUser._id])


    const deleteProductImage = (id, img) => {
        axios
            .post(api.url + "/delete-gallery-image", { id, img }, { headers: { authorization: "Bearer " + token } })
            .then(function (response) {
                if (response.data.success) {
                    alert("Delete Successfull");
                    const newListData = listData.filter((el) => {
                        return el._id !== id;
                      });
                      setListData(newListData);
                      setFilterItems(newListData);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    return (
        <>
            <div>
                <div className="content-header p-0">
                    <div className="container-fluid">
                        <h4 className="m-0 text-dark">Product Gallery </h4>
                        <hr className="my-2" />
                    </div>
                </div>
                <div className="container px-4">
                    <Grid container>
                        {filterItems.map((el, i) => {
                            return <Grid item key={i} md={3} xs={6} className={style.item}>
                                <IconButton onClick={(e) => deleteProductImage(el._id, el.img)} className={style.deleteImage} size="small">
                                    <DeleteIcon fontSize="inherit" />
                                </IconButton>
                                <img src={api.gallery + el.img} alt="" />
                                <h5 className="title">Image Title</h5>
                            </Grid>
                        })}
                    </Grid>
                </div>
            </div>
        </>
    );
};

export default GallaryPage;
