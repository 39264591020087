import { Box, Grid, Link, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import PropTypes from 'prop-types';
import { api } from "../../../../../Helper/Data";
import style from "../style.module.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}



function GetTabPanel(props) {
  const { products, tab } = props;
  const [filterList, setFilterList] = useState(products.filter((el)=>{
    return el.keyWords.toLowerCase().includes(tab)
  }))

  return (
    <Grid container className={style.productlist}>
      {filterList.map((el, i) => {
        return (
          <Grid key={i} item xs={6} md={3} className={style.productContainer} >
            <Link to={"/product-details"+el.productLink} target="_blank">
              <div className={style.productItem}>
                <img style={{ width: "100%", height: "100%" }} src={api.productImg + el.productImages[0]} alt="" />
                <h5>{el.productName}</h5>
              </div>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TemplateProductServices(props) {
  const [value, setValue] = React.useState(0);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  return (
    <div>
      <h3>
        {" "}
        PRODUCTS & SERVICES <hr className="m-0 bg-main" style={{ height: "3px" }} />
      </h3>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Products" {...a11yProps(0)} />
            <Tab label="Gallery" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container className={style.productlist}>
            {props.products.map((el, i) => {
              return (
                <Grid key={i} item xs={6} md={3} className={style.productContainer} >
                  <Link href={"/product-details"+el.productLink} target={"_blank"} underline={"none"} className="text-decoration-none">
                    <div className={style.productItem}>
                      <img style={{ width: "100%", height: "100%" }} src={api.productImg + el.productImages[0]} alt="" />
                      <h5>{el.productName}</h5>
                    </div>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container className={style.productlist}>
            {props.gallery.map((el, i) => {
              return (
                <Grid key={i} item xs={6} md={3} className={style.galleryContainer} >
                    <div className={style.productItem}>
                      <img style={{ width: "100%", height: "100%" }} src={api.gallery + el.img} alt="" />
                    </div>
                </Grid>
              );
            })}
          </Grid>
        </TabPanel>

      </Box>
    </div>
  );
}

export default TemplateProductServices;
