import React, { useEffect, useState } from 'react'
import style from "./style.module.css"
import Marquee from "react-marquee-master";
import { api } from '../../../../Helper/Data'
import axios from 'axios';

export default function AnnounceMarquee(props) {
    const [topPerformer, setTopPerformer] = useState([])

    useEffect(() => {
        axios
            .post(api.url + "/get-performers", { id: "" })
            .then(function (response) {
                // console.log(response);
                response.data.performers.forEach((item, index) => {
                    item.serial = index + 1;
                });
                setTopPerformer(response.data.performers)
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [])


    const marqueeItem = (el, i) => {
        return <h4 key={i} className="py-0 px-2 m-0 border-right border-left" style={{ minWidth: "300px", textAlign: "center", fontFamily: "monospace", fontWeight: "bold", color: "aliceblue" }}> <i className="fas fa-trophy text-danger"></i> {el.candidate} : {el.score}  </h4>
    };

    return (
        <section className="container mb-2">
            {topPerformer.length ? <div className={style.announceHeading}>
                <div>
                    <img src="/dist/img/horn.png" alt="anouncement" />
                    <div style={{ background: "#092170" }}>
                        <Marquee
                            direction="left"
                            height={50}
                            inverseMarqueeItems={true}
                            delay={15}
                            marqueeItems={topPerformer.map((el, i) => { return marqueeItem(el, i) })}
                        />
                    </div>
                    <img src="/dist/img/horn.png" alt="anouncement" />
                </div>
            </div> : ""}

        </section>
    )
}
