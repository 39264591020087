import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import { Badge, Button, Table } from "reactstrap";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent'
import { Link, useLocation } from "react-router-dom";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import moment from "moment";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import PartyComments from '../../Components/PartyComments/PartyComments'
import { getCookie } from "../../../../Helper/Cokkies";
import StatusPoppover from "../../Components/StatusPopover/StatusPoppover";
import AssignParty from '../../Components/AssignParty/AssignParty';
import Tooltip from '@mui/material/Tooltip';
const axios = require("axios").default;

function AllocationList() {
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const location = useLocation()
  const [locationUser, setLocationUser] = useState([]);
  let token = getCookie("alcToken");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [partiesCount, setPartiesCount] = useState([]);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "assignAt" });
  const deleteProp = { text: "Delete", color: "danger", icon: "fas fa-trash" }

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => <Tooltip title={row.company}><span>{row.company}</span></Tooltip>,
      sortable: true,
      sortField: "company",
      width: "350px",
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      sortable: true,
      sortField: "firstName",
      width: "250px",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
      sortField: "contact",
      width: "200px",
    },
    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <h5 className="m-0">
          <Badge
            color={row.status === "Follow up" ? "danger" : row.status === "Call back" ? "success" : row.status === "Prospective" ? "info" : row.status === "Materialize" ? "warning" : "secondary"}
            pill
          >
            {row.status}
          </Badge>
        </h5>
      ),
      sortable: true,
      width: "180px",
      sortField: "status"
    },
  ];

  useEffect(() => {
    setPending(true)
    setLocationUser(location.state.user)
    axios
      .post(api.url + "/get-all-parties", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue, id: locationUser._id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setListData(response.data.parties);
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setPartiesCount(response.data.partiesCount)
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch, locationUser, tableOptions, token]);

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row" style={{ width: "150px" }}>Email</th>
              <td>
                <strong>{data.email}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Schedule</th>
              <td>
                <strong>{!data.schedule ? "Not set" : moment(data.schedule).format("DD/MM/YYYY HH:MM A")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "150px" }}>Create At</th>
              <td>
                <strong className="text-danger">{moment(data.createdAt).format("DD/MM/YYYY")}</strong>
              </td>
              <th scope="row" style={{ width: "150px" }}>Remarks</th>
              <td style={{ width: "200px" }}>
                <strong className="text-danger">{!data.remarks ? "Fresh" : data.remarks}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "150px" }}>Address</th>
              <td style={{ width: "220px" }}>
                <strong>{data.companyAddress}</strong>
              </td>
              <th scope="row" style={{ width: "150px" }}>Products</th>
              <td style={{ width: "220px" }}>
                <strong>{data.productsList.length ? data.productsList.join(", ") : "NA"}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "150px" }}>Alternate No</th>
              <td style={{ width: "220px" }}>
                <strong>{data.alternate ? data.alternate : "NA"}</strong>
              </td>
              <th scope="row" style={{ width: "150px" }}>Assign At</th>
              <td style={{ width: "220px" }}>
                <strong> {moment(data.assignAt).format("DD/MM/YYYY")}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row px-2">
          <PartyComments setModal={(e) => setOpenModal(e)} assigned={statusmoved} party={data} open={openModal} />

          <StatusPoppover setModal={(e) => setDeleteModal(e)} open={deleteModal} updated={statusmoved} party={data} text={deleteProp} />

          <Button className="btn-round mx-1 btn-sm" color="success" onClick={() => setAssignModal(true)}>
            <i className="fas fa-expand-arrows-alt"></i> Assign To
          </Button>

          <AssignParty deleteComment={false} setModal={(e) => setAssignModal(e)} assigned={removeParty} party={[data._id]} open={assignModal} />

          <Link className="btn-round mx-1 px-4 pt-2 btn-sm btn-dark text-white" to={"/allocation/update-party/" + data._id} state={data}>Edit</Link>
        </div>
      </div>
    );
  };

  const removeParty = (e) => {
    setListData(
      listData.filter((el) => {
        return el._id !== e[0];
      })
    );
    setFilterItems(
      listData.filter((el) => {
        return el._id !== e[0];
      })
    );
  };
  const statusmoved = (e) => {
    setListData(
      listData.filter((el) => {
        return el._id !== e;
      })
    );
    setFilterItems(
      listData.filter((el) => {
        return el._id !== e;
      })
    );
  };

  const updateSelectedRow = (e) => { }

  return (
    <div className="py-2">
      <AnnounceMarquee />
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Allocation List : <small className="text-secondray">{locationUser.fullname} <small className="text-danger">( {totalRows} Parties )</small></small></h4>
          <hr className="my-2" />
        </div>
      </div>

      <h5> Filter List :  {partiesCount.map((el, i) => {
        return <Button key={i} className="btn-round mx-1 btn-sm" color={el.color} onClick={() => { setIsSearch(true); setFilterValue(el.text) }}>
          {el.text} <Badge color="light" style={{ fontSize: "12px" }} className="m-0 text-dark px-2 py-1"
            pill>{el.count}</Badge>
        </Button>
      })} </h5>


      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        setRows={updateSelectedRow}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default AllocationList;