import React, { useState } from "react";
import { Button, Modal } from "reactstrap";

function ModalComponent(props) {
  const [loginModal, setLoginModal] = useState(false);

  return (
    <>
      {props.btnLink ? (
        <Button  underline="none" className="btn-sm btn-link py-0 px-3 rounded" color="danger" type="button" onClick={() => setLoginModal(true)}>
          <small>{props.btn}</small>
        </Button>
      ) : (
        <Button className={props.btnSm?"btn-sm py-0 px-3 rounded":"btn-sm px-4 mx-3"} color="danger" type="button" onClick={() => setLoginModal(true)}>
          <small>{props.btn}</small>
        </Button>
      )}
      <Modal scrollable isOpen={loginModal} style={{overflow:"auto"}} toggle={() => setLoginModal(false)} modalClassName="modal-register" className={props.size}>
        <div className="modal-header no-border-header text-center pb-0">
          <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => setLoginModal(false)}>
            <span aria-hidden={true}>×</span>
          </button>
          <h5 className="text-center">
            <strong>{props.heading}</strong>
          </h5>
        </div>
        <div className="modal-body pt-0 mb-3 px-3">{props.form}</div>
      </Modal>
    </>
  );
}

export default ModalComponent;
