import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../../../Helper/Cokkies";
import SelectJS from "../../../../components/Select/SelectJS";
import { formValidation } from "../../../../components/Utils/Utils";
import { AddCandidate } from "../../../../Redux/Candidates";
import LaunchIcon from "@mui/icons-material/Launch";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link } from "react-router-dom";
import { IconButton, InputLabel, TextField, Tooltip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
const axios = require("axios").default;

function Candidates() {
  const dispatch = useDispatch();
  const tlData = useSelector((state) => state.CanditatesList.tlData);
  const [TLData, setTLData] = useState([]);
  const [open, setOpen] = useState(false);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [team, setTeam] = useState({ tl: "", candidate: "", team: "" });
  let token = getCookie("alcToken");
  const [pending, setPending] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    teamLeader: "",
    doj: "",
  });

  useEffect(() => {
    setTLData(
      tlData.map((el) => {
        return { value: el._id, label: el.fullname + " " + el.username };
      })
    );

    axios
      .post(
        api.url + "/get-alcusers-for-admin",
        { id: "id" },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        if (response.data.success) {
          const newList = response.data.candidate.map((item, index) => {
            return { ...item, serial: index + 1 };
          });
          setListData(newList);
          setFilterItems(newList);
          setPending(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setPending(false);
  }, [tlData, token]);

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([formData.username, formData.email, formData.doj])) {
      if (filter.test(formData.email)) {
        axios
          .post(api.url + "/new-candidate", formData, {
            headers: { authorization: "Bearer " + token },
          })
          .then(function (response) {
            if (response.data.success) {
              alert("Successfully Added");
              // console.log(response.data);
              dispatch(
                AddCandidate({
                  candidate: response.data.candidates,
                  teamLeader: response.data.teamLeader,
                })
              );
              setFormData({
                username: "",
                fullname: "",
                email: "",
                password: "",
                confirmPassword: "",
                teamLeader: "",
              });
            } else {
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        alert("Please enter valid email");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter(
        (item) =>
          item.candidate.fullname &&
          item.candidate.fullname
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
      );
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const changeStatus = (id, status) => {
    setPending(true);
    axios
      .post(
        api.url + "/change-user-status",
        { id: id, status: status },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        if (response.data.success) {
          alert("Status Updated");
          dispatch(
            AddCandidate({
              candidate: response.data.candidates,
              teamLeader: response.data.teamLeader,
            })
          );
          setPending(false);
        }
      })
      .catch(function (error) {
        console.log(error);
        setPending(false);
      });
  };

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.candidate.fullname,
      sortable: true,
      width: "120px",
    },
    // {
    //   name: <strong>User Name</strong>,
    //   selector: (row) => <>{row.candidate.username}</>,
    //   sortable: true,
    //   width: "150px",
    // },
    // {
    //   name: <strong>Email</strong>,
    //   selector: (row) => row.candidate.email,
    //   width: "150px",
    // },
    {
      name: <strong>Team Leader</strong>,
      selector: (row) =>
        row.teamLeader.fullname + " ( " + row.teamLeader.username + " )",
      width: "200px",
    },
    {
      name: <strong>DOJ</strong>,
      selector: (row) => moment(row.candidate.doj).format("DD/MM/YYYY"),
      width: "150px",
      sortable: true,
    },
    {
      name: <strong>Active</strong>,
      selector: (row) => (
        <Tooltip
          title={row.candidate.isActive ? "Active" : "Inactive"}
          placement="top"
          arrow
        >
          <IconButton
            onClick={(e) => {
              window.confirm("Change Status")
                ? changeStatus(row.candidate._id, !row.candidate.isActive)
                : alert("Cancel");
            }}
          >
            {row.candidate.isActive ? (
              <CheckCircleOutlineIcon fontSize="small" color="success" />
            ) : (
              <HighlightOffIcon fontSize="small" color="error" />
            )}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      name: <strong>ACTION</strong>,
      width: "250px",
      cell: (row) => (
        <div>
          {/* <Tooltip title="Allocation" placement="top" arrow>
            <Link
              to={"/allocation/allocation-list/" + row.candidate.username}
              state={{ user: row.candidate }}
            >
              <IconButton>
                <LaunchIcon fontSize="small" color="secondary" />
              </IconButton>
            </Link>
          </Tooltip>{" "}
          <PopupState variant="popover" popupId={"pop-" + row._id}>
            {(popupState) => (
              <div>
                <IconButton aria-label="menu" {...bindTrigger(popupState)}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
                <Popover
                  {...bindPopover(popupState)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuList dense>
                    <Link
                      to={"/allocation/change-password"}
                      state={{ user: row.candidate }}
                    >
                      <MenuItem>
                        <ListItemIcon>
                          <VpnKeyIcon fontSize="small" color="info" />
                        </ListItemIcon>
                        <ListItemText>Change Password</ListItemText>
                      </MenuItem>
                    </Link>
                    <Divider />
                    <span>
                      <MenuItem
                        onClick={(e) => {
                          setTeam({
                            ...team,
                            team: row._id,
                            candidate: row.candidate._id,
                          });
                          setOpen(!open);
                        }}
                      >
                        <ListItemIcon>
                          <SwapHorizontalCircleIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Change Team</ListItemText>
                      </MenuItem>
                    </span>
                    <Divider />
                    <span>
                      <MenuItem
                        onClick={(e) => {
                          deleteItem(row.candidate._id);
                        }}
                      >
                        <ListItemIcon>
                          <DeleteIcon fontSize="small" color="error" />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </span>
                  </MenuList>
                </Popover>
              </div>
            )}
          </PopupState> */}
          <>
            <Tooltip title="Allocate" placement="top" arrow>
              <Link
                to={"/allocation/allocation-list/" + row.candidate.username}
                state={{ user: row.candidate }}
              >
                <IconButton>
                  <LaunchIcon fontSize="small" color="secondary" />
                </IconButton>
              </Link>
            </Tooltip>{" "}
            <Tooltip title={"Change Team"} placement="top" arrow>
              <IconButton
                onClick={(e) => {
                  setTeam({
                    ...team,
                    team: row._id,
                    candidate: row.candidate._id,
                  });
                  setOpen(!open);
                }}
              >
                <SwapHorizontalCircleIcon fontSize="small" />
              </IconButton>
            </Tooltip>{" "}
            <Tooltip title="Change Password" placement="top" arrow>
              <Link
                to={"/allocation/change-password"}
                state={{ user: row.candidate }}
              >
                <IconButton>
                  <VpnKeyIcon fontSize="small" color="info" />
                </IconButton>
              </Link>
            </Tooltip>{" "}
            <Tooltip title="Update" placement="top" arrow>
              <Link
                to={"/allocation/user-update/" + row.candidate._id}
              >
                <IconButton>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Link>
            </Tooltip>{" "}
            <IconButton
              onClick={(e) => {
                deleteItem(row.candidate._id);
              }}
            >
              <DeleteIcon fontSize="small" color="error" />
            </IconButton>
          </>
        </div>
      ),
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(
        api.url + "/delete-candidate",
        { id },
        { headers: { authorization: "Bearer " + token } }
      )
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const chandidateSelected = (select) => {
    setFormData({ ...formData, teamLeader: select.value });
  };

  const updateTeam = () => {
    axios
      .post(api.url + "/update-team", team, {
        headers: { authorization: "Bearer " + token },
      })
      .then(function (response) {
        if (response.data.success) {
          setFilterItems(
            listData.map((ele, i) => {
              return team.candidate === ele.candidate._id
                ? { ...ele, teamLeader: team.tl }
                : ele;
            })
          );
          setListData(
            listData.map((ele, i) => {
              return team.candidate === ele.candidate._id
                ? { ...ele, teamLeader: team.tl }
                : ele;
            })
          );
          setOpen(!open);
          setPending(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Candidate</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="username">
                User Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="username"
                name="username"
                value={formData.username}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter First Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="fullname">
                Full Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="fullname"
                name="fullname"
                value={formData.fullname}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Full Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="assignTo">
                Team Leader{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} selected={chandidateSelected} data={TLData} />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="password">
                New Password{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                id="password"
                bsSize="sm"
                name="password"
                onChange={(e) => changeHandler(e)}
                placeholder="Enter New Password"
                type="text"
              />
            </FormGroup>{" "}
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="confirmPassword">
                Confirm Password{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <InputGroup>
                <Input
                  type={"text"}
                  bsSize="sm"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={(e) => changeHandler(e)}
                  placeholder="Confirm Password"
                />
              </InputGroup>
            </FormGroup>
            <FormGroup className="col-md-6">
              <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DatePicker
                className={style.datePicker}
                  label="Joining Date"
                  value={formData.doj}
                  renderInput={(params) => <TextField {...params} />}
                  onChange={(newValue) =>{
                    setFormData({
                      ...formData,
                      doj: newValue
                    })
                  }}
                />
              </LocalizationProvider>
            </FormGroup>
          </div>
          <Button type="submit" className="btn-sm btn-main px-4">
            Add Candidate
          </Button>
        </Form>
      </div>

      <div className="py-3 px-4">
        <div className="content-header p-0">
          <div className="container-fluid">
            <h4 className="m-0 text-dark">Chandidates List</h4>
            <hr className="my-2" />
          </div>
        </div>

        <InputGroup
          style={{ border: "solid 1px #007bff", borderRadius: "5px" }}
        >
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="nc-icon nc-zoom-split"></i>
            </InputGroupText>
          </InputGroupAddon>
          <Input
            id="search"
            name="search"
            placeholder="Text here for Search..."
            type="search"
            onChange={changeHandle}
          />
        </InputGroup>

        {open ? (
          <ModalComponent
            modalopen={open}
            disabled={pending}
            selectTeam={(e) => setTeam({ ...team, tl: e })}
            TLData={tlData}
            selectTL={team.tl}
            updateTeam={() => updateTeam()}
            modalToggle={(e) => setOpen(e)}
          />
        ) : (
          ""
        )}

        <DataTable
          pagination
          persistTableHead
          highlightOnHover
          columns={columns}
          data={filterItems}
          progressPending={pending}
        />
      </div>
    </div>
  );
}

export default Candidates;

function ModalComponent(props) {
  const handleChange = (e) => {
    props.selectTeam(e.target.value);
  };

  return (
    <Modal isOpen={props.modalopen} toggle={() => props.modalToggle(false)}>
      <ModalHeader className="py-3">Change Team</ModalHeader>
      <ModalBody>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-helper-label">
            Select Team leader
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            value={props.selectTL}
            onChange={handleChange}
            displayEmpty
          >
            {props.TLData.map((el, i) => {
              return (
                <MenuItem key={i} value={el}>
                  {el.fullname}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ModalBody>
      <ModalFooter className="px-4 py-2">
        <Button
          color="primary"
          size="sm"
          disabled={props.disabled}
          className="px-4"
          onClick={(e) => props.updateTeam()}
        >
          Submit
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
}
