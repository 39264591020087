import React from "react";
import PageHeading from "../../Components/PageHeading/PageHeading";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import { Container } from "@mui/material";
import style from "./style.module.css";

export default function Privacypolicy() {
  return (
    <div>
      <PageHeading page={"privacyPolicy"}></PageHeading>
      <div className="container">
      <Container className={style.policyContainer}>
      <BreadCrumb primary={""} secondary={""} current={"Privacy & Policy"}></BreadCrumb>
        <ol>
          <li>
            {" "}
            <p>
              This privacy policy of worldwebtrade.com is applicable to all confidential personal information that is collected on the site. In this
              policy, the site highlights how and why we tend to collect all relevant and accurate personal information and how we make use of it.
            </p>
          </li>
          <li>
            {" "}
            <p>
              worldwebtrade.com is dedicated to comply with legislations of governing privacy of confidential personal information by business and
              protects all your privacy in the best possible manner when you make deals with us.
            </p>
          </li>
          <li>
            {" "}
            <p>
              Some information doled out by our clients, contractors, customers and other third parties may be considered as private or personal.
              Without these details we won’t be able to carry on the business and initiate the services effectively.
            </p>
          </li>
          <li>
            {" "}
            <p>
              Personal information collected from different people so that if you contact us for any issue, query, we keep a complete record of that
              correspondence in the best effort basis. It can help us to find a solution and do root cause analysis of any issue or query raised by
              you.
            </p>
          </li>
          <li>
            {" "}
            <p>
              The personal data collected by us is a step to make you aware of who we are, we also allow you to gain access to information held about
              you.
            </p>
          </li>
          <li>
            {" "}
            <p>
              Use of information collected and disclosure of personal information to others. We might use or disclose any personal information that is
              held about an individual primarily for the purpose which it is collected. Such information may be collected for a purpose of maintaining
              records. The information is not disclosed to unwanted people. The information here is disclosed to people outside the periphery of our
              business in the situations set out in this policy or otherwise notified to you at the time of collection of the data.
            </p>
          </li>
          <li>
            {" "}
            <p>
              When we suspect an unlawful action or may be engaged in or has been a part of the disclosure, it becomes important for us to investigate
              and report the matter to the relevant authorities.
            </p>
          </li>
          <li>
            {" "}
            <p>When such disclosure is must under authorized law</p>
          </li>
          <li>
            {" "}
            <p>
              Where we believe that the use of the disclosure is essential for the refrainment, investigation, punishment and prosecution of crimes or
              wrongdoings for conduct of proceedings above any court or tribunal for the implementation of the order of the court or tribunal by the
              enforcement body.
            </p>
          </li>
          <li>
            {" "}
            <p>
              Websites may contain links of other websites and those of third parties that may collect personal information about you in which we are
              not liable in any manner. We are not at all responsible for the private practices of other businesses or the data on the websites that
              are linked to our websites. We on our portal encourage users to stay aware when they leave the site and read the privacy statements of
              each and every linked website.
            </p>
          </li>
          <li>
            {" "}
            <p>
              Our portal places a great emphasis on the security of the information that is associated with our customers and contractors. We have
              complete security measures that protect against loss, alteration and misuse of any personal information that is under our control.
            </p>
          </li>
          <li>
            {" "}
            <p>Personal information is to be de-identified or to be destroyed securely when no longer required by us.</p>
          </li>
          <li>
            {" "}
            <p>
              The worldwebtrade.com retains the information you dole out to us and comprises your contact and all credit details to ensure us that you
              verify transactions and customer details for adequate records for legal and accounting purposes.
            </p>
          </li>
          <li>
            {" "}
            <p>All the major information stored with our computer can only be accessed by trust worthy authorities.</p>
          </li>
          <li>
            {" "}
            <p>
              No data ought to be transmitted over the digital world is 100 percent secured. While we ensure to protect all personal information of
              yours, but anything transferred over the internet or via online means of communication we don’t provide warranty or guarantee in that
              case. The worldwebtrade.com receives your transmission and makes effort to ensure its security on its systems.
            </p>
          </li>
          <li>
            {" "}
            <p>
              Finally users are solely responsible for keeping their passwords or any form of account information secret. You ought to be careful and
              responsible whenever you are online. You must not share your password with any one for safety and you shall be solely responsible for
              keeping privacy of your account.
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>Access to and correction of personal information</strong> We at worldwebtrade.com are dedicated to maintain authentic and
              accurate information about our customers in the best possible manner.
            </p>
          </li>
          <li>
            {" "}
            <p>we will provide you with the access to that information. if it is required by compliance.</p>
          </li>
          <li>
            {" "}
            <p>
              You shall be solely responsible for any inaccurate information provided by you , we are not liable for any inaccurate information given
              by you.
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>Complaints:</strong> If you have any complaints Email us at complaints@worldwebtrade.com, support@worldwebtrade.com
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>Contacting us:</strong> If you need any information please contact us at the following address: E-mail:
              support@worldwebtrade.com
            </p>
          </li>
          <li>
            {" "}
            <p>
              <strong>Acceptance:</strong> You accept that your use of this site includes your acceptance of this Privacy Policy and the Terms of Use.
            </p>
          </li>
        </ol>
        <h2 className="font-weight-bold text-main">Refund / Money Back Policy:</h2>
        <p>
          Once subscribed & the paid services activated on worldwebtrade.com, the payment made by the User for activated Paid service is not
          refundable under any circumstances and no claim for refund would be entertained. worldwebtrade.Com neither offers any guarantees for the
          accuracy, timeliness, authenticity nor advocates any of the content posted by the other Users. All the services are on a best effort basis.
        </p>
        <p>
          If you send us a request (on the basis of reasonable ground) for refund within 10 Days of payment date then we may consider refunding your
          amount only for non activated services or work as per company’s policy. The refund process may take time 35-45 working days depending on the
          availability of the information. Please send your mail with details at billing@worldwebtrade.com The amount will be refunded through the
          same form how worldwebtrade.com receives the payment. The amount received in one form will not be refunded in a different form. (For e.g.,
          the amount received through credit card payment will be refunded only by remittance to the same credit card.)
        </p>
        <p>
          worldwebtrade.Com neither offers any guarantees for the accuracy, timeliness, authenticity nor advocates any of the content (Inquiries ,
          Product Listing, Catalog) posted by the other Users. All the services are on a best effort basis. So Under any circumstances
          worldwebtrade.com is not liable to refund.
        </p>
        <p>
          Worldwebtrade shall not be liable in the event of any miscommunication between the client and the Sales Agent (Business Consultant).
          worldwebtrade.com neither offers any guarantees for Business Deal & Return on Investment All the services are on a best effort basis where
          our team tries to give best business opportunities through portal advertising. No refund shall be initiated at any kind of circumstances
          with regard to the services already activated. So Before doing anything Please understand & read all the Policy / Terms & Condition /
          Subscription everything on worldwebtrade.com. For further confirmation Please get written confirmation only from support@worldwebtrade.com.
        </p>
        <p>
          worldwebtrade.com does not represent the seller or the buyer in transactions and does not charge any commission for enabling any
          transaction. worldwebtrade.com does not control and is not liable to or responsible for the quantity, quality, safety, lawfulness or
          availability of the products or services offered for sale on the Web Site or the ability of the suppliers to complete a sale or the ability
          of buyers to complete a purchase. User(s) are cautioned that there may be risks of dealing with foreign nationals or people acting under
          false pretences on the Web Site. Web Site uses several tools and techniques to verify the accuracy and authenticity of the information
          provided by User(s). worldwebtrade.com however, cannot and does not confirm each User(s)’s purported identity on the Web Site.
          worldwebtrade.com encourages User(s) to evaluate the User(s) with whom they would like to deal with and use common prudence while dealing
          with them. So Under any kind of circumstances worldwebtrade.com is not liable to refund.
        </p>
      </Container>
      </div>
    </div>
  );
}
