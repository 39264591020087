import React, { useState } from "react";
import { Button, Card, Form, Input, Container, Row, Col, InputGroup, InputGroupText, InputGroupAddon } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { setLocalStorage } from "../../../../Helper/LocalStorage";
import { setCookie } from "../../../../Helper/Cokkies";
import { Link, Navigate } from "react-router-dom";
const axios = require("axios").default;

function Login() {
  const url = api.url
  const [hidePass, setHidePass] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  if (localStorage.getItem('user')) {
    return <Navigate to={'/admin'} />
  }

  const passwordShow = () => {
    hidePass ? setHidePass(false) : setHidePass(true);
  };

  const changeHandle = (e, fieldType) => {
    fieldType === "email" ? setEmail(e.target.value.toLowerCase().replaceAll(" ", "")) : setPassword(e.target.value);
  };

  const loginForm = (e) => {
    e.preventDefault();
    axios.post(url + '/login', { email: email, password: password })
      .then(function (response) {
        // console.log(response);
        if (response.data) {
          setLocalStorage('user', { name: response.data.name, id: response.data.id, email: response.data.email, role: response.data.role })
          setCookie('token', response.data.token, 1)
          window.location.replace("/admin")
        }
      })
      .catch(function (error) {
        console.log(error);
        alert(error.response.data.message)
      })
  };

  return (
    <div className={style.loginContainer}>
      <Container>
        <Row>
          <Col className="ml-auto mr-auto" lg="4">
            <Card className="card-register bg-main ml-auto mr-auto">
              <h5>Welcome</h5>
              <img className="w-100" style={{ filter: "drop-shadow(0px 5px 15px white)" }} src={api.logosUrl + "logo.png"} alt="World Web Trade" />
              <Form className="register-form" onSubmit={loginForm}>
                <label htmlFor="email">Email</label>
                <Input placeholder="Email" name="email" id="email" value={email} onChange={(e) => changeHandle(e, "email")} type="text" />
                <label htmlFor="password">Password</label>
                <InputGroup>
                  <Input
                    type={hidePass ? "password" : "text"}
                    value={password}
                    onChange={(e) => changeHandle(e, "password")}
                    placeholder="Password"
                  />
                  <InputGroupAddon onClick={passwordShow} addonType="prepend">
                    <InputGroupText className={style.addOnInput}>
                      {hidePass ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
                <Button block className="btn-round" color="danger">
                  Login
                </Button>
              </Form>
              <div className="forgot">
                <Button className="btn-link btn-danger" href="#pablo" onClick={(e) => e.preventDefault()}>
                  Forgot password?
                </Button>
                <Link className="btn btn-link my-3 text-white" block="true" to='/'>Go To Public Page</Link>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Login;
