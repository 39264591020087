import React, { useState } from "react";
import { Button, Form, FormGroup, FormText, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { useSelector } from "react-redux";
import SelectJS from "../../../../components/Select/SelectJS";
import { formValidation } from "../../../../components/Utils/Utils";
const axios = require("axios").default;

function Useradd() {
  const countries = useSelector((state) => state.CountriesList.countries);
  const [formData, setFormData] = useState({
    firstName: "",
    userName: "",
    country: "India",
    contact: "",
    lastName: "",
    email: "",
    role: "user",
    password: "User@123",
    website: "",
    catalogLink: "",
    company: "",
    location: "",
    membershipPlan: "Guest",
    countryFlag: "https://flagcdn.com/in.svg",
    skypeid: "",
    businessType: "Manufacturer",
    leadsCredits: 0,
    sort: 5,
    companyAddress: "",
  });
  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const selectPlan = (e) => {
    setFormData({
      ...formData,
      membershipPlan: e.target.value,
      leadsCredits:
        e.target.value === "Silver"
          ? 10
          : e.target.value === "Platinum"
            ? 30
            : e.target.value === "Platinum Pro"
              ? 60
              : e.target.value === "Elite"
                ? 80
                : 0,
      sort:
        e.target.value === "Silver"
          ? 4
          : e.target.value === "Platinum"
            ? 3
            : e.target.value === "Platinum Pro"
              ? 2
              : e.target.value === "Elite"
                ? 1
                : 5,
    });
  };

  const getSelected = (select) => {
    setFormData({ ...formData, country: select.value, countryFlag: select.flag });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var tel = /^[0-9]{10}$/;
    if (formValidation([formData.userName, formData.catalogLink, formData.firstName, formData.email, formData.contact, formData.company, formData.companyAddress])) {
      if (filter.test(formData.email) && formData.contact.match(tel)) {
        axios
          .post(api.url + "/signup", formData)
          .then(function (response) {
            console.log(response);
            if (response.data.success) {
              joinForm(response.data.data._id);
            } else {
              alert(response.data.message);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        alert("Please enter valid email / contact");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const createCatalogLink = (e) => {
    var format = /[!@#$%^*+]/g;
    if (!format.test(e.target.value)) { setFormData({ ...formData, catalogLink: e.target.value.toLowerCase().replace(" ", "") }); }
  }

  const joinForm = (id) => {
    axios
      .post(api.url + "/join-req", { data: formData, userId: id })
      .then(function (response) {
        console.log(response);
        if (response.data.Join) {
          alert("You have Join Successfull");
        }
        setFormData({
          firstName: "",
          userName: "",
          country: "India",
          contact: "",
          lastName: "",
          email: "",
          role: "user",
          password: "User@123",
          website: "",
          catalogLink: "",
          company: "",
          location: "",
          leadsCredits: 0,
          sort: 5,
          membershipPlan: "",
          countryFlag: "https://flagcdn.com/in.svg",
          skypeid: "",
          businessType: "Manufacturer",
          companyAddress: "",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add User</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Login Information */}
        <Form onSubmit={submitHandler}>
          <h5 className="text-center">
            <strong>Login Information</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="userName">
                User Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="userName"
                name="userName"
                value={formData.userName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Username"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>
          </div>
          {/* Contact Information */}
          <h5 className="text-center">
            <strong>Other Information</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="firstName">
                First Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter First Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="lastName">
                Last Name
              </Label>
              <Input
                bsSize="sm"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Last Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Contact No."}
                type="tel"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="country">
                Country{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} data={countries} defValue={formData.country} selected={getSelected} />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="company">
                Company Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Company Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="website">
                Website
              </Label>
              <Input bsSize="sm" type="text" id="website" onChange={(e) => changeHandler(e)} name="website" placeholder="Enter your website" />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="catalogLink">
                Create Catalog Link <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input bsSize="sm" type="text" value={formData.catalogLink} id="catalogLink" onChange={(e) => createCatalogLink(e)} name="website" placeholder="Enter your catalog Link" />
              <FormText>
              Please enter url link like '/company_name'.
              </FormText>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="skypeId">
                Skype Id{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="skypeid"
                name="skypeid"
                value={formData.skypeid}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Skype Id"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="location">
                Company Location{" "}
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="location"
                name="location"
                value={formData.location}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Location"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="membershipPlan">
                Membership Plan{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="membershipPlan"
                name="membershipPlan"
                value={formData.membershipPlan}
                onChange={(e) => selectPlan(e)}
                type="select"
              >
                <option>Guest</option>
                <option>Silver</option>
                <option>Platinum</option>
                <option>Platinum Pro</option>
                <option>Elite</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Company Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={"Enter company address"}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button type="submit" className="btn-sm btn-main px-4">
            Add User
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default Useradd;
