import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CandidateDetails from "./CandidateDetails";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import axios from 'axios';

function UpdateCandidateDetails() {
  const { username } = useParams();
  // const [pending, setPending] = useState(true);
  const [fetchData, setFetchData] = useState(null);
  let token = getCookie("alcToken");

  useEffect(() => {
    axios
        .post(api.url + "/get-candidate-details", { id: username }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
            // console.log(response.data);
            setFetchData(response.data.user);
        })
        .catch(function (error) {
            console.log(error);
        });
  }, [token, username]);

  return (
    <div className="py-2">
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">
            Update Details{" "}
            <small className="text-secondray">
              {fetchData == null ? "" : fetchData.fullname}{" "}
            </small>
          </h4>
          <hr className="my-2" />
        </div>
      </div>
      {fetchData == null ? (
        ""
      ) : (
        <CandidateDetails admin={true} data={fetchData} update={true} />
      )}
    </div>
  );
}

export default UpdateCandidateDetails;
