import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { Button, Modal } from 'reactstrap';
import { api } from '../../../../Helper/Data';
import { getLocalStorage } from '../../../../Helper/LocalStorage';
import { getCookie } from '../../../../Helper/Cokkies';
import SelectJS from "../../../../components/Select/SelectJS";
import { useDispatch } from "react-redux";
import { alcUserValidate } from '../../../../components/Utils/Utils'
import { deleteAllocationCount, addAllocationCount } from '../../../../Redux/Counter';

function AssignParty(props) {
  const dispatch = useDispatch()
  const candidatesStore = useSelector((state) => state.CanditatesList.data);
  const candidates = candidatesStore.map((el) => el.candidate)
  const tl = useSelector((state) => state.CanditatesList.tlData);
  const listData = candidates.concat(tl)
  const user = getLocalStorage("alcUser")
  const [candidateList, setCandidateList] = useState([]);
  let token = getCookie("alcToken");
  const [selectedCandidate, setSelectedCandidate] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const allList = listData.map((el) => {
      return { value: el._id, label: el.fullname + " ( " + el.username+ " )" }
    })

    let filtered = candidatesStore.filter((el) => {
      return user.id === el.teamLeader._id
    })
    const teamList = filtered.map((el) => { return { value: el.candidate._id, label: el.candidate.fullname + " ( " + el.candidate.username+ " )" } })
    if (alcUserValidate(["tl"])) {
      teamList.push({ value: filtered[0].teamLeader._id, label: filtered[0].teamLeader.fullname + " ( " + filtered[0].teamLeader.username + " )" })
    }
    setCandidateList(alcUserValidate(["admin", "HR"]) ? allList : teamList);
  }, [candidatesStore]);

  const assignNow = () => {
    setIsSubmitting(true)
    axios
      .post(api.url + "/assign-candidate", { assignTo: selectedCandidate, party: props.party, updatedBy: user, deleteComment: props.deleteComment }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        if(response.data.partyExceed){
          alert(response.data.message)
          setIsSubmitting(false)
          props.setModal(false);
        } else {
          alert("Assigned...")
          setIsSubmitting(false)
          props.assigned(props.party)
          if (props.reduxValue.decrease) { dispatch(deleteAllocationCount(props.reduxValue.decrease)) }
          if (props.reduxValue.increase) { dispatch(addAllocationCount(props.reduxValue.increase)) }
          props.setModal(false);
        }
      })
      .catch(function (error) {
        setIsSubmitting(false)
        console.log(error);
      });
  };

  const getSelected = (select) => {
    setSelectedCandidate(select.value);
  };

  return (
    <Modal
      isOpen={props.open}
      toggle={() => {
        props.setModal(false);
      }}
      className={"modal-md"}
    >
      <div className="modal-header no-border-header text-center pb-0">
        <button
          className="close"
          type="button"
          onClick={() => {
            props.setModal(false);
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
        <h5 className="text-center">
          <strong>Select candidate for Assign Party</strong>
        </h5>
      </div>
      <div className="modal-body mb-3 px-4 py-2 row">
        <div style={{ width: "100%", margin: "8px" }}>
          <SelectJS sm={"sm"} selected={getSelected} data={candidateList} />
        </div>
        <Button
          type="submit"
          onClick={() => {
            assignNow();
          }}
          disabled={isSubmitting}
          className={"btn-main btn-sm m-2"}
        >
          {"Assign Now"}
        </Button>
      </div>
    </Modal>
  );
}

export default AssignParty
