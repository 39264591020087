import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import { Table } from "reactstrap";
import moment from "moment";
import { getCookie } from "../../../../Helper/Cokkies";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import PartyComments from "Modules/Allocation/Components/PartyComments/PartyComments";
import StatusPoppover from "Modules/Allocation/Components/StatusPopover/StatusPoppover";
import { Link } from "react-router-dom";
const axios = require("axios").default;

function ProspectiveParties() {
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  let token = getCookie("alcToken");
  const user = getLocalStorage("alcUser")
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "assignAt" });

  const [followUpModal, setFollowUpModal] = useState(false);
  const [callBackModal, setCallBackModal] = useState(false);
  const [materializeModal, setMaterializeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const callbackProp = {text: "Call back", color:"success", icon:"fas fa-reply-all"}
  const followupProp = {text: "Follow up", color:"primary", icon:"fas fa-hand-point-right"}
  const materializeProp = {text: "Materialize", color:"warning", icon:"fas fa-tasks"}
  const deleteProp = {text: "Delete", color:"danger", icon:"fas fa-trash"}

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
      sortField: "assignAt"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      sortable: true,
      sortField: "firstName",
      width: "250px",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
      sortField: "contact"
    },
    {
      name: <strong>Assign At</strong>,
      selector: (row) => moment(row.assignAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "assignAt"
    },
  ];

  useEffect(() => {
    setPending(true)
      axios
        .post(api.url + "/get-prospective-parties", {id: user.id, page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue}, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
           // console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setListData(response.data.parties);
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [token, user.id, isSearch, tableOptions]);
  
  const statusmoved =(e)=>{
    setListData(listData.filter((el)=>{
      return el._id !== e
    }))
    setFilterItems(listData.filter((el)=>{
      return el._id !== e
    }))
  }

  const updateSelectedRow =(e)=>{ }

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
      <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
        <tbody>
          <tr>
            <th scope="row" style={{ width: "180px" }}>Email</th>
            <td>
              <strong>{data.email}</strong>
            </td>
            <th scope="row" style={{ width: "180px" }}>Schedule</th>
              <td>
                <strong>{!data.schedule ? "NA" : moment(data.schedule).format("DD/MM/YYYY HH:MM A")}</strong>
              </td>
          </tr>
          <tr>
              <th scope="row" style={{ width: "180px" }}>Created At</th>
              <td>
                <strong className="text-danger">{moment(data.createdAt).format("DD/MM/YYYY")}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Remarks</th>
              <td>
                <strong className="text-danger">{!data.remarks ? "NA" : data.remarks}</strong>
              </td>
            </tr>
          <tr>
            <th scope="row" style={{ width: "180px" }}>Address</th>
            <td style={{ maxWidth: "220px" }}>
              <strong>{data.companyAddress}</strong>
            </td>
            <th scope="row" style={{ width: "180px" }}>Products</th>
            <td>
              <strong>{data.productsList.length ?data.productsList.join(", "): "NA" }</strong>
            </td>
          </tr>
          <tr>
              <th scope="row" style={{ width: "150px" }}>Alternate No</th>
              <td style={{ width: "220px" }}>
                <strong>{data.alternate ? data.alternate : "NA"}</strong>
              </td>
              <th scope="row" style={{ width: "150px" }}>Assign At</th>
              <td style={{ width: "220px" }}>
                <strong> {moment(data.assignAt).format("DD/MM/YYYY")}</strong>
              </td>
            </tr>
        </tbody>
      </Table>
      <div className="row px-2">
          <PartyComments setModal={(e) => setOpenModal(e)} assigned={statusmoved} party={data} open={openModal} />
          <StatusPoppover reduxValue={{ decrease: "prospectivelist", increase: "followuplist" }}  setModal={(e) => setFollowUpModal(e)} open={followUpModal} updated={statusmoved} text={followupProp} party={data} />
          <StatusPoppover reduxValue={{ decrease: "prospectivelist", increase: "callbacklist" }}  setModal={(e) => setCallBackModal(e)} open={callBackModal} updated={statusmoved} text={callbackProp} party={data} />
          <StatusPoppover reduxValue={{ decrease: "prospectivelist", increase: "materializelist" }}  setModal={(e) => setMaterializeModal(e)} open={materializeModal} updated={statusmoved} text={materializeProp} party={data} />{" "}
          <StatusPoppover reduxValue={{ decrease: "prospectivelist", increase: "" }}  setModal={(e) => setDeleteModal(e)} open={deleteModal} updated={statusmoved} party={data} text={deleteProp} />
          <Link className="btn-round mx-1 px-4 pt-2 btn-sm btn-dark text-white" to={"/allocation/update-party/" +data._id } state={data}>Edit</Link> 
        </div>
    </div>
    );
  };

  return (
    <div>
      <AnnounceMarquee/>
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Prospective List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default ProspectiveParties;
