import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import { getCookie } from "../../../../Helper/Cokkies";
import moment from "moment";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent';
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { Badge, Table } from "reactstrap";
import PartyComments from '../../Components/PartyComments/PartyComments'
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import StatusPoppover from "../../Components/StatusPopover/StatusPoppover";
import DataTable from "react-data-table-component";
import style from './style.module.css'
const axios = require("axios").default;

export default function Dashboard() {
  let token = getCookie("alcToken");
  const [pending, setPending] = useState(true);
  const [scheduledParty, setScheduledParty] = useState([])
  const [hotParties, setHotParties] = useState([])
  const [loglist, setLoglist] = useState([])
  const [openModal, setOpenModal] = useState(false);
  const [followUpModal, setFollowUpModal] = useState(false);
  const [callBackModal, setCallBackModal] = useState(false);
  const [prospectiveModal, setProspectiveModal] = useState(false);
  const [materializeModal, setMaterializeModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const callbackProp = { text: "Call back", color: "success", icon: "fas fa-reply-all" }
  const followupProp = { text: "Follow up", color: "primary", icon: "fas fa-hand-point-right" }
  const prospectiveProp = { text: "Prospective", color: "info", icon: "fas fa-comment-dollar" }
  const materializeProp = { text: "Materialize", color: "warning", icon: "fas fa-tasks" }
  const deleteProp = { text: "Delete", color: "danger", icon: "fas fa-trash" }

  useEffect(() => {
    axios
      .post(api.url + "/get-data-for-allocation-dashboard", { id: getLocalStorage("alcUser").id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1;
        });
        response.data.loglist.forEach((item, index) => {
          item.serial = index + 1;
        });
        response.data.hotParties.forEach((item, index) => {
          item.serial = index + 1;
        });
        setScheduledParty(response.data.parties)
        setHotParties(response.data.hotParties)
        setLoglist(response.data.loglist)
        setPending(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
      sortField: "schedule"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      width: "200px",
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      width: "200px",
      sortable: true,
      sortField: "firstName",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
      width: "120px",
      sortField: "contact"
    },
    {
      name: <strong>Status</strong>,
      width: "180px",
      selector: (row) => (
        <h5 className="m-0">
          <Badge
            color={row.status === "Follow up" ? "danger" : row.status === "Call back" ? "success" : row.status === "Prospective" ? "info" : row.status === "Materialize" ? "warning" : "secondary"}
            pill
          >
            {row.status}
          </Badge>
        </h5>
      ),
      sortField: "status",
      sortable: true,
    },
    {
      name: <strong>Schedule</strong>,
      selector: (row) => moment(row.schedule).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "schedule",
    },
  ];


  const columns2 = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company.company,
    },
    {
      name: <strong>Log</strong>,
      selector: (row) => row.subject,
    },
    {
      name: <strong>Date</strong>,
      sortable: true,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "120px",
    }
  ];

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Email</th>
              <td>
                <strong>{data.email}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Schedule</th>
              <td>
                <strong>{!data.schedule ? "NA" : moment(data.schedule).format("DD/MM/YYYY HH:MM A")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Created At</th>
              <td>
                <strong className="text-danger">{moment(data.createdAt).format("DD/MM/YYYY")}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Remarks</th>
              <td style={{ width: "200px" }}>
                <strong className="text-danger">{!data.remarks ? "NA" : data.remarks}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Address</th>
              <td style={{ maxWidth: "220px" }}>
                <strong>{data.companyAddress}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Country</th>
              <td>
                <strong>{data.country}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row px-2">
          <PartyComments setModal={(e) => setOpenModal(e)} assigned={statusmoved} party={data} open={openModal} />
          {data.status === "Fresh" ? <>
            <StatusPoppover reduxValue={{ decrease: "", increase: "followuplist" }} setModal={(e) => setFollowUpModal(e)} open={followUpModal} updated={statusmoved} text={followupProp} party={data} />
            <StatusPoppover reduxValue={{ decrease: "", increase: "callbacklist" }} setModal={(e) => setCallBackModal(e)} open={callBackModal} updated={statusmoved} text={callbackProp} party={data} />
            <StatusPoppover reduxValue={{ decrease: "", increase: "prospectivelist" }} setModal={(e) => setProspectiveModal(e)} open={prospectiveModal} updated={statusmoved} text={prospectiveProp} party={data} />
            <StatusPoppover reduxValue={{ decrease: "", increase: "materialzelist" }} setModal={(e) => setMaterializeModal(e)} open={materializeModal} updated={statusmoved} text={materializeProp} party={data} />{" "}
            <StatusPoppover reduxValue={{ decrease: "", increase: "" }} setModal={(e) => setDeleteModal(e)} open={deleteModal} updated={statusmoved} party={data} text={deleteProp} />
          </>
            : ""}
        </div>
      </div>
    );
  };
  const statusmoved = (e) => {
    setScheduledParty(
      scheduledParty.filter((el) => {
        return el._id !== e;
      })
    );
  };

  const updateSelectedRow = (e) => { }

  return (
    <div>
      <AnnounceMarquee />
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Dashboard</h4>
          <hr className="my-2" />
        </div>
      </div>


      <section className="container">
        {scheduledParty.length ? <div class={"card card-outline card-primary "+ style.cardBox}>
          <div class="card-header">
            <h3 class="card-title">Today's Scheduled Parties ( Reminder )</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body" style={{ display: "block" }}>
            <DataTableComponent
              notFilter={true}
              expandedComponent={e => expComponent(e)}
              expandable={true}
              dataLoad={pending}
              setRows={updateSelectedRow}
              columnsData={columns}
              data={scheduledParty} />
          </div>
        </div> : ""}

        {hotParties.length ? <div class={"card card-outline card-danger " + style.cardBox}>
          <div class="card-header py-2">
            <h3 class="card-title">Hot Parties</h3>
            <div class="card-tools">
              <button type="button" class={"btn btn-tool " + style.cardTool} data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0" style={{ display: "block" }}>
            <DataTableComponent
              notFilter={true}
              expandedComponent={e => expComponent(e)}
              expandable={true}
              dataLoad={pending}
              setRows={updateSelectedRow}
              columnsData={columns}
              data={hotParties} />
          </div>
        </div> : ""}

        {loglist.length ? <div class={"card card-outline card-success " + style.cardBox}>
          <div class="card-header py-2">
            <h3 class="card-title">Party's Logs</h3>
            <div class="card-tools">
              <button type="button" class={"btn btn-tool " + style.cardTool} data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button>
            </div>
          </div>
          <div class="card-body p-0" style={{ display: "block" }}>
          <DataTable pagination persistTableHead highlightOnHover columns={columns2} data={loglist} progressPending={pending} />
          </div>
        </div> : ""}


      </section>
    </div>
  );
}
