import React, { useState } from "react";
import { api } from "../../../../Helper/Data";
import { Badge, Button, Table } from "reactstrap";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import moment from "moment";
import { getCookie } from "../../../../Helper/Cokkies";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import { getLocalStorage } from "../../../../Helper/LocalStorage";
const axios = require("axios").default;

function SearchParties() {
  const [pending, setPending] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  let token = getCookie("alcToken");
  let user = getLocalStorage("alcUser");
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "assignAt" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      sortable: true,
      sortField: "firstName",
      width: "250px",
    },
    {
      name: <strong>Assign To</strong>,
      selector: (row) => row.assignTo.fullname,
    },
    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <h5 className="m-0">
          <Badge
            color={row.status === "Follow up" ? "danger" : row.status === "Call back" ? "success" : row.status === "Prospective" ? "info" : row.status === "Materialize" ? "warning" : "secondary"}
            pill
          >
            {row.status}
          </Badge>
        </h5>
      ),
      sortable: true,
      width: "180px",
      sortField: "status"
    },
    {
      name: <strong>Assign At</strong>,
      selector: (row) => moment(row.assignAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "assignAt"
    },
  ];

  const assignNow = (id) => {
    axios
      .post(api.url + "/assign-request", { party: id, candidate: user.id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Successfull");
        } else{
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };


  const searchPartiesFunc = (data) => {
    setPending(true)
    setTableOptions({ ...tableOptions, pageNo: data.pageNo, perPage: data.perPage, sortDirection: data.sortDirection, sortField: data.sortField })
    axios
      .post(api.url + "/get-search-parties", { page: data.pageNo, sortDirection: data.sortDirection, sortField: data.sortField, perPage: data.perPage, key: filterValue })
      .then(function (response) {
        console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (data.perPage * data.pageNo - data.perPage);
        });
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const updateSelectedRow =(e)=>{ }

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row">Assign To</th>
              <td>
                <strong className="text-danger">{data.assignTo.fullname}</strong>
              </td>
              <th scope="row">Created At</th>
              <td>
                <strong className="text-danger">{moment(data.createdAt).format("DD/MM/YYYY")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Address</th>
              <td style={{ maxWidth: "220px" }}>
                <strong>{data.companyAddress}</strong>
              </td>
              <th scope="row">Products</th>
              <td>
                <strong>{data.productsList.length ?data.productsList.join(", "): "NA" }</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Created By</th>
              <td>
                <strong>{data.createdBy}</strong>
              </td>
              <th scope="row">Request to</th>
              <td>
                <Button color="info" disabled={data.assignTo._id === user.id || user.role !== 'user' ? true : false} onClick={() => assignNow(data._id)} className="py-0 btn-icon" outline>
                  <i className="far fa-hand-point-right"></i> Assign Request
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      <AnnounceMarquee/>
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Search Parties</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => searchPartiesFunc({ pageNo: e, perPage: tableOptions.perPage, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, key: filterValue })}
        perRowsChange={(e) => searchPartiesFunc({ pageNo: 1, perPage: e.perPage, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, key: filterValue })}
        setHandleSort={(e) => searchPartiesFunc({ pageNo: tableOptions.pageNo, perPage: tableOptions.perPage, sortDirection: e.sortDirection, sortField: e.sortField, key: filterValue })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        setRows={updateSelectedRow}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => searchPartiesFunc({ pageNo: tableOptions.pageNo, perPage: tableOptions.perPage, sortDirection: e.sortDirection, sortField: e.sortField, key: filterValue })}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default SearchParties;
