import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div>
        <footer className="main-footer">
          <strong>
            Copyright ©2020 <a href="http://www.webbrightinfra.com" target={"_blank"} rel="noreferrer">WEB BRIGHT INFRA PVT LTD.</a>{" "}
          </strong>
          All rights reserved.
        </footer>
      </div>
    );
  }
}
