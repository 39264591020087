import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import { Button, Table } from "reactstrap";
import moment from "moment";
import PartyComments from "../../Components/PartyComments/PartyComments";
import { getCookie } from "../../../../Helper/Cokkies";
import AssignParty from '../../Components/AssignParty/AssignParty'
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import { useDispatch } from "react-redux";
import { deleteAllocationCount } from '../../../../Redux/Counter'
const axios = require("axios").default;

function UnassignedParties() {
  const dispatch = useDispatch()
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [partyModal, setPartyModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  let token = getCookie("alcToken");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "asc", sortField: "updateAt" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
      sortField: "updateAt"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.firstName + " " + row.lastName}</>,
      sortable: true,
      sortField: "firstName",
      width: "250px",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
      sortField: "contact",
      width: "120px"
    },
    {
      name: <strong>Last Update</strong>,
      selector: (row) => moment(row.updateAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
  ];

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-unassigned-parties", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setListData(response.data.parties);
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [isSearch, tableOptions, token]);

  const updateSelectedRow = (data) => {
    setSelectedRows(data)
  }

  const deleteParty = (id) => {
    axios
      .post(api.url + "/delete-parties", { id: id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          dispatch(deleteAllocationCount("unassignedparties"))
          statusmoved(id)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row">Email</th>
              <td>
                <strong>{data.email}</strong>
              </td>
              <th scope="row">Alternate No.</th>
              <td>
                <strong>{!data.altContact ? "NA" : data.altContact}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Assign To</th>
              <td>
                <strong className="text-danger">{data.assignTo.fullname}</strong>
              </td>
              <th scope="row">Created By</th>
              <td>
                <strong>{data.createdBy}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Address</th>
              <td style={{ maxWidth: "220px" }}>
                <strong>{data.companyAddress}</strong>
              </td>
              <th scope="row">Remarks</th>
              <td>
                <strong>{data.remarks}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Comments</th>
              <td>
                <PartyComments setModal={(e) => setPartyModal(e)} notComment={true} assigned={statusmoved} party={data} open={partyModal} />
              </td>
              <th scope="row">Action</th>
              <td>
                <Button onClick={() => { setSelectedRows([data._id]); setOpenModal(true) }} className="btn-sm py-0 px-3" color="success">
                  Assign To <i className="fas fa-expand-arrows-alt"></i>
                </Button>
                <Button onClick={() => deleteParty(data._id)} className="btn-sm py-0 ml-1 px-3" color="danger">
                  Delete <i className="fas fa-trash"></i>
                </Button>

              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const statusmoved = (e) => {
    const filteredArray = listData.filter(function (x) {
      return e.indexOf(x) < 0;
    });
    setListData(filteredArray)
    setFilterItems(filteredArray)
  };

  return (
    <div>
      <AnnounceMarquee />
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Unassigned List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <AssignParty deleteComment={true} reduxValue={{ decrease: "unassignedparties", increase: "" }} setModal={(e) => setOpenModal(e)} assigned={statusmoved} party={selectedRows} open={openModal} />

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        selecteRow={true}
        action={"Assign To"}
        selectedAssign={(e) => setOpenModal(true)}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default UnassignedParties;