import React, { useEffect, useState } from 'react'
import { Nav, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import style from "./style.module.css"
import { useNavigate } from "react-router-dom";
import { api } from '../../../../../Helper/Data'
import { Sling as Hamburger } from 'hamburger-react'
import { getCookie } from 'Helper/Cokkies';
import ApkButton from '../../../../../components/Apkbutton/ApkButton'

function MainHeader() {
  const navigate = useNavigate();
  const [bodyClick, setBodyClick] = useState(false);
  const [hamburgerClicked, setHamburgerClicked] = useState(false);

  const collapseFunc = () => {
    document.documentElement.classList.toggle("nav-open");
    setBodyClick(!bodyClick);
  }

  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        includedLanguages: 'bn,de,nl,en,es,it,fr,hi', defaultLanguage:'en', pageLanguage: "en", layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE, autoDisplay: false, multilanguagePage:true 
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    var addScript = document.createElement("script");
    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (
    <div className={style.navBarContainer}>
      <NavbarBrand
        className={style.navbarStyle}
        onClick={(e) => {
          navigate("/"); setHamburgerClicked(false)
        }} >
        <img src={api.logosUrl + "logo.png"} className={style.logoImg} alt="worldwebtrade logo" />
      </NavbarBrand>

      <div className={style.mobileNav}>
        <Hamburger toggled={hamburgerClicked} size={20} color="#004395" toggle={setHamburgerClicked} />
        <MobileNav toggleMenu={hamburgerClicked} toggleNav={(e) => setHamburgerClicked(!hamburgerClicked)} />
      </div>

      <Nav className={style.navContainer} navbar>
        <NavItem>
          <NavLink  href='#' onClick={(e) => navigate('/contact-us')}>
            <i className="fas fa-headset"></i>
            <p className="mx-1 my-0">+91-8377007766</p>
          </NavLink>
        </NavItem>
        <NavItem >
          <NavLink  href='#' onClick={(e) => { navigate('/importers'); collapseFunc() }}>
            <i className="nc-icon nc-single-02" />
            <p className="mx-1 my-0">Buyers</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink  href='#' onClick={(e) => { navigate('/product-categories'); collapseFunc() }}>
            <i className="nc-icon nc-box" />
            <p className="mx-1 my-0">Products</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink  href='#' onClick={(e) => { navigate("/companies"); collapseFunc() }}>
            <i className="nc-icon nc-chart-bar-32" />
            <p className="mx-1 my-0">Companies</p>
          </NavLink>
        </NavItem>
        <NavItem>
          {/* <NavLink href='#' onClick={(e) => navigate('/contact-us')}>
            <i className="nc-icon nc-email-85" />
            <p className="mx-1 my-0">Contact Us</p>
          </NavLink> */}
           <div className='mx-2' id="google_translate_element"></div>
        </NavItem>
        <NavItem>
          <ApkButton />
        </NavItem>
      </Nav>
    </div>
  )
}

export default MainHeader

const MobileNav = (props) => {
  const navigate = useNavigate();
  const token = getCookie("token");

  return <>
    <div className={props.toggleMenu ? style.mobileView + " " + style.showMenu : style.mobileView + " " + style.hideMenu}>
      <Nav className={style.collapseNav}>
        <br />
        <NavItem>
          <NavLink href='#' onClick={(e) => { navigate('/contact-us'); props.toggleNav(e) }}>
            <i className="fas fa-headset"></i>
            <p className="mx-1 my-0">+91-8377007766</p>
          </NavLink>
        </NavItem>
        <NavItem >
          <NavLink href='#' onClick={(e) => { navigate('/importers'); props.toggleNav(e) }}>
            <i className="nc-icon nc-single-02" />
            <p className="mx-1 my-0">Buyers</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#' onClick={(e) => { navigate('/product-categories'); props.toggleNav(e) }}>
            <i className="nc-icon nc-box" />
            <p className="mx-1 my-0">Products</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#' onClick={(e) => { navigate("/companies"); props.toggleNav(e) }}>
            <i className="nc-icon nc-chart-bar-32" />
            <p className="mx-1 my-0">Companies</p>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink href='#' onClick={(e) => { navigate('/contact-us'); props.toggleNav(e) }}>
            <i className="nc-icon nc-email-85" />
            <p className="mx-1 my-0">Contact Us</p>
          </NavLink>
        </NavItem>
        {token === undefined ? (
          <>
            <NavItem>
              <NavLink href='#' onClick={(e) => { navigate('/join-form'); props.toggleNav(e) }}>
                <i className="nc-icon nc-email-85" />
                <p className="mx-1 my-0">Join Now</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#' onClick={(e) => { navigate('/admin'); props.toggleNav(e) }}>
                <i className="nc-icon nc-email-85" />
                <p className="mx-1 my-0">Login</p>
              </NavLink>
            </NavItem>
          </>
        ) : (
          <>
            <NavItem>
              <NavLink href='#' onClick={(e) => { navigate('/admin/leads-list'); props.toggleNav(e) }}>
                <i className="nc-icon nc-email-85" />
                <p className="mx-1 my-0">Buy leads</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href='#' onClick={(e) => { navigate('/admin'); props.toggleNav(e) }}>
                <i className="nc-icon nc-email-85" />
                <p className="mx-1 my-0">Control Panel</p>
              </NavLink>
            </NavItem>
          </>
        )}
        <NavItem>
          <ApkButton />
        </NavItem>
        <br />
      </Nav>
    </div>
  </>
}