import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress, { circularProgressClasses } from "@mui/material/CircularProgress";
import { api } from '../../../../Helper/Data'
import { Grid } from "@mui/material";
import QuickCallback from "../../Components/QuickCallBack/QuickCallback";
import FooterLinks from "../../Components/Footer/FooterLinks/FooterLinks";
import CopyRight from "../../Components/Footer/CopyRight/CopyRight";
import { HeaderNav, DetailsTemplate, FeaturedProducts, TemplateBanner, ContactUsTemplate, TemplateProductServices } from "./CompanyTemplateComponents/index";
import { unloaded } from "Redux/LazyLoad";
import { loaded } from "Redux/LazyLoad";
import axios from "axios";
import {Helmet} from "react-helmet";
import ScrollToTop from '../../../../components/ScrollToTop/ScrollToTop'
import { Container } from "reactstrap";

function CompanyTemplate(props) {
  const lazyLoad = useSelector((state) => state.LazyLoad.load);
  const dispatch = useDispatch();
  const { catalogLink } = useParams()
  const [companyTemp, setCompanyTemp] = useState({});
  const [galleryList, setGalleryList] = useState([]);
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    dispatch(unloaded());
    axios
      .post(api.url + "/getDataForCompanyTemplate", { catalogLink: catalogLink })
      .then(function (response) {
        // console.log(response.data);
        if (response.data.success) {
          setCompanyTemp({ companyDetail: response.data.company[0], productList: response.data.productsList, banners: response.data.banner, featureProduct: response.data.featured })
          setGalleryList(response.data.gallery)
          dispatch(loaded());
        } else {
          notFound()
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [catalogLink, dispatch]);

  const notFound = () => {
    return navigate("/404", { state: `www.worldwebtrade.com${location.pathname}` });
  }

  const productRef = useRef(null);
  const contactRef = useRef(null);

  const scrollTo = (e) => {
    productRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  const contactscrollTo = (e) => {
    contactRef.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  };
  return (
    <>
      {!lazyLoad ? (
        <FacebookCircularProgress />
      ) : (
        <div>
          <Helmet>
            <meta name="description" content="Helmet application" />
          </Helmet>
          <ScrollToTop />
          <QuickCallback />
          {companyTemp.banners ? <HeaderNav scroll={scrollTo} contactscroll={contactscrollTo} company={companyTemp.companyDetail} /> : ""}
          <Container style={{ marginTop: "75px" }}>
            {companyTemp.banners && companyTemp.banners.length ? <TemplateBanner banners={companyTemp.banners} /> : ""}
            {companyTemp.banners ? <DetailsTemplate refProp={productRef} details={companyTemp.companyDetail} /> : ""}
            {companyTemp.featureProduct && companyTemp.featureProduct.length ? <FeaturedProducts products={companyTemp.featureProduct} /> : ""}
            {companyTemp.productList && companyTemp.productList.length ? <TemplateProductServices tabHead={companyTemp.companyDetail.tempCat} products={companyTemp.productList} gallery={galleryList} /> : ""}

            {companyTemp.banners ? <ContactUsTemplate contProp={contactRef} details={companyTemp.companyDetail} /> : ""}
          </Container>
          <FooterLinks />
          <CopyRight />
        </div>
      )}
    </>
  );

  function FacebookCircularProgress(props) {
    return (
      <Grid container style={{ height: "90vh" }} direction="column" justifyContent="center" alignItems="center">
        <Grid item sx={{ position: "relative" }}>
          <CircularProgress
            variant="determinate"
            sx={{
              color: (theme) => theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
            }}
            size={50}
            thickness={4}
            {...props}
            value={100}
          />
          <CircularProgress
            variant="indeterminate"
            disableShrink
            sx={{
              color: (theme) => (theme.palette.mode === "light" ? "#004395" : "#308fe8"),
              animationDuration: "550ms",
              position: "absolute",
              left: 0,
              [`& .${circularProgressClasses.circle}`]: {
                strokeLinecap: "round",
              },
            }}
            size={50}
            thickness={4}
            {...props}
          />
        </Grid>
        <Grid item>
          <img src={api.logosUrl + "/logo.png"} style={{ height: "50px", opacity: "0.9", filter: "drop-shadow(#004395 0px 5px 5px)" }} alt="" />
        </Grid>
      </Grid>
    );
  }
}

export default CompanyTemplate;
