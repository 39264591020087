import { configureStore } from '@reduxjs/toolkit'
import CompanyUserReducer from '../Redux/CompanyUser'
import CategoryStateReducer from '../Redux/CategoryState'
import ProductLinkReducer from '../Redux/ProductLink'
import LazyLoadReducer from '../Redux/LazyLoad'
import CountriesReducer from '../Redux/Countries'
import CandidatesReducer from '../Redux/Candidates'
import CounterReducer from '../Redux/Counter'
export const store = configureStore({
  reducer: {
    CompanyUser: CompanyUserReducer,
    CategoryState: CategoryStateReducer,
    ProductLink: ProductLinkReducer,
    LazyLoad: LazyLoadReducer,
    CountriesList: CountriesReducer,
    CanditatesList: CandidatesReducer,
    CounterList: CounterReducer,
  },
  devTools: process.env.NODE_ENV !== 'production'
})