import React, { useState } from 'react'
import { useEffect } from 'react'
import { Link, useLocation } from "react-router-dom"
import { CardBody, CardImg, Container } from "reactstrap";
import { Card, CardContent } from "@mui/material";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";

function CatDirectory() {
  const location = useLocation()
  const [data, setData] = useState(location.state)
  useEffect(() => {
    console.log(location.state)
  }, [location.state])

  return (
    <Container className="my-3">
      <BreadCrumb current={"Categories Directory"} />
      <Card className="card-outline card-danger">
        <div className="card-header">
          <h5 className="text-center m-0 font-weight-bold">Directory :{" "}<span className="text-main">{data.category} </span></h5>
        </div>
        <CardContent className="py-3">
          <div className='row'>
            {data.subCategory.map((el, i) => {
              return <div className='col-6 col-md-3'> <Link to={`/products?category=${el.subCategory.toLowerCase().replaceAll(" ", "-")}`}><Card key={i} style={{ width: '10rem' }}>
                <div style={{maxHeight:"80px", overflow:"hidden"}}>
                <CardImg top src={el.imageUrl} alt={el.subCategory} />
                </div>
                <CardBody className='py-1 px-3 text-center'>
                  {el.subCategory}
                </CardBody>
              </Card></Link></div>
            })}
            </div>
        </CardContent>
      </Card>
    </Container>
  )
}

export default CatDirectory