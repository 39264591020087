import { Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { api } from "../../../../../Helper/Data";
import style from "../style.module.css";
import RequirementForm from "../../../Components/RequirementForm/RequirementForm";
import ModalComponent from "../../../../../components/Modal/ModalComponent";
function ContactUsTemplate(props) {
  const countries = useSelector((state) => state.CountriesList.countries);
  return (
    <div className="my-4" id="contact" ref={props.contProp}>
      <h3>
        <strong> CONTACT US</strong>
        <hr className="m-0 bg-main" style={{ height: "3px" }} />
      </h3>
      <Grid container spacing={2} className="my-2 px-2">
        <Grid item md={6}>
          <h3 className="my-2 font-weight-bold">{props.details.company}</h3>
          <p className={style.contactText}>
            <i className="fas fa-map-marker-alt text-info mr-3"></i> {props.details.companyAddress}
          </p>
          <p className={style.contactText}>
            {" "}
            <i className="fas fa-phone-alt text-success mr-3"></i>
            <span className={"m-0 " + style.blurItem}>
              <ModalComponent
                btnLink={true}
                btn={"0000000000"}
                form={<RequirementForm data={countries} small={"sm"} />}
                size={""}
                heading={"Form for Your Requirement "}
              />
            </span>
          </p>
          <p className={style.contactText}>
            <i className="far fa-envelope text-danger mr-3"></i>
            <span className={"m-0 " + style.blurItem}>
              <ModalComponent
                btnLink={true}
                btn={"xxxxxxxxxx@xxxx.xxx"}
                form={<RequirementForm data={countries} small={"sm"} />}
                size={""}
                heading={"Form for Your Requirement "}
              />
            </span>
          </p>
          {/* member ship */}
          <div className={style.planIcon}>
            {props.details.membershipPlan === "Guest" ? (
              <img style={{ filter: "brightness(0) invert(1) drop-shadow(black 0px 0px 1px)" }} src={api.planUrl + "silvercrown.png"} alt="" />
            ) : (
              <img src={api.planUrl + props.details.membershipPlan.toLowerCase().replace(" ", "") + "crown.png"} alt="" />
            )}
          </div>
          <p className="text-center">
            <small>
              <strong>{props.details.membershipPlan} Member</strong>
            </small>
          </p>
        </Grid>
        <Grid item md={6}>
          <h4 className="text-center mt-1">GET IN TOUCH</h4>
          <RequirementForm data={countries} small={"sm"} />
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUsTemplate;
