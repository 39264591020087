import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Fab, Tooltip } from "@mui/material";
import style from "./style.module.css";

export default function ScrollToTop() {
  const [showButton, setShowButton] = useState("scroll-to-top-hide");
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 200) {
        setShowButton("scroll-to-top-show");
      } else {
        setShowButton("scroll-to-top-hide");
      }
    });
  }, [])


  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
        <div className={showButton}>
          <Tooltip title="Go to Top" placement="top">
            <Fab className={style.floatBtn} size="small" onClick={scrollToTop}>
              <KeyboardArrowDownIcon />
            </Fab>
          </Tooltip>
        </div>
    </div>
  );
}