import React from "react";
import Paper from "@mui/material/Paper";
import Slide from "@mui/material/Slide";
import { Button } from "@mui/material";
import CallBackForm from "../CallBackForm/CallBackForm";
import CloseIcon from "@mui/icons-material/Close";
import style from "./style.module.css";

export default function QuickCallback() {
  const [checked, setChecked] = React.useState(false);
  const containerRef = React.useRef(null);

  const callBackToggle = () => {
    checked ? setChecked(false) : setChecked(true);
  };

  return (
    <div style={{ position: "relative" }}>
      <div className={style.callBackBox}>
        {checked ? (
          <Button onClick={callBackToggle} style={{ float: "right" }} endIcon={<CloseIcon />}>
            Close
          </Button>
        ) : (
          <Button size="large" onClick={callBackToggle}>
            Quick Call Back Request
          </Button>
        )}
        <Slide direction="up" in={checked} className={checked ? style.slideBox : style.slideBoxNone} container={containerRef.current}>
          <Paper elevation={4}>
            <h5 className="text-center font-weight-bold m-0 pt-3">
              REQUEST <span className="text-danger"> CALL BACK</span>{" "}
            </h5>
            <CallBackForm close={callBackToggle}></CallBackForm>
          </Paper>
        </Slide>
      </div>
    </div>
  );
}
