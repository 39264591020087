import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import { Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Table } from "reactstrap";
import moment from "moment";
import { getCookie } from "../../../../Helper/Cokkies";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { useDispatch } from "react-redux";
import { deleteAllocationCount } from '../../../../Redux/Counter'
import PartyComments from "../../Components/PartyComments/PartyComments";
const axios = require("axios").default;

function RequestedParties() {
  const dispatch = useDispatch()
  const [currentRow, setCurrentRow] = useState(null);
  const [pending, setPending] = useState(true);
  const [partyModal, setPartyModal] = useState(false);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [filterValue, setFilterValue] = useState('');
  
  let token = getCookie("alcToken");
  let user = getLocalStorage("alcUser");

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.party.company,
      sortable: true,
    },
    {
      name: <strong>Assign to</strong>,
      selector: (row) => row.party.assignTo.fullname,
    },
    {
      name: <strong>Requested By</strong>,
      selector: (row) => row.candidate.fullname,
    },
    {
      name: <strong>Status</strong>,
      selector: (row) => (
        <h5 className="m-0">
          <Badge
            color={
              row.party.status === "Follow up"
                ? "danger"
                : row.party.status === "Call back"
                  ? "success"
                  : row.party.status === "Prospective"
                    ? "info"
                    : "warning"
            }
            pill
          >
            {row.party.status}
          </Badge>
        </h5>
      ),
      sortable: true,
      width: "180px",
    },
    {
      name: <strong>Request On</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
    },
  ];

  useEffect(() => {
    axios
      .post(api.url + "/get-requested-parties", { id: user.id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1;
        });
        setListData(response.data.parties);
        setFilterItems(response.data.parties);
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token, user.id]);

  const changeHandle = () => {
    if (filterValue.length) {
      const filteredList = listData.filter((item) => item.party.keyWords && item.party.keyWords.toLowerCase().includes(filterValue.toLowerCase()));
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const requestAction = (id, party, candidate, action) => {
    axios
      .post(api.url + "/request-action", { id, party, candidate, action }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        alert(response.data.message)
        setListData(listData.filter((el) => { return el._id !== id && el.candidate._id !== candidate }))
        setFilterItems(listData.filter((el) => { return el._id !== id && el.candidate._id !== candidate }))
        dispatch(deleteAllocationCount("requestedparties"))
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const ExpandedComponent = ({ data }) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row">Email</th>
              <td>
                <strong>{data.party.email}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Schedule</th>
              <td>
                <strong>{!data.schedule ? "NA" : moment(data.schedule).format("DD/MM/YYYY HH:MM A")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Assign To</th>
              <td>
                <strong className="text-danger">{data.party.assignTo.fullname}</strong>
              </td>
              <th scope="row">Assign At</th>
              <td>
                <strong className="text-danger">{moment(data.party.assignAt).format("DD/MM/YYYY")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row">Address</th>
              <td style={{ maxWidth: "220px" }}>
                <strong>{data.party.companyAddress}</strong>
              </td>
              <th scope="row">Comment</th>
              <td>
              <PartyComments setModal={(e) => setPartyModal(e)} notComment={true} assigned={statusmoved} party={data.party} open={partyModal} />
              </td>
            </tr>
            <tr>
              <th scope="row">Created By</th>
              <td>
                <strong>{data.party.createdBy}</strong>
              </td>
              <th scope="row">Action</th>
              <td>
                <Button
                  color="success"
                  onClick={() => requestAction(data._id, data.party._id, data.candidate._id, true)}
                  className="py-0 px-2 btn-icon"
                  outline
                >
                  <i className="far fa-thumbs-up"></i> Approve
                </Button>
                <Button
                  color="danger"
                  onClick={() => requestAction(data._id, data.party._id, data.candidate._id, false)}
                  className="py-0 mx-1 px-2 btn-icon"
                  outline
                >
                  <i className="far fa-thumbs-down"></i> Decline
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const statusmoved = (e) => {
    setListData(
      listData.filter((el) => {
        return el._id !== e;
      })
    );
    setFilterItems(
      listData.filter((el) => {
        return el._id !== e;
      })
    );
  };

  const expandItem = () => {
    return (
      <h3 className="text-danger m-1 ml-2">
        <i className="fas fa-caret-square-up fa-sm"></i>
        {/* <i class="far fa-eye-slash fa-sm"></i> */}
      </h3>
    );
  };
  const collapseItem = () => {
    return (
      <h3 className="text-primary m-1 ml-2">
        {/* <i className="far fa-eye fa-sm"></i> */}
        <i className="fas fa-caret-square-down fa-sm"></i>
      </h3>
    );
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Requested List</h4>
          <hr className="my-2" />
        </div>
      </div>


      <form onSubmit={(e) => { e.preventDefault(); changeHandle() }} >
      <InputGroup >
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className="nc-icon nc-zoom-split"></i>
          </InputGroupText>
        </InputGroupAddon>
        <Input id="search" name="search" placeholder="Text here for Search..." type="search" onChange={(e) => setFilterValue(e.target.value)} />
        <Button className="bg-main border-0" >{filterValue ? "Search" : "Reset"}</Button>
      </InputGroup>
      </form>

      <DataTable
        pagination
        persistTableHead
        highlightOnHover
        expandableRowExpanded={(row) => (row === currentRow)}
        onRowClicked={(row) => setCurrentRow(row)}
        onRowExpandToggled={(bool, row) => setCurrentRow(row)}
        columns={columns}
        data={filterItems}
        progressPending={pending}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }}
      />
    </div>
  );
}

export default RequestedParties;
