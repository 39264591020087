import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import {formValidation} from '../../../../components/Utils/Utils'
const axios = require("axios").default;

function AddOperator() {
  const token = getCookie("token");

  const [formData, setFormData] = useState({
    firstName: "",
    userName: "",
    lastName: "",
    contact: "",
    emergencyPerson: "",
    role: "operator",
    password: "Operator@123",
    emergencyContact: "",
    email: "",
    skypeid: "",
    address: "",
  });

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };


  const submitHandler = (e) => {
    e.preventDefault();
    console.log('fsdaf');
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var tel = /^[0-9]{10}$/;
    if(formValidation([formData.userName, formData.email,formData.firstName,formData.contact, formData.address])){
      if (filter.test(formData.email) && formData.contact.match(tel)) {
        axios
        .post(api.url + "/signup", formData, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            addOperator();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
      }
      else {
        alert("Please enter valid email / contact");
      }
    } else {
      alert("Please fill the required fields");
    }
  };

  const addOperator = () => {
    axios
      .post(api.url + "/add-operator", formData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response);
        if (response.data.Join) {
          alert("Operator Add Successfull");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Operator</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Login Information */}
        <Form onSubmit={submitHandler}>
          <h5 className="text-center">
            <strong>Login Information</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="userName">
                User Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="userName"
                name="userName"
                value={formData.userName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Username"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>
          </div>
          {/* Contact Information */}
          <h5 className="text-center">
            <strong>Basic Information</strong>
          </h5>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="firstName">
                First Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter First Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="lastName">
                Last Name
              </Label>
              <Input
                bsSize="sm"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Last Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Contact No."}
                // pattern="[1-9]{1}[0-9]{9}"
                title="10 digits number allowed"
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-sm-6">
              <Label className={style.formLabel} for="skypeid">
                Your Skype Id
              </Label>
              <Input
                bsSize="sm"
                id="skypeid"
                name="skypeid"
                value={formData.skypeid}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Skype Id"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="emergencyPerson">
                Emergency Name{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="emergencyPerson"
                name="emergencyPerson"
                value={formData.emergencyPerson}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Person Name"}
                type="text"
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="emergencyContact">
                Emergency Contact No.{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="emergencyContact"
                name="emergencyContact"
                value={formData.emergencyContact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Emergency Contact No."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="address">
                Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="address"
                style={{ minHeight: "50px" }}
                value={formData.address}
                onChange={(e) => changeHandler(e)}
                name="address"
                placeholder={"Enter address"}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button type="submit" className="btn-sm btn-main px-4">
            Add Operator
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default AddOperator;
