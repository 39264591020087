import RequirementForm from "Modules/Public/Components/RequirementForm/RequirementForm";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import PageHeading from "../../Components/PageHeading/PageHeading";
import { Container, Divider, ListItemIcon, ListItemText, MenuItem, MenuList } from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import AddTaskIcon from "@mui/icons-material/AddTask";
import StarIcon from "@mui/icons-material/Star";

export default function RequirementFormPage() {
  
  return (
    <div>
      <PageHeading page={"requirement"}></PageHeading>
      <Container className="py-3 container">
      <BreadCrumb current={"Post Your Requirement"}/>
        <div className="row">
          <div className="col-md-6 order-2 order-md-1">
            <h4 className="text-center my-3">
              <strong>
                What is Your <span className="text-danger"> Requirement</span>{" "}
              </strong>
            </h4>
            <RequirementForm ></RequirementForm>
          </div>
          <div className="col-md-6 order-1 order-md-2">
            <h4 className="text-center my-3">
              <strong>Buyers Advantages?</strong>
            </h4>
            <MenuList>
              <MenuItem>
                <ListItemIcon>
                  <GroupIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Get Instant Feedback from Suppliers</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <AddTaskIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Accredited Suppliers that Meet Your Needs.</p>
                </ListItemText>
              </MenuItem>
              <Divider />
              <MenuItem>
                <ListItemIcon>
                  <StarIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>
                  <p className="font-weight-bold">Get the power to choose the best!</p>
                </ListItemText>
              </MenuItem>
            </MenuList>
          </div>
        </div>
      </Container>
    </div>
  );
}
