import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RequirementForm from "../../Components/RequirementForm/RequirementForm";
import { api } from "../../../../Helper/Data";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import ProductItem from "../Products/ProductItem";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import style from "./style.module.css";
import Skeleton from '@mui/material/Skeleton';
const axios = require("axios").default;

function SearchProducts() {
  const [loading, setLoading] = useState(true);
  const { key } = useParams();
  const [keyValue, setKeyValue] = useState(key);
  const [listPage, setListPage] = useState(1);
  const [dataResponse, setDataResponse] = useState({});

  useEffect(() => {
    if(keyValue !== key){
      setListPage(1)
    }
    setLoading(true)
    axios
      .post(api.url + "/getSearchProducts", { page: listPage, key: key === "all-products" ? "" : key })
      .then(function (response) {
        // console.log(response.data);
        setDataResponse({ itemList: response.data.products, totalItemLength: response.data.totalItems })
        setKeyValue(key)
        setLoading(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [key, listPage, keyValue]);

  const handlePageChange = (event, value) => {
    setListPage(value);
  };

  return (
    <>
      <div className="container py-3">
        <BreadCrumb primary={{ url: "/product-categories", text: "Products" }} current={`Browse by Search : ${key}`} />

        <div className="row">
          <div className="col-md-3 px-2 rounded bg-white">
            <h6 className="text-center my-2">
              What is Your <span className="text-danger"> Requirement</span> <hr className="m-0" />
            </h6>
            <RequirementForm small={"sm"} />
          </div>
          <div className="col-md-9">
            {dataResponse.totalItemLength / 10 >= 1 ? (
              <div className={style.searchHeader}>
                <p className="font-weight-bold">Total Result : {dataResponse.totalItemLength}</p>
                <Stack className="mb-1" spacing={2}>
                  <Pagination defaultPage={listPage} count={Math.ceil(dataResponse.totalItemLength / 10)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            ) : (
              loading ? <Skeleton animation="wave" height={70} /> : <p className="font-weight-bold">Total Result : {dataResponse.totalItemLength}</p>
            )}
            {loading ? <><h5 className="font-weight-bold">Loading...</h5>
              {[1, 2, 3, 4].map((el, i) => <Skeleton key={i} style={{ borderRadius: "8px", margin: "5px" }} variant="rounded" animation="wave" height={150} />)}
            </> : (dataResponse.itemList.length
              ? dataResponse.itemList.map((el, i) => {
                return <ProductItem key={i} data={el} />
              })
              : "No Result Found")}
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchProducts;
