import React, { useEffect, useState } from "react";
import BreadCrumb from "../../Components/BreadCrumb/BreadCrumb";
import MainContentHeader from "../Home/MainContentHeader";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { api } from "../../../../Helper/Data";
import moment from "moment";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import PeopleIcon from '@mui/icons-material/People';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import BusinessIcon from '@mui/icons-material/Business';
import LocalPizzaIcon from '@mui/icons-material/LocalPizza';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Card, CardContent, Container, Divider, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";


function CountryPage() {
  const { countryCode } = useParams();
  const [newsUpdate, setNewsUpdate] = useState([]);
  const [topicValue, setTopicValue] = useState("Business");
  const obj = {
    in: "India",
    cn: "China", tr: "Turkey", us: "USA", jp: "Japan", my: "Malaysia", eg: "Egypt", de: "Germany", code: "de"
  }

  const topicArray = [
    { value: "Business", icon: <BusinessIcon /> },
    { value: "Food", icon: <LocalPizzaIcon /> },
    { value: "Health", icon: <HealthAndSafetyIcon /> },
    { value: "Politics", icon: <PeopleIcon /> },
    { value: "Top", icon: <PanToolAltIcon /> },
    { value: "World", icon: <TravelExploreIcon /> }
  ]


  useEffect(() => {
    console.log(countryCode);
    axios
      .get(`https://newsdata.io/api/1/news?apikey=pub_152139e62be9ab4570ef82d89e3b22f245ad8&category=${topicValue.toLocaleLowerCase()}&language=en&country=${countryCode}`)
      .then(function (response) {
        // console.log(response);
        setNewsUpdate(response.data.results);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [countryCode, topicValue]);

  return (
    <>
      <Container >
        <BreadCrumb current={"Region - " + obj[countryCode]} />
        <Card className="card-outline card-main">
          <div className="card-header">
            <h3 className="card-title">Business News / Activities</h3>
            <div className="card-tools">
            </div>
          </div>
          <div className="card-body" style={{ display: "block" }}>
            <div className="row">
              <div className="col-12 col-md-12 col-lg-8 order-2 order-md-1">
                <div className="row">
                  <div className="col-12">
                    {newsUpdate.length ? newsUpdate.map((el, i) => {
                      return <div className="post" key={i}>
                        <div className="text-lg">
                          <a href={el.link} target="_blank" rel="noreferrer">{el.title}</a>
                        </div>
                        <p>
                          {el.description}
                        </p>
                        <p>
                          <i className="fas fa-link mr-1"></i> {el.source_id}
                        </p>
                      </div>
                    }) : ""}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-4 order-1 order-md-2">
                <h4 className="text-primary">Topics</h4>
                <br />
                {topicArray.map((el, i) => {
                  return <div key={i}>
                    <ListItemButton
                    selected={topicValue === el.value}
                    onClick={(event) => setTopicValue(el.value)}
                    >
                      <ListItemIcon>
                        {el.icon}
                      </ListItemIcon>
                      <ListItemText primary={el.value} />
                    </ListItemButton>
                    <Divider />
                  </div>
                })}

              </div>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default CountryPage;
