import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, Spinner } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import SelectJS from "../../../../components/Select/SelectJS";
import { getCookie } from "../../../../Helper/Cokkies";
const axios = require("axios").default;

function ShiftParties() {
  const [submiting, setSubmiting] = useState(false)
  const [candidateList, setCandidateList] = useState([]);
  let token = getCookie("alcToken");
  const [formData, setFormData] = useState({ user: "", assignTo: "", partyLimit: 0 });

  useEffect(() => {
    axios
      .post(api.url + "/get-candidates-for-shift", { id: "id" }, { headers: { Authorization: "Bearer " + token } })
      .then(function (response) {
        setCandidateList(response.data.list.map((el) => {
          return { value: el._id, label: el.fullname + " ( " + el.username + " )" }
        }))
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token])

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const chandidateSelected = (select, text) => {
    setFormData({ ...formData, [text]: select.value });
  };

  const submitHandler = (e) => {
    e.preventDefault()
    setSubmiting(true)
    axios
      .post(api.url + "/sift-parties", formData, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Parties Shift Successfull");
          setSubmiting(false)
        } else {
          alert(response.data.message);
          setSubmiting(false)
        }
      })
      .catch(function (error) {
        console.log(error);
        setSubmiting(false)
      });
  };

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Shift Paries</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="assignTo">
                Whose Parties{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} selected={(e)=>chandidateSelected(e, "user")} data={candidateList} />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="assignTo">
                Assigne To whom{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} selected={(e)=>chandidateSelected(e, "assignTo")} data={candidateList} />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="partyLimit">
                Party Limit{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="partyLimit"
                name="partyLimit"
                value={formData.partyLimit}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Party Limit"}
                type="number"
              />
            </FormGroup>
          </div>
          <Button className="btn-sm btn-main px-4" disabled={submiting} type="submit">
            Shift Parties {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
        </Form>
      </div>
    </div>
  );
}

export default ShiftParties;
