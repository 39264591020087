import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup, Input, Label, Table, Spinner, FormText } from "reactstrap";
import style from "./style.module.css";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import { api } from "../../../../Helper/Data";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { useSelector } from "react-redux";
import moment from "moment";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import SelectJS from "../../../../components/Select/SelectJS";
import { alcUserValidate, formValidation } from "../../../../components/Utils/Utils";
import { getCookie } from "../../../../Helper/Cokkies";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
const axios = require("axios").default;

function NewParty() {
  const countries = useSelector((state) => state.CountriesList.countries);
  const candidatesStore = useSelector((state) => state.CanditatesList.data);
  const tl = useSelector((state) => state.CanditatesList.tlData);
  const [submiting, setSubmiting] = useState(false)
  const [isAssign, setIsAssign] = useState(false)
  const [candidateList, setCandidateList] = useState([]);
  const user = getLocalStorage("alcUser");
  const [dupData, setDupData] = useState({});
  const if_admin_hr = alcUserValidate(['admin', "HR"])
  let token = getCookie("alcToken");
  const [formData, setFormData] = useState({
    firstName: "", country: "India", contact: "", lastName: "", altContact: "", email: "", company: "", remarks: "", status: "Fresh", assignTo: user.id, createdBy: user, companyAddress: "", hotParty: false
  });

  useEffect(() => {
    if (candidatesStore.length && tl.length) {
      const candidates = candidatesStore.map((el) => el.candidate)
      const listData = candidates.concat(tl)
      setCandidateList(listData.map((el) => {
        return { value: el._id, label: el.fullname + " ( " + el.username + " )" }
      }))
    }
  }, [candidatesStore, tl])


  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const getSelected = (select) => {
    setFormData({ ...formData, country: select.value });
  };

  const chandidateSelected = (select) => {
    setIsAssign(true)
    setFormData({ ...formData, assignTo: select.value });
  };

  const runFuct = () => {
    return if_admin_hr && isAssign ? true : !if_admin_hr && !isAssign ? true : false
  }

  const submitHandler = (e) => {
    e.preventDefault();

    setSubmiting(true)
    setDupData({})
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (formValidation([formData.firstName, formData.email, formData.contact, formData.company, formData.companyAddress, runFuct()])) {
      if (filter.test(formData.email)) {
        axios
          .post(api.url + "/new-party", formData, { headers: { authorization: "Bearer " + token } })
          .then(function (response) {
            if (response.data.duplicate) {
              alert(response.data.message);
              setDupData(response.data.data[0]);
              setSubmiting(false)
            } else if (response.data.partyExceed) {
              alert(response.data.message);
              setSubmiting(false)
            } else {
              alert("New Party have been Successfully Add");
              setFormData({ firstName: "", country: "India", contact: "", lastName: "", altContact: "", email: "", status: "Fresh", assignTo: formData.assignTo, company: "", remarks: "", createdBy: user, companyAddress: "", });
              setSubmiting(false)
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        setSubmiting(false)
        alert("Please enter valid email / contact");
      }
    } else {
      setSubmiting(false)
      alert("Please fill the required fields");
    }
  };

  const assignNow = (id) => {
    axios
      .post(api.url + "/assign-request", { party: id, candidate: user.id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Successfull");
        } else {
          alert(response.data.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      <AnnounceMarquee />
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add New Party</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <Form onSubmit={submitHandler}>
          <div className="form-row">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="firstName">
                First Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="firstName"
                name="firstName"
                maxLength="10"
                value={formData.firstName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter First Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="lastName">
                Last Name
              </Label>
              <Input
                bsSize="sm"
                id="lastName"
                name="lastName"
                maxLength="10"
                value={formData.lastName}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Last Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="contact">
                Contact No.{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Contact No."}
                title="10 digits number allowed"
                type="number"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Email{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="email"
                name="email"
                value={formData.email}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Email"}
                type="email"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="company">
                Company Name{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Company Name"}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="altContact">
                Alternate No.{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="altContact"
                name="altContact"
                value={formData.altContact}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Alternate No."}
                type="text"
              />
              <FormText color="info">
                Note :- No. can be multiple & separate with comma ( " , " )
              </FormText>
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="remarks">
                Remarks/Comment{" "}
                <small>
                  <sup className="text-danger"></sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="remarks"
                name="remarks"
                value={formData.remarks}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter remarks/comment..."}
                type="text"
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="country">
                Country{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <SelectJS sm={"sm"} data={countries} defValue={formData.country} selected={getSelected} />
            </FormGroup>
            {if_admin_hr ?
              <FormGroup className="col-md-6">
                <Label className={style.formLabel} for="assignTo">
                  Assigned To{" "}
                  <small>
                    <sup className="text-danger">(Required)</sup>
                  </small>
                </Label>
                <SelectJS sm={"sm"} selected={chandidateSelected} data={candidateList} />
              </FormGroup> : ""}

            {if_admin_hr ?
              <FormGroup className="col-md-6">
                <FormControl>
                  <Label className={style.formLabel + " m-0"} for="companyAddress">
                    Hot Party
                  </Label>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={formData.hotParty}
                    onChange={(e) => setFormData({ ...formData, hotParty: e.target.value == "true" })}
                  >
                    <FormControlLabel value={false} control={<Radio />} label={"No"} />
                    <FormControlLabel value={true} control={<Radio />} label="Yes" />
                  </RadioGroup>
                </FormControl>
              </FormGroup> : ""}

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Company Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={"Enter company address"}
                type="textarea"
              />
            </FormGroup>
          </div>
          <Button className="btn-sm btn-main px-4" disabled={submiting} type="submit">
            Add Party {submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
        </Form>

        {dupData.email ? (
          <>
            <h4 className="text-danger my-2">
              <strong>This company already exists :</strong>
            </h4>
            <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
              <tbody>
                <tr>
                  <th scope="row">Assign To</th>
                  <td>
                    <strong className="text-danger">{dupData.assignTo.fullname} {dupData.status}</strong>
                  </td>
                  <th scope="row">Assign At</th>
                  <td>
                    <strong className="text-danger">{moment(dupData.assignAt).format("DD/MM/YYYY")}</strong>
                  </td>
                </tr>
                <tr>
                  <th scope="row">Company</th>
                  <td>
                    <strong>{dupData.company}</strong>
                  </td>
                  <th scope="row">Request to</th>
                  <td>
                    <Button color="info" onClick={() => assignNow(dupData._id)} className="py-0 btn-icon" outline>
                      <i class="far fa-hand-point-right"></i> Add
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default NewParty;
