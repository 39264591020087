import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import moment from "moment";
import { Link } from 'react-router-dom'
import { getCookie } from "../../../../Helper/Cokkies";
import { getLocalStorage } from "../../../../Helper/LocalStorage";
import { IconButton, Tooltip } from "@mui/material";
import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
const axios = require("axios").default;

function PartyStatus() {
  const [pending, setPending] = useState(true);
  const [filterItems, setFilterItems] = useState([]);
  let token = getCookie("alcToken");
  const user = getLocalStorage("alcUser");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortField: "createdAt", sortDirection: "desc" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
    },
    {
      name: <strong>Date</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      width: "120px",
    },
    {
      name: <strong>ACTION</strong>,
      selector: (row) => (<>
        <Tooltip title="Edit">
          <Link to={"/allocation/update-party-status/" + row._id}>
            <IconButton>
              <EditIcon fontSize="small" />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Show Status">
          <Link to={"/allocation/party-details/"+ row._id}>
            <IconButton>
              <LaunchIcon fontSize="small" color="success" />
            </IconButton>
          </Link>
        </Tooltip>
      </>
      ),
    },
  ];


  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-materialize-for-status", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        // console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token, user.id, isSearch, tableOptions]);

  const updateSelectedRow =(e)=>{ }

  return (
    <div className="container">
      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default PartyStatus;
