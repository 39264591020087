import React, { useEffect, useState } from 'react'
import { FormGroup, Input, Spinner, Button } from 'reactstrap';
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
const axios = require("axios").default;

export default function AddPerformer() {
    const [formData, setFormData] = useState({ score: "", candidate: "" });
    const [submiting, setSubmiting] = useState(false)
    const [filterList, setFilterList] = useState([]);
    let token = getCookie("alcToken");

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {
        axios
            .post(api.url + ("/get-performers"), { id: "formData" })
            .then(function (response) {
                // console.log(response.data);
                response.data.performers.forEach((item, index) => {
                    item.serial = index + 1;
                });
                setFilterList(response.data.performers);
                setSubmiting(false)
            })
            .catch(function (error) {
                console.log(error);
                setSubmiting(false)
            });
    }, [])



    const submitHandle = (e) => {
        setSubmiting(true)
        if (formData.score && formData.candidate) {
            if (formData.candidate) {
                axios
                    .post(api.url + ("/add-performer"), formData, { headers: { authorization: "Bearer " + token } })
                    .then(function (response) {
                        // console.log(response);
                        if (response.data.success) {
                            alert("Add Successfully")
                            setFormData({ score: "", candidate: "" })
                            setSubmiting(false)
                        } else {
                            alert("Only 3 candidate can add")
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        setSubmiting(false)
                    });
            } else {
                alert("Site url is not validate")
            }
        } else {
            alert("Please fill all fields")
            setSubmiting(false)
        }
    }

    const deletePerformer = (id) => {
        try {
            axios
                .post(api.url + "/delete-performer", { id }, { headers: { authorization: "Bearer " + token } })
                .then(function (response) {
                    console.log(response);
                    if (response.data.success) {
                        alert("Delete Successfull");
                        setFilterList(filterList.filter((el) => {
                            return id !== el._id
                        }));
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                    alert(error.response.data.message);
                });
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Performers</h4>
                    <hr className="my-2" />
                </div>
            </div>
            <div className="row align-items-center">
                <FormGroup className="col-md-5">
                    <Input
                        bsSize="sm"
                        id="candidate"
                        name="candidate"
                        value={formData.candidate}
                        onChange={(e) => changeHandler(e)}
                        placeholder={"Candidate"}
                        type="text"
                    />
                </FormGroup>
                <FormGroup className="col-md-5">
                    <Input
                        bsSize="sm"
                        id="score"
                        name="score"
                        value={formData.score}
                        onChange={(e) => changeHandler(e)}
                        placeholder={"Score"}
                        type="text"
                    />
                </FormGroup>
                <FormGroup className="col-md-2">
                    <Button className="btn-sm btn-main px-4" onClick={(e) => { submitHandle(e) }} disabled={submiting} type="submit">
                        Add Performer{submiting ? <Spinner color="light" size="sm" /> : ""}</Button>
                </FormGroup>
            </div>
            <div className="container px-5">
                <table class="table table-bordered table-sm">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Score</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filterList.length ? filterList.map((el, i) => {
                            return <tr>
                                <th scope="row">{i + 1}</th>
                                <td>{el.candidate}</td>
                                <td>{el.score}</td>
                                <td><button className='btn btn-sm py-0' onClick={(e) => deletePerformer(el._id)}>Delete</button></td>
                            </tr>
                        }) : ""}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
