import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DeleteIcon from "@mui/icons-material/Delete";
import { useSelector } from "react-redux";
import { IconButton, Tooltip } from "@mui/material";
import TelegramIcon from '@mui/icons-material/Telegram';
import moment from "moment";
import DataTableComponent from '../../../../components/DataTableComponent/DataTableComponent'
import EditIcon from "@mui/icons-material/Edit";
import style from "./style.module.css"
import { getCookie } from "Helper/Cokkies";
import { Link } from "react-router-dom";
const axios = require("axios").default;

function BlogList() {
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [pending, setPending] = useState(true);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "blogDate" });
  const token = getCookie("token");
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);

  useEffect(() => {
    if (currentCompanyUser.user || isSearch) {
      setPending(true)
      axios
        .post(api.url + "/get-blogs-for-admin", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
        .then(function (response) {
          // console.log(response.data);
          response.data.blogs.forEach((item, index) => {
            item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
          });
          setListData(response.data.blogs);
          setFilterItems(response.data.blogs);
          setTotalRows(response.data.total);
          setIsSearch(false)
          setPending(false)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [tableOptions, currentCompanyUser, isSearch, filterValue]);

  const deleteItem = (data) => {
    axios
      .post(api.url + "/delete-blog", { data }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== data._id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const publishHide = (data) => {
    axios
      .post(api.url + "/publish-action", { data }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response);
        if (response.data.success) {
          const newListData = listData.map((obj)=>{
            return data._id === obj._id ? {...obj, isPublish : !obj.isPublish} : obj 
          })
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortField: "blogDate",
      width: "65px",
    },
    {
      name: <strong>Title</strong>,
      selector: (row) => row.blogTitle,
      sortable: true,
      sortField: "blogTitle",
      width: "350px",
    },
    {
      name: <strong>IMAGE</strong>,
      selector: (row) => (
        <div >
          <img
            style={{ height: "50px" }}
            src={api.blogImg + (row.blogImages[0])}
            alt={row.blogTitle}
          />
        </div>
      ),
      width: "100px",
      center: true,
    },
    {
      name: <strong>Published</strong>,
      selector: (row) => (
        <div className={row.isPublish ? "badge badge-success" : "badge badge-danger"} >
          {row.isPublish ? "Yes" : "No"}
        </div>
      ),
      width: "120px",
      center: true,
    },
    {
      name: <strong>Post On</strong>,
      selector: (row) => moment(row.blogDate).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "blogDate"
    },
    {
      name: <strong>Action</strong>,
      cell: (row) => (
        <>
          <IconButton onClick={() => publishHide(row)}>
            <Tooltip title={row.isPublish ? "Hide" : "Publish"}>
              <TelegramIcon fontSize="small" color={row.isPublish ? "error" : "success"} />
            </Tooltip>
          </IconButton>
          <Link to={"/admin/update-blog/" + row.blogLink} state={row}>
            <Tooltip title="Edit">
              <IconButton>
                <EditIcon fontSize="small" color="info" />
              </IconButton>
            </Tooltip>
          </Link>
          <IconButton onClick={() => deleteItem(row)}>
            <Tooltip title="Delete">
              <DeleteIcon fontSize="small" color="error" />
            </Tooltip>
          </IconButton>
        </>
      ),
      width: "200px",
    },
  ];

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder" }}>
        <ul>
          <li>
            <u>Blog Title</u> : {data.blogTitle}
          </li>
          <li>
            <u>Blog Content</u> : <p className={style.blogContent} dangerouslySetInnerHTML={{ __html: data.blogDesc }} />
          </li>
        </ul>
      </div>
    );
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Product List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        inputFilterValue={filterValue}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        setRows={updateSelectedRow}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default BlogList;
