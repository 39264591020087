import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from './toolbar'
import 'react-quill/dist/quill.snow.css';
import style from './style.module.css'
function SunEditor(props) {
  const [value, setValue] = useState(props.quillValue)
  useEffect(() => {
    props.editor(value)
  }, [value])
  
  return (
    <>
      <EditorToolbar />
      <ReactQuill theme="snow"
        placeholder={props.placeholderValue} style={{height: props.height}} className={style.editorDiv} value={value} modules={modules} formats={formats} onChange={setValue} />
    </>
  );
}

export default SunEditor;
