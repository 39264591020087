import React from "react";
import Marquee from "react-marquee-master";
import { ListGroup, ListGroupItem } from "reactstrap";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import moment from "moment";

export default function Marqueecomponent(props) {
  const marqueeItem = (el, i) => {
    return (
      <p key={i}>
        <Link state={el} to={"/importer-details/" + el._id }>
          <ListGroupItem action href="#" tag="a" className="py-1 px-2">
            <div className="row font-main align-items-center" style={{borderRadius:"0.5rem"}}>
              <div className="col-3" style={{ overflow:"hidden" , textOverflow:"ellipsis"}}>
                 <span style={{whiteSpace: "nowrap"}}><img style={{ maxHeight: "15px", width:"20px" }} src={el.countryFlag} alt={el.country} /> {el.country}</span>
              </div>{" "}
              | <div className="col-6" style={{ overflow:"hidden" , textOverflow:"ellipsis"}}><span style={{whiteSpace: "nowrap"}}>{el.leadTitle}</span></div> | 
              <div className="col-2">{moment(el.createdAt).format("DD/MM/YYYY")}</div>
            </div>
          </ListGroupItem>
        </Link>
      </p>
    );
  };

  return (
    <div>
      {" "}
      <ListGroup className={style.marqueeDiv}>
        <Marquee
        direction="down"
          height={props.height}
          inverseMarqueeItems={true}
          marqueeItems={props.data.map((el, i) => {
            return marqueeItem(el, i);
          })}
        />
      </ListGroup>
    </div>
  );
}
