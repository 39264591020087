import React, { useRef } from "react";
import Slider from "react-slick";
import style from "../style.module.css";
import { api } from "../../../../../Helper/Data";
import { Button } from "reactstrap";
import { Link } from "@mui/material";

function FeaturedProducts(props) {

  const bannerSlider = useRef();
  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: props.products.length > 3 ? 3 : props.products.length,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1000,
    ref: (slider) => (bannerSlider.current = slider),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        },
      },
    ],
  };

  return (
    <div ref={props.refProp}>
      <h3>
        {" "}
        <strong>FEATURED PRODUCTS</strong> <hr className="m-0 bg-main mb-3" style={{ height: "3px" }} />
      </h3>
      <Slider {...settings}>
        {props.products.map((el, i) => {
          return (
            <div key={i} className={style.featureItem}>
              <Link href={"/product-details"+el.productLink} target={"_blank"} underline={"none"} className="text-decoration-none">
                <div className={"m-0 h-100 " + style.featureContect}>
                  <img style={{ maxHeight: "100%", height:"100%", maxWidth: "100%", margin: "auto" }} src={api.productImg + el.productImages[0]} alt="" />
                  <h5>{el.productName}</h5>
                </div>
              </Link>
            </div>
          );
        })}
      </Slider>
      {props.products.length > 3 ? (
        <div className="row px-2">
          <Button
            className={style.preBtn + " col-6 btn-sm btn-danger"}
            onClick={() => {
              bannerSlider.current.slickPrev();
            }}
          >
            <strong>Prev</strong>
          </Button>
          <Button
            className={style.nextBtn + " col-6 btn-sm btn-success"}
            onClick={() => {
              bannerSlider.current.slickNext();
            }}
          >
            <strong>Next</strong>
          </Button>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default FeaturedProducts;
