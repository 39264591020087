import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import SunEditor from "../../../../components/SunEditor/SunEditor";
import { formValidation } from "../../../../components/Utils/Utils";
import { useNavigate, useLocation } from "react-router-dom";
const axios = require("axios").default;

function UpdateBlog() {
    const location = useLocation();
    const navigate = useNavigate();
    const [formData, setFormData] = useState(location.state);
    const [imgUpdate, setImgUpdate] = useState(false);

    useEffect(() => {
        setFormData({
            ...formData,
            preImage: formData.blogImages
        });
    }, [location])

    const [uploadFiles, setUploadFiles] = useState([]);
    let token = getCookie("token");

    const changeHandler = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const editorText = (text) => {
        setFormData({ ...formData, blogDesc: text });
    };

    const uploadLogoImg = (e) => {
        const file = e.target.files[0]
        let postPhoto = [];
        var reader = new FileReader();
        const form = new FormData();
        if (e.target.files && file) {
            postPhoto.push(file.name);
            form.append("blogFile", file, file.name);
            setUploadFiles(form);
            reader.readAsDataURL(file);
            if (file.size > 200000) {
                alert("Size sould less then 200 kb ");
                setFormData({ ...formData, blogImages: [] });
                setImgUpdate(false)
                e.target.value = "";
            } else {
                if (postPhoto.length) {
                    setImgUpdate(true)
                    setFormData({ ...formData, blogImages: postPhoto });
                }
            }
        } else {
            setImgUpdate(false)
            setFormData({ ...formData, blogImages: formData.blogImages });
        }
    };

    const submitHandler = async () => {
        if (formValidation([formData.blogTitle, formData.blogDesc, formData.altTag, formData.titleTag]) && formData.blogImages.length) {
            if (imgUpdate) {
                axios
                    .post(api.url + "/upload-blogImage", uploadFiles)
                    .then(function (response) {
                        if (response.data.success) {
                            updateBlog(formData, response.data.newFileName);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            } else {
                updateBlog(formData, formData.blogImages[0])
            }
        } else {
            alert("Please fill the required fields");
        }
    };

    const updateBlog = (data, newName) => {
        let newData = data;
        newData = { ...newData, blogImages: [newName] };
        try {
            axios
                .post(api.url + "/update-blog", newData, { headers: { authorization: "Bearer " + token } })
                .then(function (response) {
                    if (response.data.success) {
                        alert("Add Successfull");
                        setImgUpdate(false)
                        navigate("/admin/blogs-list")
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div>
            <div className="content-header p-0">
                <div className="container-fluid">
                    <h4 className="m-0 text-dark">Add Blog</h4>
                    <hr className="my-2" />
                </div>
            </div>
            <div className="container px-4">
                {/* Add Categories */}
                <div>
                    <div className="form-row mt-4">
                        <img width={150} src={api.blogImg + formData.blogImages[0]} alt="" />
                        <FormGroup className="col-md-12">
                            <Label className={style.formLabel} for="image">
                                Blog Image  <small>
                                    <sup className="text-danger"> (Required)</sup>
                                </small>
                            </Label>
                            <Input bsSize="sm" id="image" className={style.fileInput} name="image" accept="image/*" onChange={(e) => uploadLogoImg(e)} type="file" />
                        </FormGroup>

                        <FormGroup className="col-md-12">
                            <Label className={style.formLabel} for="email">
                                Blog Name{" "}
                                <small>
                                    <sup className="text-danger"> (Required)</sup>
                                </small>
                            </Label>
                            <Input bsSize="sm" id="blogTitle" name="blogTitle" value={formData.blogTitle} onChange={(e) => changeHandler(e)} placeholder={"Enter blog title..."} type="text" />
                        </FormGroup>

                        <FormGroup className="col-md-6">
                            <Label className={style.formLabel} for="email">
                                Alt Tag{" "}
                                <small>
                                    <sup className="text-danger"> (Required)</sup>
                                </small>
                            </Label>
                            <Input bsSize="sm" id="altTag" name="altTag" value={formData.altTag} onChange={(e) => changeHandler(e)} placeholder={"Enter alt tag..."} type="text" />
                        </FormGroup>

                        <FormGroup className="col-md-6">
                            <Label className={style.formLabel} for="email">
                                Title Tag{" "}
                                <small>
                                    <sup className="text-danger"> (Required)</sup>
                                </small>
                            </Label>
                            <Input bsSize="sm" id="titleTag" name="titleTag" value={formData.titleTag} onChange={(e) => changeHandler(e)} placeholder={"Enter tag title..."} type="text" />
                        </FormGroup>

                        <FormGroup className="col-md-12">
                            <Label className={style.formLabel} for="companyAddress"> Blog Description{" "}  <small>
                                <sup className="text-danger"> (Required)</sup>
                            </small>
                            </Label>
                            <SunEditor height={"250px"} quillValue={formData.blogDesc} placeholderValue={'Enter blog description...'} editor={editorText} />
                        </FormGroup>
                    </div>
                    <Button type="submit" onClick={submitHandler} className="btn-sm btn-main px-4">
                        Update Blog
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default UpdateBlog;

