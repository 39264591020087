import React from 'react'
import { Outlet } from "react-router-dom";
import CopyRight from "../../Public/Components/Footer/CopyRight/CopyRight";
import QuickCallback from "../../Public/Components/QuickCallBack/QuickCallback";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import Footer from '../../Public/Components/Footer/FooterLinks/FooterLinks';
import Header from '../Components/Header';

export default function BlogLayout() {
  return (
    <>
    <ScrollToTop />
    <QuickCallback></QuickCallback>
    <Header/>
    <Outlet></Outlet>
    <Footer/>
    <CopyRight></CopyRight>
  </>
  )
}
