import React from "react";
import { Link } from "react-router-dom";
import { api } from "../../../../../Helper/Data";
import style from '../style.module.css'
import { UncontrolledCollapse, NavbarBrand, Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";

function Example(props) {
  const [bodyClick, setBodyClick] = React.useState(false);
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
          }}
        />
      ) : null}
      <Navbar color="main" className="fixed-top" style={{height:"65px",}} expand="lg">
        <Container>
          <NavbarBrand href="#" className={style.navbarIcon} onClick={(e) => e.preventDefault()}>
            {props.company.companyLogo === "noLogo.png" || !props.company.companyLogo?  props.company.company:<img src={api.companyUrl + props.company.companyLogo} alt="" />}
          </NavbarBrand>
          <button
            className="navbar-toggler"
            id="navbarTogglerDemo02"
            type="button"
            onClick={() => {
              document.documentElement.classList.toggle("nav-open");
              setBodyClick(true);
            }}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
          <UncontrolledCollapse navbar toggler="#navbarTogglerDemo02">
            <Nav className="mr-auto mt-2 mt-lg-0" navbar>
              <NavItem>
                <NavLink href="#" onClick={(e) =>{ props.scroll(e)}}>
                  Products
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="#" onClick={(e) =>{ props.contactscroll(e)}}>
                  Contact Us
                </NavLink>
              </NavItem>
            </Nav>
            <Link to="/" className={"btn-link ml-auto text-right "+style.wwtIcon}>
              <p>
                <small>Go To Home</small>
              </p>
              <img src={api.logosUrl + "logo.png"} style={{ height: "30px" }} alt="" />
            </Link>
          </UncontrolledCollapse>
        </Container>
      </Navbar>
    </>
  );
}

export default Example;
