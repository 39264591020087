import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import DeleteIcon from '@mui/icons-material/Delete';
import style from './style.module.css'
import { Input } from "reactstrap";
import { IconButton } from "@mui/material";
import { getCookie } from "Helper/Cokkies";
import moment from "moment";
const axios = require("axios").default;

function UserRequirements() {
  const token = getCookie("token")
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
      axios
        .post(api.url + "/user-requirements",{id:"id"},{ headers: { Authorization: "Bearer " + token } } )
        .then(function (response) {
          console.log(response.data);
          response.data.requirements.forEach((item, index) => { item.serial = index + 1; });
          setListData(response.data.requirements);
          setFilterItems(response.data.requirements);
          setPending(false)
        })
        .catch(function (error) {
          console.log(error);
        });
  }, [token]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width:"65px",
    },
    {
      name: <strong>NAME</strong>,
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: <strong>CONTACT</strong>,
      selector: (row) => row.contact,
      sortable: true,
      width:"130px",
    },
    {
      name: <strong>TYPE</strong>,
      selector: (row) => row.postBy,
      sortable: true,
      width:"100px",
    },
    // {
    //   name: <strong>CONNECTED</strong>,
    //   selector: (row) => row.connected === undefined || !row.connected ? <i className="fas fa-times"></i>: <i className="fas fa-check"></i>,
    //   sortable: true,
    //   width:"110px",
    // },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.postedAt).format("DD/MM/YYYY"),
      sortable: true,
      width:"120px"
    },
    {
      selector: (row) => <IconButton className={style.actionBtn} onClick={(e)=> deleteItem(row._id)} color="error" size="small">
        <DeleteIcon fontSize="small" />
      </IconButton>,
      button: true,
      width: "65px",
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-user-req", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id;
          });
          setListData(newListData);
          setFilterItems(newListData);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => (item.keyWords && item.keyWords.toLowerCase().includes(e.target.value.toLowerCase())) || (item.company.company && item.company.company.toLowerCase().includes(e.target.value.toLowerCase())) );
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const ExpandedComponent = ({ data }) => {
    return   <div style={{width:"100%", fontWeight:"bolder", padding:"5px"}}>
    <ul>
      <li> <u>Email</u> : {data.email}</li>
      <li> <u>Message</u> : {data.requirement}</li>
      <li><u>Country</u> : {data.country}</li>
      <li><u>Company</u> : {data.companyName}</li>
      </ul>
    </div>;
  }

  const expandItem=()=>{
    return <h3 className="text-danger m-1 ml-2"> 
    <i className="fas fa-caret-square-up"></i>
  </h3>
  }
  const collapseItem=()=>{
    return <h3 className="text-primary m-1 ml-2"> 
      <i className="fas fa-caret-square-down"></i>
    </h3>
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">User's Requirements</h4>
          <hr className="my-2" />
        </div>
      </div>

      <Input id="search" name="search" className="border-primary" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      <DataTable pagination persistTableHead highlightOnHover columns={columns} data={filterItems} progressPending={pending} expandableRows expandableRowsComponent={ExpandedComponent} expandableIcon={{collapsed: collapseItem(), expanded: expandItem()}}/>
    </div>
  );
}

export default UserRequirements;
