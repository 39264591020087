import React, { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Notfound from "../../../components/404/Notfound";
import AdminLayoutMain from "./AdminLayoutMain";
import Login from "../Views/Login/Login";
import Dashboard from "../../Admin/Views/Dashboard/Dashboard";
import { AddOperator, OperatorList } from "../Views/Operator/index";
import { Useradd, UpdateUser, Userlist } from "../Views/User/index";
import { AddVcertificate } from "../Views/Vcertificate/index";
import { CompanyDetails, UserDetails } from "../Views/ManageDetails/index";
import { AddSubCategory, AddCategory } from "../Views/Categories/index";
import { BlogList, UpdateBlog, AddBlog } from "../Views/Blog/index";
import { AddLeads, LeadsList, PendingLeads, PurchasedLeads, UpdateLead } from "../Views/Leads/index";
import { AddGalleryImages, GallaryPage } from "../Views/Gallery/index";
import { BannersList, Banners } from "../Views/Banners/index";
import { ProductQueries, UserRequirements, CallBackRequests } from "../Views/QueriesRequests/index";
import { AddTestimonial, TestimonialList } from "../Views/Testimonial/index";
import { UpdateProduct, AddProduct, ProductList, TrendingProduct } from "../Views/Products/index"
import ChangePassword from '../../../components/ChangePassword/ChangePassword'
import "./style.css";
import { userValidate } from '../../../components/Utils/Utils'
import ContactUs from "../Views/Contactus/ContactUs";
import Logout from "../Views/Login/Logout";
import { mainReduxFunction } from "../../../components/Utils/mainRedux";
import { useDispatch } from "react-redux";
import InvoiceGenertor from "../Views/Invoice/InvoiceGenertor";
import InvoiceList from "../Views/Invoice/InvoiceList";

export default function AdminLayout() {
  const disPatch = useDispatch();
  const admin_verify = userValidate(['admin'], 'token')
  const admin_hr_verify = userValidate(['admin', 'hr'], 'token')
  useEffect(() => {
    mainReduxFunction(disPatch)
  }, [disPatch])

  return (
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/logout" element={<Logout />}></Route>
      <Route path="/" element={<AdminLayoutMain />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />}></Route>
        <Route path="contact-us" element={<ContactUs />}></Route>

        {/* Manage */}
        <Route path="user-details" element={<UserDetails />}></Route>
        <Route path="company-details" element={<CompanyDetails />}></Route>

        {/* Operators */}
        <Route path="operator-list" element={admin_verify ? <OperatorList /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="add-operator" element={admin_verify ? <AddOperator /> : <Navigate replace to="/admin/logout" />}
        ></Route>

        {/* Invoice */}
        <Route path="generate-invoice" element={admin_verify ? <InvoiceGenertor /> : <Navigate replace to="/admin/logout" />}
        ></Route>
        <Route path="invoice-list" element={admin_verify ? <InvoiceList /> : <Navigate replace to="/admin/logout" />}
        ></Route>

        {/* Users */}
        <Route path="view-list" element={admin_hr_verify ? <Userlist /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="user-add" element={admin_hr_verify ? <Useradd /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="update-company/:id" element={<UpdateUser />}></Route>
        <Route path="change-user-password" element={<ChangePassword />}></Route>
        
        {/* Trust / V-Certificates */}
        <Route path="add-vcertificate" element={<AddVcertificate />}></Route>
        <Route path="vcertificates-list" element={<AddVcertificate />}></Route>

        {/* Categories */}
        <Route path="add-category" element={admin_verify ? <AddCategory /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="add-sub-category" element={admin_verify ? <AddSubCategory /> : <Navigate replace to="/admin/logout" />}></Route>

        {/* Categories */}
        <Route path="add-blog" element={admin_verify ? <AddBlog /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="blogs-list" element={admin_verify ? <BlogList /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="update-blog/:blog" element={<UpdateBlog />}></Route>

        {/* Products */}
        <Route path="add-product" element={<AddProduct />}></Route>
        <Route path="trending-product" element={admin_hr_verify ? <TrendingProduct /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="products-list" element={<ProductList />}></Route>
        <Route path="update-product/:id" element={<UpdateProduct />}></Route>

        {/* buyers Lead */}
        <Route path="add-lead" element={admin_hr_verify ? <AddLeads /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="pending-lead" element={admin_hr_verify ? <PendingLeads /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="leads-list" element={<LeadsList />}></Route>
        <Route path="leads-for-you" element={<LeadsList />}></Route>
        <Route path="purchased-lead" element={<PurchasedLeads />}></Route>
        <Route path="update-lead/:id" element={admin_hr_verify ? <UpdateLead /> : <Navigate replace to="/admin/logout" />}></Route>

        {/* Banners */}
        <Route path="banner-list" element={<BannersList />}></Route>
        <Route path="add-banner" element={<Banners />}></Route>

        {/* Testimonial */}
        <Route path="testimonial-list" element={admin_verify ? <TestimonialList /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="add-testimonial" element={admin_verify ? <AddTestimonial /> : <Navigate replace to="/admin/logout" />}></Route>

        {/* QueriesRequests */}
        <Route path="product-queries" element={admin_verify ? <ProductQueries /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="call-back-requests" element={admin_verify ? <CallBackRequests /> : <Navigate replace to="/admin/logout" />}></Route>
        <Route path="user-requirements" element={admin_verify ? <UserRequirements /> : <Navigate replace to="/admin/logout" />}></Route>

        {/* Gallery */}
        <Route path="add-gallery-image" element={<AddGalleryImages />}></Route>
        <Route path="product-gallery" element={<GallaryPage />}></Route>
      </Route>
      <Route path="*" element={<Notfound />} />
    </Routes>
  );
}
