import { Container } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { api } from '../../../Helper/Data';
import BlogsItem from './BlogsItem';

export default function Blogs() {
  const [blogsList, setBlogsList] = useState([])
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "blogDate" });


  useEffect(() => {
    axios
      .post(api.url + "/get-blogs", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage })
      .then(function (response) {
        console.log(response.data);
        if (response.data.success) {
          setBlogsList(response.data.blogs)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [tableOptions])

  return (
    <div>
      <Container >
        <h3 className="px-4">Blogs</h3>
            <div className="row p-4">
              {blogsList.length ? blogsList.map((el, i) => {
                return <div key={i} className="col-sm-6">
                  <BlogsItem data={el} />
                </div>
              }) : "Loading..."}
            </div>
      </Container>
    </div>
  )
}
