import { Grid } from "@mui/material";
import React from "react";
import style from "../style.module.css";
import RequirementForm from '../../../Components/RequirementForm/RequirementForm'
import ModalComponent from '../../../../../components/Modal/ModalComponent'
import { useSelector } from "react-redux";

function DetailsTemplate(props) {
  const countries = useSelector((state) => state.CountriesList.countries);
  return (
    <div className="my-3" id="detailsTemp">
      <h3>
        <strong>COMPANY PROFILE</strong> <hr className="m-0 bg-main mb-3" style={{ height: "3px" }} />
      </h3>
      <p style={{ fontSize: "large" }}>
        <strong>{props.details.aboutCompany}</strong>
      </p>
      <div ref={props.refProp}>
        <Grid container spacing={3} className={style.list}>
          <Grid item xs={6} md={3}>
            <div className={style.listItem}>
              <h1>
                <i className="fas fa-user-tie text-success"></i>
              </h1>
              <h5>CEO</h5>
              <h6>{props.details.companyCEO}</h6>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={style.listItem}>
              <h1>
                <i className="fas fa-history text-warning"></i>
              </h1>
              <h5>Establishment Year</h5>
              <span className={"m-0 " + style.blurItem}>
              <ModalComponent
                btnLink={true}
                btn={"XXXX"}
                form={<RequirementForm data={countries} small={"sm"} />}
                size={""}
                heading={"Form for Your Requirement "}
                />
                </span>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={style.listItem}>
              <h1>
                <i className="fas fa-industry text-info"></i>
              </h1>
              <h5>Business Type</h5>
              <h6>{props.details.businessType}</h6>
            </div>
          </Grid>
          <Grid item xs={6} md={3}>
            <div className={style.listItem}>
              <h1>
                <img style={{ height: "50px", padding: "5px" }} src={props.details.countryFlag} alt="" />
              </h1>
              <h5>Country</h5>
              <h6>{props.details.country}</h6>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default DetailsTemplate;
