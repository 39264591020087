import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Spinner } from "reactstrap";
import { api } from "Helper/Data";
import SelectJS from "../../../../components/Select/SelectJS";
import {formValidation, SweetAlert} from "../../../../components/Utils/Utils"
import style from "./style.module.css";
import { useSelector } from "react-redux";
const axios = require("axios").default;

export default function CallBackForm(props) {
  const countries = useSelector((state) => state.CountriesList.countries);
  const [callBackForm, setCallBackForm] = useState({ name:"", country:"India",contact:"",altContact:"", subject:"", email:"", message:""})
  const [submiting, setSubmiting] = useState(false)
  const getSelected = (select) => {
    setCallBackForm({ ...callBackForm, country: select.value });
  };

  const submitHandler=(e)=>{
    e.preventDefault();
    setSubmiting(true)
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var tel = /^[0-9]{10}$/;
    if(formValidation([callBackForm.name, callBackForm.country, callBackForm.contact, callBackForm.subject, callBackForm.email, callBackForm.message])){
      if (filter.test(callBackForm.email) && callBackForm.contact.match(tel)) {
        axios.post(api.url+'/call-back-req',callBackForm)
        .then(function (response) {
          // console.log(response);
          if (response.data.callBackReq) {
            SweetAlert({
              title: "We will call back ASAP",
              icon: 'success',
            })
            setSubmiting(false)
            setCallBackForm({ name:"", country:"India",contact:"",altContact:"", subject:"", email:"", message:""})
            props.close()
          }
        })
        .catch(function (error) {
          console.log(error);
          SweetAlert({
            title: "Please contact admin",
            icon: 'error',
          })
          setSubmiting(false)
        });
    } else{
      SweetAlert({
        title: "Please enter valid email / contact",
        icon: 'error',
      })
      setSubmiting(false)
      }
    } else{
      SweetAlert({
        title: "Please fill the required fields",
        icon: 'error',
      })
      setSubmiting(false)
    }
  }
  
  const changeHandler=(e)=>{
    setCallBackForm({...callBackForm, [e.target.name]: e.target.value})
  }
  return (
    <>
      <div className="px-2 py-3">
        <form onSubmit={submitHandler}>
          <FormGroup>
            <Label className={style.formLabel} for="name">
              Full Name <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
            </Label>
            <Input bsSize="sm" type="name" id="name" name="name" onChange={(e)=>changeHandler(e)} placeholder="Enter name" />
          </FormGroup>
          <div className="form-row">
            <Col>
            <FormGroup>
            <Label className={style.formLabel} for="country">
              Country <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
            </Label> 
            <SelectJS sm={"sm"} data={countries} selected={getSelected} defValue={callBackForm.country}/>
          </FormGroup>
            </Col>
            <Col>
            <FormGroup>
                <Label className={style.formLabel} for="email">
                  Email Address <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
                </Label>
                <Input bsSize="sm" type="email" id="email" name="email" onChange={(e)=>changeHandler(e)} placeholder="Enter your email" />
              </FormGroup>
            </Col>
          </div>
          <div className="form-row">
            <Col>
            <FormGroup>
                <Label className={style.formLabel} for="contact">
                  Contact No. <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
                </Label>
                <Input bsSize="sm"
                type="tel" id="contact" name="contact" onChange={(e)=>changeHandler(e)} placeholder="Enter your Contact No." />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label className={style.formLabel} for="altContact">
                  Alternate No.
                </Label>
                <Input bsSize="sm" type="number" id="altContact" onChange={(e)=>changeHandler(e)} name="altContact" placeholder="Enter your Alternate No." />
              </FormGroup>
            </Col>
          </div>
          <FormGroup>
            <Label className={style.formLabel} for="subject">
              Subject <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
            </Label>
            <Input bsSize="sm" type="subject" id="subject" name="subject" onChange={(e)=>changeHandler(e)} placeholder="Enter your Subject" />
          </FormGroup>
          <FormGroup>
            <Label for="message">Message <small>
                  <sup className="text-danger"> (Required)</sup>
                </small></Label>
            <Input id="message" name="message" type="textarea" onChange={(e)=>changeHandler(e)} />
          </FormGroup>
          <Button size="sm" block={true} type="submit" color="main">
            Submit {submiting? <Spinner
                  color="primary"
                  size="sm"
                />:""}
          </Button>
        </form>
      </div>
    </>
  );
}
