import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTable from "react-data-table-component";
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import style from './style.module.css'
import { Input } from "reactstrap";
import FormControlLabel from '@mui/material/FormControlLabel';
import { IconButton } from "@mui/material";
import Switch from '@mui/material/Switch';
import { getCookie } from "../../../../Helper/Cokkies";
import { styled } from '@mui/material/styles';
import moment from "moment";
const axios = require("axios").default;


function TestimonialList() {
  let token = getCookie("token");
  const [pending, setPending] = useState(true);
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  useEffect(() => {
    axios
      .post(api.url + "/get-testimonials", { id: "id" })
      .then(function (response) {
        // console.log(response.data);
        response.data.testimonials.forEach((item, index) => { item.serial = index + 1; });
        setListData(response.data.testimonials);
        setFilterItems(response.data.testimonials);
        setPending(false)
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [filterItems]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
    },
    {
      name: <strong>Name</strong>,
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: <strong>Designation</strong>,
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: <strong>Comapany</strong>,
      selector: (row) => row.companyName,
      sortable: true,
    },
    {
      name: <strong>Published</strong>,
      selector: (row) =><FormControlLabel className="mx-auto" control={<Android12Switch onChange={(e) => publishfunc(e, row._id)} checked={row.published} />} />,
      sortable: true,
    },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px"
    },
    {
      selector: (row) => <IconButton className={style.actionBtn} color="primary" size="small">
        <DragIndicatorIcon fontSize="small" />
      </IconButton>,
      button: true,
      width: "65px",
    },
  ];

  const publishfunc = (e, id) => {
    if (window.confirm(e.target.checked ? "Show testimonial ?" : "Hide testimonial ?")) {
      const obj = listData.map((el) => {
        if (el._id === id) {
          return el = { ...el, published: e.target.checked }
        } else {
          return el
        }
      })

      axios
        .post(api.url + "/publish-testimonial", { id: id, published: e.target.checked }, { headers: { authorization: "Bearer " + token } })
        .then(function (response) {
          if (response.data.success) {
            setFilterItems(obj);
            alert("Success...")
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const changeHandle = (e) => {
    if (e.target.value.length) {
      const filteredList = listData.filter((item) => (item.keyWords && item.keyWords.toLowerCase().includes(e.target.value.toLowerCase())));
      setFilterItems(filteredList);
    } else {
      setFilterItems(listData);
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    '& .MuiSwitch-track': {
      borderRadius: 22 / 2,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main),
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  const ExpandedComponent = ({ data }) => {
    return <div style={{ width: "100%", fontWeight: "bolder" }}>
      <ul>
        <li><u>Company Name</u> : {data.companyName}</li>
        <li> <u>Message</u> : {data.message}</li>
      </ul>
    </div>;
  }

  const expandItem = () => {
    return <h3 className="text-danger m-1 ml-2">
      <i className="fas fa-caret-square-up"></i>
    </h3>
  }
  const collapseItem = () => {
    return <h3 className="text-primary m-1 ml-2">
      <i className="fas fa-caret-square-down"></i>
    </h3>
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Testimonial List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <Input id="search" name="search" className="border-primary" placeholder="Text here for Search..." type="search" onChange={changeHandle} />
      <DataTable pagination persistTableHead highlightOnHover columns={columns} data={filterItems} progressPending={pending} expandableRows expandableRowsComponent={ExpandedComponent} expandableIcon={{ collapsed: collapseItem(), expanded: expandItem() }} />
    </div>
  );
}

export default TestimonialList;
