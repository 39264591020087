import { Button } from "@mui/material";
import React from "react";
import style from "./style.module.css";
import { useNavigate } from "react-router-dom";

export default function PlanSticker() {
  const history = useNavigate();
  const gotToPlans = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    history("/membership-plans");
  };
  return (
    <div className={style.stickerPlans}>
      <Button variant="contained" onClick={gotToPlans}>
        Membership Plans
      </Button>
    </div>
  );
}
