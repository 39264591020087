import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import style from "./style.module.css";
import logo from "./wbi.png";
import moment from "moment";
import { ToWords } from "to-words";

export default function InvoiceFormat(props) {
  const [invoiceItems, setInvoiceItems] = useState([]);
  const toWords = new ToWords();

  useEffect(() => {
    setInvoiceItems(
      props.data.invoiceItems.map((el) => {
        return {
          desc: el.desc,
          igst: (18 / 100) * (el.amount / 1.18),
          calcAmount: el.amount / 1.18,
          netAmount: parseInt(el.amount)
        };
      })
    );
  }, [props.data.invoiceItems]);

  return (
    <div
      style={{
        width: "1050px",
        height: "1500px",
        background: "white",
        margin: "auto",
        position:"absolute",
      }}
    >
      <br />
      <div className={style.header}>
        <Grid container style={{ width: "300px" }}>
          <Grid item xs={12}>
            <h3
              className="m-0"
              style={{ color: "#050E39", fontWeight: "bold" }}
            >
              INVOICE
            </h3>
          </Grid>
          <Grid item xs={6}>
            <p className="m-0">Invoice No</p>
            <p className="m-0">Invoice Date</p>
          </Grid>
          <Grid item xs={6} style={{ textAlign: "right" }}>
            <p className="m-0 text-dark">#{props.data.invoiceNo}</p>
            <p className="m-0 text-dark">
              {moment(props.data.createdAt).format("DD/MM/YYYY")}
            </p>
          </Grid>
        </Grid>
        <div
          style={{
            width: "35%",
            padding: "10px",
            textAlign: "right",
          }}
        >
          <img src={logo} alt="" />
        </div>
      </div>
      <br />
      <div className={style.companyDetails}>
        <div
          style={{
            backgroundColor: "#e7ebff",
            border: "solid 1px #e7ebff",
            color: "black",
            padding: "15px",
            width: "40%",
          }}
        >
          <h5 className="m-0" style={{ color: "#050E39", fontWeight: "bold" }}>
            Billed By
          </h5>
          <p className="m-0">Web Bright Infra Pvt. Ltd</p>
          <p className="m-0">Office No 804, 8th Floor</p>
          <p className="m-0">DDA-2 Building, District Centre,</p>
          <p className="m-0">Janakpuri, Delhi-110058</p>
          <p className="m-0">
            <strong>GSTIN : 07AACCW8999F1ZM</strong>
          </p>
        </div>
        <div
          style={{
            backgroundColor: "#e7ebff",
            border: "solid 1px #e7ebff",
            color: "black",
            padding: "15px",
            width: "40%",
          }}
        >
          <h5 className="m-0" style={{ color: "#050E39", fontWeight: "bold" }}>
            Billed To
          </h5>
          <p className="m-0">{props.data.company}</p>
          <p className="m-0" style={{ width: "350px" }}>
            {props.data.companyAddress}
          </p>
          <p className="m-0">
            <strong>GSTIN : {props.data.gstn}</strong>
          </p>
          <p className="m-0">
            <strong>PAN : {props.data.pan}</strong>
          </p>
        </div>
      </div>
      <br />
      <br />
      <div className={style.tableList}>
        <div className={style.itemHead}>
          <p style={{ width: "5%" }}>SNo.</p>
          <p style={{ width: "45%", textAlign: "left" }}>Description</p>
          <p style={{ width: "8%" }}>GST Rate</p>
          <p style={{ width: "14%" }}>Amount</p>
          <p style={{ width: "14%" }}>IGST</p>
          <p style={{ width: "14%" }}>Total</p>
        </div>
        {invoiceItems.map((el, i) => {
          return (
            <div className={style.itemList}>
              <p style={{ width: "5%" }}>{i + 1}</p>
              <p style={{ width: "45%", textAlign: "left" }}>{el.desc}</p>
              <p style={{ width: "8%" }}>18%</p>
              <p style={{ width: "14%" }}>
                &#8377; {Number(el.calcAmount).toFixed(2)}
              </p>
              <p style={{ width: "14%" }}>
                &#8377; {Number(el.igst).toFixed(2)}
              </p>
              <p
                style={{
                  width: "14%",
                  textAlign: "right",
                  paddingRight: "30px",
                }}
              >
                &#8377; {Number(el.netAmount).toFixed(2)}
              </p>
            </div>
          );
        })}
      </div>
      <br />
      <div className={style.tableBottom}>
        <div>
          <p>
            <strong>Total (in words) : {toWords.convert(Number(invoiceItems.reduce((n, {netAmount}) => n + netAmount, 0)).toFixed(2))} Only</strong>
          </p>
        </div>
        <div>
          <div className={style.tableBottomRight}>
            <div>
              <p>Amount</p>
              <p>IGST</p>
              <p
                style={{
                  fontSize: "20px",
                  borderTop: "solid 1px black",
                  borderBottom: "solid 1px black",
                  padding: "8px 5px",
                }}
              >
                Total (INR)
              </p>
            </div>
            <div style={{ textAlign: "right" }}>
              <p>&#8377; {Number(invoiceItems.reduce((n, {calcAmount}) => n + calcAmount, 0)).toFixed(2)}</p>
              <p>&#8377; {Number(invoiceItems.reduce((n, {igst}) => n + igst, 0)).toFixed(2)}</p>
              <p
                style={{
                  fontSize: "20px",
                  borderTop: "solid 1px black",
                  borderBottom: "solid 1px black",
                  padding: "8px 5px",
                }}
              >
                &#8377; {Number(invoiceItems.reduce((n, {netAmount}) => n + netAmount, 0)).toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </div>
      <br />
      <br />
      <br />
      <div>
        <h6>Terms and Conditions : </h6>
        <ol style={{ paddingRight: "30px", width: "90%", lineHeight: "2" }}>
          <li>
            All payments for services to be made in favor of Web Bright Infra
            Pvt Ltd only. We have not authorized any individual or organization
            to collect payments in any other name (i.e. any other individual or
            organization name) or via personal Western Union or personal PayPal
            Accounts for any services rendered by Web Bright Infra Pvt Ltd. You
            are informed that under no circumstances will Web Bright Infra Pvt
            Ltd be liable for any damage caused in your business transaction to
            such fraudulent individuals or organizations. To protect your
            business interests, please contact us immediately if any such
            fraudulent individual or organization tries to mislead you.
          </li>
          <br />
          <li>
            For any enquiry, reach out via email at{" "}
            <a href="mailto:support@webbrightinfra.com">
              support@webbrightinfra.com
            </a>
            , call on <a href="tel:918377007733">+91-8377007733</a>
          </li>
        </ol>
      </div>
    </div>
  );
}
