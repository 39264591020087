import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import { Table } from "reactstrap";
import EventMarquee from "../../Components/AnnounceMarquee/EventMarquee";
import moment from "moment";
import { Link } from 'react-router-dom'
import { getCookie } from "../../../../Helper/Cokkies";
import AnnounceMarquee from '../../Components/AnnounceMarquee/AnnounceMarquee'
import PartyComments from "../../Components/PartyComments/PartyComments";
const axios = require("axios").default;

function ExpiredParties() {
  const [pending, setPending] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [filterItems, setFilterItems] = useState([]);
  let token = getCookie("alcToken");
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "asc", sortField: "joinDate" });

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      sortable: true,
      width: "65px",
      sortField: "joinDate"
    },
    {
      name: <strong>Company</strong>,
      selector: (row) => row.company,
      sortable: true,
      sortField: "company"
    },
    {
      name: <strong>Contact Person</strong>,
      selector: (row) => <>{row.contactName}</>,
      sortable: true,
      sortField: "contactName",
      width: "250px",
    },
    {
      name: <strong>Contact</strong>,
      selector: (row) => row.contact,
      sortable: true,
      sortField: "contact"
    },
    {
      name: <strong>Join Date</strong>,
      selector: (row) => moment(row.joinDate).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "joinDate"
    },
  ];

  useEffect(() => {
    setPending(true)
    axios
      .post(api.url + "/get-expired-parties", { page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        console.log(response.data);
        response.data.parties.forEach((item, index) => {
          item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
        });
        setFilterItems(response.data.parties);
        setTotalRows(response.data.total);
        setIsSearch(false)
        setPending(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [token, isSearch, tableOptions]);

  const expComponent = (data) => {
    return (
      <div style={{ width: "100%", fontWeight: "bolder", padding: "10px" }}>
        <Table size="sm" bordered responsive style={{ backgroundColor: "rgb(245 245 245 / 70%)" }}>
          <tbody>
            <tr>
              <th scope="row"  style={{ width: "180px" }}>Plan</th>
              <td className="text-danger">
                <strong>{data.membershipPlan}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Settlement</th>
              <td>
                <strong>{data.settlement}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Join Date</th>
              <td style={{ width: "180px" }}>
                <strong className="text-danger">{moment(data.joinDate).format("DD/MM/YYYY")}</strong>
              </td>
              <th scope="row" style={{ width: "180px" }}>Expiry</th>
              <td style={{ width: "180px" }}>
                <strong className="text-danger">{moment(data.expiry).format("DD/MM/YYYY")}</strong>
              </td>
            </tr>
            <tr>
              <th scope="row" style={{ width: "180px" }}>Website</th>
              <td>
                <button style={{background:"none", border:"none"}} onClick={(e)=>window.open(data.website, '_blank').focus()}>{data.website}</button>
              </td>
              <th scope="row" style={{ width: "180px" }}>Catalog</th>
              <td>
                <button style={{background:"none", border:"none"}} onClick={(e)=>window.open(data.catalogLink, '_blank').focus()}>{data.catalogLink}</button>
              </td>
            </tr>
            <tr>
            <th scope="row" style={{ width: "180px" }}>Address</th>
              <td style={{ maxWidth: "220px" }}>
                <strong>{data.companyAddress}</strong>
              </td>
              <th scope="row" style={{ width: "150px" }}>Alternate No</th>
              <td style={{ width: "220px" }}>
                <strong>{data.altContact ? data.altContact : "NA"}</strong>
              </td>
            </tr>
            <tr>
            <th scope="row" style={{ width: "180px" }}>Email</th>
              <td>
                <strong>{data.email}</strong>
              </td>
              <th scope="row" style={{ width: "150px" }}>Products</th>
              <td style={{ width: "220px" }}>
                <strong>{data.productsList.join(", ")}</strong>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="row px-2">
          <PartyComments setModal={(e) => setOpenModal(e)} party={data} open={openModal} support={true} />
          <Link className="btn-round mx-1 px-4 pt-2 btn-sm btn-dark text-white" to={"/allocation/update-support/" +data._id } state={data}>Edit</Link>
        </div>
      </div>
    );
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <AnnounceMarquee />
      <EventMarquee/>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Fresh Support List</h4>
          <hr className="my-2" />
        </div>
      </div>

      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandedComponent={e => expComponent(e)}
        expandable={true}
        inputFilterValue={filterValue}
        setRows={updateSelectedRow}
        setSearchTrue={(e) => setIsSearch(true)}
        inputFilter={(e) => setFilterValue(e)}
        dataLoad={pending}
        columnsData={columns}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default ExpiredParties;
