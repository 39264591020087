import React, { useState } from "react";
import style from "./style.module.css";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { getCookie } from "../../../../Helper/Cokkies";
import { useSelector } from "react-redux";
import axios from "axios";
import { api } from "../../../../Helper/Data";

export default function AddGalleryImages() {
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const [productImages, setProductImages] = useState([])
  const [fileSelected, setFileSelected] = useState(false);
  let token = getCookie("token");

  const uploadLogoImg = (e) => {
    let loop = true
    if (e.target.files.length) {
      setProductImages(e.target.files)
      Array.from(e.target.files).forEach(item => {
        if(loop){
          if (item.size > 200000) {
            alert("Size sould less then 200 kb ");
            setProductImages([])
            setFileSelected(false)
            loop = false
          } else {
            setFileSelected(true);
          }
        }
      })
      // const files = e.target.files
      // files.map((file) => {
      //   postPhoto.push(file.name);
      //   form.append("galleryImg", file, file.name);
      //   setUploadFiles(form);
      //   reader.readAsDataURL(file);
      //   if (file.size > 200000) {
      //     alert("Size sould less then 200 kb ");
      //   } else {
      //       setProductImage(postPhoto);
      //   }
      // });
      
    } else {
      setFileSelected(false);
    }
  };

  const submitHandler = () => {
    if(fileSelected){
      let formData = new FormData()
      formData.append('company', currentCompanyUser._id)
      Array.from(productImages).forEach(item => {
        formData.append('galleryImages', item)
      })
  
      axios.post(api.url + "/add-gallery-images", formData).then(result => {
        console.log(result)
      }).catch(err => {
        console.log(err)
      })
    } else{
      alert("Please select image")
    }
  }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Add Product</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        <div className="form-row mt-4">
          <FormGroup className="col-sm-12">
            <Label className={style.formLabel} for="image">
              Product Image
            </Label>
            <Input bsSize="sm" id="image" multiple style={{ maxWidth: "50%" }} name="image" accept="image/*" onChange={(e) => uploadLogoImg(e)} type="file" />
          </FormGroup>
        </div>
        <Button type="submit" onClick={submitHandler} className="btn-sm btn-main px-4">
          Add Product
        </Button>
      </div>
    </div>
  );
}
