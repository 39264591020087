import React, { useEffect, useState } from "react";
import { api } from "../../../../Helper/Data";
import DataTableComponent from "../../../../components/DataTableComponent/DataTableComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import style from "./style.module.css";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import moment from "moment";
import { getCookie } from "../../../../Helper/Cokkies";
const axios = require("axios").default;


function BannersList() {
  const [listData, setListData] = useState([]);
  const [filterItems, setFilterItems] = useState([]);
  const token = getCookie("token");
  const currentCompanyUser = useSelector((state) => state.CompanyUser.data);
  const [pending, setPending] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [isSearch, setIsSearch] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [tableOptions, setTableOptions] = useState({ perPage: 10, pageNo: 1, sortDirection: "desc", sortField: "createdAt" });

  useEffect(() => {
    if (currentCompanyUser.user !== undefined) {
      setPending(true)
      axios
        .post(api.url + "/get-banners-for-admin", { userRole: currentCompanyUser.user.role, id: currentCompanyUser._id, page: tableOptions.pageNo, sortDirection: tableOptions.sortDirection, sortField: tableOptions.sortField, perPage: tableOptions.perPage, key: filterValue })
        .then(function (response) {
          // console.log(response);
          response.data.banner.forEach((item, index) => {
            item.serial = index + 1 + (tableOptions.perPage * tableOptions.pageNo - tableOptions.perPage);
            if (item.company == null) {
              item.company = { company: "World Web Trade" };
            }
          });
          setListData(response.data.banner);
          setFilterItems(response.data.banner);
          setTotalRows(response.data.total);
          setIsSearch(false)
          setPending(false)
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [currentCompanyUser, isSearch, tableOptions]);

  const columns = [
    {
      name: <strong>S.No.</strong>,
      selector: (row) => row.serial,
      width: "65px",
    },
    {
      name: <strong>BANNER IMAGE</strong>,
      selector: (row) => (
        <div className={style.bannerImg}>
          <img src={api.bannerUrl + row.bannerImg} alt="" />
        </div>
      ),
      width: "250px",
    },
    {
      name: <strong>Created By</strong>,
      selector: (row) => row.createdBy,
    },
    {
      name: <strong>COMPANY</strong>,
      selector: (row) => row.company.company,
    },
    {
      name: <strong>ADD ON</strong>,
      selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
      sortable: true,
      width: "120px",
      sortField: "createdAt",
    },
    {
      selector: (row) => (
        <IconButton className={style.actionBtn} onClick={() => deleteItem(row._id)} color="primary" size="small">
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>
      ),
      button: true,
      width: "65px",
    },
  ];

  const deleteItem = (id) => {
    axios
      .post(api.url + "/delete-banner", { id }, { headers: { authorization: "Bearer " + token } })
      .then(function (response) {
        if (response.data.success) {
          alert("Delete Successfull");
          const newListData = listData.filter((el) => {
            return el._id !== id
          })
          setListData(newListData)
          setFilterItems(newListData)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateSelectedRow =(e)=>{ }

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Product List</h4>
          <hr className="my-2" />
        </div>
      </div>


      <DataTableComponent
        pageChange={(e) => { setTableOptions({ ...tableOptions, pageNo: e }) }}
        perRowsChange={(e) => setTableOptions({ ...tableOptions, perPage: e.perPage, pageNo: 1 })}
        setHandleSort={(e) => setTableOptions({ ...tableOptions, sortDirection: e.sortDirection, sortField: e.sortField })}
        expandable={false}
        inputFilterValue={filterValue}
        setSearchTrue={(e)=>setIsSearch(true)}
        inputFilter={(e)=>setFilterValue(e)}
        columnsData={columns}
        setRows={updateSelectedRow}
        dataLoad={pending}
        totalRowsData={totalRows}
        data={filterItems} />
    </div>
  );
}

export default BannersList;
