import React, { useEffect, useState } from "react";
import { Button, FormGroup, FormText, Input, Label } from "reactstrap";
import style from "./style.module.css";
import { api } from "../../../../Helper/Data";
import { getCookie } from "../../../../Helper/Cokkies";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SelectJS from "../../../../components/Select/SelectJS";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  formValidation,
  userValidate,
} from "../../../../components/Utils/Utils";
import moment from "moment";
import { TextField } from "@mui/material";
const axios = require("axios").default;

function InvoiceGenertor() {
  const navigate = useNavigate();
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [formData, setFormData] = useState({
    _id: "",
    company: "",
    companyAddress: "",
    gstn: "",
    pan: "",
    invoiceItems :[{ desc: "", amount: 0 }],
    createdAt : (new Date()).toISOString()
  });
  const [companiesList, setCompaniesList] = useState([]);
  const verify_admin_hr = userValidate(["admin", "hr"], "token");
  let token = getCookie("token");

  useEffect(() => {
    if (verify_admin_hr) {
      axios
        .post(api.url + "/get-all-companies", { id: "id" })
        .then(function (response) {
          // console.log(response.data);
          const selectCompanies = response.data.companies.map((el) => {
            return { value: el, label: el.company };
          });
          setCompaniesList(selectCompanies);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [verify_admin_hr]);

  const getSelected = (select) => {
    setFormData({
      ...formData,
      _id: select.value._id,
      company: select.value.company,
      companyAddress: select.value.companyAddress,
      gstn: select.value.gstn,
    });
  };

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const generateInvoice = () => {
    axios
      .post(api.url + "/generate-invoice", formData, {
        headers: { authorization: "Bearer " + token },
      })
      .then(function (response) {
        if (response.data.success) {
          alert("Add Successfull");
          navigate("/admin/invoice-list");
        }
      })
      .catch(function (error) {
        console.log(error);
        setIsSubmiting(false);
        alert(error.response.data.message);
        console.log(error.response.data.message);
      });
  };

  const addItem = () => {
    let itemArray = formData.invoiceItems
    itemArray.push({ desc: "", amount: 0 })
    setFormData({ ...formData, invoiceItems: itemArray })
}

const removeItem = () => {
    let itemArray = formData.invoiceItems
    itemArray.pop()
    setFormData({ ...formData, invoiceItems: itemArray })
}

const changeHandlerItem = (e, field, index) => {
  let newArray = formData.invoiceItems.map((el, i) => {
      if (index === i) {
          return { ...el, [field]: e.target.value }
      } else {
          return el
      }
  });
  setFormData({ ...formData, invoiceItems: newArray })
}

  return (
    <div>
      <div className="content-header p-0">
        <div className="container-fluid">
          <h4 className="m-0 text-dark">Generate Invoice</h4>
          <hr className="my-2" />
        </div>
      </div>
      <div className="container px-4">
        {/* Add Categories */}
        <div>
          <div className="form-row mt-4">
            <FormGroup className="col-md-6">
              <Label className={style.formLabel}>
                Bill To
                <small>
                  <sup className="text-danger"> (Required)</sup>
                </small>
              </Label>
              {companiesList.length ? (
                <SelectJS
                  sm={"sm"}
                  defValue={""}
                  data={companiesList}
                  selected={getSelected}
                />
              ) : (
                <SelectJS
                  sm={"sm"}
                  defValue={""}
                  data={[{ value: "", label: "Loading..." }]}
                />
              )}
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                Company
                <small>
                  <sup className="text-danger"> Required</sup>
                </small>
                <small>
                  <sub> Same as GST</sub>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="company"
                name="company"
                value={formData.company}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter Comapny Name"}
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                GSTIN
                <small>
                  <sup className="text-danger"> Required</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="gstn"
                name="gstn"
                value={formData.gstn}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter GSTIN"}
              />
            </FormGroup>

            <FormGroup className="col-md-6">
              <Label className={style.formLabel} for="email">
                PAN
                <small>
                  <sup className="text-danger"> Required</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="pan"
                name="pan"
                value={formData.pan}
                onChange={(e) => changeHandler(e)}
                placeholder={"Enter PAN"}
              />
            </FormGroup>
            <FormGroup className="col-md-6">
            <LocalizationProvider className="m-2" dateAdapter={AdapterMoment}>
                <DateTimePicker
                className={style.datePicker}
                  label="Invoice Date"
                  renderInput={(params) => <TextField {...params} />}
                  value={formData.createdAt}
                  onChange={(newValue) => {
                    setFormData({
                      ...formData,
                      createdAt: moment(newValue._d).format(),
                    });
                  }}
                />
              </LocalizationProvider>
            </FormGroup>

            <FormGroup className="col-md-12">
              <Label className={style.formLabel} for="companyAddress">
                Address{" "}
                <small>
                  <sup className="text-danger">(Required)</sup>
                </small>
              </Label>
              <Input
                bsSize="sm"
                id="companyAddress"
                style={{ minHeight: "50px" }}
                value={formData.companyAddress}
                onChange={(e) => changeHandler(e)}
                name="companyAddress"
                placeholder={"Enter product details"}
                type="textarea"
                minLength={50}
              />
            </FormGroup>

            {formData.invoiceItems.map((el, i) => {
              return (
                <span key={i} className="row m-0 w-100">
                  <FormGroup className="col-md-9">
                    <Label className={style.formLabel} for={"desc-"+i}>
                    {i+1}. Item Description
                      <small>
                        <sup className="text-danger"> Required</sup>
                      </small>
                    </Label>
                    <Input
                      bsSize="sm"
                      id={"desc-"+i}
                      name={"desc-"+i}
                      value={el.desc}
                      onChange={(e) =>
                        changeHandlerItem(e, "desc", i)
                      }
                      placeholder={"Enter Description"}
                    />
                  </FormGroup>
                  <FormGroup className="col-md-3">
                    <Label className={style.formLabel} for={"amount-"+i}>
                      Invoice Amount
                      <small>
                        <sup className="text-danger"> Required</sup>
                      </small>
                    </Label>
                    <Input
                      bsSize="sm"
                      id={"amount-"+i}
                      name={"amount-"+i}
                      value={el.amount}
                      onChange={(e) =>  
                        changeHandlerItem(e, "amount", i)
                      }
                      placeholder={"Enter Amount"}
                      type="number"
                    />
                  </FormGroup>
                </span>
              );
            })}
            <div className="w-100 align-end">
              {formData.invoiceItems.length < 3? <Button className="py-0 btn-success float-right mr-3" onClick={(e)=> addItem(e)}>+</Button> : ""}
              {formData.invoiceItems.length > 1? <Button className="py-0 btn-danger float-right mr-1" onClick={(e)=> removeItem(e)}>-</Button> : ""}
            </div>
          </div>
          <Button
            disabled={isSubmiting}
            type="submit"
            onClick={generateInvoice}
            className="btn-sm btn-main px-4"
          >
            Generate Invoice
          </Button>
        </div>
      </div>
    </div>
  );
}

export default InvoiceGenertor;
